define(
	'pagecontrollers/property-listing/views/GridItemView',[
		'backbone',
		'utils/AbstractView',

		'text!../templates/GridItemTemplate.html',
		'text!assets/svg/detail-carousel-arrow-left.svg',
		'text!assets/svg/detail-carousel-arrow-right.svg',
		'text!../templates/GridItemLMTemplate.html',
	], 
	function(Backbone, AbstractView, Template, ArrowLeftSVG, ArrowRightSVG, TemplateLM){

		var GridItemView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			options: null,
			abstractView: null,

			// options

			index: 0,

			// config

			autoShow: false,

			// flags

			isShowing: false,
			isListening: false,
			isFirstShow: true,

			// vars

			$veil: null,
			$gallery: null,
			$arrowsContainer: null,
			$leftArrow: null,
			$rightArrow: null,
			$favorite: null,

			$galleryImages: null,
			$currentGalleryImage: null,

			galleryRatio: 1.67,
			galleryIndex: 0,

			containerWidth: 0,

			// init

			initialize: function(options){

				var self = this;

				self.options = options;
				self.$container = self.options.$container;
				self.el = self.options.el;
				self.index = self.options.index;

				//

				var galleryWidth = self.$container.data('gallery-width');
				var galleryHeight = self.$container.data('gallery-height');

				if(galleryWidth && galleryHeight){ self.galleryRatio = galleryWidth / galleryHeight; }

				//

				if(Utils.DOMUtils.getURLParameter('page') == 'property_listing_lm') {
					self.template = _.template(TemplateLM);
				}
				else {
					self.template = _.template(Template);
				}


				self.model = $.extend({
					ArrowLeftSVG: ArrowLeftSVG,
					ArrowRightSVG: ArrowRightSVG,
				}, self.model);

				// 

				self.abstractView = new AbstractView({
					view: self,
					autoShow: true
				});
			},

			onDomReady: function(){

				var self = this;
				self.$veil = self.$el.find('figure.veil');
				self.$gallery = self.$el.find('.gallery');
				self.$galleryImages = self.$gallery.find('li');
				self.$arrowsContainer = self.$el.find('.arrow-buttons-container');
				self.$leftArrow = self.$arrowsContainer.find('.arrow-button.left');
				self.$rightArrow = self.$arrowsContainer.find('.arrow-button.right');
				self.$favorite = self.$el.find('.favorite');

				self.$currentGalleryImage = $(self.$galleryImages[0]);
			},

			onAddListeners: function(){

				var self = this;

				self.$el.on('mouseenter', $.proxy(self._onMouseEnter, self));
				self.$el.on('mouseleave', $.proxy(self._onMouseLeave, self));
				self.$el.on('click', $.proxy(self._onPropertyClick, self));

				self.$leftArrow.on('click', $.proxy(self._onArrowClick, self));
				self.$rightArrow.on('click', $.proxy(self._onArrowClick, self));
				self.$favorite.on('click', $.proxy(self._onFavoriteClick, self));

				var options = { preventDefault: true };
				self.$el.hammer(options).on('swiperight', $.proxy(self._onSwipe, self));
				self.$el.hammer(options).on('swipeleft', $.proxy(self._onSwipe, self));
			},

			_onPropertyClick:function(){
				var self = this;
				var urlDetail = self.$el.find('.url-detail').attr('href');
				window.location.href = urlDetail;
			},

			//

			_onMouseEnter: function(e){

				var self = this;
				self.showArrows();
			},

			_onMouseLeave: function(e){

				var self = this;
				self.hideArrows();
			},

			_onSwipe: function(e){

				var self = this;

				if(e.type === 'swiperight'){
					self.prevItem();
				}
				else if(e.type === 'swipeleft'){
					self.nextItem();
				}
			},

			_onArrowClick: function(e){

				var self = this;
				var $target = $(e.currentTarget);

				if($target.hasClass('left')){ self.prevItem(); }
				else { self.nextItem(); }

				e.stopImmediatePropagation();
				e.preventDefault();
				return false;
			},

			_onFavoriteClick: function (e) {

				console.log("favorite clicked", e.currentTarget);

				var self = this;
				if ($("#userIsLogged").length > 0) {

					var propertyId = self.$favorite[0].id;
					var $target = $(e.currentTarget);
					var data = {};
					data["propertyId"] = propertyId;
					window.$vent.trigger('showSpinner', [$target, self]);

					$.ajax({
						type: 'POST',
						url: '/user/toggleFavorite',
						data: JSON.stringify(data),
						async: true,
						contentType: "application/json; charset=utf-8",
						success: function (response) {
							window.$vent.trigger('hideSpinner');
							if (response.success) {
								$target.toggleClass('selected');
							} else if (response.error) {
								console.log("success error",response);
							}else{
								console.log("something went wrong to save session");
							}
						},
						error: function (e) {
							console.log("favorite not created");
							window.$vent.trigger('hideSpinner');
						}

					});
				}
				else {
					window.$vent.trigger('openModal', {modalId: '/global/login'});
				}

				e.preventDefault();
				return false;
			},

			//

			nextItem: function(){

				var self = this;
				var newIndex = self.galleryIndex+1;

				self.seekByIndex(newIndex);
			},

			prevItem: function(){

				var self = this;
				var newIndex = self.galleryIndex-1;

				self.seekByIndex(newIndex);
			},

			seekByIndex: function(index){

				var self = this;

				if(self.galleryIndex != index){
					self.changeGalleryImageByIndex(index);
				}
			},

			//

			changeGalleryImageByIndex: function(newIndex){

				var self = this;
				var lastIndex = self.galleryIndex;
				var $lastGalleryImage = self.$currentGalleryImage;

				var direction = (newIndex > self.galleryIndex) ? 1 : -1;

				if(newIndex >= self.$galleryImages.length){ newIndex = 0; }
				else if(newIndex < 0){ newIndex = self.$galleryImages.length-1; }

				self.galleryIndex = newIndex;
				self.$currentGalleryImage = $(self.$galleryImages[newIndex]);

				//Change the src
				var src = self.$currentGalleryImage.find('img').attr('src');
				var newSrc = self.$currentGalleryImage.find('img').attr('data-original');
				if(!src){
					self.$currentGalleryImage.find('img').attr('src', newSrc ? newSrc : '');
				}

				TweenMax.to($lastGalleryImage, 0.3, {x:0-self.containerWidth * direction, force3D:true, ease:Sine.easeIn});
				TweenMax.to($lastGalleryImage, 0.4, {x:0-self.containerWidth * direction, delay:0.2, ease:Cubic.easeOut, onComplete:function(){
					$lastGalleryImage.removeClass('hidden').addClass('hidden');
				}});

				TweenMax.fromTo(self.$currentGalleryImage, 0.3, {x:self.containerWidth * direction, force3D:true}, {x:0, ease:Sine.easeIn});
				TweenMax.to(self.$currentGalleryImage, 0.4, {x:0, delay:0.2, ease:Cubic.easeOut});
				self.$currentGalleryImage.removeClass('hidden');


			},

			// show / hide arrows

			showArrows: function(){

				var self = this;
				TweenMax.set(self.$arrowsContainer, {opacity:1});
			},

			hideArrows: function(){

				var self = this;
				TweenMax.set(self.$arrowsContainer, {opacity:0});
			},

			// show / hide white veil

			showVeil: function(){

				var self = this;
				
				TweenMax.killTweensOf(self.$veil);
				TweenMax.fromTo(self.$veil, 0.6, {opacity:0}, {opacity:1, ease:Cubic.easeOut});
				self.$veil.removeClass('hidden');
			},

			hideVeil: function(){

				var self = this;

				TweenMax.killTweensOf(self.$veil);
				TweenMax.to(self.$veil, 0.6, {opacity:0, ease:Cubic.easeOut, onComplete:function(){
					self.$veil.removeClass('hidden').addClass('hidden');
				}});
			},

			onWindowResize: function(){

				var self = this;
				var winWidth = window.innerWidth;

				self.containerWidth = parseInt(self.$container.width());

				if(winWidth >= 768){ 
					self.containerWidth = Math.floor((self.containerWidth-1)/2); 
					TweenMax.set(self.$el, {width:self.containerWidth});
				}
				else {					
					TweenMax.set(self.$el, {clearProps:'width'});
				}

				TweenMax.set(self.$gallery, {width:self.containerWidth, height:parseInt(self.containerWidth/self.galleryRatio)});
			},

			//

			onShow: function(){
				var self = this;
				self.trigger('showComplete');
			}	

		});

	return GridItemView;
});
