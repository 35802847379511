define(
    'modules/validation-forms/app',[
        'backbone',
        'validate',
        'text!../../config/form-messagesEs.json',
        'text!../../config/form-messagesEn.json',
    ],
    function (Backbone, validate, messagesEs, messagesEn) {

        var AppView = Backbone.View.extend({

            // core vars
            lang: null,
            forms: null,
            formsConfig: null,
            formConfig: null,
            formName: null,

            $context: null,

            // init

            initialize: function (options) {

                window.$vent.trigger('validationFormReady');

                var self = this;
                self.$context = $;

            },

            // start module --------------------------------------------------------------------  /

            start: function () {

                var self = this;
                $(function () {
                    self.lang = $('#lang').text();

                    self.onDomReady();
                });


            },

            onDomReady: function () {
                console.log("onDomReady");

                var self = this;

                window.$vent.on('formLoaded', $.proxy(self.onValidateForms, self));

                self.onValidateForms();


            },

            onValidateForms: function () {
                var self = this;

                //Get the forms in the page
                self.forms = $("form:not(.skip-validation)");
                console.log("forms to validate", self.forms);

                if (self.forms.length > 0) {

                    //Get the messages
                    self.getMessagesAndConf();

                    self.forms.each(function (i, form) {

                        if ($(form).attr('data-form-name')) {
                            self.formName = $(form).attr('data-form-name');
                        } else {
                            self.formName = $(form).find(':button:visible').attr('data-form-name') ? $(form).find(':button:visible').attr('data-form-name') : 'default';
                        }

                        var initialForm = self.formName;

                        //Get config for this form
                        console.log('self.formName', self.formName);
                        self.formName.toString();
                        self.formConfig = self.formsConfig[self.formName];

                        //Add listener for forms in modals
                        var button = $(form).find('.submitButtonModal');
                        if (button) {
                            $(button).on('click', function (e) {
                                console.log('submitButtonModal clicked', $(form));

                                if ($(form).valid() == true) {
                                    console.log('submitButtonModal form is valid');
                                    //Get config for this form
                                    if ($(e.currentTarget).attr('data-form-name')) {
                                        self.formName = $(e.currentTarget).attr('data-form-name');
                                        console.log('self.formName', self.formName);
                                        self.formName.toString();
                                        self.formConfig = self.formsConfig[self.formName];
                                    }

                                    window.$vent.trigger('showSpinner', e.currentTarget);
                                    var current_formaction = $(form).attr('action');
                                    var current_formtarget = $(form).attr('target');
                                    var formaction = $(e.currentTarget).attr('data-form-action');
                                    var formtarget = $(e.currentTarget).attr('data-form-target');
                                    if (formaction) {
                                        $(form).attr('action', formaction);
                                    }
                                    if (formtarget) {
                                        $(form).attr('target', formtarget);
                                    }

                                    if (self.formConfig.method != 'noAjaxSubmit') {
                                        self.submitAjax(form);
                                    } else {
                                        $("form :disabled").removeAttr('disabled');
                                        form.submit();
                                        if (!current_formtarget) {
                                            form.removeAttribute('target');
                                        } else {
                                            $(form).attr('target', current_formtarget);
                                        }
                                        if (!current_formtarget) {
                                            form.removeAttribute('action');
                                        } else {
                                            $(form).attr('action', current_formaction);
                                        }
                                        self.formConfig = self.formsConfig[initialForm];
                                        return false;
                                    }
                                }
                            });
                        }

                        if (jQuery().validate) {
                            self.validateForm(form);
                        } else {
                            throw new Error("Validation plugin is not upload");
                        }
                    });
                }

            },

            getMessagesAndConf: function () {
                var self = this;

                if (self.lang == "es") {
                    self.formsConfig = JSON.parse(messagesEs);

                } else {
                    self.formsConfig = JSON.parse(messagesEn);
                }
            },

            validateForm: function (form) {
                var self = this;

                console.log('form to validate', $(form));

                $('[name="select_country"]').change(function() {
                    var countrySelected = $(this).val();

                    if (countrySelected == '73') {
                        $('[name="state_text"]').addClass('ignore');
                        $('#region-text-input').addClass('hide');
                        $('[name="state_text"]').prop('disabled', true);
                        $('#region-select-input').removeClass('hide');
                        $('#region-select-inputSelectBoxIt').removeClass('selectboxit-disabled');
                        $('[name="state_select"]').prop('disabled', false);
                    } else if (countrySelected  != '') {

                        $('[name="state_text"]').removeClass('ignore');
                        $('#region-select-input').addClass('hide');
                        $('#region-select-inputSelectBoxIt').addClass('selectboxit-disabled');
                        $('[name="state_select"]').prop('disabled', true);
                        $('#region-text-input').removeClass('hide');
                        $('[name="state_text"]').prop('disabled', false);
                    }
                }).change();


                //Plugin settings
                $(form).validate({
                    ignore: '.ignore',
                    rules: {
                        name: {
                            required: self.formConfig.isNameRequired,
                            minlength: self.formConfig.minLengthName
                        },
                        first_name: {
                            required: self.formConfig.isFirstNameRequired,
                            minlength: self.formConfig.minLengthFirstName
                        },
                        last_name: {
                            required: self.formConfig.isLastNameRequired,
                            minlength: self.formConfig.minLengthLastName
                        },
                        password: {
                            required: self.formConfig.isPasswordRequired,
                            minlength: self.formConfig.minLengthPassword
                        },
                        doc_type: {
                            required: self.formConfig.isDocTypeRequired
                        },
                        doc_number: {
                            required: self.formConfig.isDocNumberRequired,
                            minlength: self.formConfig.minLengthDocNumber
                        },
                        occupant_first_name: {
                            required: self.formConfig.isFirstNameRequired,
                            minlength: self.formConfig.minLengthFirstName
                        },
                        occupant_last_name: {
                            required: self.formConfig.isLastNameRequired,
                            minlength: self.formConfig.minLengthLastName
                        },
                        occupant_doc_type: {
                            required: self.formConfig.isDocTypeRequired
                        },
                        occupant_doc_number: {
                            required: self.formConfig.isDocNumberRequired,
                            minlength: self.formConfig.minLengthDocNumber
                        },
                        address_street: {
                            required: self.formConfig.isAddressStreetRequired,
                            minlength: self.formConfig.minLengthAddressStreet
                        },
                        address_number: {
                            required: self.formConfig.isAddressNumberRequired
                        },
                        address_floor: {
                            required: self.formConfig.isAddressFloorRequired
                        },
                        address_city: {
                            required: self.formConfig.isAddressCityRequired,
                            minlength: self.formConfig.minLengthAddressCity
                        },
                        state_text: {
                            required: self.formConfig.isStateTextRequired,
                            minlength: self.formConfig.minLengthStateText
                        },
                        state_select: {
                            required: self.formConfig.isStateSelectRequired
                        },
                        address_zip: {
                            required: self.formConfig.isAddressZipRequired,
                            minlength: self.formConfig.minLengthAddressZip
                        },
                        select_country: {
                            required: self.formConfig.isSelectCountryRequired
                        },
                        occupant_select_country: {
                            required: self.formConfig.isSelectCountryRequired
                        },
                        prefix: {
                            required: self.formConfig.isPrefixRequired
                        },
                        occupant_prefix: {
                            required: self.formConfig.isPrefixRequired
                        },
                        prefix_2: {
                            minlength: self.formConfig.isPrefix2Required
                        },
                        phone_number: {
                            required: self.formConfig.isPhoneNumberRequired,
                            digits: self.formConfig.isDigitsType,
                            minlength: self.formConfig.minLengthPhoneNumber
                        },
                        occupant_phone_number: {
                            required: self.formConfig.isPhoneNumberRequired,
                            digits: self.formConfig.isDigitsType,
                            minlength: self.formConfig.minLengthPhoneNumber
                        },
                        phone_number_2: {
                            required: self.formConfig.isPhoneNumber2Required,
                            digits: self.formConfig.isDigitsType,
                            minlength: self.formConfig.minLengthPhoneNumber2
                        },
                        select_language: {
                            required: self.formConfig.isSelectLanguageRequired
                        },
                        comments: {
                            required: self.formConfig.isCommentsRequired,
                            minlength: self.formConfig.minLengthComments
                        },
                        email: {
                            required: self.formConfig.isEmailRequired,
                            email: self.formConfig.isEmailType
                        },
                        occupant_email: {
                            required: self.formConfig.isEmailRequired,
                            email: self.formConfig.isEmailType
                        },
                        phone: {
                            required: self.formConfig.isPhoneRequired,
                            digits: self.formConfig.isDigitsType,
                            minlength: self.formConfig.minLengthPhone
                        },
                        method: {
                            required: self.formConfig.isMethodRequired,
                            minlength: self.formConfig.minLengthMethod
                        },
                        current_password: {
                            required: self.formConfig.isCurrentPasswordRequired,
                            minlength: self.formConfig.minLengthPassword
                        },
                        new_password: {
                            required: self.formConfig.isNewPasswordRequired,
                            minlength: self.formConfig.minLengthPassword
                        },
                        confirm_new_password: {
                            required: self.formConfig.isConfirmPasswordRequired,
                            minlength: self.formConfig.minLengthPassword,
                            equalTo: self.formConfig.equalToPassword
                        },
                        confirm_password: {
                            required: self.formConfig.isConfirmPasswordRequired,
                            minlength: self.formConfig.minLengthPassword,
                            equalTo: self.formConfig.equalToPassword
                        }
                    },
                    messages: {
                        name: {
                            required: self.formConfig.requiredMessage,
                            minlength: self.formConfig.minLengthNameMessage
                        },
                        first_name: {
                            required: self.formConfig.requiredMessage,
                            minlength: self.formConfig.minLengthFirstNameMessage
                        },
                        last_name: {
                            required: self.formConfig.requiredMessage,
                            minlength: self.formConfig.minLengthLastNameMessage
                        },
                        password: {
                            required: self.formConfig.requiredMessage,
                            minlength: self.formConfig.minLengthPasswordMessage
                        },
                        doc_type: {
                            required: self.formConfig.requiredMessage
                        },
                        doc_number: {
                            required: self.formConfig.requiredMessage,
                            minlength: self.formConfig.minLengthDocNumberMessage
                        },
                        occupant_first_name: {
                            required: self.formConfig.requiredMessage,
                            minlength: self.formConfig.minLengthFirstNameMessage
                        },
                        occupant_last_name: {
                            required: self.formConfig.requiredMessage,
                            minlength: self.formConfig.minLengthLastNameMessage
                        },
                        occupant_doc_type: {
                            required: self.formConfig.requiredMessage
                        },
                        occupant_doc_number: {
                            required: self.formConfig.requiredMessage,
                            minlength: self.formConfig.minLengthDocNumberMessage
                        },
                        address_street: {
                            required: self.formConfig.requiredMessage,
                            minlength: self.formConfig.minLengthAddressStreetMessage
                        },
                        address_number: {
                            required: self.formConfig.requiredMessage
                        },
                        address_floor: {
                            required: self.formConfig.requiredMessage
                        },
                        address_city: {
                            required: self.formConfig.requiredMessage,
                            minlength: self.formConfig.minLengthAddressCityMessage
                        },
                        state_text: {
                            required: self.formConfig.requiredMessage,
                            minlength: self.formConfig.minLengthStateTextMessage
                        },
                        state_select: {
                            required: self.formConfig.requiredMessage
                        },
                        address_zip: {
                            required: self.formConfig.requiredMessage,
                            minlength: self.formConfig.minLengthAddressZipMessage
                        },
                        select_country: {
                            required: self.formConfig.requiredMessage
                        },
                        occupant_select_country: {
                            required: self.formConfig.requiredMessage
                        },
                        prefix: {
                            required: self.formConfig.requiredMessage
                        },
                        prefix_2: {
                            minlength: self.formConfig.requiredMessage
                        },
                        phone_number: {
                            required: self.formConfig.requiredMessage,
                            digits: self.formConfig.digitsTypeMessage,
                            minlength: self.formConfig.minLengthPhoneNumberMessage
                        },
                        phone_number_2: {
                            required: self.formConfig.requiredMessage,
                            digits: self.formConfig.digitsTypeMessage,
                            minlength: self.formConfig.minLengthPhoneNumber2Message
                        },
                        occupant_prefix: {
                            required: self.formConfig.requiredMessage
                        },
                        occupant_phone_number: {
                            required: self.formConfig.requiredMessage,
                            digits: self.formConfig.digitsTypeMessage,
                            minlength: self.formConfig.minLengthPhoneNumberMessage
                        },
                        select_language: {
                            required: self.formConfig.requiredMessage
                        },
                        comments: {
                            required: self.formConfig.requiredMessage,
                            minlength: self.formConfig.minLengthCommentsMessage
                        },
                        email: {
                            required: self.formConfig.requiredMessage,
                            email: self.formConfig.emailTypeMessage
                        },
                        occupant_email: {
                            required: self.formConfig.requiredMessage,
                            email: self.formConfig.emailTypeMessage
                        },
                        phone: {
                            required: self.formConfig.requiredMessage,
                            digits: self.formConfig.digitsTypeMessage,
                            minlength: self.formConfig.minLengthPhoneMessage
                        },
                        method: {
                            required: self.formConfig.requiredMessage,
                            minlength: self.formConfig.minLengthMethodMessage
                        },
                        current_password: {
                            required: self.formConfig.requiredMessage,
                            minlength: self.formConfig.minLengthPasswordMessage
                        },
                        new_password: {
                            required: self.formConfig.requiredMessage,
                            minlength: self.formConfig.minLengthPasswordMessage
                        },
                        confirm_new_password: {
                            required: self.formConfig.requiredMessage,
                            minlength: self.formConfig.minLengthPasswordMessage,
                            equalTo: self.formConfig.equalToPasswordMessage
                        },
                        confirm_password: {
                            required: self.formConfig.requiredMessage,
                            minlength: self.formConfig.minLengthPasswordMessage,
                            equalTo: self.formConfig.equalToPasswordMessage
                        }
                    },
                    errorPlacement: function (error, element) {
                        if (element.is('select')) {
                            error.addClass('select-error');
                            error.appendTo(element.parent("div"));
                        } else {
                            error.insertAfter(element);
                        }
                    },
                    submitHandler: function (form) {
                        //Show spinner
                        if (self.formConfig.showSpinner) {
                            var button = $(form).find('.spinner-container');
                            window.$vent.trigger('showSpinner', button);
                        }

                        console.log("submitHandler self.formConfig", self.formConfig);

                        if (self.formConfig.method != 'noAjaxSubmit') {
                            self.submitAjax(form);
                        } else {
                            $("form :disabled").removeAttr('disabled');
                            form.submit();
                            return false;
                        }

                    }
                });
            },

            showSuccesMessage: function (form) {
                var self = this;
                var $form = $(form);
                $.proxy(self.onValidateForms(), self);

                var succesMessage = self.formConfig.succesMessage;
                var container = $form.find(".messagesContainer");
                console.log('messagesContainer', container);
                console.log('succesMessage', succesMessage);
                if (container.hasClass('sent') || container.hasClass('unsent')) {
                    container.removeClass('sent');
                    container.removeClass('unsent');
                }
                container.addClass("sent");

                TweenMax.to(container, 0.3, {
                    opacity: 0, ease: Cubic.easeInOut, onComplete: function () {

                        container.html(succesMessage);
                        TweenMax.to(container, 0.3, {opacity: 1, ease: Cubic.easeOut});

                    }
                });
            },

            showErrorMessage: function (form, specificMessageError) {
                var self = this;
                var $form = $(form);
                var errorMessage;

                if (!specificMessageError) {
                    errorMessage = self.formConfig.errorMessage;
                } else {
                    errorMessage = specificMessageError;
                }
                var container = $form.find(".messagesContainer");

                if (container.hasClass('sent') || container.hasClass('unsent')) {
                    container.removeClass('sent');
                    container.removeClass('unsent');
                }
                container.addClass("unsent");

                TweenMax.to(container, 0.3, {
                    opacity: 0, ease: Cubic.easeInOut, onComplete: function () {

                        container.html(errorMessage);
                        TweenMax.to(container, 0.3, {opacity: 1, ease: Cubic.easeOut});

                        /*window.$vent.trigger('domUpdate');*/
                    }
                });

                /*container.html(errorMessage);*/
            },

            submitAjax: function (form) {
                console.log('submitAjax entered');
                var self = this;
                var data = {};
                var $form = $(form);
                var formData = $form.serializeArray();
                if ($(form).attr('data-form-name')) {
                    self.formName = $(form).attr('data-form-name');
                } else {
                    self.formName = $(form).find(':button:visible').attr('data-form-name') ? $(form).find(':button:visible').attr('data-form-name') : 'default';
                }
                self.formName.toString();
                self.formConfig = self.formsConfig[self.formName];

                var url = self.formConfig.url ? self.formConfig.url : $(':button').attr('formaction');
                var specificSubmitMethod = self.formConfig.specificSubmitMethod;

                $.each(formData, function () {
                    if (formData[this.name]) {
                        if (!data[this.name].push) {
                            data[this.name] = [data[this.name]];
                        }
                        data[this.name].push(this.value || '');
                    } else {
                        data[this.name] = this.value || '';
                    }
                });

                if ($('[data-bankTransfer="true"]').hasClass('selected')) {
                    data["bank_transfer"] = true
                }

                //data = JSON.stringify(data);
                $.ajax({
                    url: url,
                    type: 'POST',
                    contentType: "application/json",
                    data: JSON.stringify(data),
                    dataType: "JSON", /*este es el tipo de dato que espera que le llegue, sino es tipo json da error la peticion ajax*/
                    success: function (response, jqXHR, textStatus, errorThrown) {
                        console.log('submit sucessful', response);
                        console.log('submit error', jqXHR);
                        console.log('submit error', textStatus);
                        console.log('submit error', errorThrown);
                        var message;
                        var urlRedirect;
                        var paymentGateway;
                        /* Reset Form */
                        $form.validate().resetForm();
                        window.$vent.trigger('hideSpinner');
                        if (self.formConfig.formReset == 'true') {
                            $form[0].reset();
                        }
                        if (response.result) {
                            message = response.result.message;
                            urlRedirect = response.result.urlRedirect;
                            paymentGateway = response.result.paymentGateway;
                        }

                        if (specificSubmitMethod && (!response.error || response.error == 'OK')) {
                            console.log("triggering submit method", specificSubmitMethod);
                            window.$vent.trigger(specificSubmitMethod, [{
                                sucessMessage: self.formConfig.succesMessage,
                                message: message,
                                urlRedirect: urlRedirect,
                                paymentGateway: paymentGateway
                            }]);
                        } else if (response.error) {
                            var errorMessage = response.error;
                            self.showErrorMessage($form,errorMessage);

                        }
                        else {
                            console.log('showSuccesMessage');
                            self.showSuccesMessage($form);
                        }
                    },
                    error: function (jqXHR, textStatus, errorThrown) {
                        console.log("jqXHR", jqXHR);
                        console.log("textStatus", textStatus);
                        console.log("errorThrown", errorThrown);
                        window.$vent.trigger('hideSpinner');
                        $form.validate().resetForm();
                        self.showErrorMessage($form);
                    }

                });

                return false;
            },

        });

        return AppView;
    }
);

