define(
	'modules/reservation-sticky-nav/app',[
		'backbone',
		'modules/reservation-sticky-nav/views/ReservationStickyNavView',
	], 
	function(Backbone, ReservationStickyNavView){

		var AppView = Backbone.View.extend({

			// vars

			stickyNavView: null,

			// init

			initialize: function(options){

				var self = this;
			},

			// start module --------------------------------------------------------------------  /

			start: function(){

				var self = this;
				window.$vent.trigger('resStickyNavReady');

				$(function(){
					self.onDomReady();
					self.onAddListeners();
				});
			},

			onAddListeners: function(){

				var self = this;
			},

			onDomReady: function(){

				var self = this;

				self.$el = $("#mobile-reservation-sticky-nav");

				var $stickyNav = self.$el;

				self.stickyNavView = new ReservationStickyNavView({
					el: $stickyNav,
					reservationContainer: $("#reservation-page")
				});

			},
			
			// ---------------------------------------------------------------------------------  /

		});

		return AppView;
	}
);
