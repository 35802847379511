define(
    'modules/guests/views/GuestsMenuView',[
        'backbone',
        'utils/AbstractView',
        'text!../templates/GuestsMenuTemplate.html',
    ],
    function (Backbone, AbstractView, Template) {

        var GuestsMenuView = Backbone.View.extend({

            // core vars

            $el: null,
            $container: null,
            $mobileContainer: null,
            options: null,
            abstractView: null,

            // vars

            serverQuery: null,
            shortResults: null,

            $closeButton: null,

            // init

            initialize: function (options) {
                var self = this;

                self.options = options;
                self.$container = self.options.$container;
                self.$mobileContainer = self.options.$mobileContainer;
                self.el = self.options.el;
                self.selectedGuests = self.options.selectedGuests;

                self.template = _.template(Template);
                self.model = $.extend({}, self.model);

                //

                self.abstractView = new AbstractView({
                    view: self,
                    autoShow: false,
                    hideClass: 'display-none',
                });

                console.log('GuestsMenuView', self.$container);

                //

                $(window).on('resize', $.proxy(self._onWindowResize, self));
                self._onWindowResize();
            },

            // render

            onRender: function () {
                var self = this;

                // calculate total guests

                self.$closeButton = self.$el.find('.fa.close').add(self.$el.find('.close-btn'));

            },

            // listeners

            onAddListeners: function () {

                var self = this;

                self.$el.find('li button.remove').on('click', $.proxy(self._onRemoveClick, self));
                self.$el.find('li button.add').on('click', $.proxy(self._onAddClick, self));
                self.$closeButton.on('click', $.proxy(self._onCloseClick, self));
            },

            onRemoveListeners: function () {

                var self = this;

                self.$el.find('li button.remove').off('click', $.proxy(self._onRemoveClick, self));
                self.$el.find('li button.add').off('click', $.proxy(self._onAddClick, self));
                self.$closeButton.off('click', $.proxy(self._onCloseClick, self));
            },

            // listener methods

            _onRemoveClick: function (e) {

                var self = this;

                var $target = $(e.currentTarget);
                var $guestOption = $target.parent().parent();
                var $add = $guestOption.find('.add');
                var $remove = $guestOption.find('.remove');
                var $number = $guestOption.find('.number');

                var numValue = parseInt($number.find('h2').html()) - 1;
                var minValue = parseInt($guestOption.data('min'));
                var maxValue = parseInt($guestOption.data('max'));

                numValue = Math.max(numValue, minValue);
                if (maxValue >= 0) {
                    numValue = Math.min(numValue, maxValue);
                }

                if (numValue === minValue) {
                    $remove.removeClass('disabled').addClass('disabled');
                }
                else {
                    $remove.removeClass('disabled');
                }

                if (numValue === maxValue) {
                    $add.removeClass('disabled').addClass('disabled');
                }
                else {
                    $add.removeClass('disabled');
                }

                $number.find('h2').html(numValue);
                $guestOption.data('count', numValue);

                self.tabulateGuests();
            },

            _onAddClick: function (e) {
                var self = this;

                var $target = $(e.currentTarget);
                var $guestOption = $target.parent().parent();
                var $add = $guestOption.find('.add');
                var $remove = $guestOption.find('.remove');
                var $number = $guestOption.find('.number');

                var numValue = parseInt($number.find('h2').html()) + 1;
                var minValue = parseInt($guestOption.data('min'));
                var maxValue = parseInt($guestOption.data('max'));

                numValue = Math.max(numValue, minValue);
                if (maxValue >= 0) {
                    numValue = Math.min(numValue, maxValue);
                }

                if (numValue === minValue) {
                    $remove.removeClass('disabled').addClass('disabled');
                }
                else {
                    $remove.removeClass('disabled');
                }

                if (numValue === maxValue) {
                    $add.removeClass('disabled').addClass('disabled');
                }
                else {
                    $add.removeClass('disabled');
                }

                $number.find('h2').html(numValue);
                $guestOption.data('count', numValue);

                self.tabulateGuests();
            },

            _onCloseClick: function () {

                var self = this;
                self.trigger('closeMenu');
            },

            // manual trigger option

            triggerOption: function (optionId) {
                var self = this;

                console.log('****** GuestsMenu triggerOption', optionId);

                if (optionId) {

                    var options = optionId.split(',');

                    console.log('triggerOption', optionId, options, self.$el);

                    for (var i = 0; i < options.length; i++) {

                        var option = options[i];
                        var split = option.split('.');
                        var $guestOption = self.$el.find('.guest-option[data-id="' + split[1] + '"]');

                        if ($guestOption.length) {
                            $guestOption.attr('data-count', split[0]);
                            $guestOption.find('.number h2').html(split[0]);
                        }
                    }

                    Utils.MiscUtils.setCookie('guests', optionId, window.SEARCHFILTERS_COOKIE_EXPIRES);

                    self.tabulateGuests();
                }
            },

            // return total guests

            tabulateGuests: function () {
                var self = this;
                var totalGuests = 0;
                var newQuery = '';
                _.each(self.$el.find('li'), function (guest, i) {
                    var $guest = $(guest);
                    totalGuests += $guest.data('count');
                    newQuery += (i > 0 ? ',' : '') + $guest.data('count') + '.' + $guest.data('id');
                });

                self.serverQuery = newQuery;
                if ($('#lang').text() == "en") {
                    self.shortResults = totalGuests + ' Guest' + ((totalGuests === 1) ? '' : 's');
                } else {
                    self.shortResults = totalGuests + ' Persona' + ((totalGuests === 1) ? '' : 's');
                }

                self.trigger('optionSelected', {target: self, query: self.serverQuery, results: self.shortResults});
                if (window.innerWidth < 768) {
                    window.$vent.trigger('guestsQueryMobile', {
                        total: totalGuests,
                        target: self,
                        query: self.serverQuery,
                        results: self.shortResults
                    });
                }
            },

            onShow: function () {

                var self = this;
                var d = 0.0;

                if (window.innerWidth < 768) {

                    window.requestAnimationFrame(function () {

                        self._onWindowResize();

                        window.$vent.trigger('lockScrollZone', {$target: self.$el});
                        self.trigger('showComplete');
                    });

                }
                else {

                    _.each(self.$el.find('li'), function (guest, i) {

                        var $guest = $(guest);
                        var $guestContent = $guest.find('.label').add($guest.find('.number')).add($guest.find('.buttons'));
                        var $guestBorder = $guest.find('.border');

                        TweenMax.fromTo($guestContent, 0.2, {opacity: 0, y: 4}, {
                            opacity: 1,
                            y: 0,
                            delay: i * 0.02 + d + 0.1,
                            ease: Cubic.easeOut
                        });
                        TweenMax.fromTo($guestBorder, 0.5, {opacity: 0}, {
                            opacity: 1,
                            delay: i * 0.02 + d + 0.1,
                            ease: Cubic.easeOut
                        });
                    });

                    TweenMax.fromTo(self.$el, 0.5, {opacity: 0, y: 0}, {
                        opacity: 1, y: 0, ease: Cubic.easeOut, onComplete: function () {
                            self.trigger('showComplete');
                        }
                    });

                    self.tabulateGuests();
                }
            },

            onHide: function () {

                var self = this;

                if (window.innerWidth < 768) {
                    window.$vent.trigger('restoreScrolling', {$target: self.$el});
                    self.trigger('hideComplete');
                }
                else {

                    TweenMax.to(self.$el, 0.3, {
                        opacity: 0, y: 0, ease: Cubic.easeOut, onComplete: function () {
                            self.trigger('hideComplete');
                        }
                    });
                }

            },

            _onWindowResize: function () {
                var self = this;
                var winWidth = window.innerWidth;
                var winHeight = window.innerHeight;

                if (winWidth < 768) {
                    TweenMax.set(self.$el, {minHeight: winHeight});
                }
                else {
                    TweenMax.set(self.$el, {clearProps: 'min-height'});
                }
            },

            // ---------------------------------------------------------------------------------  /

        });

        return GuestsMenuView;
    });
