
define(
	'modules/listings-items/views/ListingsItemView',[
		'backbone',
		'utils/AbstractView',
		'utils/AbstractButtonView',

		'moment',
	], 
	function(Backbone, AbstractView, AbstractButtonView){

		var ListingsItemView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			options: null,
			abstractView: null,
			abstractButtonView: null,

			// vars

			expandedTemplate: null,
			$expandedContainer: null,
			$expandedContainerMobile: null,			
			$expandedPanel: null,
			$blockDateItems: null,
			$blockDateItemsContainer: null,

			queryURL: null,
			availabilityData: null,

			isExpanded: false,
			isFirstExpansion: true,
			isLoadingData: false,

			// init

			initialize: function(options){

				var self = this;

				self.options = options;
				self.$container = self.options.$container;
				self.expandedTemplate = self.options.expandedTemplate;

				//

				//self.template = _.template(Template);
				self.model = $.extend({}, self.model);

				// 

				self.abstractView = new AbstractView({
					view: self,
					autoShow: true
				});

				self.abstractButtonView = new AbstractButtonView({
					abstractView: self.abstractView,
					selectable: false,
				});
			},

			// render

			onDomReady: function(){

				var self = this;

				self.$expandedContainer = self.$el.find('.availability-expanded-container');
				self.$expandedContainerMobile = $('#mobile-listing-container');

				// once calendars are prepped, start loading config

				self.queryURL = self.$el.data('query-url');
				self.loadAvailabilityData(self.queryURL);
			},

			onAddListeners: function(){

				var self = this;

				self.$el.find('.btn-availability').on('click', $.proxy(self._onAvailabilityClick, self));

				$(window).on('resize', $.proxy(self._onWindowResize, self));
			},

			// expanded state

			_onExpandedState: function(){

				var self = this;
			},

			_onCreateABlockClick: function(){

				var self = this;
			},

			_offExpandedState: function(){

				var self = this;
			},

			// 

			_onAvailabilityClick: function(e){

				var self = this;
				var $target = $(e.currentTarget);

				if(self.isExpanded){ self.close(); }
				else { self.expand(); }

				if(window.innerWidth < 768){
					self.slidePageLeft();
				}

				e.preventDefault();
			},

			expand: function(){

				var self = this;

				self.isExpanded = true;
				self.isLoadingData = true;

				self.checkDataLoad();
			},

			close: function(){

				var self = this;

				self.$el.removeClass('expanded');
				self.$expandedPanel.remove();
				self.abstractButtonView.deselect();
				self.isExpanded = false;

				self._offExpandedState();

				window.requestAnimationFrame(function(){
					//window.$vent.trigger('domUpdate');
					$(window).trigger('scroll');
				});
			},

			slidePageLeft: function(){

				var self = this;
				var $listings = $('#listings-container');
				var $page = $('#listings-landing-page');
				var winWidth = window.innerWidth;

				TweenMax.set($page, {zIndex:1001});
				TweenMax.set(self.$expandedContainerMobile, {x:winWidth, position:'absolute'});

				TweenMax.to($page, 0.6, {x:-winWidth, force3D:true, ease:Cubic.easeInOut, onComplete:function(){
					TweenMax.set($listings, {position:'absolute'});
					TweenMax.set(self.$expandedContainerMobile, {position:'relative'});
				}});

				window.$vent.trigger('seekToTop');
			},

			slidePageRight: function(){

				var self = this;
				var $listings = $('#listings-container');
				var $page = $('#listings-landing-page');
				var winWidth = window.innerWidth;

				TweenMax.set($page, {zIndex:1001});
				TweenMax.set(self.$expandedContainerMobile, {x:winWidth, position:'absolute'});
				
				self.$el.find('.btn-availability').trigger('deselect');
				
				TweenMax.to($page, 0.6, {x:0, force3D:true, ease:Cubic.easeInOut, onComplete:function(){
					
					TweenMax.set($listings, {clearProps:'position'});
					TweenMax.set(self.$expandedContainerMobile, {clearProps:'position'});

					self.close();
				}});

				window.$vent.trigger('seekToTop');
			},

			// show loading while getting json

			showLoading: function(){

				var self = this;

				console.log("show loading");
			},

			hideLoading: function(){

				var self = this;

				console.log("hide loading");
			},

			// load availability data for expanded pane

			reloadData: function(){

				var self = this;

				// TODO - refresh calendar if any changes are made in create-a-block
				// TODO - refresh calendar if dates are edited / removed
			},

			loadAvailabilityData: function(queryURL){

				var self = this;

				$.ajax({

					type: 'GET',
					url: queryURL,
					async: false,
					jsonpCallback: 'callBack',
					contentType: 'application/json',
					dataType: 'jsonp',

					success: function(json) {
						self._onQueryComplete(json.results);
					},
					error: function(e) {
						console.log('JSON Load Error', self);
						console.log(e.message);
						self._onQueryError(e);
					}
				});
			},

			_onQueryComplete: function(json){

				var self = this;

				self.availabilityData = json;
				self.isLoadingData = false;

				self.hideLoading();
				
				if(self.isExpanded){ 
					self.populateExpandedMenu();
				}
			},

			checkDataLoad: function(){

				var self = this;

				if(self.availabilityData){
					self.populateExpandedMenu();
				}
			},

			_onQueryError: function(e){

				var self = this;
			},

			_onAvailabilityDataRequest: function(e, obj){

				var self = this;
				var target = obj.target;

				if(target.$container.data('id') === self.availabilityData.id){
					target.setAvailabilityData(self.availabilityData);
				}
			},

			_onRemoveBlockDate: function(e){

				var self = this;
				var $target = $(e.target);
				var $row = self.$blockDateItemsContainer.find('.block-date-item-' + $target.data('index'));

				TweenMax.to($row, 0.3, {opacity:0.2, ease:Cubic.easeOut});

				$.when($.proxy(self.removeQuery, self)()).then(function(json){
					TweenMax.killTweensOf($row);
					$row.remove();
				});
				
				e.preventDefault();
			},
			
			_onBackClick: function(){

				var self = this;
				self.slidePageRight();
			},

			removeQuery: function(){

				var self = this;
				var dfd = $.Deferred();

				$.ajax({

					type: 'GET',
					url: self.$el.data('remove-query-url'),
					async: false,
					jsonpCallback: 'callBack',
					contentType: 'application/json',
					dataType: 'jsonp',

					success: function(json) {
						dfd.resolve(json);
					},
					error: function(e) {
						console.log('JSON Load Error', self);
						console.log(e.message);
						dfd.reject();
					}
				});

				return dfd.promise();
			},

			populateExpandedMenu: function(){

				var self = this;
				var expandedTemplate = _.template(self.expandedTemplate);
				
				self.$expandedPanel = $(expandedTemplate({
					availability: self.availabilityData,
					queryURL: self.queryURL
				}));
				
				self.$expandedPanel.find('.mobile-back-button').on('click', $.proxy(self._onBackClick, self));
				
				if(self.isFirstExpansion){				

					self.$blockDateItemsContainer = self.$expandedPanel.find('.block-date-items');
					self.$blockDateItems = self.$blockDateItemsContainer.find('.block-date-item');
					self.$blockDateItems.find('.availability-close').on('click', $.proxy(self._onRemoveBlockDate, self));

					window.$vent.on('availabilityDataRequest', $.proxy(self._onAvailabilityDataRequest, self));
				}

				self.$el.removeClass('expanded').addClass('expanded');
				
				if(window.innerWidth >= 768){ self.$expandedContainer.append(self.$expandedPanel); }
				else { 
					// I changed the append method to html because the container should be empty when adding elements
					self.$expandedContainerMobile.html(self.$expandedPanel); 
				}

				self.abstractButtonView.select();

				self._onExpandedState();

				window.requestAnimationFrame(function(){
					window.$vent.trigger('domUpdate', [self.$expandedPanel]);
				});
			},

			_onWindowResize: function(){

				var self = this;

				if(self.$expandedPanel && self.isExpanded){

					if(window.innerWidth >= 768){ 

						if(!self.$expandedContainer[0].contains(self.$expandedPanel[0])){
							self.$expandedContainer.append(self.$expandedPanel); 
						}
					}
					else { 

						if(!self.$expandedContainerMobile[0].contains(self.$expandedPanel[0])){
							self.$expandedContainerMobile.html(self.$expandedPanel); 
						}
					}
				}
			},

			// ---------------------------------------------------------------------------------  /

		});

	return ListingsItemView;
});
