define(
	'modules/promo/app',[
		'backbone',
		'modules/promo/views/PromoView',
	], 
	function(Backbone, PromoView){

		var AppView = Backbone.View.extend({

			// core vars

			$context: null,

			$moduleContainers: null,
			modules: null,

			// init

			initialize: function(options){

				var self = this;
				self.$context = $;
			},

			// start module --------------------------------------------------------------------  /

			start: function(){

				var self = this;						
				self.modules = [];

				self.checkDomForModules();
				
				window.$vent.trigger('promoReady');
				window.$vent.on('domUpdate', $.proxy(self._onDomUpdate, self));
			},

			_onDomUpdate: function(){

				var self = this;
				self.checkDomForModules();
			},
			
			// button types

			checkDomForModules: function(){

				var self = this;

				self.$moduleContainers = $('.promo-module');

				_.each(self.$moduleContainers, function(formContainer, i){

					var $formContainer = $(formContainer);
										
					if(!self.checkExisting($formContainer)){

						var promoView = new PromoView({
							el: $formContainer,
							$context: self.$context
						});

						self.modules.push(promoView);
					}
				});	
			},

			// check for existing buttons ------------------------------------------------------  /

			checkExisting: function(el){

				var self = this;
				var exists = false;

				if(typeof(el.context) !== 'undefined'){ el = el[0]; }

				_.each(self.modules, function(view, i){

					var viewEl = view.$el[0];
					if(typeof(viewEl.context) !== 'undefined'){ viewEl = viewEl[0]; }

					if(viewEl === el){
						exists = true;
					}
				});

				return exists;
			},

			// ---------------------------------------------------------------------------------  /

		});

		return AppView;
	}
);
