
define(
	'modules/global-scripted-buttons/views/ClassTogglerButtonView',[
		'backbone',
		'utils/AbstractView',
		'utils/AbstractButtonView',
	], 
	function(Backbone, AbstractView, AbstractButtonView){

		var ClassTogglerButtonView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			options: null,
			abstractView: null,
			button: null,

			// vars

			$targetContainer: null,
			targetClass: null,
			targetId: null,
			toggledClass: null,

			matchThisSelectionState: false,
			reverseThisSelectionState: false,

			isWaitingForDeselect: false,

			// init

			initialize: function(options){

				var self = this;

				self.options = options;
				self.$container = self.options.$container;
				self.matchThisSelectionState = (self.options.matchThisSelectionState === true);
				self.reverseThisSelectionState = (self.options.reverseThisSelectionState === true);

				// 

				self.abstractView = new AbstractView({
					view: self,
					autoShow: true
				});

				self.button = new AbstractButtonView({
					abstractView: self.abstractView,
					animateInteractionsWhenSelected: true,
					selectable: true,
				});
			},

			// render

			onRender: function(){

				var self = this;
			},

			//

			onShow: function(){

				var self = this;

				if(self.$el.data('container-id')){
					self.$targetContainer = $('#' + self.$el.data('container-id'));
				}
				else {
					self.$targetContainer = $('body');
				}

				self.targetClass = self.$el.data('target-class') || '';
				self.targetId = self.$el.data('target-id') || '';
				self.toggledClass = self.$el.data('toggled-class') || '';

				self.matchThisSelectionState = !!self.$el.data('match-selected-state') || false;
				self.reverseThisSelectionState = !!self.$el.data('reverse-selected-state') || false;

				if(self.$el.hasClass('selected')){
					self.select();
				}

				self.trigger('showComplete');
			},

			//

			onAnimateClick: function(){

				var self = this;

				if(self.targetId){
					self.toggleTarget(self.$targetContainer.find('#' + self.targetId));
				}

				if(self.targetClass){

					var $targets = self.$targetContainer.find('.' + self.targetClass);

					_.each($targets, function(target, i){
						var $target = $(target);
						self.toggleTarget($target);
					});
				}
			},

			toggleTarget: function($target){

				var self = this;

				if(self.matchThisSelectionState){
					
					// if this is selected, target adds class

					if(!self.button.isSelected){ 
						$target.removeClass(self.toggledClass).addClass(self.toggledClass); 
					}
					else { $target.removeClass(self.toggledClass); }
				}
				else if(self.reverseThisSelectionState){

					// if this is selected, target removes class

					if(self.button.isSelected){ 
						$target.removeClass(self.toggledClass).addClass(self.toggledClass); 
					}
					else { $target.removeClass(self.toggledClass); }
				}
				else {

					// toggle class regardless of this selected state

					if($target.hasClass(self.toggledClass)){
						$target.removeClass(self.toggledClass);
					}
					else {
						$target.addClass(self.toggledClass);
					}
				}
			},

			// ---------------------------------------------------------------------------------  /

		});

	return ClassTogglerButtonView;
});
