
define(
	'modules/global-form-helpers/views/RadioButtonsGroupListenerView',[
		'backbone',
		'utils/AbstractView',
	], 
	function(Backbone, AbstractView){

		var RadioButtonsGroupListenerView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			options: null,
			abstractView: null,

			$radioButtons: null,

			// init

			initialize: function(options){

				var self = this;

				// options vars

				self.options = options;
				self.$container = self.options.$container;

				// template config

				//self.template = _.template(Template);
				self.model = $.extend({}, self.model);

				// initialize AbstractView

				self.abstractView = new AbstractView({
					view: self,
					autoShow: self.options.autoShow !== false,
				});
			},

			onDomReady: function(){

				var self = this;

				if(!self.$radioButtons){

					self.$radioButtons = self.$el.find('input:radio');

					_.each(self.$radioButtons, function(radioButton, i){
						var $radioButton = $(radioButton);
						$radioButton.on('change', $.proxy(self._onButtonChange, self)); 
						self.checkButtonStatus($radioButton);
					});
				}
			},

			_onButtonChange: function(e){

				var self = this;
				var $target = $(e.target);
				self.selectButton($target);
			},

			selectButton: function($target){

				var self = this;
				$target.attr('checked', 'checked');

				_.each(self.$radioButtons, function(radioButton){

					var $radioButton = $(radioButton);
					if($radioButton[0] !== $target[0]){
						$radioButton.attr('checked', '');
					}
				});

				self.checkButtonStatus($target);
			},

			checkButtonStatus: function($target){

				var self = this;
				var action = $target.data('action');

				if($target.attr('checked') === 'checked'){

					self.selectedAction($target, action);

					_.each(self.$radioButtons, function(radioButton){

						var $radioButton = $(radioButton);

						if($radioButton[0] !== $target[0]){
							self.deselectedAction($radioButton, action);
						}
					});
				}
				else {
					self.deselectedAction($target, action);
				}
			},

			selectedAction: function($target, action){

				var self = this;
				var $actionTarget = $('#' + $target.data('target-id'));
				$actionTarget.removeClass('hide');
			},

			deselectedAction: function($target, action){

				var self = this;
				var $actionTarget = $('#' + $target.data('target-id'));
				$actionTarget.removeClass('hide').addClass('hide');

			},

		});

	return RadioButtonsGroupListenerView;
});
