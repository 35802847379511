
define(
	'modules/global-scripted-buttons/views/AnchorButtonView',[
		'backbone',
		'utils/AbstractView',
		'utils/AbstractButtonView',
	], 
	function(Backbone, AbstractView, AbstractButtonView){

		var AnchorButtonView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			options: null,
			abstractView: null,
			abstractButtonView: null,

			// vars

			// init

			initialize: function(options){

				var self = this;

				// options vars

				self.options = options;
				self.$container = self.options.$container;

				// template config

				self.model = $.extend({}, self.model);

				// initialize AbstractView and AbstractButtonView

				self.abstractView = new AbstractView({
					view: self,
					autoShow: self.options.autoShow !== false,
				});

				self.abstractButtonView = new AbstractButtonView({
					abstractView: self.abstractView,
				});
			},

			// render

			onAddListeners: function(){

				var self = this;
				self.$el.on('click', $.proxy(self._onClick, self));
			},

			// listener methods

			_onClick: function(e){

				var self = this;
				var anchorId;
				var href = self.$el.attr('href');
				var dataId = self.$el.data('anchor-id');
				var anchorOffset = parseInt(self.$el.data('anchor-offset'));

				if(!_.isNumber(anchorOffset) || isNaN(anchorOffset)){ anchorOffset = 0; }

				if(href && href.indexOf('#') >= 0){ anchorId = href.substr(1); }
				else if(dataId){ anchorId = dataId; }

				if(anchorId){
					var $anchorTarget = $('#' + anchorId);
					window.$vent.trigger('seekToPosition', {scrollY: $anchorTarget.offset().top + anchorOffset});
				}
				else {
					console.log('WARNING - No Anchor ID specified at', self);
				}

				e.preventDefault();
			},

			// ---------------------------------------------------------------------------------  /

		});

	return AnchorButtonView;
});
