
(function($){


    var lastTime = 0;
    var vendors = ['webkit', 'moz'];
    for(var x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
        window.requestAnimationFrame = window[vendors[x]+'RequestAnimationFrame'];
        window.cancelAnimationFrame =
            window[vendors[x]+'CancelAnimationFrame'] || window[vendors[x]+'CancelRequestAnimationFrame'];
    }

    if (!window.requestAnimationFrame)
        window.requestAnimationFrame = function(callback, element) {
            var currTime = new Date().getTime();
            var timeToCall = Math.max(0, 16 - (currTime - lastTime));
            var id = window.setTimeout(function() { callback(currTime + timeToCall); },
                timeToCall);
            lastTime = currTime + timeToCall;
            return id;
        };

    if (!window.cancelAnimationFrame)
        window.cancelAnimationFrame = function(id) {
            clearTimeout(id);
        };

    if($('#property-listing-page').length){

        var $resultsGrid = $('#results-grid');

        $resultsGrid.css({
            'min-height' : $(window).height() - 800
        });
    }



    // custom selects

    window.$rootContext = $;
    window.$vent = $('<div></div>'); // global communication object
    window.vars = {};
    window.MOBILE_WIDTH = 768;

    // cookie expire times
    window.SEARCHFILTERS_COOKIE_EXPIRES = 3600*1000;

    /* the mockup for area guides map to make the tabs unselected when one of them is clicked */

    $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
        e.preventDefault();
        $('.tm-filters li:not(.active) a').attr('aria-expanded','false');
    });

    /* end the mockup for area guides map to make the tabs unselected when one of them is clicked */

    $('.featured-item .img-wrapper').mouseenter(function(e){
        $(this).parents('.featured-item').css('z-index', '150');
        $('.prop-grid-overlay').css('visibility', 'visible');
        $(this).parents('.featured-item').find('.prop-navigators').css('visibility', 'visible');

        /*hardcoded, just to ilustrate the change on hover*/
        $('.pin-7').addClass('active');
    });

    $('.featured-item .img-wrapper').mouseout(function(e){
        e = e.toElement || e.relatedTarget;

        if (e == this || $(e).parents('.img-wrapper').length==1)
           return;
        $(this).parents('.featured-item').css('z-index', '50');
        $('.prop-grid-overlay').css('visibility', 'hidden');
        $(this).parents('.featured-item').find('.prop-navigators').css('visibility', 'hidden');
        $('.pin-7').removeClass('active');
    });

    /* Footer Accordion */

    $('.fd-open').click(function(e){
        e.preventDefault();
        $('.fd-open').not(this).each(function(i, el){
            if($(el).parent('.footer-accordion').hasClass('opened'))
            {
                $(el).parent('.footer-accordion').removeClass('opened') ;
                $(el).children('.btn-expand').removeClass('active');
            }
        });
        $(this).parents('.footer-accordion').toggleClass('opened');
        $(this).children('.btn-expand').toggleClass('active');
    });

    /* End Footer Accordion */

    // PAGE-SPECIFIC MODULES -------------------------------------------------------------------  /

    //$.getScript('modules/main.js');

})($);

requirejs(['app'],
    function(AppView){
        var app = new AppView({el:$('#main-wrapper')});
        app.start();
    }
);


define("init", function(){});

