define(
	'modules/detail-carousel/app',[
		'backbone',
		'modules/detail-carousel/views/DetailCarouselView',
	], 
	function(Backbone, DetailCarouselView){

		var AppView = Backbone.View.extend({

			// core vars

			$detailCarousels: null,
			detailCarousels: null,

			// init

			initialize: function(options){

				var self = this;
			},

			// start module --------------------------------------------------------------------  /

			start: function(){

				var self = this;
				
				self.$detailCarousels = $('.detail-carousel-container');		
				self.detailCarousels = [];

				_.each(self.$detailCarousels, function(carousel, i){
					
					var $carousel = $(carousel).find('.detail-carousel');					
					var carouselView = new DetailCarouselView({
						el: $carousel
					});

					self.detailCarousels.push(carouselView);
				});	
				
				window.$vent.trigger('detailCarouselReady');
			},
			
			// ---------------------------------------------------------------------------------  /

		});

		return AppView;
	}
);
