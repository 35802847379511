
define(
	'modules/payment-buttons/views/PaymentButtonView',[
		'backbone',
		'utils/AbstractView',
		'utils/AbstractButtonView',
	],
	function(Backbone, AbstractView, AbstractButtonView){

		var ButtonTemplateView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			options: null,
			abstractView: null,
			abstractButtonView: null,

			// vars

			$addPayment: null,
			$paymentTypes: null,
			$creditCardForm: null,
			$bankForm: null,
			$overlayContainer: null,

			creditFormTemplate: null,
			creditFormMobileTemplate: null,

			bankFormTemplate: null,
			bankFormMobileTemplate: null,

			isNewOption: false,
			isOutListening: false,

			// init

			initialize: function(options){

				var self = this;

				self.options = options;
				self.$container = self.options.$container;
				self.el = self.options.el;

				//

				//self.template = _.template(Template);
				self.model = $.extend({

				}, self.model);

				//

				self.abstractView = new AbstractView({
					view: self,
					autoShow: true
				});

				self.abstractButtonView = new AbstractButtonView({
					abstractView: self.abstractView,
				});
			},

			// render

			onRender: function(){

				var self = this;

				if(self.$el.hasClass('new-option')){
					self.isNewOption = true;
					self.$addPayment = self.$el.find('.pm-add-method');
					self.$paymentTypes = self.$el.find('.pm-select-type');
				}

				self.$overlayContainer = self.$el.find('.form-overlay-container');
			},

			addPaymentForms: function(desktopCardTemplate, mobileCardTemplate, desktopBankTemplate, mobileBankTemplate){

				var self = this;

				// credit card

				self.creditFormTemplate = _.template(desktopCardTemplate);
				self.creditFormMobileTemplate = _.template(mobileCardTemplate);

				self.$creditCardForm = $(self.creditFormTemplate());
				window.$rootContext(self.$creditCardForm.find('select')).selectBoxIt();

				// bank transfer

				self.bankFormTemplate = _.template(desktopBankTemplate);
				self.bankFormMobileTemplate = _.template(mobileBankTemplate);

				self.$bankForm = $(self.bankFormTemplate());
				window.$rootContext(self.$bankForm.find('select')).selectBoxIt();
			},

			onAddListeners: function(){

				var self = this;

				if(self.isNewOption){
					self.$el.find('.pm-inner').on('click', $.proxy(self._onAddPaymentClick, self));
				}
				else {
					self.$el.find('.pm-inner').on('click', $.proxy(self._onEditCardClick, self));
				}
			},

			_onAddPaymentClick: function(e){

				var self = this;
				self.showPaymentSelect();

				e.preventDefault();
			},

			_onEditCardClick: function(e){

				var self = this;
				self.showCreditForm();

				e.preventDefault();
			},

			// step 2 - payment select

			showPaymentSelect: function(){

				var self = this;

				TweenMax.to(self.$addPayment, 0.5, {y:0-self.$el.innerHeight(), ease:Quint.easeOut, onComplete:function(){
					self.$addPayment.addClass('hidden');
				}});

				TweenMax.fromTo(self.$paymentTypes, 0.5, {y:self.$el.innerHeight()}, {y:0, ease:Quint.easeOut});
				self.$paymentTypes.removeClass('hidden');

				self.$paymentTypes.find('button').on('click', $.proxy(self._onPaymentMethodClick, self));
				self.addOutListener();
			},

			hidePaymentSelect: function(){

				var self = this;

				TweenMax.to(self.$addPayment, 0.5, {y:0, ease:Quint.easeOut});
				self.$addPayment.removeClass('hidden');

				TweenMax.to(self.$paymentTypes, 0.5, {y:self.$el.innerHeight(), ease:Quint.easeOut, onComplete:function(){
					self.$paymentTypes.addClass('hidden');
				}});

				self.removeOutListener();
			},

			//

			_onPaymentMethodClick: function(e){

				var self = this;
				var $target = $(e.currentTarget);

				if($target.hasClass('credit')){
					self.showCreditForm();
				}
				else if($target.hasClass('bank')){
					self.showBankForm();
				}
			},

			_onCloseFormClick: function(e){

				var self = this;
				self.hideCreditForm();
			},

			// show credit card form

			showCreditForm: function(){

				var self = this;

				self.$overlayContainer.html(self.$creditCardForm);
				self.$overlayContainer.removeClass('hidden');

				self.$creditCardForm.find('.close-btn').on('click', $.proxy(self._onCloseFormClick, self));

				self.$creditCardForm.find('#personal-info .rounded-checkbox').on('click', $.proxy(self._onChangeCardType, self));

				TweenMax.set(self.$el, {
					marginBottom: self.$creditCardForm.outerHeight() - 170 + 40
				});

				self.addOutListener();
			},

			_onChangeCardType: function(e){

				var self = this;

				var $firstInput 	= $('.first-changing-input');
				var $secondInput 	= $('.second-changing-input');
				var $target = $( e.currentTarget );

				if($target.data('showinfo') == true)
				{
					$firstInput.attr('placeholder', 'Company Name');
					$secondInput.attr('placeholder', 'VAT Number');
				}
				else
				{
					$firstInput.attr('placeholder', 'First Name');
					$secondInput.attr('placeholder', 'Last Name');
				}

			},

			hideCreditForm: function(){

				var self = this;

				self.$overlayContainer.empty();
				self.$overlayContainer.removeClass('hidden').addClass('hidden');

				self.$creditCardForm.find('.close-btn').off('click', $.proxy(self._onCloseFormClick, self));

				TweenMax.set(self.$el, {
					clearProps: 'marginBottom'
				});

				self.removeOutListener();
			},

			// bank transfer form

			showBankForm: function(){

				var self = this;

				self.$overlayContainer.html(self.$bankForm);
				self.$overlayContainer.removeClass('hidden');

				self.$bankForm.find('.close-btn').on('click', $.proxy(self._onCloseFormClick, self));

				TweenMax.set(self.$el, {
					marginBottom: self.$bankForm.outerHeight() - 170 + 40
				});

				self.addOutListener();
			},

			hideBankForm: function(){

				var self = this;

				self.$overlayContainer.empty();
				self.$overlayContainer.removeClass('hidden').addClass('hidden');

				self.$bankForm.find('.close-btn').off('click', $.proxy(self._onCloseFormClick, self));

				TweenMax.set(self.$el, {
					clearProps: 'marginBottom'
				});

				self.removeOutListener();
			},

			//

			addOutListener: function(){

				var self = this;

				if(!self.isOutListening){

					self.isOutListening = true;

					window.requestAnimationFrame(function(){
						$(document).on('click', $.proxy(self._onOutClick, self));
					});
				}
			},

			removeOutListener: function(){

				var self = this;

				$(document).off('click', $.proxy(self._onOutClick, self));

				self.isOutListening = false;
			},

			//

			_onOutClick: function(e){

				var self = this;

				if(!self.$el[0].contains(e.target)){
					if(self.isNewOption){ self.hidePaymentSelect(); }
					self.hideCreditForm();
					self.hideBankForm();
					e.preventDefault();
				}
			},

			// ---------------------------------------------------------------------------------  /

		});

	return ButtonTemplateView;
});

