define(
	'modules/why-slider/views/CarouselView',[
		'backbone',
		'utils/AbstractView',

		'modules/why-slider/views/CarouselItemView',
	], 
	function(Backbone, AbstractView, CarouselItemView){

		var AppView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			options: null,
			abstractView: null,

			// vars

			$carouselCaptionsContainer: null,
			$carouselImagesContainer: null,
			$carouselNavContainer: null,

			$carouselCaptions: null,
			$carouselImages: null,
			$carouselNavItems: null,

			carouselItems: null,
			carouselImages: null,
			carouselCaptions: null,

			winWidth: null,
			winHeight: null,
			containerWidth: null,
			containerHeight: null,

			currentIndex: -1,
			currentCarouselItem: null,
			$currentCarouselImage: null,

			$navLine: null,
			$progressText: null,
			$textContent: null,
			$textBg: null,

			imageRatio: null,
			totalItems: null,

			// init

			initialize: function(options){

				var self = this;

				self.options = options;
				self.$container = self.options.$container;
				self.el = self.options.el;

				//self.template = _.template(Template);
				self.model = $.extend({

				}, self.model);

				// 

				self.abstractView = new AbstractView({
					view: self,
					autoShow: false,
				});

				//

				var imageWidth = self.$el.data('image-width') || 1180;
				var imageHeight = self.$el.data('image-height') || 581;

				self.imageRatio = imageWidth/imageHeight;

				//

				self.show();
			},


			onRender: function(){

				var self = this;

				self.carouselItems = [];
				self.carouselImages = [];
				self.carouselCaptions = [];

				self.$carouselCaptionsContainer = self.$el.find('ul.captions');
				self.$carouselImagesContainer = self.$el.find('ul.images');
				self.$carouselNavContainer = self.$el.find('ul.slider-nav');

				self.$carouselCaptions = self.$carouselCaptionsContainer.find('li');
				self.$carouselImages = self.$carouselImagesContainer.find('li');
				self.$carouselNavItems = self.$carouselNavContainer.find('li');

				self.totalItems = self.$carouselImages.length;

				for(var i=0; i<self.totalItems; i++){

					var itemModel = {
						index: i
					};

					var carouselItem = new CarouselItemView({						
						el: self.$carouselImages[i],
						$caption: $(self.$carouselCaptions[i]),
						model: itemModel,
						index: i
					});

					self.carouselItems.push(carouselItem);

					// image

					var $image = $(self.$carouselImages[i]);
					self.carouselImages.push($image);

					TweenMax.set($image, {force3D:true});

					// captions

					var $caption = $(self.$carouselCaptions[i]);
					self.carouselCaptions.push($caption);
				}

				self.$textContent = self.$carouselCaptionsContainer.find('.image-caption.captions');
				self.$progressText = self.$el.find('.progress-indicator .progress-text');
				self.$navLine = self.$el.find('.nav-line');

				TweenMax.set(self.$navLine, {transformOrigin:'0% 0%'});
			},

			// 

			onShow: function(){

				var self = this;
				var $arrowsContainer = self.$el.find('.arrow-buttons-container');

				self.onWindowResize();

				TweenMax.to(self.$el, 0.4, {opacity:1, delay:0.0, ease:Cubic.easeInOut});
				TweenMax.fromTo($arrowsContainer, 0.4, {opacity:0}, {opacity:1, ease:Cubic.easeInOut});

				self.seekByIndex(0);
				self.trigger('showComplete');
			},

			// listeners

			onAddListeners: function(){

				var self = this;

				var $leftArrow = self.$el.find('.arrow-buttons-container .arrow-button.left');
				var $rightArrow = self.$el.find('.arrow-buttons-container .arrow-button.right');

				$leftArrow.on('click', $.proxy(self._onArrowClick, self));
				$rightArrow.on('click', $.proxy(self._onArrowClick, self));

				self.$carouselNavItems.on('click', $.proxy(self._onNavItemClick, self));
			},

			onRemoveListeners: function(){

				var self = this;
			},

			// listener methods

			_onArrowClick: function(e){

				var self = this;
				var $target = $(e.currentTarget);

				if($target.hasClass('left')){ self.prevItem(); }
				else { self.nextItem(); }
			},

			_onNavItemClick: function(e){

				var self = this;
				var $target = $(e.currentTarget);

				self.seekByIndex($target.data('index'));

				e.preventDefault();
			},

			// carousel controls

			nextItem: function(){

				var self = this;
				var newIndex = self.currentIndex+1;

				self.seekByIndex(newIndex);
			},

			prevItem: function(){

				var self = this;
				var newIndex = self.currentIndex-1;

				self.seekByIndex(newIndex);
			},

			seekByIndex: function(index){

				var self = this;

				if(self.currentIndex != index){
					self.changeActiveItem(index);
				}
			},

			//

			changeActiveItem: function(newIndex){

				var self = this;
				var lastIndex = self.currentIndex;
				var lastCarouselItem = self.currentCarouselItem;

				if(newIndex >= self.totalItems){ newIndex = 0; }
				else if(newIndex < 0){ newIndex = self.totalItems-1; }

				var direction = (newIndex > self.currentIndex) ? 1 : -1;

				self.currentIndex = newIndex;
				self.currentCarouselItem = self.carouselItems[newIndex];
				self.$currentCarouselImage = self.carouselImages[newIndex];
				self.$currentCarouselCaption = self.carouselCaptions[newIndex];

				// progress indicator

				self.$progressText.html((newIndex + 1) + '/' + self.totalItems);

				// active nav item

				self.$carouselNavContainer.find('.active').removeClass('active');
				var $currentNavItem = $(self.$carouselNavItems[self.currentIndex]).addClass('active');

				// move line

				var navItemOffset = $currentNavItem.position();
				var navItemWidth = $currentNavItem.find('a').outerWidth();

				TweenMax.to(self.$navLine, 0.4, {x:navItemOffset.left, scaleX:navItemWidth/100, opacity:1, ease:Quint.easeOut});

				//

				if(lastCarouselItem){

					var $lastCarouselImage = lastCarouselItem.$el;
					var $lastCarouselCaption = lastCarouselItem.$caption;

					var $curEl = self.currentCarouselItem.$el;
					var $lastEl = lastCarouselItem.$el;

					TweenMax.to($lastCarouselImage, 0.4, {x:0-self.containerWidth * direction, force3D:true, ease:Sine.easeIn});
					TweenMax.to($lastCarouselImage, 0.6, {x:0-self.containerWidth * direction, delay:0.2, ease:Cubic.easeOut, onComplete:function(){
						$lastCarouselImage.removeClass('hidden').addClass('hidden');
						lastCarouselItem.hide();
					}});

					var d = 0.3;

					TweenMax.fromTo(self.$currentCarouselImage, 0.4, {x:self.containerWidth * direction, force3D:true}, {x:0, ease:Sine.easeIn});
					TweenMax.to(self.$currentCarouselImage, 0.6, {x:0, delay:0.2, ease:Cubic.easeOut});
					
					// show new image

					self.$currentCarouselImage.removeClass('hidden');
					self.currentCarouselItem.show();

					// caption swap

					TweenMax.to($lastCarouselCaption, 0.5, {opacity:0, ease:Cubic.easeOut, onComplete:function(){
						$lastCarouselCaption.addClass('hidden');
					}});

					TweenMax.fromTo(self.$currentCarouselCaption, 0.6, {opacity:0}, {opacity:1, delay:0.44, ease:Cubic.easeInOut});
					TweenMax.fromTo(self.$currentCarouselCaption, 0.6, {y:6}, {y:0, delay:0.44, ease:Cubic.easeOut});

					self.$currentCarouselCaption.removeClass('hidden');
				}
				else {

					// show image

					self.$currentCarouselImage.removeClass('hidden');
					self.currentCarouselItem.show();

					// show caption

					TweenMax.fromTo(self.$currentCarouselCaption, 0.6, {opacity:0}, {opacity:1, ease:Cubic.easeInOut});
					TweenMax.fromTo(self.$currentCarouselCaption, 0.6, {y:6}, {y:0, ease:Cubic.easeOut});
					self.$currentCarouselCaption.removeClass('hidden');
				}

				self.onWindowResize();
			},

			// window resizing

			onWindowResize: function(){

				var self = this;

				self.winWidth = window.innerWidth;
				self.winHeight = window.innerHeight;

				var $imagesContainer = self.$el.find('ul.images');
				var $arrowsContainer = self.$el.find('.arrow-buttons-container');

				self.containerWidth = self.$el.outerWidth();

				var imageHeight = Math.round(self.containerWidth / self.imageRatio);

				TweenMax.set($imagesContainer, {
					height: imageHeight
				});

				TweenMax.set($arrowsContainer, {
					top: Math.round(imageHeight / 2)
				});

				self.containerHeight = self.$el.outerHeight();

			},
			
			// ---------------------------------------------------------------------------------  /

		});

		return AppView;
	}
);
