define(
	'modules/home-inspiration/views/HomeInspirationItemView',[
		'backbone',
		'utils/AbstractView',
		'utils/AbstractButtonView',
	], 
	function(Backbone, AbstractView, AbstractButtonView){

		var HomeInspirationItemView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,

			options: null,
			abstractView: null,
			abstractButtonView: null,

			// options

			index: 0,

			// config

			autoShow: false,

			// flags

			isShowing: false,
			isListening: false,
			isFirstShow: true,

			// vars

			$rollOverPanel: null,
			$veil: null,

			// init

			initialize: function(options){

				var self = this;

				self.options = options;
				self.$container = self.options.$container;
				self.el = self.options.el;
				
				//

				self.model = $.extend({}, self.model);

				// 

				self.abstractView = new AbstractView({
					view: self,
					autoShow: true
				});

				self.abstractButtonView = new AbstractButtonView({
					abstractView: self.abstractView
				});
			},

			onDomReady: function(){

				var self = this;
				self.$veil = self.$el.find('figure.image-veil');
				self.$rollOverPanel = self.$el.find('.rollover-panel');

				if(window.innerWidth >= 768){
					TweenMax.set(self.$rollOverPanel, {y:80, force3D:true});
				}
			},

			onAddListeners: function(){

				var self = this;
			},

			//

			onAnimateOver: function(){

				var self = this;
				self.$rollOverPanel.removeClass('hidden');

				if(window.innerWidth >= 768){
					TweenMax.to(self.$rollOverPanel, 0.5, {y:0, ease:Quint.easeOut});
				}
			},

			onAnimateOut: function(){

				var self = this;

				if(window.innerWidth >= 768){
					
					TweenMax.to(self.$rollOverPanel, 0.3, {y:80, ease:Cubic.easeInOut, onComplete:function(){
						self.$rollOverPanel.removeClass('hidden').addClass('hidden');
					}});
				}
			},

			// show / hide white veil

			showVeil: function(){

				var self = this;

				// TweenMax.killTweensOf(self.$veil);
				// TweenMax.set(self.$veil, {opacity:1});
				// self.$veil.removeClass('hidden');
			},

			hideVeil: function(){

				var self = this;

				// TweenMax.killTweensOf(self.$veil);
				// TweenMax.set(self.$veil, {opacity:0});
				// TweenMax.to(self.$veil, 0.6, {ease:Cubic.easeOut, onComplete:function(){
				// 	self.$veil.removeClass('hidden').addClass('hidden');
				// }});
			},

			onWindowResize: function(){

				var self = this;

				//TweenMax.set(self.$gallery, {width:self.galleryWidth, height:parseInt(self.galleryWidth/self.galleryRatio)});
			},

			//

			onShow: function(){
				var self = this;
				self.trigger('showComplete');
			}	

		});

	return HomeInspirationItemView;
});
