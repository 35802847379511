
define(
	'modules/global-scripted-buttons/views/TitleSwapButtonView',[
		'backbone',
		'utils/AbstractView',
		'utils/AbstractButtonView',
	], 
	function(Backbone, AbstractView, AbstractButtonView){

		var TitleSwapButtonView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			options: null,
			abstractView: null,
			button: null,

			// vars

			$swapLink: null,

			isWaitingForDeselect: false,

			// init

			initialize: function(options){

				var self = this;

				self.options = options;
				self.$container = self.options.$container;
				self.el = self.options.el;

				// 

				self.abstractView = new AbstractView({
					view: self,
					autoShow: true
				});

				self.button = new AbstractButtonView({
					abstractView: self.abstractView,
					animateInteractionsWhenSelected: true,
					selectable: true,
				});
			},

			// render

			onDomReady: function(){

				var self = this;

				var swapLinkId = self.$el.data('swap-link-id');
				
				if(swapLinkId){
					self.$swapLink = $('#' + swapLinkId);
				}
			},

			//

			onShow: function(){

				var self = this;
				if(self.$el.hasClass('selected')){
					self.select();
				}

				self.trigger('showComplete');
			},

			//

			onAnimateOver: function(e){

				var self = this;

				if(!self.button.isSelected){
					TweenMax.killTweensOf(self.$el);
					//TweenMax.to(self.$el, 0.4, {opacity:1, ease:Cubic.easeOut});
				}
			},

			onAnimateOut: function(e){

				var self = this;

				if(!self.button.isSelected && !self.isWaitingForDeselect){
					TweenMax.killTweensOf(self.$el);
					//TweenMax.to(self.$el, 0.4, {opacity:0.25, ease:Cubic.easeOut});
				}
			},

			onAnimateSelect: function(e){

				var self = this;
				var $defaultText = self.$el.find('.default-title');
				var $alternativeText = self.$el.find('.alternative-title');	

				TweenMax.to($defaultText, 0.3, {opacity:0, ease:Cubic.easeOut, onComplete:function(){
					$defaultText.addClass('hidden');
				}});

				TweenMax.fromTo($alternativeText, 0.4, {opacity:0, y:12}, {opacity:1, y:0, delay:0.24, ease:Quint.easeOut});

				$alternativeText.removeClass('hidden');

				if(self.$swapLink){ self.$swapLink.removeClass('selected').addClass('selected'); }
			},

			onAnimateDeselect: function(e){

				var self = this;
				var $defaultText = self.$el.find('.default-title');
				var $alternativeText = self.$el.find('.alternative-title');	

				self.isWaitingForDeselect = true;

				TweenMax.to($alternativeText, 0.3, {opacity:0, ease:Cubic.easeOut, onComplete:function(){
					$alternativeText.removeClass('hidden').addClass('hidden');
				}});

				TweenMax.fromTo($defaultText, 0.4, {opacity:0, y:-12}, {opacity:1, y:0, delay:0.24, ease:Quint.easeOut});
				$defaultText.removeClass('hidden');

				if(self.$swapLink){ self.$swapLink.removeClass('selected'); }
			},

			// ---------------------------------------------------------------------------------  /

		});

	return TitleSwapButtonView;
});
