
define(
	'pagecontrollers/area-map/views/EditorialView',[
		'backbone',
		'utils/AbstractView',
		'text!../templates/EditorialTemplate.html',
	], 
	function(Backbone, AbstractView, Template){

		var EditorialView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			options: null,
			abstractView: null,

			// init

			initialize: function(options){

				var self = this;

				self.options = options;
				self.$container = self.options.$container;

				self.template = _.template(Template);
				self.model = $.extend({

				}, self.model);

				// 

				self.abstractView = new AbstractView({
					view: self,
					autoShow: true
				});
			},

			onDestroyComplete: function(){
				window.$vent.trigger('domUpdate');
			},

			onHide: function(){

				var self = this;
				TweenMax.to(self.$el, 0.5, {opacity:0, ease:Cubic.easeInOut, onComplete:function(){
					self.trigger('hideComplete');
				}});
			}

		});

	return EditorialView;
});
