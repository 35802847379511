// PLEASE REMOVE || Utils.DOMUtils.getHashParameter('isrealtimebooking') from production.

define(
    'modules/property-detail-bar/app',[
        'backbone',

        'modules/property-detail-bar/views/SidebarRequestFormView',

        'modules/calendars/views/CalendarMenuView',
        'modules/property-detail-bar/views/GuestsMenuView',
        'text!../../config/propertyDetailMessages-es.json',
        'text!../../config/propertyDetailMessages-en.json',

        'jquerymousewheel',
        'moment',
        'clndr',
    ],
    function (Backbone, SidebarRequestFormView, CalendarMenuView, GuestsMenuView, MessagesEs, MessagesEn) {

        var AppView = Backbone.View.extend({

            // core vars

            // vars

            $context: null,

            $filterOptions: null,
            $filterOverlays: null,
            $currentStepEl: null,
            $activeFilterButton: null,
            $preFooter: null,
            $pageFooter: null,
            $pageHero: null,
            $realBookingTitle: null,

            currentStep: null,
            messages: null,

            currentStepIndex: 0,
            propertyConfig: null,

            currentMenu: null,

            winWidth: null,
            winHeight: null,
            docHeight: null,
            sidebarHeight: null,
            heroHeight: null,
            footerHeight: null,
            scrollTop: null,

            requestFormMobile: null,
            requestFormMessages: null,

            calendarResults: null,
            guestsResults: null,

            isModalOpen: false,
            isFixedPosition: false,
            isFiltersInitialized: false,
            isFormConfigLoaded: false,
            isConfigLoaded: false,
            isModuleLoaded: false,
            isTicked: false,
            is992: false,
            is768: false,

            mobileCalendarSelected: false,
            mobileGuestsSelected: false,

            // init

            initialize: function (options) {

                var self = this;
                self.$context = $;
                self.$el = self.el = $('#property-detail-bar');

                window.$vent.trigger('propertyDetailBarReady');

                self.searchFilters = {};
            },

            // start module --------------------------------------------------------------------  /

            start: function () {

                var self = this;
                $(document).ready(function () {
                    self.onDomReady();
                });
            },
            onDomReady: function () {
                var self = this;

                self.$lang = $('#lang').html();
                self.messages = self.lang == 'es' ? JSON.parse(MessagesEs) : JSON.parse(MessagesEn);

                self.$preFooter = $('.prop-apts-wrap');
                self.$pageFooter = $('#page-footer');
                self.$pageHero = $('.prop-detail-hero');
                self.$favoriteButton = $('.btn-add-to-favorites');

                //

                $(window).on('resize', $.proxy(self._onWindowResize, self));
                $(window).on('scroll', $.proxy(self._onScroll, self));
                $(window).mousewheel($.proxy(self._onMouseWheel, self));
                $(self.$favoriteButton).on('click', $.proxy(self._onFavoriteClick, self));

                window.$vent.on('availabilityDataRequest', $.proxy(self._onAvailabilityDataRequest, self));
                window.$vent.on('favoriteClick', $.proxy(self._onFavoriteClick, self));
                window.$vent.on('trackEvent', $.proxy(self._onTrackEvent, self));

                window.$vent.on('modalMobileLoaded', $.proxy(self._onMobileLoaded, self));

                self.loadRequestFormMessages();

                self.showLoading();
                self.loadPropertyConfig();
            },

            // loading state (while config loads)

            showLoading: function () {

                var self = this;
            },

            hideLoading: function () {

                var self = this;
            },

            showError: function () {

                var self = this;
            },

            // components to load

            assetLoadComplete: function () {

                var self = this;
                self.checkModuleLoaded();
            },

            checkModuleLoaded: function () {

                var self = this;

                if (self.isConfigLoaded && self.isFormConfigLoaded && !self.isModuleLoaded) {
                    self.isModuleLoaded = true;
                    self.moduleReady();
                }
            },

            // all loads complete

            moduleReady: function () {
                var self = this;


                self.sidebarRequestFormView = new SidebarRequestFormView({
                    $container: self.$el,
                    model: self.propertyConfig,
                    autoShow: false,
                    isRealTimeBooking: self.propertyConfig.isRealTimeBooking,
                });

                // listen for global commands to open menus

                window.$vent.on('openCalendar', $.proxy(self._onOpenCalendar, self));
                window.$vent.on('openGuests', $.proxy(self._onOpenGuests, self));

                //Check if dates exist
                self.searchFilters.checkIn = Utils.MiscUtils.getCookie('checkIn') ? Utils.MiscUtils.getCookie('checkIn') : '';
                self.searchFilters.checkOut = Utils.MiscUtils.getCookie('checkOut') ? Utils.MiscUtils.getCookie('checkOut') : '';
                if (self.searchFilters.checkIn && self.searchFilters.checkOut) {
                    if (self.isDatesAvailable(self.searchFilters.checkIn, self.searchFilters.checkOut)) {
                        self.calendarResults = {};
                        self.calendarResults.checkInDate = moment(self.searchFilters.checkIn, 'MM/DD/YY');
                        self.calendarResults.checkOutDate = moment(self.searchFilters.checkOut, 'MM/DD/YY');
                    } else {
                        self.showIncorrectDatesMessage = true;
                        //Show message error in laptop
                        var content = $('#date-message-container');
                        var text = self.messages.minNights_error;
                        TweenMax.to(content, 0, {
                            opacity: 0, ease: Cubic.easeOut, onComplete: function () {
                                $(content).html('<p>' + text + '</p>');
                                TweenMax.fromTo(content, 0, {y: 0}, {y: 0, opacity: 1, ease: Sine.easeOut});
                            }
                        });
                    }
                }

                //Check if there are guests
                self.searchFilters.guests = Utils.MiscUtils.getCookie('guests') ? Utils.MiscUtils.getCookie('guests') : '';
                if (self.searchFilters.guests) {
                    self.guestsResults = {};
                    var guestItems = [];
                    guestItems = self.searchFilters.guests.split(',');
                    console.log('guestItems', guestItems);
                    var adults = guestItems[0].split('.');
                    var children = guestItems[1].split('.');
                    var infants = guestItems[2].split('.');
                    self.guestsResults.adults = adults[0];
                    self.guestsResults.children = children[0];
                    self.guestsResults.infants = infants[0];
                    self.guestsResults.total = parseInt(adults[0]) + parseInt(children[0]) + parseInt(infants[0])
                }

                self.$currentStepEl = self.sidebarRequestFormView.$el;

                self.sidebarRequestFormView.show();
                self.resetFilters();

                self.validateForm(self.sidebarRequestFormView.$requestForm);
            },

            validateForm: function($form) {

                var self = this;

                var fieldsRequiredMessageContainer = $form.find('.fields-required');

                var options = {
                    ignore: [], // TO NOT IGNORE SELECT, CHECKBOXES
                    lang: (self.$lang == 'es' ? self.$lang : 'en'),
                    rules: {
                        firstname: {
                            required: true
                        },
                        lastname: {
                            required: true
                        },
                        email: {
                            required: true
                        },
                        prefix: {
                            required: true
                        },
                        phone_number: {
                            required: true
                        },
                        comments: {
                            required: true
                        },
                        contact_by: {
                            required: true
                        },
                        ss_tos: {
                            required: true
                        },
                    },
                    messages: {
                        firstname: {
                            required: self.requestFormMessages.requiredMessage
                        },
                        lastname: {
                            required: self.requestFormMessages.requiredMessage
                        },
                        email: {
                            required: self.requestFormMessages.requiredMessage
                        },
                        prefix: {
                            required: self.requestFormMessages.requiredMessage
                        },
                        phone_number: {
                            required: self.requestFormMessages.requiredMessage
                        },
                        comments: {
                            required: self.requestFormMessages.requiredMessage
                        },
                        contact_by: {
                            required: self.requestFormMessages.requiredMessage
                        },
                        ss_tos: {
                            required: self.requestFormMessages.requiredPrivacyPolicies
                        },
                    },
                    errorPlacement: function (error, element) {
                        var politiesConditionErrorClass = 'ss_tos-error';
                        var className = element.attr('name')+'-error';
                        var errorContainer = $('.'+className);

                        if (errorContainer.length > 0 && errorContainer.hasClass(politiesConditionErrorClass)){
                            var previousError = errorContainer.find('label#'+error.attr('id'))
                            if (previousError.length > 0) {
                                previousError.replaceWith(error);
                            } else {
                                error.appendTo(errorContainer);
                            }
                        } else {
                            fieldsRequiredMessageContainer.show();
                            return false;
                        }
                    },
                    submitHandler: function () {
                        // Show spinner
                        var button = $form.find('.spinner-container');
                        window.$vent.trigger('showSpinner', [button, self]);

                        console.log("fieldsRequiredMessageContainer", fieldsRequiredMessageContainer);
                        fieldsRequiredMessageContainer.hide();

                        self.sendRequestForm($form);
                    }
                }

                $form.validate(options);
            },
            sendRequestForm: function($form){

                var requiredMessageContainer = $form.find('.fields-required');
                if($form.valid() == true) {
                  requiredMessageContainer.hide();
                } else {
                  return false;
                }

                var self = this;
                var dfd = $.Deferred();

                var url = $form.attr('action');

                var checkinField = $form.find('.js-check-in');
                var checkoutField = $form.find('.js-check-out');
                var checkin = checkinField.is('input') ? checkinField.val() : checkinField.text();
                var checkout = checkoutField.is('input') ? checkoutField.val() : checkoutField.text();
                var checkinParsed = moment(checkin, "DD/MM/YY").format('MM/DD/YY');
                var checkOutParsed = moment(checkout, "DD/MM/YY").format('MM/DD/YY');

                var adults = $form.find("[data-id='adults']").data('count');
                var children = $form.find("[data-id='children']").data('count');
                var babies = $form.find("[data-id='infants']").data('count');

                var propertyId = self.propertyConfig.id;
                var propertyName = self.propertyConfig.title;

                var firstName = $form.find('.js-req-form-firstname').val();
                var lastName = $form.find('.js-req-form-lastname').val();
                var email = $form.find('.js-req-form-email').val();
                var prefix = $form.find('.js-req-form-prefix').val();
                var phoneNumber = $form.find('.js-req-form-phone-number').val();
                var contactBy = $form.find('input[name=contact_by]:checked').val();
                var comments = $form.find('.js-req-form-comments').val();

                var data = {
                    checkin: checkinParsed,
                    checkout: checkOutParsed,
                    adults: adults,
                    children: children,
                    babies: babies,
                    propertyId: propertyId,
                    propertyName: propertyName,
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    contactBy: contactBy,
                    comments: comments,
                    prefix: prefix,
                    phoneNumber: phoneNumber,
                };

                var messagesTimeDuration = 15000; // milliseconds

                // console.log("URL", url);
                // console.log(JSON.stringify(data));

                $.ajax({
                    type: 'POST',
                    url: url,
                    data: JSON.stringify(data),
                    async: true,
                    contentType: "application/json",
                    success: function(response) {
                        window.$vent.trigger('hideSpinner');
                        if (response.success) {
                            $form.find('.success-messages-container').removeClass('hide');
                            setTimeout(function() { $form.find('.success-messages-container').addClass('hide'); }, messagesTimeDuration);
                            $form.trigger('reset');
                        } else {
                            $form.find('.error-messages-container').removeClass('hide');
                            setTimeout(function() { $form.find('.error-messages-container').addClass('hide'); }, messagesTimeDuration);
                        }
                        dfd.resolve();
                    },
                    error: function(e) {
                        $form.find('.error-messages-container').removeClass('hide');
                        setTimeout(function() { $form.find('.error-messages-container').addClass('hide'); }, messagesTimeDuration);
                        window.$vent.trigger('hideSpinner');
                        dfd.resolve();
                    }
                });

                return dfd.promise();
            },

            isDatesAvailable: function (checkInDateString, checkOutDateString) {

                var self = this;

                var checkInDate = moment(checkInDateString, 'MM/DD/YY');
                var checkOutDate = moment(checkOutDateString, 'MM/DD/YY');
                self.endDate = checkOutDate;

                if (self.propertyConfig) {

                    for (var i = 0; i < self.propertyConfig.dateRangePrices.length; i++) {
                        var dateRange = self.propertyConfig.dateRangePrices[i];
                        if (moment(dateRange.startDate).format('x') <= checkInDate.format('x')) {
                            self.propertyConfig.minNightsStay = dateRange.minNightsStay;
                        }
                    }

                    var nextOccupiedDate = self.getNextOccupiedDate(checkInDate.subtract(1, "days"), checkOutDate);
                    var numDays = (checkOutDate.format('x') - checkInDate.format('x')) / 86400000;

                    if (!self.isAvailableDate(checkOutDate.format('x'))) {
                        return false;
                    } else {
                        if (numDays > self.propertyConfig.maxNightsStay) {
                            return false;
                        }
                        else if (checkOutDate.format('x') < checkInDate.format('x')) {
                            return false;
                        }
                        else if (numDays < self.propertyConfig.minNightsStay) {
                            return false;
                        }
                        else if (checkOutDate.format('x') > nextOccupiedDate.format('x')) {
                            return false;
                        }
                        else {
                            return true;
                        }
                    }
                } else {
                    return false;
                }

            },
            // calculate next occupied date  ---------------------------------------------------  /

            getNextOccupiedDate: function (dateTime, minDate) {

                var self = this;

                if (self.propertyConfig) {

                    minDate = minDate || self.endDate;
                    minDate = self.getMinRangeTime(self.propertyConfig.myBlockDates, dateTime, minDate);
                    minDate = self.getMinRangeTime(self.propertyConfig.bookedOccupiedDates, dateTime, minDate);
                    minDate = self.getMinRangeTime(self.propertyConfig.holdOccupiedDates, dateTime, minDate);
                    minDate = self.getMinRangeTime(self.propertyConfig.ownerOccupiedDates, dateTime, minDate);

                    return minDate;
                }

                return false;
            },

            getMinRangeTime: function (rangeObj, dateTime, minDate) {

                var self = this;
                var minDateTime = minDate ? minDate.format('x') : false;

                for (var i = 0; i < rangeObj.length; i++) {

                    var dateRange = rangeObj[i];
                    var startDate = moment(dateRange['startDate']);

                    var rangeStartTime = startDate.format('x');

                    if (rangeStartTime > dateTime.format('x') && (rangeStartTime < minDateTime || !minDateTime)) {
                        minDate = startDate;
                        minDateTime = dateTime;
                    }
                }

                return minDate;
            },

            isAvailableDate: function (dateTime) {

                var self = this;
                var isInBlocked = self.isWithinDateRange(self.propertyConfig.myBlockDates, dateTime);
                var isInBooked = self.isWithinDateRange(self.propertyConfig.bookedOccupiedDates, dateTime);
                var isInHold = self.isWithinDateRange(self.propertyConfig.holdOccupiedDates, dateTime);
                var isInOwner = self.isWithinDateRange(self.propertyConfig.ownerOccupiedDates, dateTime);

                return !(isInBooked || isInHold || isInOwner || isInBlocked);
            },

            isWithinDateRange: function (rangeObj, dateTime) {

                var self = this;

                for (var i = 0; i < rangeObj.length; i++) {

                    var dateRange = rangeObj[i];
                    var startDateTime = moment(dateRange['startDate']).format('x');
                    var endDateTime = moment(dateRange['endDate']).format('x');

                    if (dateTime >= startDateTime && dateTime <= endDateTime) {
                        return true;
                    }
                }

                return false;
            },

            _onOpenCalendar: function () {

                var self = this;
                self.openMenu(self.calendarMenu);

                window.$vent.trigger('seekToTop');
            },

            _onOpenGuests: function () {

                var self = this;
                self.openMenu(self.guestsMenu);

                window.$vent.trigger('seekToTop');
            },

            // load property configuration

            loadPropertyConfig: function () {

                var self = this;

                console.log('query-url:', self.$el.data('query-url'));

                console.log('Curent context: ', self);
                console.log('Current element', self.$el);
                $.ajax({
                    type: 'GET',
                    url: self.$el.data('query-url'),
                    async: false,
                    jsonpCallback: 'callBack',
                    contentType: 'application/json',
                    dataType: 'jsonp'
                }).done(function (json) {
                    console.log('sidebarConfig Loaded', json.results);
                    self._onPropertyConfigComplete(json.results);
                }).fail(function (e) {
                    console.log('JSON Load Error: ', e.message);
                    self._onPropertyConfigFail(e);
                });

            },

            loadRequestFormMessages: function () {
                var self = this;

                var url;

                if (self.$lang == "es") {
                    url = "/assets/modules/config/form-messagesEs.json";
                } else {
                    url = "/assets/modules/config/form-messagesEn.json";
                }

                $.getJSON(url, function (json) {
                    self.requestFormMessages = json['default'];

                    self.isFormConfigLoaded = true;
                    self.checkModuleLoaded();
                });
            },

            _onAvailabilityDataRequest: function () {

                var self = this;

                console.dir(self.propertyConfig);

                if (self.propertyConfig) {
                    window.$vent.trigger('setAvailabilityData', self.propertyConfig);
                }
            },

            _onPropertyConfigComplete: function (data) {

                var self = this;

                var userFirstName = $('#user-data').data('first-name');
                var userLastName = $('#user-data').data('last-name');
                var minPrice = data.defaultPrice;
                var maxPrice = data.defaultPrice;

                var isRealTimeBooking = data.realTimeBooking || Utils.DOMUtils.getHashParameter('isrealtimebooking');

                _.each(data.dateRangePrices, function (dateRangePrice) {
                    minPrice = Math.min(minPrice, dateRangePrice.price);
                    maxPrice = Math.max(maxPrice, dateRangePrice.price);
                });

                self.propertyConfig = $.extend({

                    userFirstName: userFirstName,
                    userLastName: userLastName,

                    minPrice: minPrice,
                    maxPrice: maxPrice,
                    isRealTimeBooking: isRealTimeBooking,

                }, data);

                window.$vent.trigger('setAvailabilityData', self.propertyConfig);
                self.isConfigLoaded = true;
                self.assetLoadComplete();

            },

            _onPropertyConfigFail: function (e) {

                var self = this;
                self.showError();
            },

            _onAvailabilityClickMobile: function () {
                var self = this;
                window.$vent.trigger('trackEvent', [{
                    trackAction: 'availability',
                    trackCategory: 'Availability Step 0'
                }, self]);
            },
            _onHoldClickMobile: function () {
                var self = this;
                window.$vent.trigger('trackEvent', [{trackAction: 'hold', trackCategory: 'Hold Step 1'}, self]);
            },

            // listeners

            _onSetFixed: function () {

                var self = this;
                self.isFixedPosition = true;

                self._onWindowResize();
                self._onScroll();
            },

            _onSetAbsolute: function () {

                var self = this;
                self.isFixedPosition = false;

                self._onWindowResize();
                self._onScroll();
            },

            // calendar and guests filters
            resetFilters: function () {

                var self = this;

                if (self.isFiltersInitialized) {
                    self.removeExistingFilters();
                }

                self.$filterOptions = self.$currentStepEl.find('.filter-options');
                self.$filterOverlays = self.$currentStepEl.find('.filter-overlays');

                self.calendarMenu = new CalendarMenuView({
                    $container: self.$filterOverlays,
                    $context: self.$context,
                    model: self.propertyConfig,
                    selectedDates: self.calendarResults,
                    shortDate: true,
                    autoShow: false,
                });

                self.guestsMenu = new GuestsMenuView({
                    $container: self.$filterOverlays,
                    model: self.propertyConfig,
                    selectedGuests: self.guestsResults,
                    autoShow: false,
                });

                _.each(self.$filterOptions.find('li'), function (filterButton, i) {

                    var $filterButton = $(filterButton);

                    if (!$filterButton.data('default-text')) {
                        var defaultText = $filterButton.find('figcaption').html();
                        $filterButton.attr('data-default-text', defaultText);
                    }

                    $filterButton.on('click', $.proxy(self._onFilterOptionClick, self));
                });

                self.calendarMenu.on('clearDates', self._onClearDates, self);
                self.calendarMenu.on('optionSelected', self._onDateSelected, self);
                self.calendarMenu.on('closeMenu', self._onCloseMenu, self);
                self.calendarMenu.on('hide', self._onMenuClosed, self);
                if (self.calendarResults) {
                    self.calendarMenu.onRender();
                } else {
                    self.calendarMenu.isDateSelected = false;
                }

                self.guestsMenu.on('optionSelected', self._onGuestsSelected, self);
                self.guestsMenu.on('closeMenu', self._onCloseMenu, self);
                self.guestsMenu.on('hide', self._onMenuClosed, self);

                //


                if (self.calendarResults) {
                    self.$filterOptions.find('.filter-option.check-in figcaption').html(moment(self.calendarResults.checkInDate).format('DD/MM/YY'));
                    self.$filterOptions.find('.filter-option.check-out figcaption').html(moment(self.calendarResults.checkOutDate).format('DD/MM/YY'));
                }

                self.startingScrollTop = $(window).scrollTop();
                self.isFiltersInitialized = true;
                self._onWindowResize();
            },

            removeExistingFilters: function () {

                var self = this;

                if (self.calendarMenu) {

                    self.calendarMenu.off('optionSelected', self._onDateSelected, self);
                    self.calendarMenu.off('closeMenu', self._onCloseMenu, self);
                    self.calendarMenu.off('hide', self._onMenuClosed, self);

                    self.calendarMenu.exit();
                }

                if (self.guestsMenu) {

                    self.guestsMenu.off('optionSelected', self._onGuestsSelected, self);
                    self.guestsMenu.off('closeMenu', self._onCloseMenu, self);
                    self.guestsMenu.off('hide', self._onMenuClosed, self);

                    self.guestsMenu.exit();
                }

                self.calendarMenu = null;
                self.guestsMenu = null;

                self.isFiltersInitialized = false;
            },

            _onFilterOptionClick: function (e) {

                var self = this;
                var $target = $(e.currentTarget);
                var $nextFilterButton = $target;
                var nextMenu;

                if ($target.hasClass('check-in')) {

                    $nextFilterButton = self.$filterOptions.find('.date-option');
                    nextMenu = self.calendarMenu;

                } else if ($target.hasClass('check-out')) {

                    $nextFilterButton = self.$filterOptions.find('.date-option');
                    nextMenu = self.calendarMenu;

                } else if ($target.hasClass('guests')) {

                    nextMenu = self.guestsMenu;
                }

                if (nextMenu && nextMenu !== self.currentMenu) {

                    if (e) {
                        e.preventDefault();
                        e.stopPropagation();
                    }

                    self.openMenu(nextMenu, $nextFilterButton);
                }
                else if (!nextMenu) {
                    self.isModalOpen = false;
                }
            },

            _onDateSelected: function (e) {

                var self = this;
                console.log('_onDateSelected', e);

                self.isDateSelected = true;
                self.dateQuery = e.query;
                self.dateCheckInQuery = e.checkInQuery;
                self.dateCheckOutQuery = e.checkOutQuery;

                Utils.MiscUtils.setCookie('checkIn', self.dateCheckInQuery, window.SEARCHFILTERS_COOKIE_EXPIRES);
                Utils.MiscUtils.setCookie('checkOut', self.dateCheckOutQuery, window.SEARCHFILTERS_COOKIE_EXPIRES);

                self.updateFilterButtonText('check-in', e.results);
                self.updateFilterButtonText('check-out', e.results2);
                self.calendarResults = e.resultsObj;
            },

            _onClearDates: function (e) {

                var self = this;

                self.updateFilterButtonText('check-in');
                self.updateFilterButtonText('check-out');
            },

            _onGuestsSelected: function (e) {

                var self = this;
                self.isGuestsSelected = true;
                self.guestsQuery = e.query;

                console.log('_onGuestsSelected', e);

                Utils.MiscUtils.setCookie('guests', self.guestsQuery, window.SEARCHFILTERS_COOKIE_EXPIRES);

                self.updateFilterButtonText('guests', e.results);
                self.guestsResults = e.resultsObj;
            },

            _onCloseMenu: function (e) {

                var self = this;
                self.closeCurrentMenu();
            },

            // Favorite button
            _onFavoriteClick: function (e, data) {

                var self = this;
                var $notFavorite = $('.not-favorite');
                var $target
                var propertyId

                if ($("#userIsLogged").length > 0) {

                    if ($notFavorite.css("opacity") == 1) {
                        window.$vent.trigger('setAlertCount', {count: 'increment'});
                    } else {
                        window.$vent.trigger('setAlertCount', {count: 'decrement'});
                    }

                    if (data) {
                        propertyId = $(data).attr('id');
                        $target = $(data);
                    } else {
                        propertyId = self.$favoriteButton[0].id;
                        $target = $(e.currentTarget);
                    }

                    var data = {};
                    data["propertyId"] = propertyId;

                    window.$vent.trigger('showSpinner', [$target, self]);

                    $.ajax({
                        type: 'POST',
                        url: '/user/toggleFavorite',
                        data: JSON.stringify(data),
                        async: true,
                        contentType: "application/json; charset=utf-8",
                        success: function (response) {
                            window.$vent.trigger('hideSpinner');
                            if (response.success) {
                                $target.toggleClass('selected');
                                self.updateFavoriteButtons();
                            } else if (response.error) {
                                console.log("success error", response);
                            } else {
                                console.log("something went wrong to save session");
                            }
                        },
                        error: function (e) {
                            console.log("favorite not created");
                            window.$vent.trigger('hideSpinner');
                        }
                    });
                }
                else {
                    window.$vent.trigger('openModal', {modalId: '/global/login'});
                }
                e.preventDefault();
                return false;

            },

            updateFavoriteButtons: function () {
                var self = this;

                var favoriteButton = $('.btn-add-to-favorites');
                $(favoriteButton).find('.favorite').toggleClass('hidden');
                $(favoriteButton).find('.not-favorite').toggleClass('hidden');
            },

            openMenu: function (nextMenu, $nextFilterButton) {

                var self = this;

                if (self.currentMenu) {
                    self.closeCurrentMenu(nextMenu);
                }

                self.isModalOpen = true;
                self.currentMenu = nextMenu;
                self.$activeFilterButton = $nextFilterButton;

                self.currentMenu.show();

                $(document).off('click', $.proxy(self._onOutClick, self));

                window.requestAnimationFrame(function () {
                    $(document).on('click', $.proxy(self._onOutClick, self));
                });

                self.$filterOverlays.removeClass('hidden');
                self.$el.removeClass('overflow').addClass('overflow');

                window.$vent.trigger('openFiltersMenu');
            },

            // close menu

            closeCurrentMenu: function (nextMenu) {

                var self = this;

                if (self.currentMenu && self.isModalOpen) {

                    //self.restoreCurrentFilterButton();

                    if (!nextMenu) {

                        self.currentMenu.on('hideComplete', function (e) {
                            self.$filterOverlays.removeClass('hidden').addClass('hidden');
                            self.$el.removeClass('overflow');
                        });
                    }

                    self.currentMenu.hide();
                    self.currentMenu = null;

                    $(document).off('click', $.proxy(self._onOutClick, self));
                }

            },

            // menu listeners

            _onMenuClosed: function (e) {

                var self = this;
                if (self.calendarMenu.isDateSelected == false) {
                    self.isDateSelected = false;
                    Utils.MiscUtils.deleteCookie('checkIn');
                    Utils.MiscUtils.deleteCookie('checkOut');
                }

                self.searchFilters.checkIn = Utils.MiscUtils.getCookie('checkIn');
                self.searchFilters.checkOut = Utils.MiscUtils.getCookie('checkOut');
                self.searchFilters.guests = Utils.MiscUtils.getCookie('guests');
                self.searchFilters.checkIn = self.searchFilters.checkIn ? self.searchFilters.checkIn : '';
                self.searchFilters.checkOut = self.searchFilters.checkOut ? self.searchFilters.checkOut : '';
                self.searchFilters.guests = self.searchFilters.guests ? self.searchFilters.guests : '';
            },

            _onOutClick: function (e) {

                var self = this;

                if (e) {

                    if (!self.$filterOverlays[0].contains(e.target) && $('body')[0].contains(e.target)) {
                        self.closeCurrentMenu();
                    }
                }
                else {
                    self.closeCurrentMenu();
                }
            },

            // hide / restore clicked filter button values

            hideCurrentFilterButton: function () {

                var self = this;

                _.each(self.$activeFilterButton, function (filter, i) {
                    var $filter = $(filter);
                    TweenMax.to($filter.find('a'), 0.3, {opacity: 0, ease: Cubic.easeOut});
                });
            },

            restoreCurrentFilterButton: function () {

                var self = this;

                _.each(self.$activeFilterButton, function (filter, i) {
                    var $filter = $(filter);
                    TweenMax.fromTo($filter.find('a'), 0, {opacity: 0}, {opacity: 1, ease: Quint.easeInOut});
                });
            },

            updateFilterButtonText: function (buttonClass, text) {

                var self = this;
                var $filterButton = self.$currentStepEl.find('.' + buttonClass);

                if (text) {
                    $filterButton.find('figcaption').html(text);
                    $filterButton.removeClass('selected').addClass('selected');
                }
                else {
                    $filterButton.find('figcaption').html($filterButton.data('default-text'));
                    $filterButton.removeClass('selected');
                }
            },

            _onMouseWheel: function (e) {

                var self = this;
                self.scrollTick();
            },

            _onScroll: function () {

                var self = this;
                self.scrollTick();
            },

            scrollTick: function () {

                var self = this;

                if (!self.isTicked) {
                    self.isTicked = true;
                    window.requestAnimationFrame(self.scrollNow.bind(self));
                }
            },

            scrollNow: function () {

                var self = this;
                self.scrollTop = $(document).scrollTop();

                self.checkPosition();
                self.isTicked = false;
            },

            // check position - should bar be fixed or absolute

            checkPosition: function () {

                var self = this;
                var availableScreenHeight = Math.min(self.winHeight, self.docHeight - self.footerHeight - self.scrollTop);
                var yOffset = self.winWidth >= 992 ? 60 : 0;
                var yPos = yOffset - self.scrollTop;
                var top = 0;

                if (self.winWidth >= 992) {

                    if (availableScreenHeight >= self.sidebarHeight) { // && self.scrollTop >= yOffset){
                        yPos = 0;
                    }
                    else if (yPos + self.sidebarHeight <= availableScreenHeight) {
                        yPos = availableScreenHeight - self.sidebarHeight;
                    }
                }
                else if (self.winWidth >= 768) {

                    top = self.heroHeight + 0;

                    if (availableScreenHeight >= self.sidebarHeight) { // && self.scrollTop >= yOffset){

                        // if(self.scrollTop > top){
                        // 	yPos += self.scrollTop - top;
                        // }
                    }
                    else if (yPos + self.sidebarHeight <= availableScreenHeight) {
                        //yPos = availableScreenHeight - self.sidebarHeight;
                    }

                    if (self.scrollTop > top) {
                        yPos += self.scrollTop - top;
                    }

                    if (self.scrollTop + self.winHeight > self.docHeight - self.footerHeight) {
                        yPos -= (self.scrollTop + self.winHeight) - (self.docHeight - self.footerHeight);
                    }
                }

                TweenMax.set(self.$el, {top: top});

                if (self.lastYPos != yPos) {

                    TweenMax.set(self.$el, {
                        top: top,
                        y: yPos,
                        force3D: true
                    });

                    self.lastYPos = yPos;
                }
            },

            // window resize -------------------------------------------------------------------  /

            _onWindowResize: function () {

                var self = this;

                self.winHeight = window.innerHeight;
                self.winWidth = window.innerWidth;
                self.docHeight = $(document).outerHeight();

                if (self.isModuleLoaded) {

                    if ($('#media-breaks .break-992').css('opacity') == 1) {
                        self.is992 = true;
                        self.is768 = false;
                    }
                    else if ($('#media-breaks .break-768').css('opacity') == 1) {
                        self.is992 = false;
                        self.is768 = true;
                    }

                    if (self.is992) {
                        self.footerHeight = self.docHeight - self.$preFooter.offset().top;
                    }
                    else {
                        self.footerHeight = self.docHeight - self.$pageFooter.offset().top;
                    }

                    self.heroHeight = self.$pageHero.outerHeight();

                    if (self.currentStep) {
                        self.setSidebarHeight();
                    }

                    self.scrollTick();
                }
                else {
                    self.checkModuleLoaded();
                }
                self.checkPosition();
            },

            setSidebarHeight: function (addPixels) {

                var self = this;

                if (typeof addPixels == 'undefined') {
                    addPixels = 0;
                }


                self.sidebarHeight = self.currentStep.getMinHeight();

                var availableScreenHeight = self.winHeight - 60;

                if (self.sidebarHeight <= self.winHeight) {

                    self.sidebarHeight = self.winHeight;

                    if (!self.is992 && self.is768) {
                        self.sidebarHeight -= 60;
                    }
                }

                self.sidebarHeight += addPixels;

                TweenMax.set(self.$el, {
                    height: self.sidebarHeight
                });
            },
            //Function to get params from the url
            urlParam: function (name) {
                var results = new RegExp('[?&]' + name + '=([^&#]*)').exec(window.location.href);
                if (results) {
                    return results[1]
                } else {
                    return 0
                }
            },

            validateFormMobile: function () {

                var self = this;
                self.validateForm(self.requestFormMobile);
            },

            // Moible loaded   ----------------------------------------------------------------  /
            _onMobileLoaded: function () {
                var self = this;

                self.requestFormMobile = $('.request-form-mobile');
                self.validateFormMobile();
                window.$vent.on('sendRequestFormMobile', $.proxy(self._sendRequestFormMobile, self));

                self.$currentStepEl = $('.mobile-request-form');

                //Listeners
                window.$vent.on('dateSelectedInMobile', $.proxy(self._onDateSelectedInMobile, self));
                window.$vent.on('calendarClearDates', $.proxy(self._onClearDatesInMobile, self));
                window.$vent.on('guestsQueryMobile', $.proxy(self._onGuestsSelectedInMobile, self));

                //Check if dates exist
                self.searchFilters.checkIn = Utils.MiscUtils.getCookie('checkIn') ? Utils.MiscUtils.getCookie('checkIn') : '';
                self.searchFilters.checkOut = Utils.MiscUtils.getCookie('checkOut') ? Utils.MiscUtils.getCookie('checkOut') : '';
                if (self.searchFilters.checkIn && self.searchFilters.checkOut) {
                    if (self.isDatesAvailable(self.searchFilters.checkIn, self.searchFilters.checkOut)) {
                        self.calendarResults = {};
                        self.calendarResults.checkInDate = moment(self.searchFilters.checkIn, 'MM/DD/YY');
                        self.calendarResults.checkOutDate = moment(self.searchFilters.checkOut, 'MM/DD/YY');
                        /* In mobile the calendars/app triggers when it is loaded and the function sendSelectedDates sends the
                         selected dates to print them in the calendar
                         * */
                        window.$vent.off("calendarMobileLoaded", $.proxy(self._onSendSelectedDates, self));
                        window.$vent.on("calendarMobileLoaded", $.proxy(self._onSendSelectedDates, self));
                    } else {
                        self.isDateSelectedMobile = false;
                        self.showErrorMessageMobile(self.messages.minNights_error);
                    }
                }

                //Check if there are guests
                self.searchFilters.guests = Utils.MiscUtils.getCookie('guests') ? Utils.MiscUtils.getCookie('guests') : '';
                if (self.searchFilters.guests) {
                    self.guestsResults = self.getGuestObject(self.searchFilters.guests);
                    self.isGuestsSelectedMobile = self.guestsResults.adults >= 1 ? true : false;

                    window.$vent.on("guestsMobileLoaded", $.proxy(self._onSendGuestsSelected, self));
                }

            },

            _sendRequestFormMobile: function (e) {
              var self = this;
              e.preventDefault();

              if (self.requestFormMobile.valid() == true) {
                  self.sendRequestForm(self.requestFormMobile);
              }

            },

            _onDateSelectedInMobile: function (e, data) {
                var self = this;
                if (data.datesSelected) {
                    if (data.datesSelected.resultsObj != self.calendarResults) {
                        self.calendarResults = data.datesSelected.resultsObj;

                        Utils.MiscUtils.setCookie('checkIn', self.calendarResults.checkInDate.format('MM/DD/YY'), window.SEARCHFILTERS_COOKIE_EXPIRES);
                        Utils.MiscUtils.setCookie('checkOut', self.calendarResults.checkOutDate.format('MM/DD/YY'), window.SEARCHFILTERS_COOKIE_EXPIRES);

                        if (self.isDatesAvailable(self.calendarResults.checkInDate.format('MM/DD/YY'), self.calendarResults.checkOutDate.format('MM/DD/YY'))) {
                            self.hideErrorMessageMobile();
                            self.isDateSelectedMobile = true;
                        } else {
                            self.isDateSelectedMobile = false;
                            self.hideErrorMessageMobile();
                            self.showErrorMessageMobile(self.messages.minNights_error);
                        }
                    }
                }
            },

            _onClearDatesInMobile: function () {
                var self = this;

                self.isDateSelectedMobile = false;
                self.calendarResults = {
                    checkInDate: null,
                    checkOutDate: null
                }
                Utils.MiscUtils.deleteCookie('checkIn');
                Utils.MiscUtils.deleteCookie('checkOut');
                self.showErrorMessageMobile(self.messages.minNights_error);
            },

            _onSendSelectedDates: function () {
                var self = this;
                window.$vent.trigger('defaultDatesMobile', [{selectedDates: self.calendarResults}, self]);
            },

            _onSendGuestsSelected: function () {
                var self = this;
                window.$vent.trigger('setSelectedGuestsMobile', [{guestsSelected: self.guestsResults}, self]);

            },

            _onGuestsSelectedInMobile: function (e, data) {
                var self = this;
                self.isGuestsSelectedMobile = true;
                self.guestsResults = self.getGuestObject(data.query);
            },

            showErrorMessageMobile: function (text) {
                var self = this;
                var content = $('.request-booking-modal #date-message-container');
                TweenMax.to(content, 0, {
                    opacity: 0, ease: Cubic.easeOut, onComplete: function () {
                        $(content).html(text);
                        TweenMax.fromTo(content, 0, {y: 0}, {y: 0, opacity: 1, ease: Sine.easeOut});
                    }
                })
            },

            hideErrorMessageMobile: function () {
                var self = this;
                var content = $('.request-booking-modal #date-message-container');
                var text = "";
                TweenMax.to(content, 0, {
                    opacity: 0, ease: Cubic.easeOut, onComplete: function () {
                        $(content).html(text);
                        TweenMax.fromTo(content, 0, {y: 0}, {y: 0, opacity: 1, ease: Sine.easeOut});
                    }
                })
            },

            getGuestObject: function (guests) {
                var self = this;
                var guestItems = [];
                var guestsResults = {};
                guestItems = guests.split(',');
                var adults = guestItems[0].split('.');
                var children = guestItems[1] ? guestItems[1].split('.') : 0;
                var infants = guestItems[2] ? guestItems[2].split('.') : 0;
                guestsResults.adults = adults[0];
                guestsResults.children = children[0];
                guestsResults.infants = infants[0];
                guestsResults.total = parseInt(adults[0]) + parseInt(children[0]) + parseInt(infants[0]);

                return guestsResults;
            },

            _onTrackEvent: function (e, data) {
                var self = this;

                var checkIn;
                var checkOut;
                var guests;
                var propReference = $('.btn-add-to-favorites').attr('id');
                var location = $('.zone').text();
                var urlCode = window.location.href;

                if (self.isDateSelected) {
                    checkIn = moment(self.dateCheckInQuery, 'MM/DD/YY').format('YYYY/MM/DD');
                    checkOut = moment(self.dateCheckOutQuery, 'MM/DD/YY').format('YYYY/MM/DD');
                    console.log("checkIn", checkIn);
                    console.log("checkOut", checkOut);
                } else if (data.checkIn && data.checkOut) {
                    checkIn = moment(data.checkIn, 'MM/DD/YY').format('YYYY/MM/DD');
                    checkOut = moment(data.checkOut, 'MM/DD/YY').format('YYYY/MM/DD');
                    console.log("checkIn", checkIn);
                    console.log("checkOut", checkOut);
                }

                if (self.isGuestsSelected) {
                    guests = self.guestsQuery;
                    console.log("guests", guests);
                }
                window.dataLayer.push({
                    'event': 'track',
                    'trackAction': data ? data.trackAction : '',
                    'trackCategory': data && data.trackCategory ? data.trackCategory : 'Detail Search',
                    'trackLabel': {
                        'propReference': propReference,
                        'propLocation': location,
                        'checkIn': checkIn,
                        'checkOut': checkOut,
                        'guests': guests
                    }
                });
            },
        });

        return AppView;
    }
);

