
define(
	'pagecontrollers/property-listing/views/PaginationView',[
		'backbone',
		'utils/AbstractView',
		'text!../templates/PaginationTemplate.html',
	], 
	function(Backbone, AbstractView, Template){

		var PaginationView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			options: null,
			abstractView: null,
			lang:null,

			isRendered: false,

			paginationIndex: 0,
			totalItems: 0,
			itemsPerPage: 0,

			// init

			initialize: function(options){

				var self = this;


				// options vars

				self.options = options;
				self.$container = self.options.$container;
				self.lang = self.options.lang;

				// template config

				//self.template = _.template(Template);
				self.model = $.extend({}, self.model);

				// initialize AbstractView

				self.abstractView = new AbstractView({
					view: self,
					autoShow: false,
				});
			},

			rebuild: function(paginationIndex, totalItems, itemsPerPage){

				var self = this;

				self.paginationIndex = paginationIndex;
				self.totalItems = totalItems;
				self.itemsPerPage = itemsPerPage;

				if(self.isRendered){
					
					TweenMax.to(self.$el, 0.3, {opacity:0, ease:Cubic.easeOut, onComplete:function(){
						self.$el.remove();
						self.rebuildNow();
					}});
				}
				else { self.rebuildNow(); }
			},

			rebuildNow: function(){

				var self = this;
				self.removePaginationListeners();

				self.$el = $(_.template(Template)({

					index: self.paginationIndex,
					startItemIndex: self.paginationIndex * self.itemsPerPage + 1,
					endItemIndex: Math.min(self.paginationIndex * self.itemsPerPage + self.itemsPerPage, self.totalItems),
					totalItems: self.totalItems,
					pluralS: (self.totalItems === 1) ? '' : 's',
					pageCount: Math.ceil(self.totalItems / self.itemsPerPage),
					lang:self.lang

				}));

				self.$container.html(self.$el);
				self.addPaginationListeners();

				TweenMax.fromTo(self.$el, 0.5, {opacity:0}, {opacity:1, ease:Cubic.easeInOut});
			},

			addPaginationListeners: function(){

				var self = this;
				var $buttons = self.$el.find('.prop-pagination a');

				$buttons.on('click', $.proxy(self._onPaginationClick, self));
			},

			removePaginationListeners: function(){

				var self = this;
				var $buttons = self.$el.find('.prop-pagination a');

				$buttons.off('click', $.proxy(self._onPaginationClick, self));

			},

			_onPaginationClick: function(e){

				var self = this;
				var $target = $(e.currentTarget);

				if(!$target.hasClass('selected')){

					self.$el.find('.selected').removeClass('selected');
					$target.addClass('selected');

					window.$vent.trigger('paginate', $target.data('index'));
				}
			},

		});

	return PaginationView;
});
