
define(
	'modules/blog-header-carousel/views/CarouselItemView',[
		'backbone',
		'utils/AbstractView',
		'text!../../../assets/svg/quote.svg',
	], 
	function(Backbone, AbstractView, QuoteSVG){

		var CarouselItemView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			options: null,
			abstractView: null,

			//

			$image: null,
			$description: null,
			imageRatio: null,

			// init

			initialize: function(options){

				var self = this;

				self.options = options;
				self.$container = self.options.$container;
				self.$description = self.options.$description;

				// 

				self.abstractView = new AbstractView({
					view: self,
					autoShow: false
				});
			},

			onRender: function(){

				var self = this;

				self.$image = self.$el.find('img.carousel-image');
				self.$image.attr('src', self.model.imageURL);
			},

			onShow: function(){

				var self = this;
				var winWidth = window.innerWidth;

				if(winWidth >= 768){

					var $date = self.$description.find('.date.hidden-xs');
					var $title = self.$description.find('.title');
					var $desc = self.$description.find('p.description');
					var $author = self.$description.find('.author');

					TweenMax.killTweensOf([$date, $title, $desc, $author]);
					TweenMax.set(self.$el, {opacity:1});

					var d = 0.6;

					TweenMax.fromTo($date, 0.6, {opacity:0}, {opacity:1, delay:d, ease:Quad.easeInOut});
					TweenMax.fromTo($date, 0.8, {y:12}, {y:0, delay:d, ease:Quint.easeOut});

					d += 0.08;

					TweenMax.fromTo($title, 0.6, {opacity:0}, {opacity:1, delay:d, ease:Quad.easeInOut});
					TweenMax.fromTo($title, 0.8, {y:12}, {y:0, delay:d, ease:Quint.easeOut});

					d += 0.08;

					TweenMax.fromTo($desc, 0.6, {opacity:0}, {opacity:1, delay:d, ease:Quad.easeInOut});
					TweenMax.fromTo($desc, 0.8, {y:12}, {y:0, delay:d, ease:Quint.easeOut});

					d += 0.08;

					TweenMax.fromTo($author, 0.6, {opacity:0}, {opacity:1, delay:d, ease:Quad.easeInOut});
					TweenMax.fromTo($author, 0.8, {y:12}, {y:0, delay:d, ease:Quint.easeOut, onComplete:function(){
						self.trigger('showComplete');
					}});
				}
				else {

					TweenMax.fromTo(self.$el, 0.4, {opacity:0}, {opacity:1, ease:Cubic.easeOut});
					self.trigger('showComplete');					
				}

				self.$description.removeClass('display-none');
			},

			hideDescription: function(){

				var self = this;

				var $date = self.$description.find('.date.hidden-xs');
				var $title = self.$description.find('.title');
				var $desc = self.$description.find('p.description');
				var $author = self.$description.find('.author');

				TweenMax.killTweensOf([$date, $title, $desc, $author]);
				TweenMax.to([$date, $title, $desc, $author], 0.6, {opacity:0, ease:Cubic.easeOut, onComplete:function(){
					self.$description.removeClass('display-none').addClass('display-none');
					self.trigger('hideComplete');
				}});
			},

		});

	return CarouselItemView;
});
