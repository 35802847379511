define(
	'modules/global-modals/views/GenericModalView',[
		'backbone',
		'utils/AbstractView',
	], 
	function(Backbone, AbstractView){

		var GenericModalView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			$innerContainer: null,

			options: null,
			abstractView: null,

			$inputLabels: null,

			// vars

			initialScrollTop: 0,
			isLongModal: false,
			isDomUpdate: false,

			// init

			initialize: function(options){

				var self = this;

				self.options = options;
				self.$container = self.options.$container;
				self.el = self.options.el;

				self.template = _.template(self.options.template);
				self.model = $.extend({
					
				}, self.model);

				//

				self.isLongModal = self.model.longModal;

				// 

				self.abstractView = new AbstractView({
					view: self,
					autoShow: self.options.autoShow === true,
				});
			},

			onRender: function(){

				var self = this;
				self.$innerContainer = self.$el.find('>.inner-container');
				self.initialScrollTop = $(window).scrollTop();

				self.$inputLabels = self.$el.find('label');

				window.$rootContext(self.$el.find('select').not('.js-no-selectBoxIt')).selectBoxIt({
					isMobile: function () {
						return false;
					},
					autoWidth: false
				});
				self._onWindowResize();
			},

			onAddListeners: function(){

				var self = this;

				self.$el.find('.close-modal').on('click', $.proxy(self._onCloseClick, self));
				self.$el.find('.btn-close-modal').on('click', $.proxy(self._onCloseClick, self));
				self.$el.find('.btn-close').on('click', $.proxy(self._onCloseClick, self));

				//there's no need for proxy on this event
				self.$inputLabels.on('click', self._onLabelClick);

				$(document).on('click', $.proxy(self._onOutClick, self));
				$(window).on('resize', $.proxy(self._onWindowResize, self));	
				window.$vent.on('domUpdate', $.proxy(self._onDomUpdate, self));	
				
				window.requestAnimationFrame(function(){
					self._onWindowResize();
				});
			},

			onRemoveListeners: function(){

				var self = this;

				$(document).off('click', $.proxy(self._onOutClick, self));
				$(window).off('resize', $.proxy(self._onWindowResize, self));
			},

			// listener methods

			_onOutClick: function(e){

				var self = this;

				if(window.innerWidth > 768 && e){
					
					if(!self.$el[0].contains(e.target) && $('body')[0].contains(e.target)){
						self.closeModal();
					}	
				}

				e.preventDefault();
			},

			_onCloseClick: function(e){

				var self = this;
				self.closeModal();

				e.preventDefault();
			},

			_onLabelClick: function () {

				console.log('_onLabelClick');
				var theInput = $(this).find('input');
				theInput.prop('checked', true);
			},

			// close modal

			closeModal: function(){

				var self = this;

				//if(self.isLongModal){ window.$vent.trigger('seekToPosition', {scrollY:0}); }

				self.trigger('closeClick');
			},

			// show / hide

			onShow: function(){

				var self = this;
				
				if(window.innerWidth < 768){

					self.trigger('showComplete');
					window.$vent.trigger('lockScrollZone', {$target:self.$el});
					window.$vent.trigger('updateScrollZone');
				}
				else {

					TweenMax.fromTo(self.$innerContainer, 0.6, {opacity:0}, {opacity:1, delay:0.12, ease:Cubic.easeOut, onComplete:function(){
						self.trigger('showComplete');
					}});
				}

				var $closeButton = self.$el.find('.close-modal');
				TweenMax.fromTo($closeButton, 0.6, {opacity:0}, {opacity:1, delay:0.4, ease:Cubic.easeOut});


			},

			onHide: function(){

				var self = this;

				if(window.innerWidth < 768){

					window.$vent.trigger('restoreScrolling', {$target:self.$el});
					self.trigger('hideComplete');
				}
				else {

					TweenMax.to(self.$el, 0.4, {opacity:0, ease:Cubic.easeOut, onComplete:function(){
						/*TweenMax.set($('body'), {'clearProps' : 'min-height'});*/
						self.trigger('hideComplete');
					}});
				}
			},

			// window resize

			_onDomUpdate: function(){

				var self = this;
				self.isDomUpdate = true;

				/*TweenMax.set(self.$innerContainer, {clearProps: 'min-height'});*/

				window.requestAnimationFrame(function(){
					self.isDomUpdate = false;
					self._onWindowResize();
				});
			},

			_onWindowResize: function(){

				var self = this;
				var modalHeight = self.$innerContainer.outerHeight();
				var winWidth = window.innerWidth;
				var winHeight = window.innerHeight;
				var modalContainerHeight = Math.max(winHeight, modalHeight);
				var minHeight = 'initial';
				
				if(self.$el.hasClass('mobile-only') && winWidth >= 768){
					self.closeModal();
				}

				if(!self.isDomUpdate){

					if(self.isLongModal || self.$el.hasClass('full-height')){
						modalHeight = Math.max(modalHeight, winHeight);
						minHeight = modalHeight + 'px';
						console.log(minHeight);
					}

					if(winWidth < 768 && self.$el.hasClass('full-height-mobile')){
						modalHeight = Math.max(modalHeight, winHeight);
						minHeight = modalHeight + 'px';
					}

					/*TweenMax.set(self.$innerContainer, {'min-height': minHeight});*/

					window.requestAnimationFrame(function(){
						
						var topY = 0;

						modalHeight = self.$innerContainer.outerHeight();
						if(minHeight != 'initial'){ modalHeight = Math.max(modalHeight, winHeight); }

						/*TweenMax.set(self.$el, {'min-height': modalHeight});*/

						if(winWidth < 768){}
						else {

							if(modalHeight > winHeight){ 

								TweenMax.set(self.$el, {clearProps:'position'});
								topY = Math.round((modalContainerHeight - modalHeight)/2) + self.initialScrollTop + 60;
								/*TweenMax.set(self.$el, {top:topY});*/
							}
							else { 

								if(modalHeight == 0) {
									modalHeight = self.$innerContainer.find('.the-modal').outerHeight();
									topY = Math.round(modalHeight/2) + self.initialScrollTop + 60;
								}
								else {
									topY = Math.round((winHeight - modalHeight)/2) + self.initialScrollTop + 60;
								}

								/*TweenMax.set(self.$el, {top: topY});*/

							}

							var minScrollHeight = parseInt(minHeight) + topY;

							if($('body').height() < parseInt(minScrollHeight)){
								/*TweenMax.set($('body'), {'min-height':minScrollHeight + topY});*/
							}
						}

						window.$vent.trigger('updateScrollZone');
					});
					window.$vent.trigger('updateScrollZone');
				}
			},

		});

	return GenericModalView;
});
