
define(
	'modules/global-scripted-buttons/views/CommentsFieldButtonView',[
		'backbone',
		'utils/AbstractView',
		'utils/AbstractButtonView',
		
		'text!../templates/CommentsFieldButtonTemplate.html',
	], 
	function(Backbone, AbstractView, AbstractButtonView, CommentsFieldTemplate){

		var CommentsFieldButtonView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			options: null,
			abstractView: null,
			abstractButtonView: null,

			// vars

			// init

			initialize: function(options){

				var self = this;

				// options vars

				self.options = options;
				self.$container = self.options.$container;

				// template config

				//self.template = _.template(Template);
				self.model = $.extend({}, self.model);

				// initialize AbstractView and AbstractButtonView

				self.abstractView = new AbstractView({
					view: self,
					autoShow: self.options.autoShow !== false,
				});

				self.abstractButtonView = new AbstractButtonView({
					abstractView: self.abstractView,
				});
			},

			// render

			onAnimateClick: function(){

				var self = this;

				var fieldModel = {id:self.$el.data('field-id')}
				var $commentsField = $(_.template(CommentsFieldTemplate)(fieldModel));
				
				TweenMax.fromTo($commentsField, 0.4, {opacity:0}, {opacity:1, ease:Cubic.easeInOut});

				self.$el.replaceWith($commentsField);

				window.$vent.trigger('domUpdate');
			},

			// ---------------------------------------------------------------------------------  /

		});

	return CommentsFieldButtonView;
});
