define(
	'modules/main-menu/app',[
		'backbone',
		'modules/main-menu/views/ExpandedMenuView',
		'utils/components/app/ScrollTriggeredElement',
	], 
	function(Backbone, ExpandedMenuView){

		var AppView = Backbone.View.extend({

			// vars

			$expandedContainer: null,

			$initialRollOver: null,
			$rollOverLine: null,
			$rollOverShadow: null,

			$menuButtons: null,
			$exploreButton: null,
			$destinationsButton: null,
			$helpButton: null,
			$contactButton: null,
			$profilePhoto: null,
			$topAlert: null,
			$closeAlert: null,

			$logo: null,
			$logoSVG: null,

			expandedMenu: null,
			currentSection: null,
			currentTarget: null,

			isExpanded: false,
			isShort: false,
			isFixed: false,
			isHomepage: false,
			isMobile: false,
			isHamburgerExpanded: false,

			// init

			initialize: function(options){

				var self = this;
				self.$el = self.el = $('#main-menu-wrapper');
				self.isMobile = window.innerWidth < window.MOBILE_WIDTH;
			},

			// start module --------------------------------------------------------------------  /

			start: function(){

				var self = this;
				window.$vent.trigger('mainMenuReady');

				$(function(){
					self.onDomReady();
					self.addListeners();
				});
			},

			// dom ready

			onDomReady: function(){

				var self = this;

				//expanded Container

				self.$expandedContainer = self.$el.find('#main-menu-expanded-container');

				// rollover settings

				self.$initialRollOver = self.$expandedContainer.find('.rollover-shade');
				self.$rollOverLine = self.$initialRollOver.find('.line');
				self.$rollOverShadow = self.$initialRollOver.find('.shadow');

				TweenMax.set(self.$initialRollOver, {opacity:0});
				TweenMax.set(self.$rollOverShadow, {opacity:0});
				TweenMax.set(self.$rollOverLine, {y:-8, opacity:0});

				TweenMax.fromTo(self.$el, 0.4, {opacity:0}, {opacity:1, ease:Cubic.easeInOut});

				//

				self.$menuButtons = self.$el.find('.menu-anchor');
				self.$exploreButton = self.$el.find('#expand-explore');
				self.$destinationsButton = self.$el.find('#expand-destinations');
				self.$helpButton = self.$el.find('#expand-help');
				self.$contactButton = self.$el.find('#contact');
				self.$profileButton = self.$el.find('#expand-user');
				self.$topAlert = $("#vs-alert-top");
				self.$closeAlert = self.$topAlert.find(".close-alert");
				self.$hamburger = self.$el.find('.hamburger');

				self.isHomepage = $("#home-page").length > 0;

				self.$logo = self.$el.find('.logo-svg');
				self.$logoSVG = self.$el.find('.logo-svg svg *');

				// 

				var $mainMenuWrapper = $('#main-menu-wrapper');
				self.isFixed = !$mainMenuWrapper.hasClass('absolute-nav');

				self.isShort = self.$el.hasClass('shorter');

				if(self.isShort){
					TweenMax.set(	self.$expandedContainer, {y:-41}	);
				}

				if(self.isMobile)
				{
					//TweenMax.set(		self.$logoSVG, {fill:'#3a9a92'}		);
				}
			},

			// listeners

			addListeners: function(){

				var self = this;


				if( self.isHomepage && ! self.isMobile ) {
					$(window).on('scroll', $.proxy(self._onWindowScroll, self));
					self._onWindowScroll();
				}

				$(window).on('resize', $.proxy(self._onWindowResize, self));

				self.$el.on('mouseenter', $.proxy(self._onContainerEnter, self));
				self.$el.on('mouseleave', $.proxy(self._onContainerLeave, self));

				self.$closeAlert.on('click', $.proxy(self._onAlertClose, self));

				self.$exploreButton.on('mouseenter', $.proxy(self._onButtonEnter, self));
				self.$destinationsButton.on('mouseenter', $.proxy(self._onButtonEnter, self));
				self.$helpButton.on('mouseenter', $.proxy(self._onButtonEnter, self));
				self.$contactButton.on('mouseenter', $.proxy(self._onButtonEnter, self));
				self.$profileButton.on('mouseenter', $.proxy(self._onButtonEnter, self));

				self.$exploreButton.on('mouseleave', $.proxy(self._onButtonLeave, self));
				self.$destinationsButton.on('mouseleave', $.proxy(self._onButtonLeave, self));
				self.$helpButton.on('mouseleave', $.proxy(self._onButtonLeave, self));
				self.$contactButton.on('mouseleave', $.proxy(self._onButtonLeave, self));
				self.$profileButton.on('mouseleave', $.proxy(self._onButtonLeave, self));

				self.$exploreButton.on('mouseenter', $.proxy(self._onButtonClick, self));
				self.$destinationsButton.on('mouseenter', $.proxy(self._onButtonClick, self));
				self.$helpButton.on('mouseenter', $.proxy(self._onButtonClick, self) );
				self.$contactButton.on('click', $.proxy(self._onButtonClick, self));
				self.$profileButton.on('click', $.proxy(self._onProfileClick, self));
				self.$profileButton.on('mouseenter', $.proxy(self._onButtonClick, self));
				self.$hamburger.on('click', $.proxy(self._onHamburgerClick, self) )

				$('#mobile-profile-menu .logout').on('click', $.proxy(self._onLogoutClick, self));

				// mobile

				self.$el.find('.user-anchor-mobile').on('click', $.proxy(self._onProfileMobileClick, self));
				$('#mobile-profile-menu').on('click', $.proxy(self._onProfileMobileClickOut, self));

				// 


				$('.mobile-expnav-wrapper').click(function(e){
					if(e.target==this)
						$('.mobile-expnav-wrapper').removeClass('displayed');
				});

				$('.mobile-main-menu a.parent').click(function(e){					
					$('.mobile-main-menu a.parent').not(this).each(function(i, el){
						if($(el).parent('li').hasClass('active'))
						{
							$(el).parent('li').removeClass('active');
							$(el).removeClass('active');
						}
					});
					$(this).parent('li').toggleClass('active');
					$(this).toggleClass('active');
				});
			},

			removeListeners: function(){

				var self = this;

				$(window).off('resize', $.proxy(self._onWindowResize, self));
				$(window).off('scroll', $.proxy(self._onWindowScroll, self));

				self.$el.off('mouseenter', $.proxy(self._onContainerEnter, self));
				self.$el.off('mouseleave', $.proxy(self._onContainerLeave, self));

				self.$exploreButton.off('mouseenter', $.proxy(self._onButtonEnter, self));
				self.$destinationsButton.off('mouseenter', $.proxy(self._onButtonEnter, self));
				self.$helpButton.off('mouseenter', $.proxy(self._onButtonEnter, self));
				self.$contactButton.off('mouseenter', $.proxy(self._onButtonEnter, self));
				self.$profileButton.off('mouseenter', $.proxy(self._onButtonEnter, self));

				self.$exploreButton.off('mouseleave', $.proxy(self._onButtonLeave, self));
				self.$destinationsButton.off('mouseleave', $.proxy(self._onButtonLeave, self));
				self.$helpButton.off('mouseleave', $.proxy(self._onButtonLeave, self));
				self.$contactButton.off('mouseleave', $.proxy(self._onButtonLeave, self));
				self.$profileButton.off('mouseleave', $.proxy(self._onButtonLeave, self));

				self.$exploreButton.off('mouseenter', $.proxy(self._onButtonClick, self));
				self.$destinationsButton.off('mouseenter', $.proxy(self._onButtonClick, self));
				self.$helpButton.off('mouseenter', $.proxy(self._onButtonClick, self));
				self.$contactButton.off('click', $.proxy(self._onButtonClick, self));
				self.$profileButton.off('click', $.proxy(self._onProfileClick, self));
				self.$profileButton.off('mouseenter', $.proxy(self._onButtonClick, self));
				self.$hamburger.off('click', $.proxy(self._onHamburgerClick, self) )

				$('#mobile-profile-menu').off('click', $.proxy(self._onLogoutClick, self));
			},

			// listener methods

			_onLogoutClick: function(e){

				var self = this;
				window.$vent.trigger('logout');
			},

			_onProfileClick: function(e){

				var self = this;
			},

			_onProfileMobileClick: function(e){

				var self = this;

				if(self.isExpanded){ 
					self.isExpanded = false;
				} 
				else if( $(".logged-in:not(.hide)").length > 0 ) {
					
					var mobileProfileMenu = $('#mobile-profile-menu');

					self.isExpanded = true;
					mobileProfileMenu.addClass('displayed');
					$('.mobile-expnav-wrapper').removeClass('displayed');

				}
				
				self._onWindowResize();
			},

			_onProfileMobileClickOut: function(e){

				var self = this;
				var $mobileProfileMenu = $('#mobile-profile-menu');
				
				if(!$mobileProfileMenu[0].contains(e.target) || e.target == $mobileProfileMenu[0]){
					$mobileProfileMenu.removeClass('displayed');
					self.isExpanded = false;					
				}
				
				self._onWindowResize();
			},

			_onContainerEnter: function(e){

				var self = this;

				if(!self.isExpanded && !self.isShort){

					TweenMax.killTweensOf([self.$initialRollOver, self.$rollOverShadow, self.$rollOverLine, self.$menuButtons]);

					TweenMax.to(self.$initialRollOver, 0.4, {opacity:1, ease:Cubic.easeOut});
					TweenMax.to(self.$rollOverShadow, 0.6, {opacity:1, ease:Cubic.easeIn});
					TweenMax.to(self.$rollOverShadow, 0.8, {opacity:1, delay:0.1, ease:Sine.easeOut});
					TweenMax.to(self.$rollOverLine, 0.4, {opacity:1, ease:Cubic.easeOut});
					TweenMax.to(self.$rollOverLine, 0.4, {y:0, ease:Cubic.easeOut});

					//TweenMax.to(self.$menuButtons, 0.5, {color:'#bebebe', delay:0.1, ease:Sine.easeOut});

					self.$initialRollOver.removeClass('hidden');
				}
			},

			_onContainerLeave: function(e){

				var self = this;

				if(!self.isExpanded && !self.isShort){

					TweenMax.killTweensOf([self.$initialRollOver, self.$rollOverShadow, self.$rollOverLine, self.$menuButtons]);
					
					TweenMax.to(self.$initialRollOver, 0.5, {opacity:0, ease:Cubic.easeInOut});
					TweenMax.to(self.$rollOverShadow, 0.4, {opacity:0, ease:Cubic.easeOut});
					TweenMax.to(self.$rollOverLine, 0.5, {opacity:0, ease:Cubic.easeInOut, onComplete:function(){
						self.$initialRollOver.removeClass('hidden').addClass('hidden');
						TweenMax.set(self.$rollOverLine, {y:-8});
					}});

					TweenMax.to(self.$menuButtons, 0.5, {color:'#ffffff', ease:Cubic.easeOut});
				}
			},

			_onButtonEnter: function(e){

				var self = this;
				var $target = $(e.currentTarget);
				var section = self.getSectionByButton($target, 'explore');
				var overColor = $('#home-page').length ? '#bebebe' : '#3a9a92';
	
				// color whatever the mouse is on

				TweenMax.killTweensOf($target);
				TweenMax.to($target, 0.4, {color:overColor, ease:Sine.easeOut});
				$target.removeClass('mouse-over').addClass('mouse-over');

				self.currentTarget = $target.html();

				if(self.getCloseButtonStatus($target)){
					self.closeMenu();
				}
				else if(section && !self.isExpanded){

					// also color whatever button is associated with the section 
					// (just in case it's different)

					var $button = self.$el.find('.menu-button-' + section);
					
					if($target[0] !== $button[0]){						
						TweenMax.killTweensOf($button);
						TweenMax.to($button, 0.4, {color:'#3a9a92', ease:Sine.easeOut});
					}
					
					$button.removeClass('selected').addClass('selected');
					self.expandMenu(section);
				}
			},

			_onButtonLeave: function(e){

				var self = this;
				var $target = $(e.currentTarget);
				var outColor = $('#home-page').length ? '#bebebe' : '#bebebe';
				
				$target.removeClass('mouse-over');

				if(!$target.hasClass('selected')){
					TweenMax.to($target, 0.4, {color:outColor, ease:Sine.easeOut});
				}
			},

			_onButtonClick: function(e){

				var self = this;
				var $target = $(e.currentTarget);
				var section = self.getSectionByButton($target);

				if(self.getCloseButtonStatus($target)){
					self.closeMenu();
				}
				else {

					var $lastSectionButton = self.$el.find('.selected');
					$lastSectionButton.removeClass('selected');
					self._onButtonLeave({currentTarget:$lastSectionButton[0]});

					TweenMax.killTweensOf($target);
					TweenMax.to($target, 0.4, {color:'#3a9a92', ease:Sine.easeOut});
					$target.removeClass('selected').addClass('selected');

					self.changeSection(section);
				}
			},

			_onAlertClose: function () {
				var self = this,
				 	alertWrapper = self.$topAlert.parents('.container-wrapper')
				 	mainMenuWrapper = $("#main-menu-wrapper");

				TweenMax.to(alertWrapper, 0.3, {
					top: -60, 
					ease: Sine.easeOut,
					onComplete: function () {
						alertWrapper.hide();
					}
				});
				
				TweenMax.to(mainMenuWrapper, 0.3, {
					top: 0,
					ease: Sine.easeOut,
					delay: 0.3
				});

				self.$topAlert = null;

			},

			_onHamburgerClick: function () {

				var self = this;

				self.isHamburgerExpanded = !self.isHamburgerExpanded;

				if(self.isHamburgerExpanded){
					$('.mobile-expnav-wrapper').addClass('displayed');
				}else{
					//$('#mobile-profile-menu').removeClass('displayed');
					$('.mobile-expnav-wrapper').removeClass('displayed');
				}

				self._onWindowResize();
			},

			getCloseButtonStatus: function($target){

				var self = this;				
				var isCloseButton = false;

				if($target.attr('id') === 'contact'){
					isCloseButton = true;
				}
				else if($target.attr('id') === 'expand-user' && $(".logged-in.hide").length > 0 ){
					isCloseButton = true;
				}

				return isCloseButton;
			},

			_onExpandedLeave: function(e){

				var self = this;
				self.closeMenu();
				self._onContainerLeave();
			},

			getSectionByButton: function($button, defaultSection){

				var self = this;
				var section = defaultSection;

				if($button[0] === self.$exploreButton[0]){ section = 'explore'; }
				else if($button[0] === self.$destinationsButton[0]){ section = 'destinations'; }
				else if($button[0] === self.$helpButton[0]){ section = 'help'; }
				else if($button[0] === self.$profileButton[0]){ section = 'user'; }

				return section;
			},

			//

			expandMenu: function(section){

				var self = this;

				if(!self.expandedMenu){
					
					self.currentSection = section;

					self.expandedMenu = new ExpandedMenuView({
						$container: self.$expandedContainer,
						firstSection: self.currentSection,
					});

					self.expandedMenu.on('mouseleave', self._onExpandedLeave.bind(self));
					self.expandedMenu.on('hideComplete', self._onMenuHidden.bind(self));

				}
				else {

					self.expandedMenu.show();
				}

				self.changeMenuColorExpanded();
				self.isExpanded = true;
				self._onWindowResize();
			},

			changeSection: function(section){

				var self = this;

				if(self.expandedMenu && section){
					self.currentSection = section;
					self.expandedMenu.changeSection(section);
				}
			},

			closeMenu: function(){

				var self = this;

				if(self.expandedMenu && self.isExpanded){
					
					self.expandedMenu.hide();
					self.changeMenuColorNormal();

					var $lastSectionButton = self.$el.find('.selected');
					$lastSectionButton.removeClass('selected');
					
					self._onButtonLeave({currentTarget:$lastSectionButton[0]});
				}
				
				// mobile

				$('#mobile-profile-menu').removeClass('displayed');	

				//

				self.isExpanded = false;
				self._onWindowResize();
			},

			// animate menu colors for expanded / normal

			changeMenuColorExpanded: function(){

				console.log('changeMenuColorExpanded');
				var self = this;

				TweenMax.killTweensOf([self.$menuButtons, self.$logoSVG]);

				TweenMax.to(self.$menuButtons.not('.selected').not('.mouse-over'), 0.4, {color:'#bebebe', ease:Cubic.easeIn});
				TweenMax.to(self.$menuButtons.not('.selected').not('.mouse-over'), 0.4, {color:'#bebebe', ease:Sine.easeOut});
				
				TweenMax.to(self.$logoSVG, 0.4, {fill:'#3a9a92', ease:Cubic.easeIn});
				TweenMax.to(self.$logoSVG, 0.3, {fill:'#3a9a92', delay:0.1, ease:Sine.easeOut});
			},

			changeMenuColorNormal: function(){

				var self = this;
				
				if( !self.isShort && self.isHomepage && self.currentTarget == 'Contact' ){
					TweenMax.killTweensOf([self.$menuButtons, self.$logoSVG]);
					return false;
				}

				if(!self.isShort){
					TweenMax.killTweensOf([self.$menuButtons, self.$logoSVG]);
					TweenMax.to(self.$menuButtons, 0.5, {color:'#3a9a92', ease:Sine.easeOut});
					TweenMax.to(self.$logoSVG, 0.5, {fill:'#fff', ease:Sine.easeOut});
				}

			},

			// menu destroy confirmation

			_onMenuHidden: function(){

				var self = this

				if(self.expandedMenu){
					self.expandedMenu.exit();
					self.expandedMenu.off('hideComplete', self._onMenuHidden.bind(self));
					self.expandedMenu.off('mouseleave', $.proxy(self._onExpandedLeave, self));
					self.expandedMenu = null;
				}
			},

			_onWindowResize: function(){


				var self = this,
					winWidth = window.innerWidth,
					winHeight = window.innerHeight,
					$mainMenuWrapper = $('#main-menu-wrapper'),
					$longMainMenuWrapper = $("#main-menu-wrapper").not('.shorter');
				
				//recalculate isMobile property
				self.isMobile = window.innerWidth < window.MOBILE_WIDTH;
				
				//detach scroll handler
				$(window).off('scroll', $.proxy(self._onWindowScroll, self));
				
				//attach scroll handler for the desktop home page 
				if(!self.isMobile && self.isHomepage)
				{
					$(window).on('scroll', $.proxy(self._onWindowScroll, self));

					//set the appropriate menu state based on the presence (or absence) of the $topAlert element
					if( self.$topAlert != null ) {
						TweenMax.set( $longMainMenuWrapper, {top: 60} );
					}
					else {
						TweenMax.set( $longMainMenuWrapper, {top: 0} );
					}

				}

				if(!self.isFixed){

					if(winWidth < 992){

						TweenMax.set($mainMenuWrapper, {top: 0});
						
						if(self.isExpanded){
							$mainMenuWrapper.removeClass('absolute-nav');
						}

					}
					else {
						if(!$mainMenuWrapper.hasClass('absolute-nav')){
							$mainMenuWrapper.removeClass('absolute-nav').addClass('absolute-nav');
						}
					}
				}

				//reset the mobile main menu bar
				if( self.isMobile ) {
					TweenMax.set( $mainMenuWrapper, { top: 0 } );
				}

				//set the mobile hamburger overflow height
				if( self.isHamburgerExpanded ) {
					$('.mobile-main-menu').css('maxHeight', winHeight - 60);
				}

				//set the menu overflow height
				if( self.isExpanded && self.isMobile ) {
					$('.m-user-profile-inner').css('maxHeight', winHeight - 60);
				}

			},

			_onWindowScroll: function (event) {

				var self = this;

				self.closeMenu();
				
				self._setMenuState();
				
				TweenMax.killTweensOf([self.$initialRollOver]);
				TweenMax.set(self.$initialRollOver, {opacity: 0});
			},

			_setMenuState: function () {

				var self = this;

				if ( $(window).scrollTop() > 60 ) {
					self._setShortMenuState();
				}
				else {
					self._setLongMenuState();
				}

			},
			_setShortMenuState: function () {

				var self = this;

				if( !self.$el.hasClass('shorter m-border-grey') ) {
					self.$el
						.removeClass('bg-0')
							.addClass('shorter m-border-grey bg-2');
					
					self.$el.find('.container-inner')
						.removeClass('color-1 main-menu-centered col-xs-24')
							.addClass('color-15 left-spacing right-spacing')
								.attr("style", "");
					

					self.isShort = true;
					TweenMax.fromTo(	self.$el, 0.5, {top: -100}, {top:0, ease:Sine.easeOut}	);				
					TweenMax.set(	self.$expandedContainer, {y:-41}	);

					TweenMax.killTweensOf([self.$menuButtons, self.$logoSVG]);
					TweenMax.set(self.$menuButtons, {'color': '#b0b0b0'});
					TweenMax.set(self.$logoSVG, {fill:'#3a9a92'});
				}
			},
			_setLongMenuState: function () {

				var self = this,
					top = self.$topAlert != null && self.$topAlert.length != 0 && self.$topAlert.is(':visible') ? self.$topAlert.height() : 0;

				self.isShort = false;

				if( self.$topAlert != null )
					TweenMax.set(self.$topAlert, {opacity: 1});

				TweenMax.to(self.$el, 0.3, {top:top, ease:Sine.easeOut, onComplete: function() {

						self.$el
							.removeClass('shorter m-border-grey bg-2')
								.addClass('bg-0');
						
						self.$el.find('.container-inner')
							.removeClass('color-15 left-spacing right-spacing')
								.addClass('color-1 main-menu-centered col-xs-24')
									.attr('style', "");
								
						TweenMax.set(self.$expandedContainer, {y:0});
						
						if(self.$topAlert != null)
							TweenMax.to(self.$topAlert, 0.2, {opacity: 1, ease:Sine.easeOut});
						
						self.closeMenu();
						
						TweenMax.killTweensOf([self.$menuButtons, self.$logoSVG]);
						TweenMax.set(self.$menuButtons, {'color': '#fff'});
						TweenMax.set(self.$logoSVG, {fill:'#fff'});

					} 
				});
			}
			
			// ---------------------------------------------------------------------------------  /

		});

		return AppView;
	}
);
