define(
    'modules/property-filters/app',[
        'backbone',
        'underscore',
        'modules/property-filters/views/FiltersMenuView',
        'modules/property-filters/views/TagItemView',
        'modules/calendars/views/CalendarMenuView',
        'modules/property-filters/views/GuestsMenuView',
        'modules/property-filters/views/LocationsMenuView',

    ],
    function (Backbone, _, FiltersMenuView, TagItemView, CalendarMenuView,
              GuestsMenuView, LocationsMenuView) {

        var AppView = Backbone.View.extend({

            // vars
            lang: null,

            $filterMenuContainer: null,
            $moreFiltersMenu: null,
            $tagsContainer: null,
            $showFiltersButton: null,

            locationsMenu: null,
            calendarMenu: null,
            guestsMenu: null,

            dateCheckInQuery: null,
            dateCheckOutQuery: null,
            guestsQuery: null,
            locationQuery: null,

            $heroContainer: null,
            $filtersContainer: null,
            $overlayContainer: null,
            $filterOptions: null,
            $activeFilterButton: null,
            $mobileOverlaysContainer: null,
            $sortDropdown: null,

            currentMenu: null,
            isModalOpen: false,
            isMobile: false,

            queryResult: null,
            lastQueryString: null,

            filtersConfig: null,
            filtersMenu: null,
            tags: null,

            winWidth: 0,
            winHeight: 0,

            // init

            initialize: function () {

                var self = this;
                self.options = self.options || {};

                self.lang = $('#lang').text();

                self.loadAreasConfig();

                self.$heroContainer = $('#property-hero-filters-wrapper');
                self.$filtersContainer = $('#property-hero-filters');
                self.$overlayContainer = $('#property-hero-filters .property-hero-filters-overlays');
                self.$filterOptions = self.$filtersContainer.find('ul.filter-options');

                self.$filterMenuContainer = $('#all-filters-wrapper');
                self.$moreFiltersMenu = self.$filterMenuContainer.find('#filters-menu');

                self.$tagsContainer = self.$heroContainer.find('#tags-container');
                self.$showFiltersButton = self.$heroContainer.find('.show-filters');
                self.$applyFiltersButton = self.$heroContainer.find('.apply-filters');

                self.$sortDropdown = window.$rootContext('#summary-and-filter-row #sort-filter');

                self.searchFilters = {};

                // init vars

                self.tags = [];

                // fire event to page controller

                /*window.$vent.trigger('propertyFiltersReady');*/
            },

            // start

            start: function () {
                console.log("---------- propertyFiltersStart --------------");

                var self = this;

                $(function () {

                    self.initFiltersMenu();

                    self.locationMenu = $('select[name="location"]');
                    $(self.locationMenu).selectBoxIt({
                        isMobile: function () {
                            return false;
                        },
                        autoWidth: false,
                        // Uses the jQuery 'fadeIn' effect when opening the drop down
                        showEffect: "fadeIn",

                        // Sets the jQuery 'fadeIn' effect speed to 400 milleseconds
                        showEffectSpeed: 0,

                        // Uses the jQuery 'fadeOut' effect when closing the drop down
                        hideEffect: "fadeOut",

                        // Sets the jQuery 'fadeOut' effect speed to 400 milleseconds
                        hideEffectSpeed: 0
                    });

                    // locations
                    self.setLocationsSelect();

                    /*self.locationsMenu = new LocationsMenuView({
                     $container: self.$overlayContainer,
                     $mobileContainer: self.$mobileOverlaysContainer,
                     model: self.filtersConfig,
                     lang: self.lang
                     });*/

                    // calendars

                    self.calendarMenu = new CalendarMenuView({
                        $container: self.$overlayContainer,
                        $mobileContainer: self.$mobileOverlaysContainer,
                        hideClass: 'display-none',
                        model: self.filtersConfig,
                        lang: self.lang
                    });

                    // guests

                    self.guestsMenu = new GuestsMenuView({
                        $container: self.$overlayContainer,
                        $mobileContainer: self.$mobileOverlaysContainer,
                        model: self.filtersConfig,
                        lang: self.lang
                    });

                    self.addListeners();
                    self.loadFirstQuery();

                    // start

                    window.requestAnimationFrame(function () {

                        // this is only if there is no map

                        window.$vent.trigger('requestListings');
                        window.$vent.trigger('requestLocation');
                    });
                });
            },

            // listeners -----------------------------------------------------------------------  /

            addListeners: function () {

                var self = this;

                /*window.$vent.on('requestLocation', $.proxy(self._onRequestLocation, self));*/

                /*                self.locationsMenu.on('optionSelected', self._onLocationSelected, self);
                 self.locationsMenu.on('closeMenu', self._onCloseMenu, self);
                 self.locationsMenu.on('hide', self._onMenuClosed, self);*/
                self.locationMenu.each(function (i, item) {
                    $(item).on('change', $.proxy(self._onLocationSelected, self));
                });

                self.calendarMenu.on('optionSelected', self._onDateSelected, self);
                self.calendarMenu.on('closeMenu', self._onCloseMenu, self);
                self.calendarMenu.on('hide', self._onMenuClosed, self);
                self.calendarMenu.on('clearDates', self._onClearDates, self);

                self.guestsMenu.on('optionSelected', self._onGuestsSelected, self);
                self.guestsMenu.on('closeMenu', self._onCloseMenu, self);
                self.guestsMenu.on('hide', self._onMenuClosed, self);

                self.$sortDropdown.on('changed', $.proxy(self._onSortClick, self));

                self.$showFiltersButton.on('click', $.proxy(self._onFilterButtonClick, self));
                self.$applyFiltersButton.on('click', $.proxy(self._applyFilters, self));
                window.$vent.on('hideApplyFiltersButton', $.proxy(self.hideApplyFiltersButton, self));

                _.each(self.$filterOptions.find('li'), function (filterButton, i) {

                    var $filterButton = $(filterButton);
                    var defaultText = $filterButton.find('figcaption').html();
                    $filterButton.attr('data-default-text', defaultText);
                    $filterButton.on('click', $.proxy(self._onFilterOptionClick, self));
                });

                // select default option for location


                if (self.urlParam('page')) {
                    self.page = self.urlParam('page');
                } else {
                    self.page = 0;
                }

                self.applyParams();

                //

                $(window).on('resize', $.proxy(self.onWindowResize, self));
                self.onWindowResize();
            },

            // Load areas and zones to show in the dropdown

            loadAreasConfig: function () {

                var self = this;
                var dfd = $.Deferred();

                $.ajax({
                    type: 'GET',
                    url: "/global/searchConfig",
                    async: false,
                    jsonpCallback: 'callBack',
                    contentType: 'application/json',
                    dataType: 'jsonp',

                    success: function (json) {
                        self.filtersConfig = json.results;
                        console.log('Property listing config loaded', self.filtersConfig);
                        window.$vent.trigger('propertyFiltersReady');
                        dfd.resolve(json.results);
                    },
                    error: function (e) {
                        console.log('JSON Load Error', self);
                        console.log(e.message);
                        dfd.reject();
                    }
                });

                return dfd.promise();
            },

            //

            _onRequestLocation: function () {
                console.log("onRequestLocation");

                var self = this;
                var locationParam = self.urlParam('location');
                self.locationsMenu.triggerOption(locationParam);
            },


            applyParams: function () {
                var self = this;

                var locationParam = self.urlParam('location');

                if (locationParam) {
                    self.isLocationSelected = true;
                    self.locationQuery = locationParam;
                }

                var checkInParamRaw = '';
                var checkOutParamRaw = '';

                self.searchFilters.checkIn = Utils.MiscUtils.getCookie('checkIn');
                self.searchFilters.checkOut = Utils.MiscUtils.getCookie('checkOut');
                self.searchFilters.guests = Utils.MiscUtils.getCookie('guests');
                self.searchFilters.checkIn = self.searchFilters.checkIn ? self.searchFilters.checkIn : '';
                self.searchFilters.checkOut = self.searchFilters.checkOut ? self.searchFilters.checkOut : '';
                self.searchFilters.guests = self.searchFilters.guests ? self.searchFilters.guests : '';

                if (self.searchFilters) {
                    if (self.searchFilters.checkIn && self.searchFilters.checkOut) {
                        self.dateCheckInQuery = self.searchFilters.checkIn;
                        self.dateCheckOutQuery = self.searchFilters.checkIn;
                        checkInParamRaw = self.searchFilters.checkIn;
                        checkOutParamRaw = self.searchFilters.checkOut;
                        self.isDateSelected = true;
                    }

                    if (self.searchFilters.guests) {
                        self.guestsQuery = self.searchFilters.guests;
                        var guestsParam = self.searchFilters.guests;
                        self.isGuestsSelected = true;
                    }
                }

                var tagsParam = self.urlParam('tags');

                if (locationParam) {
                    self.setSelectedLocationOption(locationParam);
                }
                if (checkInParamRaw && checkOutParamRaw) {
                    var checkInParam = moment(checkInParamRaw).format('MM/DD') + "/20" + moment(checkInParamRaw).format('YY');
                    var checkOutParam = moment(checkOutParamRaw).format('MM/DD') + "/20" + moment(checkOutParamRaw).format('YY');
                }

                self.calendarMenu.triggerOption(checkInParamRaw, checkOutParamRaw);
                self.guestsMenu.triggerOption(guestsParam);
                if (tagsParam) {
                    self.filtersMenu.initTags(tagsParam);
                }

            },

            initSort: function () {
                var self = this;
                var sort = 0;
                if (self.urlParam('sort')) {
                    sort = self.urlParam('sort');
                    self.sortValue = "sort=" + sort;
                    self.$sortDropdown.data("selectBox-selectBoxIt").selectOption(sort);
                } else {
                    self.sortValue = "sort=" + 1;
                    self.$sortDropdown.data("selectBox-selectBoxIt").selectOption(0);
                }

            },
            initPage: function () {
                var self = this;
                if (self.urlParam('page')) {
                    var page = 0;
                    page = self.urlParam('page');
                    self.lastQueryString = self.removeParam(self.lastQueryString, 'page') + '&page=' + page;
                }
            },

            // listener methods

            _applyFilters: function (e) {
                var self = this;

                if (self.calendarMenu.isDateSelected == false) {
                    self.isDateSelected = false;
                    Utils.MiscUtils.deleteCookie('checkIn');
                    Utils.MiscUtils.deleteCookie('checkOut');
                }

                self.searchFilters.checkIn = Utils.MiscUtils.getCookie('checkIn');
                self.searchFilters.checkIn = self.searchFilters.checkIn ? self.searchFilters.checkIn : '';
                self.searchFilters.checkOut = Utils.MiscUtils.getCookie('checkOut') ? Utils.MiscUtils.getCookie('checkOut') : '';
                self.searchFilters.checkOut = self.searchFilters.checkOut ? self.searchFilters.checkOut : '';

                self.trackSearch();

                window.$vent.trigger('showSpinner', e.currentTarget);
                window.$vent.trigger('filterQueryUpdate', [self.lastQueryString]);
            },

            _onFilterOptionClick: function (e) {

                var self = this;
                var $target = $(e.currentTarget);
                var $nextFilterButton = $target;
                var nextMenu;

                self.isModalOpen = false;

                if ($target.hasClass('location')) {

                    nextMenu = self.locationsMenu;

                } else if ($target.hasClass('check-in')) {

                    $nextFilterButton = self.$filterOptions.find('.date-option');
                    nextMenu = self.calendarMenu;

                } else if ($target.hasClass('check-out')) {

                    $nextFilterButton = self.$filterOptions.find('.date-option');
                    nextMenu = self.calendarMenu;

                } else if ($target.hasClass('guests')) {

                    nextMenu = self.guestsMenu;
                }

                if (nextMenu && nextMenu !== self.currentMenu) {

                    self.isModalOpen = true;

                    if (e) {
                        e.stopPropagation();
                    }
                    if (self.currentMenu) {
                        self.closeCurrentMenu(true);
                    }

                    self.currentMenu = nextMenu;
                    self.$activeFilterButton = $nextFilterButton;

                    self.currentMenu.show();
                    //self.hideCurrentFilterButton();

                    self.$el.off('click', $.proxy(self._onOutClick, self));
                    self.$el.on('click', $.proxy(self._onOutClick, self));

                    if (self.winWidth < 768) {
                        self.$mobileOverlaysContainer.removeClass('display-none');
                    }

                    window.$vent.trigger('openFiltersMenu');
                }
            },

            _onLocationSelected: function (e) {

                var self = this;
                var currentTarget = e.currentTarget;

                self.locationMenu.parents('.select-container').addClass('active');
                self.isLocationSelected = true;
                self.locationQuery = $(currentTarget).val();
                self.locationText = $(currentTarget).find('option[value=' + self.locationQuery + ']').attr('t_text');

                window.$vent.trigger('createAreaFilter',[{},self]);

                self.updateQuery();

            },

            _onDateSelected: function (e) {

                var self = this;

                self.isDateSelected = true;
                self.dateCheckInQuery = e.checkInQuery;
                self.dateCheckOutQuery = e.checkOutQuery;

                Utils.MiscUtils.setCookie('checkIn', self.dateCheckInQuery, window.SEARCHFILTERS_COOKIE_EXPIRES);
                Utils.MiscUtils.setCookie('checkOut', self.dateCheckOutQuery, window.SEARCHFILTERS_COOKIE_EXPIRES);

                self.updateFilterButtonText('check-in', e.results);
                self.updateFilterButtonText('check-out', e.results2);
            },

            _onGuestsSelected: function (e) {

                var self = this;

                self.isGuestsSelected = true;
                self.guestsQuery = e.query;

                Utils.MiscUtils.setCookie('guests', self.guestsQuery, window.SEARCHFILTERS_COOKIE_EXPIRES);

                self.updateFilterButtonText('guests', e.results);
            },

            _onCloseMenu: function (e) {

                var self = this;
                self.closeCurrentMenu();
            },

            _onMenuClosed: function (e) {
                var self = this;
                self.searchFilters.guests = Utils.MiscUtils.getCookie('guests');
                self.searchFilters.guests = self.searchFilters.guests ? self.searchFilters.guests : '';
                self.updateQuery();
            },

            _onOutClick: function (e) {

                var self = this;

                if (e) {

                    if (!self.$filtersContainer[0].contains(e.target) && !self.filtersMenu.$el[0].contains(e.target) && document.contains(e.target)) {
                        self.closeCurrentMenu();
                    }

                    if ($(e.target).hasClass('close')) {
                        self.closeCurrentMenu();
                    }
                }
                else {
                    self.closeCurrentMenu();
                }
            },

            _onSortClick: function (e) {

                var self = this;
                if (self.loadFirst) {
                    self.loadFirst = false;
                } else {
                    self.page = 0;
                }
                self.checkSortValue();
            },

            checkSortValue: function () {
                var self = this;
                var selectIndex = parseInt(self.$sortDropdown.val());
                var selectValue = $(self.$sortDropdown.find('option')[selectIndex - 1]).data('sort-param');
                var sort = 0;
                if (selectValue) {
                    self.sortValue = selectValue;
                } else {
                    self.sortValue = "sort=" + 1
                }

                var currentUrl = window.location.search;
                var queryString = self.lastQueryString;
                currentUrl = self.removeParam(currentUrl, 'page');
                self.lastQueryString = self.removeParam(self.lastQueryString, 'page');
                if (self.urlParam('sort')) {
                    currentUrl = self.removeParam(currentUrl, 'sort');
                    self.lastQueryString = self.removeParam(self.lastQueryString, 'sort');
                }

                var newUrl = currentUrl + "&" + self.sortValue + "&page=" + self.page;
                self.lastQueryString += "&" + self.sortValue + "&page=" + self.page;

                window.history.replaceState({}, "", newUrl);
                window.$vent.trigger('filterQueryUpdate', [self.lastQueryString]);

            },

            // close menu

            closeCurrentMenu: function (maintainVeil) {
                var self = this;

                if (self.currentMenu && self.isModalOpen) {
                    console.info('1-closeCurrentMenu', self.currentMenu);

                    self.restoreCurrentFilterButton();

                    if (self.winWidth < 768 && !maintainVeil) {
                        self.currentMenu.on('hideComplete', function () {
                            self.$mobileOverlaysContainer.removeClass('display-none').addClass('display-none');
                        });
                    }

                    self.currentMenu.hide();
                    self.currentMenu = null;

                    self.$el.off('click', $.proxy(self._onOutClick, self));

                    if (maintainVeil !== true) {
                        window.$vent.trigger('closeFiltersMenu');
                    }
                }
            },

            // hide / restore clicked filter button values

            hideCurrentFilterButton: function () {

                var self = this;

                _.each(self.$activeFilterButton, function (filter, i) {
                    var $filter = $(filter);
                    TweenMax.to($filter.find('a'), 0.3, {opacity: 0, ease: Cubic.easeOut});
                });
            },

            restoreCurrentFilterButton: function () {
                console.info('1-restoreCurrentFilterButton');
                var self = this;

                _.each(self.$activeFilterButton, function (filter, i) {
                    var $filter = $(filter);
                    TweenMax.fromTo($filter.find('a'), 0, {opacity: 0}, {opacity: 1, ease: Quint.easeInOut});
                });
            },

            updateFilterButtonText: function (buttonClass, text) {

                var self = this;
                var $filterButton = self.$el.find('.filter-option.' + buttonClass);

                if (text) {
                    $filterButton.find('figcaption').html(text);
                    $filterButton.removeClass('selected').addClass('selected');
                }
                else {
                    $filterButton.find('figcaption').html($filterButton.data('default-text'));
                    $filterButton.removeClass('selected');
                }
            },

            // filters menu

            initFiltersMenu: function () {

                var self = this;
                console.log("initFiltersMenu self.filtersConfig", self.filtersConfig);
                self.filtersMenu = new FiltersMenuView({
                    el: $('#filters-menu-container'),
                    currency: self.filtersConfig.currency
                });

                self.filtersMenu.on('addTag', self._onAddTag, self);
                self.filtersMenu.on('removeTag', self._onRemoveTag, self);
                self.filtersMenu.on('hide', self._onFiltersMenuHide, self);
                self.filtersMenu.on('setSelectLocation', self._onSetSelectLocationMobile, self);

                // mobile filters buttons

                _.each(self.filtersMenu.$el.find('.top-level-filters .filter-option'), function (filterButton, i) {

                    var $filterButton = $(filterButton);
                    var defaultText = $filterButton.find('figcaption').html();
                    $filterButton.attr('data-default-text', defaultText);
                    $filterButton.on('click', $.proxy(self._onFilterOptionClick, self));
                });

                self.$mobileOverlaysContainer = self.filtersMenu.$el.find('.menu-mobile-overlays');
            },

            setLocationsSelect: function () {
                var self = this;
                var arrayOptions = [];
                var locations;
                var defaultOption = {}

                console.log("------- setLocationsSelect ", self.filtersConfig.locations.locations);
                /* Set default option */
                if (self.lang == 'es') {
                    defaultOption = {'value': 'default', 'text': 'Destino'}
                } else {
                    defaultOption = {'value': 'default', 'text': 'Location'}
                }
                arrayOptions.push(defaultOption);

                /* Set list of options */
                if (self.filtersConfig.locations.locations) {
                    locations = self.filtersConfig.locations.locations;


                    arrayOptions.push({
                        'value': locations.id,
                        'text': locations.title,
                        'data-locationType': 'location'
                    });

                    if ($(locations.regions).size() > 0) {
                        $(locations.regions).each(function (i, region) {
                            var singleObj = {}
                            singleObj['value'] = 'region-' + region.code;
                            singleObj['text'] = region.title + ' (' + region.properties + ')';
                            singleObj['t_text'] = region.title;
                            singleObj['data-locationType'] = 'region';
                            arrayOptions.push(singleObj);
                            if (region.cities) {
                                $(region.cities).each(function (i, city) {
                                    var singleObj = {}
                                    singleObj['value'] = 'city-' + city.code;
                                    singleObj['text'] = city.title + ' (' + city.properties + ')';
                                    singleObj['t_text'] = region.title;
                                    singleObj['data-locationType'] = 'city';
                                    singleObj['code'] = city.code;
                                    arrayOptions.push(singleObj);
                                });
                            }
                        });
                    }
                    self.locationMenu.each(function (i, select) {
                        $(select).data("selectBox-selectBoxIt").remove();
                        $(select).data("selectBox-selectBoxIt").add(arrayOptions);
                    })
                }

            },

            setSelectedLocationOption: function (locationParam) {
                var self = this;

                self.locationMenu.each(function (i, select) {
                    $(select).parents('.select-container').addClass('active');
                    var locationValue = locationParam.replace(/%20/g, ' ');
                    $(select).find("option[value='" + locationValue + "']").attr('selected', 'selected');
                    $(select).data("selectBox-selectBoxIt").refresh();
                })

            },

            // tags

            _onAddTag: function (e) {

                var self = this;

                console.log("----- onAddKeyword of propertyListing e ", e);

                if (!_.findWhere(self.tags, {id: e.id})) {

                    var tagItem = new TagItemView({
                        $container: self.$tagsContainer,
                        model: e,
                        id: e.id,
                    });

                    tagItem.on('destroy', self._onDestroyTag, self);
                    self.tags.push(tagItem);
                }
            },

            _onDestroyTag: function (e) {

                var self = this;
                var tagItem = e.target;

                console.log("---------- onDestroyTag in propertyFilters --------------");
                console.log("----- _onDestroyTag of propertyListing e ", e);

                if (tagItem) {

                    tagItem.off('destroy', self._onRemoveTag, self);
                    self.tags = _.without(self.tags, tagItem);

                    if (self.filtersMenu && tagItem.clickClosed) {
                        self.filtersMenu.removeTag(tagItem.model);
                        self.updateQuery();
                    }
                }
            },

            _onRemoveTag: function (e) {

                console.log("----- onRemoveKeyword of propertyListing e ", e);

                var self = this;
                var tagItem = _.findWhere(self.tags, {id: e.id});

                console.log('_onRemoveTag', e);

                if (tagItem) {

                    tagItem.off('destroy', self._onRemoveTag, self);
                    tagItem.exit();

                    self.tags = _.without(self.tags, tagItem);
                }
            },

            _onFiltersMenuHide: function () {
                console.log("---------- onFilterMenuHide --------------");

                var self = this;
                self.updateQuery();
            },

            // server query --------------------------------------------------------------------  /

            updateQuery: function () {
                console.log("--------- updateQuery ----------");

                var self = this;
                var queryString = 'location=';
                var urlString = 'location=';

                console.info('=====> FILTERS: ', self.locationQuery, self.searchFilters);

                /* Show applyFilters button */
                self.showApplyFiltersButton();

                if (self.isLocationSelected) {
                    urlString += self.locationQuery;
                    queryString += self.locationQuery;
                }
                if (self.isDateSelected) {
                    queryString += (queryString ? '&checkIn=' : '') + self.dateCheckInQuery;
                    queryString += (queryString ? '&checkOut=' : '') + self.dateCheckOutQuery;
                }
                if (self.isGuestsSelected) {
                    queryString += (queryString ? '&guests=' : '') + self.guestsQuery;
                }

                var tagQuery;

                if (self.tags.length) {

                    tagQuery = 'tags=';

                    _.each(self.tags, function (tag, i) {
                        var $tag = tag.$el;
                        if (i > 0) {
                            tagQuery += '|';
                        }
                        tagQuery += $tag.data('tag');
                    });
                    urlString += (urlString ? '&' : '') + tagQuery;
                    queryString += (queryString ? '&' : '') + tagQuery;
                }


                if (self.lastQueryString === null || (queryString && queryString != self.lastQueryString)) {
                    self.lastQueryString = queryString;

                    var page = self.urlParam('page');
                    var sort = self.urlParam('sort');
                    var newUrl = "?" + urlString + "&sort=" + sort + "&page=0";
                    window.history.replaceState("", "", newUrl);
                    self.lastQueryString = self.lastQueryString + "&sort=" + sort + "&page=0";
                    console.log("-----updateQuery------ ", self.lastQueryString);
                }
            },

            loadFirstQuery: function () {
                console.log("--------- loadFirstQuery ----------");

                var self = this;
                var queryString = 'location=';
                var urlString = 'location=';

                if (self.isLocationSelected) {
                    urlString += self.locationQuery;
                    queryString += self.locationQuery;
                }
                if (self.isDateSelected) {
                    queryString += (queryString ? '&checkIn=' : '') + self.dateCheckInQuery;
                    queryString += (queryString ? '&checkOut=' : '') + self.dateCheckOutQuery;
                }
                if (self.isGuestsSelected) {
                    queryString += (queryString ? '&guests=' : '') + self.guestsQuery;
                }

                var tagQuery;

                if (self.tags.length) {

                    tagQuery = 'tags=';

                    _.each(self.tags, function (tag, i) {
                        var $tag = tag.$el;
                        if (i > 0) {
                            tagQuery += '|';
                        }
                        tagQuery += $tag.data('tag');
                    });

                    urlString += (urlString ? '&' : '') + tagQuery;
                    queryString += (queryString ? '&' : '') + tagQuery;
                }

                self.loadFirst = true;
                if (self.lastQueryString === null || (queryString && queryString != self.lastQueryString)) {
                    self.lastQueryString = queryString;

                    console.log("-----loadFirstQuery------ ", self.lastQueryString);

                    self.initPage();
                    self.initSort();

                }
            },

            // listeners -----------------------------------------------------------------------  /

            _onFilterButtonClick: function (e) {

                var self = this;

                if (!self.filtersMenu) {
                    self.initFiltersMenu();
                }

                self.filtersMenu.show();
                e.preventDefault();
            },
            _onClearDates: function (e) {

                var self = this;
                self.isDateSelected = false;

                self.updateFilterButtonText('check-in');
                self.updateFilterButtonText('check-out');
            },

            // Apply filters

            showApplyFiltersButton: function () {
                var self = this;

                if (self.$applyFiltersButton.hasClass('hide')) {
                    self.$applyFiltersButton.removeClass('hide');
                }
            },
            hideApplyFiltersButton: function () {
                var self = this;

                if (!self.$applyFiltersButton.hasClass('hide')) {
                    window.$vent.trigger('hideSpinner');
                    self.$applyFiltersButton.addClass('hide');
                }
            },

            // window resizing

            onWindowResize: function (e) {

                var self = this;

                self.winWidth = $(window).width();
                self.winHeight = $(window).height();

                // change filter containers

                if (self.$mobileOverlaysContainer) {

                    if (self.winWidth >= 768 && self.isMobile) {

                        self.isMobile = false;

                        self.$overlayContainer.append(self.locationsMenu.$el);
                        self.$overlayContainer.append(self.calendarMenu.$el);
                        self.$overlayContainer.append(self.guestsMenu.$el);

                        self.locationsMenu.$container = self.$overlayContainer;
                        self.calendarMenu.$container = self.$overlayContainer;
                        self.guestsMenu.$container = self.$overlayContainer;
                    }
                    else if (self.winWidth < 768 && !self.isMobile) {

                        self.isMobile = true;

                        /*self.$mobileOverlaysContainer.append(self.locationsMenu.$el);*/
                        self.$mobileOverlaysContainer.append(self.calendarMenu.$el);
                        self.$mobileOverlaysContainer.append(self.guestsMenu.$el);

                        /*self.locationsMenu.$container = self.$mobileOverlaysContainer;*/
                        self.calendarMenu.$container = self.$mobileOverlaysContainer;
                        self.guestsMenu.$container = self.$mobileOverlaysContainer;
                    }
                }
            },

            //Function to get params from the url
            urlParam: function (name) {
                console.log("---------------- urlParam", name);

                var results = new RegExp('[?&]' + name + '=([^&#]*)').exec(window.location.href);
                if (results) {
                    return results[1]
                } else {
                    return 0
                }
            },

            removeParam: function (url, parameter) {
                return url
                    .replace(new RegExp('[?&]' + parameter + '=[^&#]*(#.*)?$'), '$1')
                    .replace(new RegExp('([?&])' + parameter + '=[^&]*&'), '$1');
            },

            trackSearch: function () {
                console.log("-- trackSearch --");
                var self = this;
                var checkIn;
                var checkOut;
                var guests;
                var location;
                var urlCode = window.location.href;

                if (self.isDateSelected) {
                    checkIn = moment(self.dateCheckInQuery, 'MM/DD/YY').format('YYYY/MM/DD');
                    checkOut = moment(self.dateCheckOutQuery, 'MM/DD/YY').format('YYYY/MM/DD');
                }

                if (self.isLocationSelected) {
                    location = self.locationText;
                }

                if (self.isGuestsSelected) {
                    guests = self.guestsQuery;
                }

                window.dataLayer.push({
                    'event': 'track',
                    'trackAction': 'search',
                    'trackCategory': 'Listing Search',
                    'trackLabel': {
                        'location': location,
                        'checkIn': checkIn,
                        'checkOut': checkOut,
                        'guests': guests
                    }
                });
            },

        });

        return AppView;
    }
);

