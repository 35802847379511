define(
    'modules/spinner/app',[
        'backbone',
    ],
    function (Backbone) {

        var AppView = Backbone.View.extend({

                // core vars
                lang: null,
                $spinnerContainers: null,

                // init

                initialize: function (options) {

                    window.$vent.trigger('spinnerReady');

                    var self = this;

                },

                // start module --------------------------------------------------------------------  /

                start: function () {

                    var self = this;
                    $(function () {
                        self.onDomReady();
                    });


                },

                onDomReady: function () {

                    var self = this;

                    self.getSpinnerContainers();

                    window.$vent.on('spinnerButtonInDom', $.proxy(self._onGetSpinnerContainers, self));
                    window.$vent.on('showSpinner', $.proxy(self._onShowSpinner, self));
                    window.$vent.on('hideSpinner', $.proxy(self._onHideSpinner, self));


                },

                _onGetSpinnerContainers: function () {
                    var self = this;
                    console.log("self.$spinnerContainers", self.$spinnerContainers);

                    self.getSpinnerContainers();
                },
                _onShowSpinner: function (e, data) {
                    var self = this;
                    var button = $(data);

                    if(!$(button).hasClass('spinner-container')){
                        button = $(button).find('.spinner-container');
                    };
                    button.addClass('btn-disabled');
                    button.find('.spinner').removeClass('hide');

                },
                _onHideSpinner: function () {
                    var self = this;
                    self.getSpinnerContainers();

                    self.$spinnerContainers.each(function (i, container) {
                        $(container).removeClass('btn-disabled');
                        if ($(container).prop('tagName').toLowerCase() == 'img') {
                            self.$spinnerContainers.siblings('.spinner').addClass('hide');
                        } else {
                            $(container).find('.spinner').addClass('hide');
                        }
                    });

                },
                getSpinnerContainers: function () {
                    var self = this;

                    self.$spinnerContainers = $('.spinner-container');
                    console.log("self.$spinnerContainers", self.$spinnerContainers.prop('tagName'));
                }
                ,

            })
            ;

        return AppView;
    }
);
