define(
	'modules/main-menu/views/ExpandedMenuView',[
		'backbone',
		'utils/AbstractView',
	], 
	function(Backbone, AbstractView, Template){

		var BaseView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			options: null,
			abstractView: null,

			// options

			firstSection: null,

			// vars

			$exploreSection: null,
			$helpSection: null,
			$userSection: null,
			$destinationsSection: null,

			$currentSection: null,
			currentSectionId: null,

			$sectionThumbs: null,
			$veil: null,

			isFirstSection: true,

			// init

			initialize: function(options){

				var self = this;

				self.options = options;
				self.$container = self.options.$container;
				
				self.template = _.template($('#expanded-main-menu-template').html());
				self.model = $.extend({

				}, self.model);

				//

				self.firstSection = self.options.firstSection || 'explore';
				self.isFirstSection = true;

				// 

				self.abstractView = new AbstractView({
					view: self,
					autoShow: true
				});
			},

			//

			onRender: function(){

				var self = this;

				console.log('expanded menu view el: ');
				console.dir(self.$el);

				self.$exploreSection = self.$el.find('.section-explore');
				self.$destinationsSection = self.$el.find('.section-destinations');
				self.$helpSection = self.$el.find('.section-help');
				self.$userSection = self.$el.find('.section-user');
			},

			onDomReady: function(){

				var self = this;
				var $bg = self.$el.find('.nav-bg');

				TweenMax.set($bg, {y:-80});

				self.changeSection(self.firstSection);
			},

			// add / remove listeners

			onAddListeners: function(){

				var self = this;
				$('#main-menu-wrapper').on('mouseleave', $.proxy(self._onMouseLeave, self));
				$('.logout').on('click', $.proxy(self._onLogoutClick, self));
			},

			onRemoveListeners: function(){

				var self = this;
				$('#main-menu-wrapper').off('mouseleave', $.proxy(self._onMouseLeave, self));
				$('.logout').off('click', $.proxy(self._onLogoutClick, self));
			},

			// listener methods

			_onMouseLeave: function(e){

				var self = this;
				self.trigger('mouseleave');
			},

			_onLogoutClick: function(e){

				var self = this;
				window.$vent.trigger('logout');
			},

			//

			changeSection: function(sectionId){

				var self = this;

				if(sectionId && sectionId != self.currentSectionId){
					
					var lastSectionId = self.currentSectionId;

					if(self.$currentSection){ self.closeCurrentSection(); }
					if(lastSectionId){
						self.$el.removeClass('section-' + self.currentSectionId);
					}

					self.currentSectionId = sectionId;
					self.$currentSection = self.$el.find('.section-' + sectionId);
					self.$el.removeClass('section-' + self.currentSectionId).addClass('section-' + self.currentSectionId);
					
					self.openCurrentSection();
				}
			},

			openCurrentSection: function(){

				var self = this;

				if(self.$currentSection){
						
					self.addSectionListeners();

					if(self.currentSectionId === 'destinations'){
                        self.$el.removeClass('tall').addClass('tall');
                    }else if(self.currentSectionId === 'user'){
                        self.$el.removeClass('medium').addClass('medium');
                    }

					var d = self.isFirstSection ? 0.1 : 0.3;
					var $header = self.$currentSection.find('.exp-nav-headers');
					var $thumbs = self.$currentSection.find('.subnav-link');
					var $navCol = self.$currentSection.find('.exp-nav-right');

					TweenMax.killTweensOf([self.$currentSection, $header, $thumbs, $navCol]);
					TweenMax.fromTo(self.$currentSection, 0.3, {opacity:0}, {opacity:1, delay:d, ease:Cubic.easeOut});
					TweenMax.fromTo($header, 0.6, {opacity:0}, {y:0, opacity:1, delay:d, ease:Cubic.easeOut});

					_.each($thumbs, function(thumb, i){
						var $thumb = $(thumb);
						TweenMax.fromTo($thumb, 0.4, {y:12, opacity:0}, {y:0, opacity:1, delay:d + i * 0.06, ease:Sine.easeOut});
					});

					if($navCol.length){
						TweenMax.fromTo($navCol, 0.6, {opacity:0}, {opacity:1, delay:d + 0.2, ease:Sine.easeOut});
					}

					self.$currentSection.removeClass('hidden');		
					self.isFirstSection = false;			
					
					if(self.currentSectionId === 'user'){
						var count = parseInt(	$("#expand-user .count").html()	);
						$("#favorites-number").html( count );
					}
				}
			},

			closeCurrentSection: function(animationTime){

				var self = this;
				var animationTime = animationTime ? animationTime : 0.4;
				
				if(self.$currentSection){

					self.removeSectionListeners();
					if(self.currentSectionId === 'destinations'){
                        self.$el.removeClass('tall');
                    }else if(self.currentSectionId === 'user'){
                        self.$el.removeClass('medium');
                    }

					var $lastSection = self.$currentSection;
					var $header = $lastSection.find('.exp-nav-headers');
					var $thumbs = $lastSection.find('.subnav-link');
					var $navCol = $lastSection.find('.exp-nav-right');

					self.$currentSection = null;
					self.currentSectionId = null;

					TweenMax.killTweensOf([$lastSection, $header, $thumbs, $navCol]);
					TweenMax.to($lastSection, animationTime, {opacity:0, ease:Sine.easeOut, onComplete:function(){
						$lastSection.removeClass('hidden').addClass('hidden');
					}});
				}
			},

			addSectionListeners: function(){

				var self = this;

				if(self.$currentSection && !self.$currentSection.hasClass('listening')){

					self.$sectionThumbs = self.$currentSection.find('a.subnav-link');
					_.each(self.$sectionThumbs, function(sectionThumb, i){
						
						self.$currentSection.addClass('listening');
						var $sectionThumb = $(sectionThumb);

						$sectionThumb.on('mouseenter', $.proxy(self._onThumbOver, self));
						$sectionThumb.on('mouseleave', $.proxy(self._onThumbOut, self));
						$sectionThumb.on('click', $.proxy(self._onThumbClick, self));
					});
				}
			},

			removeSectionListeners: function(){

				var self = this;
				
				if(self.$currentSection && self.$currentSection.hasClass('listening')){

					self.$currentSection.removeClass('listening');
					self.$sectionThumbs = self.$currentSection.find('a.subnav-link');

					_.each(self.$sectionThumbs, function(sectionThumb, i){
						
						var $sectionThumb = $(sectionThumb);

						$sectionThumb.off('mouseenter', $.proxy(self._onThumbOver, self));
						$sectionThumb.off('mouseleave', $.proxy(self._onThumbOut, self));
						$sectionThumb.off('click', $.proxy(self._onThumbClick, self));
					});
				}
			},

			_onThumbOver: function(e){

				var self = this;
				var $target = $(e.currentTarget);

				if(!$target.hasClass('over')){
					
					var $lastTarget = self.$sectionThumbs.find('.over');
					$lastTarget.removeClass('over');
					$target.addClass('over');

					var $otherThumbs = self.$sectionThumbs.not('.over');
					
					_.each($otherThumbs, function(thumb, i){						
						var $thumb = $(thumb);
						var $veil = $thumb.find('.white-veil');
						TweenMax.set($veil, {opacity:1});
					});
				}
			},

			_onThumbOut: function(e){

				var self = this;
				var $target = $(e.currentTarget);
				
				if($target.hasClass('over')){
					
					$target.removeClass('over');

					var $otherThumbs = self.$sectionThumbs.not('.over');
					
					_.each($otherThumbs, function(thumb, i){						
						var $thumb = $(thumb);
						var $veil = $thumb.find('.white-veil');
						TweenMax.set($veil, {opacity:0});
					});
				}
			},

			_onThumbClick: function(e){

				var self = this;
				var $target = $(e.currentTarget);
			},

			_onVeilClick: function(e){

				var self = this;
				self.trigger('mouseleave');
			},

			// show / hide

			onShow: function(){

				var self = this;
				var $bg = self.$el.find('.nav-bg');

				if(!self.$veil){ 
					self.$veil = $('<div class="veil"></div>').appendTo($('#main-wrapper'));
					self.$veil.on('click', $.proxy(self._onVeilClick, self));
				}

				TweenMax.killTweensOf([self.$veil, self.$el]);
				TweenMax.to($bg, 0.4, {y:0, ease:Quint.easeOut});

				TweenMax.to(self.$veil, 1.0, {opacity:1, ease:Cubic.easeOut});
				TweenMax.to(self.$el, 0.2, {opacity:1, ease:Sine.easeOut});

				TweenMax.to(self.$el, 0.6, {ease:Cubic.easeOut, onComplete:function(){
					self.trigger('showComplete');
				}});
			},

			onHide: function(){

				var self = this;
				var $bg = self.$el.find('.nav-bg');

				self.removeSectionListeners();

				if(self.$veil){

					self.$veil.off('click', $.proxy(self._onVeilClick, self));
	
					TweenMax.killTweensOf(self.$veil);
					TweenMax.to(self.$veil, 0.5, {opacity:0, ease:Sine.easeInOut, onComplete:function(){
						self.$veil.remove();
						self.$veil = null;
					}});
				}

				if(self.$currentSection){
					self.closeCurrentSection();
					//TweenMax.to(self.$currentSection, 0.3, {opacity:0, ease:Cubic.easeOut});
				}

				TweenMax.killTweensOf(self.$el);
				TweenMax.to(self.$el, 0.2, {opacity:0, ease:Sine.easeOut});

				TweenMax.to(self.$el, 0.6, {onComplete:function(){
					TweenMax.set($bg, {y:-80});
					self.trigger('hideComplete');
				}});
			},

			// ---------------------------------------------------------------------------------  /

		});

	return BaseView;
});
