
define(
	'modules/global-positioning-helpers/views/MatchingVerticalRowView',[
		'backbone',
		'utils/AbstractView',
	], 
	function(Backbone, AbstractView){

		var MatchingVerticalRowView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			options: null,
			abstractView: null,

			$cols: null,

			winWidth: null,

			// init

			initialize: function(options){

				var self = this;

				// options vars

				self.options = options;
				self.$container = self.options.$container;

				// template config

				//self.template = _.template(Template);
				self.model = $.extend({}, self.model);

				// initialize AbstractView

				self.abstractView = new AbstractView({
					view: self,
					autoShow: self.options.autoShow !== false,
				});
			},

			onDomReady: function(){

				var self = this;
				self.$cols = self.$el.find('.vert-match-col');
			},

			onWindowResize: function(){

				var self = this;
				var tMaxHeight = 0;
				var tWinWidth = window.innerWidth;

				if(self.winWidth != tWinWidth){

					self.winWidth = tWinWidth;

					for(var i=0; i<self.$cols.length; i++){					
						var $col = self.$cols[i];
						TweenMax.set($col, {clearProps:'minHeight'});
					}

					window.requestAnimationFrame(function(){

						for(var i=0; i<self.$cols.length; i++){					
							var $col = $(self.$cols[i]);
							tMaxHeight = Math.max($col.height(), tMaxHeight);
						}

						for(var i=0; i<self.$cols.length; i++){					
							var $col = $(self.$cols[i]);
							TweenMax.set($col, {minHeight:tMaxHeight});
						}
						
					});
				}
			},

		});

	return MatchingVerticalRowView;
});
