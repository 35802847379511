define(
	'modules/multi-form/app',[
		'backbone',
		'modules/multi-form/views/MultiFormView',

		'selectboxit'
	], 
	function(Backbone, MultiFormView){

		var AppView = Backbone.View.extend({

			// core vars

			$context: null,

			$formContainers: null,
			forms: null,

			// init

			initialize: function(options){

				var self = this;
				self.$context = $;
			},

			// start module --------------------------------------------------------------------  /

			start: function(){

				var self = this;						
				self.forms = [];

				self.checkCalendarButtons();
				
				window.$vent.trigger('multiFormReady');
				window.$vent.on('domUpdate', $.proxy(self._onDomUpdate, self));
			},

			_onDomUpdate: function(){

				var self = this;
				self.checkCalendarButtons();
			},
			
			// button types

			checkCalendarButtons: function(){

				var self = this;

				self.$formContainers = $('.multi-form');

				_.each(self.$formContainers, function(formContainer, i){

					var $formContainer = $(formContainer);
										
					if(!self.checkExisting($formContainer)){

						var multiForm = new MultiFormView({
							el: $formContainer,
							$context: self.$context
						});

						self.forms.push(multiForm);
					}
				});	
			},

			// check for existing buttons ------------------------------------------------------  /

			checkExisting: function(el){

				var self = this;
				var exists = false;

				if(typeof(el.context) !== 'undefined'){ el = el[0]; }

				_.each(self.forms, function(view, i){

					var viewEl = view.$el[0];
					if(typeof(viewEl.context) !== 'undefined'){ viewEl = viewEl[0]; }

					if(viewEl === el){
						exists = true;
					}
				});

				return exists;
			},

			// ---------------------------------------------------------------------------------  /

		});

		return AppView;
	}
);
