define(
	'modules/calendars/app',[
		'backbone',
		'modules/calendars/views/CalendarMenuView',
		'modules/calendars/views/CalendarMenuButtonsView',

		'clndr',
		'moment'
	], 
	function(Backbone, CalendarMenuView, CalendarMenuButtonsView){

		var AppView = Backbone.View.extend({

			// core vars
			lang:null,

			$context: null,

			$calendarContainers: null,
			$calendarButtonContainers: null,

			calendars: null,
			calendarButtons: null,

			// init

			initialize: function(options){

				var self = this;
				self.$context = $;

				self.lang = $('#lang').text();

			},

			// start module --------------------------------------------------------------------  /

			start: function(){

				var self = this;						
				self.calendars = [];
				self.calendarButtons = [];

				window.$vent.off('defaultDatesMobile', $.proxy(self.setSelectedDates,self));
				window.$vent.on('defaultDatesMobile', $.proxy(self.setSelectedDates,self));
				window.$vent.trigger("calendarMobileLoaded");
				self._onDomUpdate();
				
				window.$vent.trigger('calendarsReady');
				window.$vent.on('domUpdate', $.proxy(self._onDomUpdate, self));
			},

			_onDomUpdate: function(){
				var self = this;

				self.checkCalendars();
				self.checkCalendarButtons();
			},
			
			// calendars

			checkCalendars: function(){

				var self = this;
				self.$calendarContainers = $('.calendars-container');

				_.each(self.$calendarContainers, function(calendarContainer, i){

					var $calendarContainer = $(calendarContainer);
										
					if(!self.checkExisting($calendarContainer, self.calendars, true)){

						var calendarMenu = new CalendarMenuView({
							$container: $calendarContainer,
							$context: self.$context,
							isEmbedded: true,
							autoShow: true,
							lang:self.lang,
							selectedDates: self.selectedDates
						});

						self.calendars.push(calendarMenu);
					}
				});	
			},

			// calendar buttons

			checkCalendarButtons: function(){

				var self = this;
				self.$calendarButtonContainers = $('.calendar-buttons');

				_.each(self.$calendarButtonContainers, function(calendarButtonsContainer, i){

					var $calendarButtonsContainer = $(calendarButtonsContainer);
										
					if(!self.checkExisting($calendarButtonsContainer, self.calendarButtons)){

						var $calendarsContainer = $('#' + $calendarButtonsContainer.data('mobile-overlay-id'));
						
						var calendarButtons = new CalendarMenuButtonsView({
							el: $calendarButtonsContainer,
							$context: self.$context,
							$calendarsContainer: $calendarsContainer,
							selectedDates: self.selectedDates
						});

						self.calendarButtons.push(calendarButtons);
					}
				});	
			},

			// check for existing buttons ------------------------------------------------------  /

			checkExisting: function(el, searchArray, useContainer){

				var self = this;
				var exists = false;

				if(typeof(el.context) !== 'undefined'){ el = el[0]; }

				_.each(searchArray, function(view, i){

					var viewEl = view.$el[0];
					if(useContainer === true){ viewEl = view.$container[0]; }

					if(typeof(viewEl.context) !== 'undefined'){ viewEl = viewEl[0]; }

					if(viewEl === el){
						exists = true;
					}
				});

				return exists;
			},

			// ---------------------------------------------------------------------------------  /

			setSelectedDates: function(e,data){
				var self = this;

				console.info(">>>>>>>>> setSelectedDates in calendars app ",data.selectedDates.checkInDate);
				console.info(">>>>>>>>> setSelectedDates in calendars app ",e);
				if(data.selectedDates){
					self.selectedDates = data.selectedDates;
				}

			},

		});

		return AppView;
	}
);
