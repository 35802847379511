
/* Utilities for Array-related methods */

if(typeof(Utils) === 'undefined' || !Utils){ Utils = {}; }

Utils.ArrayUtils = {

	// remove specific entry

	removeObject: function(array, value){
		if(array){
			var pos = array.indexOf(value);
			if(pos !== -1){ array.splice(value); }
		}
	},

	// only add to array if value does not already exist

	pushUnique: function(array, value){
		var pos = array.indexOf(value);
		if(pos == -1){ array.push(value); }
		return array;
	},

	/**
	 * Randomize array element order in-place.
	 * Using Fisher-Yates shuffle algorithm.
	 * from http://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
	 */

	shuffleArray: function(array) {

	    for (var i = array.length - 1; i > 0; i--) {
	        var j = Math.floor(Math.random() * (i + 1));
	        var temp = array[i];
	        array[i] = array[j];
	        array[j] = temp;
	    }
	    return array;
	},

	createShuffledArray: function(length){

		var shuffledArray = [];
		for(var i = 0; i<length; i++){
			shuffledArray.push(i);
		}

		return this.shuffleArray(shuffledArray);
	},

	// compare if values of two arrays are equal

	matchingContents: function(array1, array2){

		var self = this;

		if(!array1 && !array2){ return false; }
		if(!array1 || !array2){ return false; }

		if((_.difference(array1, array2).length === 0) && (_.difference(array2, array1).length === 0)){
			return true;
		}

		return false;
	},

	// -----------------------------------------------------------------------------------------  /

};
define("utils/utils/ArrayUtils", function(){});

