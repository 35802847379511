define(
    'modules/property-detail-bar/views/SidebarRequestFormView',[
        'backbone',
        'utils/AbstractView',
        'text!../templates/SidebarRequestFormTemplate.html',
    ],
    function (Backbone, AbstractView, Template) {

        var SidebarRequestFormView = Backbone.View.extend({

            // core vars


            $el: null,
            $container: null,
            options: null,
            abstractView: null,

            // vars

            $lang: null,

            $centeredContent: null,
            $floatingSocials: null,
            $realBookingTitle: null,

            $requestForm: null,
            $sendRequestFormButton: null,

            $requestFormMessages: null,
            $favoriteButton: null,

            isRealTimeBooking: false,

            // init

            initialize: function (options) {
                var self = this;

                self.options = options;
                self.$container = self.options.$container;
                self.el = self.options.el;
                self.isRealTimeBooking = self.options.isRealTimeBooking;

                self.template = _.template(Template);
                self.model = $.extend({}, self.model);

                //

                self.abstractView = new AbstractView({
                    view: self,
                    autoShow: self.options.autoShow === true,
                });

            },

            onRender: function () {

                var self = this;

                self.$lang = $('#lang').html();
                self.$centeredContent = self.$el.find('.vert-center-content');
                self.$floatingSocials = self.$el.find('.floating-socials');
                self.$realBookingTitle = self.$el.find('.real-booking-title');
                self.$requestForm = self.$el.find('.request-form');
                self.$sendRequestFormButton = self.$el.find('.btn-send-form');
                self.$favoriteButton = self.$el.find('.btn-add-to-favorites');
                self.$facebookShare = self.$el.find('.facebook-share');
                self.$twitterShare = self.$el.find('.twitter-share');
                self.socialUrl = $('#social-url').text()

            },

            onDomReady: function () {

                var self = this;

                window.$vent.trigger('domUpdate');
            },

            // listeners

            onAddListeners: function () {

                var self = this;

                self.$favoriteButton.on('click', $.proxy(self._onFavoriteClick, self));

                self.$facebookShare.on('click', $.proxy(self._onFacecbookShareClick, self));
                self.$twitterShare.on('click', $.proxy(self._onTwitterShareClick, self));

                $(window).on('resize', $.proxy(self._onWindowResize, self));
            },

            _onFacecbookShareClick: function () {
                var self = this;

                var popup = window.open("https://www.facebook.com/sharer/sharer.php?u=" + self.socialUrl, "pop", "width=600, height=400, scrollbars=no");
            },

            _onTwitterShareClick: function () {
                var self = this;

                var popup = window.open("https://twitter.com/share?url=" + self.socialUrl, "pop", "width=600, height=400, scrollbars=no");

            },

            _onFavoriteClick: function (e) {
                var self = this;

                window.$vent.trigger('favoriteClick',[e.currentTarget,self]);
                e.preventDefault();
                return false;

            },

            onRemoveListeners: function () {
                var self = this;

                self.$sendFormButton.off('click', $.proxy(self._onBookingReqClick, self));
                self.$favoriteButton.off('click', '.favorite', $.proxy(self._onFavoriteClick, self));
                self.$favoriteButton.off('click', '.not-favorite', $.proxy(self._onSelectedFavoriteClick, self));

                $(window).off('resize', $.proxy(self._onWindowResize, self));
            },

            // show / hide

            onShow: function () {
                var self = this;

                self.trigger('showComplete');
                window.requestAnimationFrame(self._onWindowResize.bind(self));
            },

            getMinHeight: function () {
                var self = this;

                var centeredContentHeight = self.$centeredContent.outerHeight(true);

                return centeredContentHeight + 65 * 2;
            },

            // window resize

            _onWindowResize: function () {
                var self = this;

                var winHeight = window.innerHeight;
                var centeredContentHeight = self.$centeredContent.outerHeight(true) + 45;

                if (winHeight > centeredContentHeight) {

                    TweenMax.set(self.$el, {
                        clearProps: 'height'
                    });

                    TweenMax.set(self.$centeredContent, {
                        top: Math.round((winHeight - centeredContentHeight) / 2),
                    });

                    TweenMax.set(self.$floatingSocials, {
                        //top: winHeight - 45,
                    });

                    self.trigger('setFixed');
                }
                else {

                    TweenMax.set(self.$el, {
                        height: self.getMinHeight()
                    });

                    TweenMax.set(self.$centeredContent, {
                        top: Math.round((winHeight - centeredContentHeight) / 2) + 105,
                    });

                    TweenMax.set(self.$floatingSocials, {
                        //clearProps: 'top',
                    });

                    self.trigger('setAbsolute');
                }
            },

        });

        return SidebarRequestFormView;
    });

