define(
    'modules/hero-filters/app',[
        'backbone',

        'modules/calendars/views/CalendarMenuView',
        'modules/guests/views/GuestsMenuView',


        'clndr'
    ],
    function (Backbone, CalendarMenuView, GuestsMenuView) {

        var AppView = Backbone.View.extend({

            // vars

            filtersConfig: null,

            locationMenu: null,
            calendarMenu: null,
            guestsMenu: null,

            dateQuery: null,
            guestsQuery: null,
            locationQuery: null,

            $heroContainer: null,
            $heroContent: null,
            $heroInfo: null,

            $filtersContainer: null,
            $overlayContainer: null,
            $filterOptions: null,

            currentMenu: null,
            $activeFilterButton: null,

            isModalOpen: false,
            isMobile: false,

            queryResult: null,
            lastQueryString: null,

            // init

            initialize: function () {

                var self = this;
                self.options = self.options || {};

                self.lang = $('#lang').text();
                self.loadHeroConfig();

                self.$heroContainer = $('#hero');
                self.$heroContent = self.$heroContainer.find('.hero-content-middle');
                self.$heroInfo = self.$heroContainer.find('.hero-info');
                self.$filtersContainer = $('#hero-filters');
                self.$overlayContainer = $('#hero-filters-overlays');
                self.$filterOptions = self.$filtersContainer.find('ul.filter-options');
                self.searcherButton = self.$heroContainer.find('.hero-btn').find('#seacher-link');
                self.listingLink = self.$heroContainer.find('.hero-btn').find('.results').find('a').attr('href');

                self.searchFilters = {};

                //console.log('self.filtersConfig',self.filtersConfig);
                //console.dir(self.filtersConfig);

            },

            // start

            start: function () {

                var self = this;

                self.locationMenu = $('select[name="location"]');

                $(self.locationMenu).selectBoxIt({
                    isMobile: function () {
                        return false;
                    },
                    autoWidth: false,
                    // Uses the jQuery 'fadeIn' effect when opening the drop down
                    showEffect: "fadeIn",

                    // Sets the jQuery 'fadeIn' effect speed to 400 milleseconds
                    showEffectSpeed: 0,

                    // Uses the jQuery 'fadeOut' effect when closing the drop down
                    hideEffect: "fadeOut",

                    // Sets the jQuery 'fadeOut' effect speed to 400 milleseconds
                    hideEffectSpeed: 0
                });

                self.rebuildMenus();

                //

                $(function () {
                    self.addListeners();
                    self.show();
                });
            },

            loadHeroConfig: function () {

                var self = this;
                var dfd = $.Deferred();

                $.ajax({
                    type: 'GET',
                    url: "/global/searchConfig",
                    async: false,
                    jsonpCallback: 'callBack',
                    contentType: 'application/json',
                    dataType: 'jsonp',

                    success: function (json) {
                        self.filtersConfig = json.results;
                        console.log('Hero config loaded', self.filtersConfig);
                        window.$vent.trigger('heroFiltersReady');
                        dfd.resolve(json.results);
                    },
                    error: function (e) {
                        console.log('JSON Load Error', self);
                        console.log(e.message);
                        dfd.reject();
                    }
                });

                return dfd.promise();
            },

            // rebuild menus (if switched from desktop to mobile or otherwise)

            rebuildMenus: function () {

                var self = this;

                /*if (self.locationsMenu) {
                 self.locationsMenu.exitImmediately();
                 }*/
                if (self.calendarMenu) {
                    self.calendarMenu.exitImmediately();
                }
                if (self.guestsMenu) {
                    self.guestsMenu.exitImmediately();
                }

                // determine overlay container

                if (window.innerWidth >= window.MOBILE_WIDTH) {
                    self.$overlayContainer = $('#hero-filters-overlays');
                }
                else {
                    self.$overlayContainer = $('#modal-content-wrapper');
                }

                // locations
                self.setLocationsSelect();

                // calendars

                self.calendarMenu = new CalendarMenuView({
                    $container: self.$overlayContainer,
                    model: self.filtersConfig,
                    autoShow: false,
                });

                // guests

                self.guestsMenu = new GuestsMenuView({
                    $container: self.$overlayContainer,
                    model: self.filtersConfig,
                    autoShow: false,
                });

                // menu listeners

                //window.requestAnimationFrame(function () {

                /*                    self.locationsMenu.on('optionSelected', self._onLocationSelected, self);
                 self.locationsMenu.on('closeMenu', self._onCloseMenu, self);
                 self.locationsMenu.on('hide', self._onMenuClosed, self);*/

                self.calendarMenu.on('clearDates', self._onClearDates, self);
                self.calendarMenu.on('optionSelected', self._onDateSelected, self);
                self.calendarMenu.on('closeMenu', self._onCloseMenu, self);
                self.calendarMenu.on('hide', self._onMenuClosed, self);

                self.guestsMenu.on('optionSelected', self._onGuestsSelected, self);
                self.guestsMenu.on('closeMenu', self._onCloseMenu, self);
                self.guestsMenu.on('hide', self._onMenuClosed, self);
                //});
            },

            // listeners -----------------------------------------------------------------------  /

            addListeners: function () {

                var self = this;

                _.each(self.$filterOptions.find('li'), function (filterButton, i) {

                    var $filterButton = $(filterButton);
                    var defaultText = $filterButton.find('figcaption').html();
                    $filterButton.attr('data-default-text', defaultText);
                    $filterButton.on('click', $.proxy(self._onFilterOptionClick, self));
                });

                self.locationMenu.on('change', $.proxy(self._onLocationSelected, self));
                self.searcherButton.on('click', $.proxy(self._onSearchProperties, self));

                self.searchFilters.checkIn = Utils.MiscUtils.getCookie('checkIn') ? Utils.MiscUtils.getCookie('checkIn') : '';
                self.searchFilters.checkOut = Utils.MiscUtils.getCookie('checkOut') ? Utils.MiscUtils.getCookie('checkOut') : '';
                self.searchFilters.guests = Utils.MiscUtils.getCookie('guests') ? Utils.MiscUtils.getCookie('guests') : '';

                if (self.searchFilters && self.searchFilters.checkIn && self.searchFilters.checkOut) {
                    console.log('self.searchFilters found triggering calendar', self.searchFilters);
                    self.calendarMenu.isDateSelected = true;
                    self.calendarMenu.triggerOption(self.searchFilters.checkIn, self.searchFilters.checkOut);
                }
                if (self.searchFilters && self.searchFilters.guests) {
                    console.log('self.searchFilters found triggering guests', self.searchFilters);
                    self.guestsMenu.isGuestSelected = true;
                    self.guestsMenu.triggerOption(self.searchFilters.guests);
                }

                $(window).on('resize', $.proxy(self.onWindowResize, self));
                self.onWindowResize();
            },

            show: function () {

                var self = this;
                TweenMax.fromTo(self.$heroContent, 0.6, {opacity: 0}, {opacity: 1, ease: Cubic.easeInOut});
            },

            // listener methods

            _onFilterOptionClick: function (e) {

                var self = this;
                var $target = $(e.currentTarget);
                var $nextFilterButton = $target;
                var nextMenu;

                self.isModalOpen = false;

                if ($target.hasClass('location')) {

                    nextMenu = self.locationsMenu;

                } else if ($target.hasClass('check-in')) {

                    $nextFilterButton = self.$filterOptions.find('.date-option');
                    nextMenu = self.calendarMenu;

                } else if ($target.hasClass('check-out')) {

                    $nextFilterButton = self.$filterOptions.find('.date-option');
                    nextMenu = self.calendarMenu;

                } else if ($target.hasClass('guests')) {

                    nextMenu = self.guestsMenu;
                }

                if (nextMenu && nextMenu !== self.currentMenu) {

                    self.isModalOpen = true;

                    if (e) {
                        e.stopPropagation();
                    }
                    if (self.currentMenu) {
                        self.closeCurrentMenu();
                    }

                    self.currentMenu = nextMenu;
                    self.$activeFilterButton = $nextFilterButton;

                    self.currentMenu.show();
                    self.hideCurrentFilterButton();

                    self.$el.off('click', $.proxy(self._onOutClick, self));
                    self.$el.on('click', $.proxy(self._onOutClick, self));

                    self.$overlayContainer.removeClass('hidden');

                    window.$vent.trigger('openFiltersMenu');

                    e.preventDefault();
                }
            },

            _onLocationSelected: function (e) {

                var self = this;
                var currentTarget = e.currentTarget;

                self.locationMenu.parents('.select-container').addClass('active');
                self.isLocationSelected = true;
                self.locationQuery = $(currentTarget).val();
                self.locationText = $(currentTarget).find('option[value=' + self.locationQuery + ']').attr('t_text');
                console.log("id ", self.locationQuery);
                self.refreshQuery();
            },

            _onDateSelected: function (e) {

                var self = this;

                console.dir(e);

                self.isDateSelected = true;
                self.dateCheckInQuery = e.checkInQuery;
                self.dateCheckOutQuery = e.checkOutQuery;

                Utils.MiscUtils.setCookie('checkIn', self.dateCheckInQuery, window.SEARCHFILTERS_COOKIE_EXPIRES);
                Utils.MiscUtils.setCookie('checkOut', self.dateCheckOutQuery, window.SEARCHFILTERS_COOKIE_EXPIRES);

                self.updateFilterButtonText('check-in', e.results);
                self.updateFilterButtonText('check-out', e.results2);
            },

            _onClearDates: function (e) {

                var self = this;

                self.updateFilterButtonText('check-in');
                self.updateFilterButtonText('check-out');
            },

            _onGuestsSelected: function (e) {

                var self = this;

                self.isGuestsSelected = true;
                self.guestsQuery = e.query;

                var pos = self.guestsQuery.indexOf('guests=');
                if (pos === 0) {
                    self.guestsQuery = self.guestsQuery.substr(7);
                }

                Utils.MiscUtils.setCookie('guests', self.guestsQuery, window.SEARCHFILTERS_COOKIE_EXPIRES);

                self.updateFilterButtonText('guests', e.results);
            },

            _onCloseMenu: function (e) {

                var self = this;
                self.closeCurrentMenu();
            },

            _onMenuClosed: function (e) {

                var self = this;

                if (self.calendarMenu.isDateSelected == false) {
                    self.isDateSelected = false;
                    Utils.MiscUtils.deleteCookie('checkIn');
                    Utils.MiscUtils.deleteCookie('checkOut');
                }

                self.searchFilters.checkIn = Utils.MiscUtils.getCookie('checkIn');
                self.searchFilters.checkOut = Utils.MiscUtils.getCookie('checkOut');
                self.searchFilters.guests = Utils.MiscUtils.getCookie('guests');
                self.searchFilters.checkIn = self.searchFilters.checkIn ? self.searchFilters.checkIn : '';
                self.searchFilters.checkOut = self.searchFilters.checkOut ? self.searchFilters.checkOut : '';
                self.searchFilters.guests = self.searchFilters.guests ? self.searchFilters.guests : '';

                self.refreshQuery();
            },

            _onOutClick: function (e) {

                var self = this;

                if (e) {

                    if (!self.$overlayContainer[0].contains(e.target) && $('body')[0].contains(e.target)) {
                        self.closeCurrentMenu();
                    }
                }
                else {
                    self.closeCurrentMenu();
                }
            },

            _onSearchProperties: function (e) {
                var self = this;

                var url = self.searcherButton.attr('href');
                self.trackSearch();

                window.location.replace(url);

                return false;
            },

            // close menu

            closeCurrentMenu: function (e) {

                var self = this;

                if (self.currentMenu && self.isModalOpen) {

                    self.restoreCurrentFilterButton();

                    self.currentMenu.on('hideComplete', function (e) {
                        if (!self.currentMenu) {
                            self.$overlayContainer.removeClass('hidden').addClass('hidden');
                        }
                    });

                    self.currentMenu.hide();
                    self.currentMenu = null;

                    self.$el.off('click', $.proxy(self._onOutClick, self));
                }
            },

            // hide / restore clicked filter button values

            setLocationsSelect: function () {
                var self = this;
                var arrayOptions = [];
                var locations;
                var defaultOption = {}

                /* Set default option */
                if (self.lang == 'es') {
                    defaultOption = {'value': 'default', 'text': 'Destino'}
                } else {
                    defaultOption = {'value': 'default', 'text': 'Location'}
                }
                arrayOptions.push(defaultOption);

                /* Set list of options */
                if (self.filtersConfig.locations.locations) {
                    locations = self.filtersConfig.locations.locations;

                    arrayOptions.push({
                        'value': locations.id,
                        'text': locations.title,
                        't_text': locations.title,
                        'data-locationType': 'location'
                    });

                    if ($(locations.regions).size() > 0) {
                        $(locations.regions).each(function (i, region) {
                            var singleObj = {}
                            singleObj['value'] = 'region-' + region.code;
                            singleObj['text'] = region.title + ' (' + region.properties + ')';
                            singleObj['t_text'] = region.title;
                            singleObj['data-locationType'] = 'region';
                            arrayOptions.push(singleObj);
                            if (region.cities) {
                                $(region.cities).each(function (i, city) {
                                    var singleObj = {}
                                    singleObj['value'] = 'city-' + city.code;
                                    singleObj['text'] = city.title + ' (' + city.properties + ')';
                                    singleObj['t_text'] = city.title;
                                    singleObj['data-locationType'] = 'city';
                                    singleObj['code'] = city.code;
                                    arrayOptions.push(singleObj);
                                });
                            }
                        });
                    }
                    console.log(arrayOptions);
                    self.locationMenu.data("selectBox-selectBoxIt").remove();
                    self.locationMenu.data("selectBox-selectBoxIt").add(arrayOptions);
                }

            },


            hideCurrentFilterButton: function () {

                var self = this;

                _.each(self.$activeFilterButton, function (filter, i) {
                    var $filter = $(filter);
                    TweenMax.to($filter.find('a'), 0.3, {opacity: 0, ease: Cubic.easeOut});
                });
            },

            restoreCurrentFilterButton: function () {

                var self = this;

                _.each(self.$activeFilterButton, function (filter, i) {
                    var $filter = $(filter);
                    TweenMax.fromTo($filter.find('a'), 0, {opacity: 0}, {opacity: 1, ease: Quint.easeInOut});
                });
            },

            updateFilterButtonText: function (buttonClass, text) {

                var self = this;
                var $filterButton = self.$el.find('.filter-option.' + buttonClass);

                if (text) {
                    $filterButton.find('figcaption').html(text);
                    $filterButton.removeClass('selected').addClass('selected');
                }
                else {
                    $filterButton.find('figcaption').html($filterButton.data('default-text'));
                    $filterButton.removeClass('selected');
                }
            },

            // server query and results --------------------------------------------------------  /

            refreshQuery: function () {

                var self = this;

                var queryString = self.$filtersContainer.data('query-url') || '';
                //var queryString = '/propertyListing/propertyListingResults';

                if (self.isLocationSelected) {
                    queryString = self.replaceURLParam(queryString, 'location', self.locationQuery);
                }

                if (self.searchFilters) {
                    console.log('refreshQuery self.searchFilters', self.searchFilters);
                    queryString = self.replaceURLParam(queryString, 'checkIn', self.searchFilters.checkIn);
                    queryString = self.replaceURLParam(queryString, 'checkOut', self.searchFilters.checkOut);
                }
                //if (self.isDateSelected) {
                //    //queryString = self.replaceURLParam(queryString, 'checkIn', self.dateCheckInQuery);
                //    //queryString = self.replaceURLParam(queryString, 'checkOut', self.dateCheckOutQuery);
                //}
                if (self.isGuestsSelected) {
                    queryString = self.replaceURLParam(queryString, 'guests', self.guestsQuery);
                }

                //////

                console.log('refreshQuery', self.lastQueryString);

                if (!self.isLocationSelected) {
                    self.showLocationMessage();
                }
                else {

                    if (queryString && queryString != self.lastQueryString) {

                        // TODO - show loading status

                        var loadStartTime = (new Date()).getTime();
                        var minTime = 1;

                        self.lastQueryString = queryString;
                        self.showQueryProgress();

                        $.when($.proxy(self.queryServer, self)()).then(function (e) {
                            var d = Math.max((minTime - ((new Date()).getTime() - loadStartTime) / 1000), 0);
                            console.log('query count e: ', e);

                            if ($('#lang').text() == "en") {
                                self.queryResult = '<i class="icon search-icon">Q</i><span class="count">' + e.length + '</span> Available Villas.<span class="hidden-xs"> See results</span><i class="icon arrow-icon">)</i>';
                            } else {
                                self.queryResult = '<i class="icon search-icon">Q</i><span class="count">' + e.length + '</span> Villas Disponibles.<span class="hidden-xs"> Ver resultados</span><i class="icon arrow-icon">)</i>';
                            }
                            self.showQueryResults(d);
                        });
                    }
                }
            },

            queryServer: function () {

                var self = this;
                var dfd = $.Deferred();

                console.log("queryServer - self.lastQueryString: ", self.lastQueryString);

                $.ajax({

                    type: 'GET',
                    url: self.lastQueryString,
                    async: true,
                    jsonpCallback: 'callBack',
                    contentType: 'application/json',
                    dataType: 'jsonp',

                    success: function (json) {
                        dfd.resolve(json.results);
                    },
                    error: function (e) {
                        console.log('queryServer JSON Load Error', self);
                        console.log(e.message);
                        dfd.reject();
                    }
                });

                return dfd.promise();
            },

            showQueryProgress: function () {

                var self = this;
                var $resultsButton = self.$heroContainer.find('.hero-btn');
                var $loadingText = $resultsButton.find('.idle figcaption');
                var $resultsState = $resultsButton.find('.results');
                var $resultsText = $resultsState.find('a');
                console.log('================', $resultsText);

                if ($resultsState.hasClass('hidden')) {

                    TweenMax.to($loadingText, 0.3, {
                        opacity: 0, ease: Cubic.easeOut, onComplete: function () {
                            $loadingText.html('<span class="spinner"></span>');
                            TweenMax.fromTo($loadingText, 0.3, {y: 6}, {y: 0, opacity: 1, ease: Sine.easeOut});
                        }
                    });
                }
                else {
                    $resultsText.addClass('btn-disabled');

                    TweenMax.to($resultsText, 0.3, {
                        opacity: 0, ease: Cubic.easeOut, onComplete: function () {
                            $resultsText.find('figcaption').html('<span class="spinner"></span>');
                            TweenMax.fromTo($resultsText, 0.3, {y: 6}, {y: 0, opacity: 1, ease: Sine.easeOut});
                        }
                    });
                }
            },

            showLocationMessage: function () {

                var self = this;
                var $resultsButton = self.$heroContainer.find('.hero-btn');
                var $loadingText = $resultsButton.find('.idle figcaption');

                TweenMax.to($loadingText, 0.3, {
                    opacity: 0, ease: Cubic.easeOut, onComplete: function () {
                        if ($('#lang').text() == "en") {
                            $loadingText.html('Please Select Location');
                        } else {
                            $loadingText.html('Seleciona una ubicación');
                        }

                        TweenMax.fromTo($loadingText, 0.3, {y: 6}, {y: 0, opacity: 1, ease: Sine.easeOut});
                    }
                });
            },

            showQueryResults: function (d) {

                var self = this;
                var $resultsButton = self.$heroContainer.find('.hero-btn');
                var $loadingText = $resultsButton.find('.idle figcaption');
                var $resultsState = $resultsButton.find('.results');
                var $resultsText = $resultsState.find('a');

                if (self.queryResult) {
                    var locationsConfig = self.filtersConfig.locations.locations.regions;
                    var locationType = self.locationQuery.split('-')[0];
                    var locationId = self.locationQuery.split('-')[1];
                    var selectedArea = "";
                    var selectedZone = "";
                    console.log('locationsConfig', locationsConfig);
                    console.log('self.locationQuery', self.locationQuery);
                    _.each(locationsConfig, function (reg) {
                        if (locationType == 'city') {
                            _.each(reg.cities, function (cit) {
                                if (cit.code == locationId) {
                                    console.log('cit.code found', cit.code);
                                    selectedArea = cit;
                                    selectedZone = reg;
                                }
                            })
                        } else {
                            if (reg.code == locationId) {
                                selectedZone = reg;
                            }
                        }
                    });


                    var resultsLink = '';
                    if (selectedZone) {
                        if (selectedArea) {
                            resultsLink = self.listingLink + "/" + selectedZone.id + "/" + selectedArea.id;
                        } else {
                            resultsLink = self.listingLink + "/" + selectedZone.id;
                        }
                    } else {
                        resultsLink = self.listingLink
                    }

                    if (self.isLocationSelected) {
                        resultsLink = self.replaceHashParam(resultsLink, 'location', self.locationQuery);
                    }

                    $resultsText.attr('href', resultsLink);


                    console.log('$resultsText.href', $resultsText.attr('href'));

                    if ($resultsState.hasClass('hidden')) {

                        TweenMax.to($loadingText, 0.3, {
                            opacity: 0, ease: Cubic.easeOut, delay: d, onComplete: function () {

                                $resultsState.removeClass('hidden');
                                $resultsText.find('figcaption').html(self.queryResult);

                                TweenMax.fromTo($resultsState, 0.5, {opacity: 0}, {opacity: 1, ease: Cubic.easeOut});
                            }
                        });
                    }
                    else {

                        TweenMax.to($resultsText, 0.3, {
                            opacity: 0, ease: Cubic.easeOut, delay: d, onComplete: function () {

                                $resultsText.find('figcaption').html(self.queryResult);

                                TweenMax.fromTo($resultsText, 0.3, {opacity: 0}, {opacity: 1, ease: Cubic.easeOut});
                            }
                        });
                    }
                }
                else {

                    d = 0.3;

                    if (!$resultsState.hasClass('hidden')) {

                        TweenMax.to($resultsState, 0.3, {
                            opacity: 0, ease: Cubic.easeOut, onComplete: function () {
                                $resultsState.removeClass('hidden').addClass('hidden');
                            }
                        });
                    }
                    else {
                        TweenMax.to($loadingText, 0.3, {opacity: 0, ease: Cubic.easeOut});
                    }

                    TweenMax.delayedCall(d, function () {
                        $loadingText.html('Searching...');
                        TweenMax.fromTo($loadingText, 0.3, {y: 6}, {y: 0, opacity: 1, ease: Sine.easeOut});
                    });
                }

                $resultsText.removeClass('btn-disabled');
            },

            replaceHashParam: function (url, paramName, paramValue) {

                var pattern = new RegExp('(' + paramName + '=).*?(&|$)');
                var newUrl = url;

                if (url.search(pattern) >= 0) {
                    newUrl = url.replace(pattern, '$1' + paramValue + '$2');
                }
                else {
                    newUrl = newUrl + (newUrl.indexOf('?') >= 0 ? '&' : '?') + paramName + '=' + paramValue;
                }
                return newUrl;
            },

            replaceURLParam: function (url, paramName, paramValue) {

                var pattern = new RegExp('(' + paramName + '=).*?(&|$)');
                var newUrl = url;

                if (url.search(pattern) >= 0) {
                    newUrl = url.replace(pattern, '$1' + paramValue + '$2');
                }
                else {
                    newUrl = newUrl + (newUrl.indexOf('?') >= 0 ? '&' : '?') + paramName + '=' + paramValue;
                }
                return newUrl;
            },

            // window resizing

            onWindowResize: function (e) {

                var self = this;

                self.winWidth = window.innerWidth;
                self.winHeight = window.innerWidth;
                self.docHeight = $(document).outerHeight();

                if (self.winWidth >= window.MOBILE_WIDTH) {

                    var filtersScale = 1;
                    var infoScale = 1;
                    var paddingScale = 1;

                    if (self.winWidth < 1300 && self.winWidth >= 768) {

                        var minFiltersScale = (768 - 80) / 1200;
                        var minInfoScale = 0.8;
                        var minPaddingScale = 0;
                        var widthDiff = 1300 - 768;

                        filtersScale = (widthDiff - (1300 - self.winWidth)) / widthDiff * (1 - minFiltersScale) + minFiltersScale;
                        infoScale = (widthDiff - (1300 - self.winWidth)) / widthDiff * (1 - minInfoScale) + minInfoScale;
                        paddingScale = (widthDiff - (1300 - self.winWidth)) / widthDiff * (1 - minPaddingScale) + minPaddingScale;
                    }

                    //TweenMax.set(self.$filtersContainer, {scale:filtersScale});
                    TweenMax.set(self.$heroContent, {bottom: 18 * (filtersScale * filtersScale) + '%'});
                    //TweenMax.set(self.$heroInfo, {transformOrigin:'50% 100%', scale:infoScale, paddingBottom:0 * paddingScale + 'px'});

                    if (self.isMobile) {
                        //self.$filtersContainer.append(self.$overlayContainer);
                    }

                    //TweenMax.set(self.$overlayContainer, {clearProps:'zIndex,top,height'});

                    self.isMobile = false;
                }
                else {

                    TweenMax.set(self.$filtersContainer, {scale: 1});
                    TweenMax.set(self.$heroContent, {bottom: 0 + '%'});
                    TweenMax.set(self.$heroInfo, {transformOrigin: '50% 100%', scale: 1});

                    if (!self.isMobile) {
                        //$('.page-wrapper').append(self.$overlayContainer);
                    }

                    //TweenMax.set(self.$overlayContainer, {height:self.docHeight, top:0, zIndex:10000});
                    self.isMobile = true;


                }
            },

            // ---------------------------------------------------------------------------------  /

            trackSearch: function () {
                var self = this;
                var checkIn;
                var checkOut;
                var guests;
                var location;
                var urlCode = window.location.href;

                if (self.isDateSelected) {
                    checkIn = moment(self.dateCheckInQuery, 'MM/DD/YY').format('YYYY/MM/DD');
                    checkOut = moment(self.dateCheckOutQuery, 'MM/DD/YY').format('YYYY/MM/DD');
                }

                if (self.isLocationSelected) {
                    location = self.locationText;
                }

                if (self.isGuestsSelected) {
                    guests = self.guestsQuery;
                }

                window.dataLayer.push({
                    'event': 'track',
                    'trackAction': 'search',
                    'trackCategory': 'Home Search',
                    'trackLabel': {
                        'location': location,
                        'checkIn': checkIn,
                        'checkOut': checkOut,
                        'guests': guests
                    }
                });
            },

        });

        return AppView;
    }
);

