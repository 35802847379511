
define(
	'modules/global-scripted-buttons/views/FocusButtonView',[
		'backbone',
		'utils/AbstractView',
		'utils/AbstractButtonView',
	], 
	function(Backbone, AbstractView, AbstractButtonView){

		var FocusButtonView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			options: null,
			abstractView: null,
			abstractButtonView: null,

			// init

			initialize: function(options){

				var self = this;

				// options vars

				self.options = options;
				self.$container = self.options.$container;

				// template config

				self.model = $.extend({}, self.model);

				// initialize AbstractView and AbstractButtonView

				self.abstractView = new AbstractView({
					view: self,
					autoShow: self.options.autoShow !== false,
				});

				self.abstractButtonView = new AbstractButtonView({
					abstractView: self.abstractView,
				});
			},

			// render

			_onDocumentClick: function(e){

				var self = this;
				
				if(!self.$el[0].contains(e.target)){
					self.blur();
				}
			},

			onAnimateClick: function(){

				var self = this;
					
				if(self.$el.hasClass('focused')){ self.blur(); }
				else { self.focus(); }
			},

			focus: function(){

				var self = this;
				$(document).off('click', $.proxy(self._onDocumentClick, self));

				self.$el.removeClass('focused').addClass('focused');
					
				window.requestAnimationFrame(function(){
					$(document).on('click', $.proxy(self._onDocumentClick, self));
				});
			},

			blur: function(){

				var self = this;
				$(document).off('click', $.proxy(self._onDocumentClick, self));
					
				self.$el.removeClass('focused');	
			}

			// ---------------------------------------------------------------------------------  /

		});

	return FocusButtonView;
});
