
define(
	'modules/global-scripted-buttons/views/UpgradeButtonView',[
		'backbone',
		'utils/AbstractView',
		'utils/AbstractButtonView',
	], 
	function(Backbone, AbstractView, AbstractButtonView){

		var UpgradeButtonView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			options: null,
			abstractView: null,
			button: null,

			// vars

			isWaitingForDeselect: false,

			// init

			initialize: function(options){

				var self = this;

				self.options = options;
				self.$container = self.options.$container;
				self.el = self.options.el;

				// 

				self.abstractView = new AbstractView({
					view: self,
					autoShow: true
				});

				self.button = new AbstractButtonView({
					abstractView: self.abstractView,
					animateInteractionsWhenSelected: true,
					selectable: true,
				});
			},

			// render

			onRender: function(){

				var self = this;
			},

			onAddListeners: function(){

				var self = this;
				$(window).on('resize', $.proxy(self._onWindowResize, self));
			},

			//

			onShow: function(){

				var self = this;
				if(self.$el.hasClass('selected')){
					self.select();
				}

				self.trigger('showComplete');
			},

			//

			onAnimateOver: function(e){

				var self = this;

				if(!self.button.isSelected){
					// TweenMax.killTweensOf(self.$el);
					// TweenMax.to(self.$el, 0.4, {opacity:1, ease:Cubic.easeOut});
				}
			},

			onAnimateOut: function(e){

				var self = this;

				if(!self.button.isSelected && !self.isWaitingForDeselect){
					// TweenMax.killTweensOf(self.$el);
					// TweenMax.to(self.$el, 0.4, {opacity:0.25, ease:Cubic.easeOut});
				}
			},

			onAnimateSelect: function(e){

				var self = this;
				var $deselectedState = self.$el.find('.deselected-state');
				var $deselectedText = $deselectedState.find('add').add($deselectedState.find('.price'));
				
				var $selectedState = self.$el.find('.selected-state');
				var $selectedText = $selectedState.find('span');
				var $check = $selectedState.find('.check');
				
				var btnHeight = self.$el.height();

				TweenMax.killTweensOf([$selectedState, $deselectedState, $deselectedText, $selectedText, $check]);

				TweenMax.to([$selectedState, $deselectedState], 0.4, {y:-btnHeight, ease:Quint.easeOut, onComplete:function(){
					$deselectedState.removeClass('hidden').addClass('hidden');
				}});

				TweenMax.to($deselectedText, 0.3, {opacity:0, ease:Cubic.easeInOut});

				TweenMax.fromTo($selectedText, 0.4, {opacity:0, y:6}, {opacity:1, y:0, delay:0.24, ease:Cubic.easeOut});
				TweenMax.fromTo($check, 0.3, {scale:0, opacity:1}, {scale:1, delay:0.22, ease:Back.easeOut});

				$selectedState.removeClass('hidden');
				self.$el.removeClass('selected').addClass('selected');
			},

			onAnimateDeselect: function(e){

				var self = this;

				var $deselectedState = self.$el.find('.deselected-state');
				var $selectedState = self.$el.find('.selected-state');

				var $deselectedText = $deselectedState.find('.add').add($deselectedState.find('.price'));
				var $selectedText = $selectedState.find('span').add($selectedState.find('.check'));

				TweenMax.killTweensOf([$selectedState, $deselectedState, $deselectedText, $selectedText]);

				TweenMax.to([$selectedState, $deselectedState], 0.4, {y:0, ease:Quint.easeOut, onComplete:function(){
					$selectedState.removeClass('hidden').addClass('hidden');
				}});
				
				TweenMax.to($selectedText, 0.3, {opacity:0, ease:Cubic.easeInOut});
				TweenMax.fromTo($deselectedText, 0.4, {opacity:0, y:6}, {opacity:1, y:0, delay:0.24, ease:Cubic.easeOut});
				
				$deselectedState.removeClass('hidden');
				self.$el.removeClass('selected');
			},

			//

			_onWindowResize: function(){

				var self = this;

				var $deselectedState = self.$el.find('.deselected-state');
				var $deselectedText = $deselectedState.find('add').add($deselectedState.find('.price'));
				
				var $selectedState = self.$el.find('.selected-state');
				var $selectedText = $selectedState.find('span');
				var $check = $selectedState.find('.check');
				
				var btnHeight = self.$el.height();

				if(self.$el.hasClass('selected')){
					
					TweenMax.set([$selectedState, $deselectedState], {y:-btnHeight});
					TweenMax.set($selectedText, {opacity:1, y:0});
					TweenMax.set($check, {scale:1});

					$deselectedState.removeClass('hidden').addClass('hidden');
					$selectedState.removeClass('hidden');
				}
				else {
					
					TweenMax.set([$selectedState, $deselectedState], {y:0});
					TweenMax.set($selectedText, {opacity:0});
					TweenMax.set($deselectedText, {opacity:1});

					$selectedState.removeClass('hidden').addClass('hidden');
					$deselectedState.removeClass('hidden');
				}
			},

			// ---------------------------------------------------------------------------------  /

		});

	return UpgradeButtonView;
});
