define('modules/property-filters/views/FiltersMenuView',[

        'backbone',
        'utils/AbstractView',

        'text!../templates/FiltersMenuTemplate.html',
        'text!../../../config/filters-menuEn.json',
        'text!../../../config/filters-menuEs.json',
    ],

    function (Backbone, AbstractView, Template, FiltersDataEn, FiltersDataEs) {

        var FiltersMenuView = Backbone.View.extend({

            // core vars

            $el: null,
            $container: null,
            options: null,
            abstractView: null,
            lang: null,
            isLogged: null,

            // vars

            filtersData: null,

            $desktopContainer: null,
            $mobileContainer: null,

            $roomsSelector: null,
            $bathsSelector: null,
            $favoritesToggle: null,
            $lastMinToggle: null,
            $tagField: null,

            $closeButton: null,

            priceSlider: null,
            priceSliderTagModel: null,

            bedroomsTagModel: null,
            bathroomsTagModel: null,
            featuresTagModel: null,
            areasTagModel: null,
            sizesTagModel: null,
            isFavoritesToggled: null,

            keywordTags: null,

            //

            initialize: function (options) {

                var self = this;

                self.options = options;
                self.$container = self.options.$container;
                self.el = self.options.el;
                self.id = self.options.id;
                self.lang = $('#lang').text();

                //Check language
                if (self.lang == "es") {
                    self.filtersData = JSON.parse(FiltersDataEs);
                } else {
                    self.filtersData = JSON.parse(FiltersDataEn);
                }

                //Check weather its logged
                self.isLogged = $('#isLogged').text() ? true : null;

                self.template = _.template(Template);
                self.model = $.extend({}, self.filtersData, {'isLogged': self.isLogged});

                //

                self.$desktopContainer = $('#all-filters-wrapper');
                self.$mobileContainer = $('#overlay-content-wrapper');

                //

                self.abstractView = new AbstractView({
                    view: self,
                    autoShow: false
                });
            },

            onDomReady: function () {

                var self = this;

                /* self.trigger('setSelectLocation', self);*/

                // select fields

                self.$roomsSelector = self.$el.find('.filter-rooms-wrapper .filters-select');
                self.$roomsSelector.selectBoxIt({
                    isMobile: function () {
                        return false;
                    },
                    autoWidth: false
                });
                self.$roomsSelector.on('change', $.proxy(self._onSelectBedroom, self));

                self.$bathsSelector = self.$el.find('.filter-bathrooms-wrapper .filters-select');
                self.$bathsSelector.selectBoxIt({
                    isMobile: function () {
                        return false;
                    },
                    autoWidth: false
                });
                self.$bathsSelector.on('change', $.proxy(self._onSelectBathroom, self));

                self.$areaSizeSelector = self.$el.find('.filter-sizes-wrapper .filters-select');
                self.$areaSizeSelector.selectBoxIt({
                    isMobile: function () {
                        return false;
                    },
                    autoWidth: false
                });
                self.$areaSizeSelector.on('change', $.proxy(self._onSelectSize, self));

                // areas select

                self.$areaSelector = self.$el.find('.filter-areas-wrapper .filters-select');
                self.$areaSelector.selectBoxIt({
                    isMobile: function () {
                        return false;
                    },
                    autoWidth: false
                });
                self.$areaSelector.on('change', $.proxy(self._onSelectArea, self));
                window.$vent.on('createAreaFilter', $.proxy(self._onCreateAreaFilter, self));

                // keyword tag field

                var taggingOptions = {
                    "no-duplicate": true,
                    "forbidden-chars": [",", ".", "_", "?"],
                    "no-spacebar": true,
                    "edit-on-delete": false,

                    "tag-template": '<div class="tag"><figure class="tag-symbol">#</figure><span class="tag-text"><%= text %></span><input type="hidden" name="<%= inputName %>" value="<%= inputText %>" /><button class="tag-i tag-x-button"></button></div>',
                };
                self.$tagField = self.$el.find('.keyword-field');
                self.$tagField.tagging(taggingOptions);
                self.$tagField.on('addTag', $.proxy(self._onAddKeywordTag, self));
                self.$tagField.on('removeTag', $.proxy(self._onRemoveKeywordTag, self));

                // checkboxes

                self.$el.find('.checkbox-wrappers input').on('click', $.proxy(self._onCheckBoxClick, self));

                // toggle favorites

                self.$favoritesToggle = self.$el.find('.favorites-toggle');
                self.$lastMinToggle = self.$el.find('.last-min-toggle');

                self.$el.find('.toggle-selector .on').on('click', $.proxy(self._onFavoriteToggleClick, self));
                self.$el.find('.toggle-selector .off').on('click', $.proxy(self._onFavoriteToggleClick, self));

                // close

                self.$closeButton = self.$el.find('.filter-btn-close');
                self.$closeButton.on('click', $.proxy(self._onCloseClick, self));
                self.$closeButton.on('click', $.proxy(self._onScrollTop, self));


                // selecting initial filter menu items

                // range slider
                var from = 0;
                var to = 10000;
                var max = 10000;
                console.log('self.options', self.options);
                var prefix = self.options.currency ? self.options.currency : "&euro;";
                if (self.priceSliderTagModel) {
                    var tagValue = self.priceSliderTagModel.id.split(':')[1].split('.')[0].replace('%20', ' ');
                    from = tagValue.split('-')[0];
                    to = tagValue.split('-')[1];
                }
                self.loadPriceSlider(from, to, max, prefix);

                // other filters
                if (self.bedroomsTagModel) {
                    self.selectInitTags(self.bedroomsTagModel);
                }
                if (self.bathroomsTagModel) {
                    self.selectInitTags(self.bathroomsTagModel);
                }
                if (self.featuresTagModel) {
                    _.each(self.featuresTagModel, function (featureModel) {
                        if (featureModel) {
                            self.selectInitTags(featureModel);
                        }
                    });
                }

                if (self.keywordTags) {
                    console.log('self.keywordTags found', self.keywordTags);
                    _.each(self.keywordTags, function (keywordModel) {
                        if (keywordModel) {
                            self.selectInitTags(keywordModel);
                        }
                    });
                }
                if (self.isFavoritesToggled) {
                    self.selectInitTags(self.isFavoritesToggled);
                }
                if (self.sizesTagModel) {
                    self.selectInitTags(self.sizesTagModel);
                }

            },
            loadPriceSlider: function (from, to, max, prefix) {

                var self = this;

                $("#priceRange").ionRangeSlider({

                    type: "double",
                    min: 0,
                    max: max,
                    from: from,
                    to: to,
                    step: 100,
                    prettify_separator: ",",
                    prefix: prefix,

                    onFinish: function (data) {

                        var lastTagModel = self.priceSliderTagModel;
                        if (lastTagModel) {
                            self.trigger('removeTag', lastTagModel);
                        }

                        self.priceSliderTagModel = {
                            id: 'priceRange:' + (data.from + '-' + data.to),
                            type: 'price-range',
                            label: (prefix + data.from + ' - ' + prefix + data.to)
                        };
                        self.trigger('addTag', _.clone(self.priceSliderTagModel));
                    },
                });

                self.priceSlider = $("#priceRange").data("ionRangeSlider");
            },

            // event listeners -----------------------------------------------------------------  /

            _onSelectBedroom: function (e, obj) {

                var self = this;
                var $target = $(e.currentTarget);

                if ($target) {

                    var value = $($target).val();
                    var tagModel = _.clone(_.findWhere(self.filtersData.bedrooms, {id2: value}));
                    var lastTagModel = self.bedroomsTagModel;

                    if (tagModel) {
                        tagModel.id = 'bedrooms:' + tagModel.id
                    }

                    var repeatedTag = false;
                    if (lastTagModel) {
                        if (lastTagModel.id == tagModel.id) {
                            repeatedTag = true
                        }
                        ;
                    }

                    if (!self.bedroomsTagModel || value != self.bedroomsTagModel.id && !repeatedTag) {

                        if (lastTagModel) {
                            self.trigger('removeTag', lastTagModel);
                        }
                        if (tagModel) {
                            self.trigger('addTag', $.extend({type: 'bedrooms'}, tagModel));
                        }

                        self.bedroomsTagModel = tagModel;
                    }
                }
            },

            _onSelectBathroom: function (e, obj) {

                var self = this;
                var $target = $(e.currentTarget);

                if ($target) {

                    var value = $($target).val();
                    var tagModel = _.clone(_.findWhere(self.filtersData.bathrooms, {id2: value}));
                    var lastTagModel = self.bathroomsTagModel;
                    if (tagModel) {
                        tagModel.id = 'bathrooms:' + tagModel.id
                    }

                    var repeatedTag = false;
                    if (lastTagModel) {
                        if (lastTagModel.id == tagModel.id) {
                            repeatedTag = true
                        }
                    }

                    if (!self.bathroomsTagModel || value != self.bathroomsTagModel.id && !repeatedTag) {

                        if (lastTagModel) {
                            self.trigger('removeTag', lastTagModel);
                        }
                        if (tagModel) {
                            self.trigger('addTag', $.extend({type: 'bathrooms'}, tagModel));
                        }

                        self.bathroomsTagModel = tagModel;
                    }
                }
            },

            _onSelectSize: function (e, obj) {
                var self = this;
                var $target = $(e.currentTarget);
                if ($target) {

                    var value = $($target).val();
                    var tagModel = _.clone(_.findWhere(self.filtersData.sizes, {shortLabel: value}));
                    var lastTagModel = self.sizesTagModel;
                    if (tagModel) {
                        tagModel.id = 'sizes:' + tagModel.id
                    }
                    var isTagRepeated = false;
                    if (lastTagModel) {
                        if (lastTagModel.id == tagModel.id) {
                            isTagRepeated = true
                        }
                    }
                    if ((!self.sizesTagModel || value != self.sizesTagModel.id) && !isTagRepeated) {

                        if (lastTagModel) {
                            self.trigger('removeTag', lastTagModel);
                        }
                        if (tagModel) {
                            self.trigger('addTag', $.extend({type: 'sizes'}, tagModel));
                        }

                        self.sizesTagModel = tagModel;
                    }
                }
            },
            _onSelectArea: function (e, obj) {
                var self = this;
                var $target = $(e.currentTarget);
                if ($target) {
                    var value = $($target).val();
                    var tagModel = null;
                    if (value && value != 'all') {
                        tagModel = self.createAreaTagModel(value);

                    }
                    var lastTagModel = self.areasTagModel;
                    var isTagRepeated = false;
                    if (lastTagModel && tagModel) {
                        if (lastTagModel.id == tagModel.id) {
                            isTagRepeated = true
                        }
                    }
                    if ((!self.areasTagModel || value != self.areasTagModel.id) && !isTagRepeated) {

                        if (lastTagModel) {
                            self.trigger('removeTag', lastTagModel);
                        }
                        if (tagModel) {
                            self.trigger('addTag', $.extend({type: 'areas'}, tagModel));
                        }

                        self.areasTagModel = tagModel;
                    }
                }
            },

            _onAddKeywordTag: function (e, obj) {

                var self = this;

                if (!_.findWhere(self.keywordTags, {label: obj.text})) {

                    var tagModel = {id: obj.text, label: obj.text, type: 'keyword'};
                    if (tagModel) {
                        tagModel.id = 'keyword:' + tagModel.id
                    }

                    if (self.keywordTags) {
                        self.keywordTags.push(tagModel);
                    } else {
                        self.keywordTags = [tagModel];
                    }

                    console.log('self.keywordTags', self.keywordTags);

                    self.trigger('addTag', tagModel);
                }
            },

            _onRemoveKeywordTag: function (e, obj) {


                var self = this;

                var tagModel = _.findWhere(self.keywordTags, {label: obj.text});

                if (tagModel) {

                    self.keywordTags = _.without(self.keywordTags, tagModel);
                    self.trigger('removeTag', tagModel);
                }
            },

            _onCheckBoxClick: function (e) {

                var self = this;
                var $target = $(e.currentTarget);
                var isChecked = e.currentTarget.checked;

                /*				var tagModel = _.findWhere(self.filtersData.category, {id:$target.data('id')});
                 if(!tagModel){ tagModel = _.findWhere(self.filtersData.features, {id:$target.data('id')}); }
                 tagModel = _.clone(tagModel);*/

                var tagModel = _.clone(_.findWhere(self.filtersData[$target.data('type')], {id: $target.data('id')}));
                if (tagModel) {
                    tagModel.id = $target.data('type') + ':' + tagModel.id
                }

                if (isChecked) {
                    self.trigger('addTag', $.extend({type: 'checkbox'}, tagModel));
                }
                else {
                    self.trigger('removeTag', tagModel);
                }

            },

            _onFavoriteToggleClick: function (e) {

                var self = this;
                var $target = $(e.currentTarget);
                var $selector = $target.parent();
                var type = $selector.data('type');

                if (!$target.hasClass('selected')) {

                    var $lastSelected = $selector.find('.selected');
                    $lastSelected.removeClass('selected');

                    $target.removeClass('selected').addClass('selected');
                }

                // add / remove tag

                var tagModel = _.clone(self.filtersData[type]);
                if (tagModel) {
                    tagModel.id = 'favorites:' + tagModel.id
                }

                if ($selector.find('.on').hasClass('selected')) {
                    self.trigger('addTag', $.extend({type: 'favorites'}, tagModel));
                }
                else {
                    self.trigger('removeTag', tagModel);
                }
            },

            _onCloseClick: function (e) {

                var self = this;
                self.hide();

            },

            _onScrollTop: function (e) {
                var self = this;

                $('body').animate({
                    scrollTop: $('#main-wrapper').offset().top
                }, 1000);
            },

            _onCreateAreaFilter: function (e, areas) {
                var self = this;

                var areaContainer = $('.areas-container');

                self.createSelectAreas(areas);

                $(areaContainer).addClass('hidden').removeClass('hidden');

                // self.areasTagModel exists when this params is in the url and this is to get selected the area in the area-select
                if (self.areasTagModel) {
                    self.selectInitTags(self.areasTagModel);
                }

            },

            // public controls -----------------------------------------------------------------  /

            removeTag: function (tagModel) {
                console.log("removeTag", tagModel);
                var self = this;

                if (tagModel) {

                    var selectBoxIt;

                    switch (tagModel.type) {

                        case 'sizes':
                            self.sizesTagModel = null;
                            self.$areaSizeSelector.data("selectBox-selectBoxIt").selectOption(0);
                            break;

                        case 'areas':
                            self.areasTagModel = null;
                            self.$areaSelector.data("selectBox-selectBoxIt").selectOption(0);
                            break;

                        case 'bedrooms':
                            self.bedroomsTagModel = null;
                            self.$roomsSelector.data("selectBox-selectBoxIt").selectOption(0);
                            break;

                        case 'bathrooms':
                            self.bathroomsTagModel = null;
                            self.$bathsSelector.data("selectBox-selectBoxIt").selectOption(0);
                            break;

                        case 'keyword':
                            self.keywordTags = _.reject(self.keywordTags, function (keywordTag) {
                                return keywordTag.id == tagModel.id
                            });
                            self.$tagField.tagging('remove', tagModel.label);
                            break;

                        case 'checkbox':
                            var $checkBox = self.$el.find('.facilities-checkboxes .checkbox-wrappers input[data-id="' + tagModel.id.split(":")[1] + '"]');
                            $checkBox.trigger('click');
                            break;

                        case 'favorite':
                            self.$favoritesToggle.find('.on').removeClass('selected');
                            self.$favoritesToggle.find('.off').removeClass('selected').addClass('selected');
                            break;

                        case 'lastmindeals':
                            self.$lastMinToggle.find('.on').removeClass('selected');
                            self.$lastMinToggle.find('.off').removeClass('selected').addClass('selected');
                            break;

                        case 'price-range':
                            self.priceSlider.reset();
                            break;
                    }
                }
            },

            initTags: function (tags) {
                var self = this;
                var tagKey;
                var tagValue;
                var tagArray = tags.split('|');
                var tagModel;
                _.each(tagArray, function (tag) {
                    tagKey = tag.split(':')[0];
                    tagValue = tag.split(':')[1].split('.')[0].replace('%20', ' ');
                    if (tagKey) {

                        var selectBoxIt;

                        switch (tagKey) {

                            case 'sizes':
                                tagModel = _.clone(_.findWhere(self.filtersData.sizes, {id: tagValue}));
                                if (tagModel) {
                                    tagModel.id = 'sizes:' + tagModel.id;
                                    self.sizesTagModel = tagModel;
                                    self.trigger('addTag', $.extend({type: tagKey}, tagModel));
                                }
                                break;

                            case 'areas':
                                tagModel = self.createAreaTagModel(tagValue);
                                if (tagModel) {
                                    self.trigger('addTag', $.extend({type: tagKey}, tagModel));
                                    self.areasTagModel = tagModel;
                                }
                                break;

                            case 'bathrooms':
                                tagModel = _.clone(_.findWhere(self.filtersData[tagKey], {id2: tagValue}));
                                if (tagModel) {
                                    tagModel.id = tagKey + ':' + tagModel.id;
                                    self.trigger('addTag', $.extend({type: tagKey}, tagModel));
                                    self.bathroomsTagModel = tagModel;
                                }
                                break;
                            case 'bedrooms':
                                tagModel = _.clone(_.findWhere(self.filtersData[tagKey], {id2: tagValue}));
                                if (tagModel) {
                                    tagModel.id = tagKey + ':' + tagModel.id;
                                    self.trigger('addTag', $.extend({type: tagKey}, tagModel));
                                    self.bedroomsTagModel = tagModel;
                                }
                                break;

                            case 'categories':
                            case 'features':
                                tagModel = _.clone(_.findWhere(self.filtersData[tagKey], {id: tagValue}));
                                if (tagModel) {
                                    tagModel.id = tagKey + ':' + tagModel.id;
                                    self.trigger('addTag', $.extend({type: 'checkbox'}, tagModel));
                                    if (self.featuresTagModel) {
                                        self.featuresTagModel.push(tagModel);
                                    } else {
                                        self.featuresTagModel = [tagModel];
                                    }
                                }
                                break;

                            case 'priceRange':
                                self.priceSliderTagModel = {
                                    id: 'priceRange:' + (tagValue.split('-')[0] + '-' + tagValue.split('-')[1]),
                                    type: 'price-range',
                                    label: ('&euro;' + tagValue.split('-')[0] + ' - &euro;' + tagValue.split('-')[1])
                                };
                                self.trigger('addTag', _.clone(self.priceSliderTagModel));
                                break;

                            case 'keyword':
                                var tagModel = {id: tagValue, label: tagValue, type: 'keyword'};
                                if (tagModel) {
                                    tagModel.id = 'keyword:' + tagModel.id;
                                    if (self.keywordTags) {
                                        self.keywordTags.push(tagModel);
                                    } else {
                                        self.keywordTags = [tagModel];
                                    }
                                    self.trigger('addTag', tagModel);
                                }
                                break;
                            case 'favorites':
                                var tagModel = {id: tagValue, label: tagValue, type: 'favorites'};
                                if (tagModel) {
                                    self.isFavoritesToggled = tagModel;
                                    tagModel.id = 'favorites:' + tagModel.id;
                                    self.trigger('addTag', tagModel);
                                }
                                break;
                        }
                    }

                });

            },

            selectInitTags: function (tagModel) {
                if (tagModel) {
                    var self = this;
                    var tagKey = tagModel.id.split(':')[0];
                    var tagValue = tagModel.id.split(':')[1].split('.')[0].replace('%20', ' ');
                    switch (tagKey) {
                        case 'sizes':
                            self.$areaSizeSelector.data("selectBox-selectBoxIt").selectOption(tagModel.label);
                            break;
                        case 'areas':
                            self.$areaSelector.data("selectBox-selectBoxIt").selectOption(tagValue);
                            break;
                        case 'bedrooms':
                            self.$roomsSelector.data("selectBox-selectBoxIt").selectOption(tagValue);
                            break;
                        case 'bathrooms':
                            self.$bathsSelector.data("selectBox-selectBoxIt").selectOption(tagValue);
                            break;
                        case 'categories':
                        case 'features':
                            var $checkBox = self.$el.find('.facilities-checkboxes .checkbox-wrappers input[data-id="' + tagValue + '"]');
                            $checkBox.trigger('click');
                            break;
                        case 'keyword':
                            self.$tagField.tagging('add', tagModel.label);
                            break;
                        case 'favorites':
                            self.$el.find('.toggle-selector .on').trigger('click');
                            break;

                    }
                }
            },

            createSelectAreas: function (areas) {
                var self = this;
                var optionsArray = [{value: 'all', text: self.filtersData.text.allAreas}];
                if (areas.length) {
                    $(areas).each(function (i, area) {
                        var singleObj = {};
                        var value = area.toLowerCase().split(' ').join('-');
                        singleObj['value'] = value;
                        singleObj['text'] = area;
                        optionsArray.push(singleObj);
                    });
                }

                self.$areaSelector.data("selectBox-selectBoxIt").remove();
                self.$areaSelector.data("selectBox-selectBoxIt").add(optionsArray);
            },

            createAreaTagModel: function (value) {
                var self = this;
                var tagModel;
                if (value) {
                    var label = value.split('-').map(function (value) {
                        return value.charAt(0).toUpperCase() + value.slice(1)
                    }).join(' ');
                    tagModel = {
                        "id": value,
                        "label": label,
                        "shortLabel": label
                    };
                    tagModel.id = 'areas:' + tagModel.id;
                }
                return tagModel;
            },

            // animation in / out --------------------------------------------------------------  /

            onShow: function () {

                var self = this;

                TweenMax.fromTo(self.$el, 0.6, {opacity: 0}, {
                    opacity: 1, ease: Cubic.easeOut, onComplete: function () {
                        self.trigger('showComplete');
                    }
                });

                window.$vent.trigger('openFiltersMenu');
                self.onWindowResize();
            },

            onHide: function () {

                var self = this;

                TweenMax.to(self.$el, 0.3, {
                    opacity: 0, ease: Cubic.easeOut, onComplete: function () {
                        self.trigger('hideComplete');
                    }
                });

                window.$vent.trigger('closeFiltersMenu');
                self.onWindowResize();
            },

            // window resize -------------------------------------------------------------------  /

            onWindowResize: function () {

                var self = this;
                var winWidth = window.innerWidth;
                var $resultsGrid = $('#results-grid');

                if (self.abstractView.isShowing) {

                    if (winWidth >= 768) {

                        $resultsGrid.css('pointer-events', 'auto');

                        if (self.$container[0] !== self.$desktopContainer[0]) {
                            self.$desktopContainer.append(self.$el);
                        }
                    }
                    else {

                        $resultsGrid.css('pointer-events', 'none');

                        if (self.$container[0] !== self.$mobileContainer[0]) {
                            self.$mobileContainer.append(self.$el);
                        }
                    }
                }
                else {
                    $resultsGrid.css('pointer-events', 'auto');
                }
            },

        });

        return FiltersMenuView;
    });
