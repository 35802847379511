
define(
	'modules/global-scripted-buttons/views/VentTriggerButtonView',[
		'backbone',
		'utils/AbstractView',
		'utils/AbstractButtonView',
	], 
	function(Backbone, AbstractView, AbstractButtonView, Template){

		var VentTriggerButtonView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			options: null,
			abstractView: null,
			abstractButtonView: null,

			// vars

			// init

			initialize: function(options){

				var self = this;

				// options vars

				self.options = options;
				self.$container = self.options.$container;

				// template config

				//self.template = _.template(Template);
				self.model = $.extend({}, self.model);

				// initialize AbstractView and AbstractButtonView

				self.abstractView = new AbstractView({
					view: self,
					autoShow: self.options.autoShow !== false,
				});

				self.abstractButtonView = new AbstractButtonView({
					abstractView: self.abstractView,
				});
			},

			// render

			onAnimateClick: function(){

				var self = this;
				var triggerId = self.$el.data('trigger-id');
				var triggerData = self.$el.data('trigger-data');

				window.$vent.trigger('showSpinner',self.$el[0]);
				window.$vent.trigger(triggerId, triggerData);
			},

			// ---------------------------------------------------------------------------------  /

		});

	return VentTriggerButtonView;
});
