
define(
	'modules/property-filters/views/GuestsMenuView',[
		'backbone',
		'utils/AbstractView',
		'text!../templates/GuestsMenuTemplate.html',
	], 
	function(Backbone, AbstractView, Template){

		var GuestsMenuView = Backbone.View.extend({

			// core vars

			lang:null,

			$el: null,
			$container: null,
			$mobileContainer: null,
			options: null,
			abstractView: null,

			// vars

			serverQuery: null,
			shortResults: null,

			// init

			initialize: function(options){

				var self = this;

				self.options = options;
				self.$container = self.options.$container;
				self.$mobileContainer = self.options.$mobileContainer;
				self.el = self.options.el;
				self.lang = options.lang;

				self.template = _.template(Template);
				self.model = $.extend({

				}, self.model);

				// 

				self.abstractView = new AbstractView({
					view: self,
					autoShow: false,
					hideClass: 'display-none',
				});

				//

				$(window).on('resize', $.proxy(self._onWindowResize, self));
				self._onWindowResize();
			},

			// render

			onRender: function(){

				var self = this;

				// calculate total guests

				self.isGuestSelected = false;
			},

			// listeners

			onAddListeners: function(){

				var self = this;

				self.$el.find('li button.remove').on('click', $.proxy(self._onRemoveClick, self));
				self.$el.find('li button.add').on('click', $.proxy(self._onAddClick, self));
				self.$el.find('.fa.close').on('click', $.proxy(self._onCloseClick, self));
			},

			onRemoveListeners: function(){

				var self = this;

				self.$el.find('li button.remove').off('click', $.proxy(self._onRemoveClick, self));
				self.$el.find('li button.add').off('click', $.proxy(self._onAddClick, self));
				self.$el.find('.fa.close').off('click', $.proxy(self._onCloseClick, self));
			},

			// listener methods

			triggerOption: function(optionId){

				var self = this;

				if(optionId){

					var options = optionId.split(',');

					for(var i=0; i<options.length; i++){
						
						var option = options[i];
						var split = option.split('.');
						var $guestOption = self.$el.find('.guest-option[data-id="' + split[1] + '"]');

						if($guestOption.length){
							$guestOption.attr('data-count', split[0]);
							$guestOption.find('.number h2').html(split[0]);
						}
					}
                    //
					//Utils.MiscUtils.setCookie('guests', optionId, window.SEARCHFILTERS_COOKIE_EXPIRES);
					
					self.tabulateGuests();
				}
			},

			_onRemoveClick: function(e){

				var self = this;

				var $target = $(e.currentTarget);
				var $guestOption = $target.parent().parent();
				var $add = $guestOption.find('.add');
				var $remove = $guestOption.find('.remove');
				var $number = $guestOption.find('.number');

				var numValue = parseInt($number.find('h2').html()) - 1;
				var minValue = parseInt($guestOption.data('min'));
				var maxValue = parseInt($guestOption.data('max'));

				numValue = Math.max(numValue, minValue);
				if(maxValue >= 0){ numValue = Math.min(numValue, maxValue); }

				if(numValue === minValue){ $remove.removeClass('disabled').addClass('disabled'); }
				else { $remove.removeClass('disabled'); }

				if(numValue === maxValue){ $add.removeClass('disabled').addClass('disabled'); }
				else { $add.removeClass('disabled'); }

				$number.find('h2').html(numValue);
				$guestOption.data('count', numValue);
				
				self.tabulateGuests();
			},

			_onAddClick: function(e){

				var self = this;

				var $target = $(e.currentTarget);
				var $guestOption = $target.parent().parent();
				var $add = $guestOption.find('.add');
				var $remove = $guestOption.find('.remove');
				var $number = $guestOption.find('.number');
				
				var numValue = parseInt($number.find('h2').html()) + 1;
				var minValue = parseInt($guestOption.data('min'));
				var maxValue = parseInt($guestOption.data('max'));

				numValue = Math.max(numValue, minValue);
				if(maxValue >= 0){ numValue = Math.min(numValue, maxValue); }

				if(numValue === minValue){ $remove.removeClass('disabled').addClass('disabled'); }
				else { $remove.removeClass('disabled'); }

				if(numValue === maxValue){ $add.removeClass('disabled').addClass('disabled'); }
				else { $add.removeClass('disabled'); }

				$number.find('h2').html(numValue);
				$guestOption.data('count', numValue);
				
				self.tabulateGuests();
			},

			_onCloseClick: function(e){

				var self = this;
				self.trigger('closeMenu');

				console.log("close menu");
			},

			// return total guests

			tabulateGuests: function(){

				var self = this;
				var totalGuests = 0;
				var newQuery = '';

				_.each(self.$el.find('li'), function(guest, i){
					var $guest = $(guest);
					totalGuests += $guest.data('count');
					newQuery += (i > 0 ? ',' : '') + $guest.data('count') + '.' + $guest.data('id');
				});

				var guestTitle = ""
				if(self.lang == "en"){
					guestTitle = "Guest"
				}else{
					guestTitle = "Persona"
				}


				self.serverQuery = newQuery;
				self.shortResults = totalGuests + " " + guestTitle + ((totalGuests === 1) ? '' : 's');
				console.log('tabulateGuests self.serverQuery', self.serverQuery);
				self.trigger('optionSelected', {target:self, query:self.serverQuery, results:self.shortResults});
			},

			onShow: function(){

				var self = this;
				var d = 0.0;

				_.each(self.$el.find('li'), function(guest, i){
					
					var $guest = $(guest);
					var $guestContent = $guest.find('.label').add($guest.find('.number')).add($guest.find('.buttons'));
					var $guestBorder = $guest.find('.border');
					
					TweenMax.fromTo($guestContent, 0.2, {opacity:0, y:4}, {opacity:1, y:0, delay:i * 0.02 + d + 0.1, ease:Cubic.easeOut});
					TweenMax.fromTo($guestBorder, 0.5, {opacity:0}, {opacity:1, delay:i * 0.02 + d + 0.1, ease:Cubic.easeOut});
				});

				TweenMax.fromTo(self.$el, 0.5, {opacity:0, y:0}, {opacity:1, y:0, ease:Cubic.easeOut, onComplete:function(){
					self.trigger('showComplete');
				}});
				
				self.tabulateGuests();
			},

			_onWindowResize: function(){

				var self = this;
				var winWidth = window.innerWidth;
				var docHeight = $(document).height();

				if(winWidth < 768){
					TweenMax.set(self.$el, {minHeight:docHeight});
				}
				else {
					TweenMax.set(self.$el, {clearProps:'min-height'});
				}
			},

			// ---------------------------------------------------------------------------------  /

		});

	return GuestsMenuView;
});
