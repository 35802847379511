define(
	'modules/home-inspiration/app',[
		'backbone',
		'modules/home-inspiration/views/HomeInspirationItemView',
	], 
	function(Backbone, HomeInspirationItemView){

		var AppView = Backbone.View.extend({

			// core vars

			$homeInspirationContainer: null,
			$lightboxContainer: null,

			homeInspirationItems: null,
			lightbox: null,

			tweenDelay: null,
			fadeDelay: null,

			// init

			initialize: function(options){

				var self = this;
				window.$vent.trigger('homeInspirationReady');
			},

			// start module --------------------------------------------------------------------  /

			start: function(){

				var self = this;
				
				$(function(){
					self.onDomReady();
					self.addListeners();
				});
			},

			addListeners: function () {
				
				var self = this;

				$(window).on('resize', $.proxy(self._onWindowResize, self));
			},

			removeListeners: function () {
				
				var self = this;

				$(window).off('resize', $.proxy(self._onWindowResize, self));
			},

			onDomReady: function(){

				var self = this;
				self.$homeInspirationContainer = $('.home-inspiration-items');
				self.$lightboxContainer = $('.lightbox-container');
				self.homeInspirationItems = [];

				_.each(self.$homeInspirationContainer, function(homeInspirationItem, i){
					
					var $homeInspirationContainer = $(homeInspirationItem);
					var $homeInspirationItems = $homeInspirationContainer.find('.home-inspiration-item');

					for(var i=0; i<$homeInspirationItems.length; i++){

						var $homeInspirationItem = $($homeInspirationItems[i]);
						var homeInspirationItemView = new HomeInspirationItemView({
							el: $homeInspirationItem,
							$container: self.$homeInspirationContainer
						});

						homeInspirationItemView.on('mouseenter', self._onMouseEnter, self);
						homeInspirationItemView.on('mouseleave', self._onMouseLeave, self);

						self.homeInspirationItems.push(homeInspirationItemView);
					}
				});	

				self.$homeInspirationItems = self.$homeInspirationContainer.find('li');

				window.$vent.trigger('domUpdate', {view:self, type:'home-inspiration-item'});
			},

			_onMouseEnter: function(e){

				var self = this;
				var $target = e.target.$el;

				if(!$target.hasClass('over')){

					var $homeInspirationItemContainer = $target.parent();
					var $homeInspirationItems = $homeInspirationItemContainer.find('li.home-inspiration-item');					
					var $lastTarget = $homeInspirationItemContainer.find('.over');

					$lastTarget.removeClass('over');
					$target.addClass('over');

					if(self.fadeDelay){ self.fadeDelay.kill(); }
					self.fadeDelay = TweenMax.delayedCall(0.1, function(){

						_.each($homeInspirationItems, function(homeInspirationItem, i){						
							
							var $homeInspirationItem = $(homeInspirationItem);

							if(!$homeInspirationItem.hasClass('over')){

								var $veil = $homeInspirationItem.find('.image-veil');
								
								TweenMax.set($veil, {opacity:1});
								if(self.tweenDelay){ self.tweenDelay.kill(); }
								
								$veil.removeClass('hidden');
							}
						});
					});
				}
			},

			_onMouseLeave: function(e){

				var self = this;
				var $target = e.target.$el;
				
				if($target.hasClass('over')){

					var $homeInspirationItemContainer = $target.parent();
					var $homeInspirationItems = $homeInspirationItemContainer.find('li.home-inspiration-item');
					
					$target.removeClass('over');

					if(self.fadeDelay){ self.fadeDelay.kill(); }

					_.each($homeInspirationItems, function(homeInspirationItem, i){			
						
						var $homeInspirationItem = $(homeInspirationItem);

						if(!$homeInspirationItem.hasClass('over')){

							var $veil = $homeInspirationItem.find('.image-veil');
						
							TweenMax.set($veil, {opacity:0});
							
							if(self.tweenDelay){ self.tweenDelay.kill(); }
							self.tweenDelay = TweenMax.delayedCall(0.6, function(){
								$veil.removeClass('hidden').addClass('hidden');
							});
						}
					});
				}
			},

			_onWindowResize: function () {


				var winWidth = $(window).width();
				var homeInspirationModal = $("#modal-content-wrapper #home-inspiration-modal");

				if( homeInspirationModal.length > 0 && winWidth >768 ) {
					var homeItemWidth = parseInt( $(".home-inspiration-item").first().width() );
					var maxWidth = homeItemWidth * 2 + 20;
					homeInspirationModal.find('.inner-container').css('maxWidth', maxWidth);
				}
				
			}
			
			// ---------------------------------------------------------------------------------  /

		});

		return AppView;
	}
);
