
define(
	'modules/global-scripted-buttons/views/SwapButtonView',[
		'backbone',
		'utils/AbstractView',
		'utils/AbstractButtonView',
	], 
	function(Backbone, AbstractView, AbstractButtonView){

		var ButtonTemplateView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			options: null,
			abstractView: null,
			abstractButtonView: null,

			// vars

			// init

			initialize: function(options){

				var self = this;

				// options vars

				self.options = options;
				self.$container = self.options.$container;

				// template config

				//self.template = _.template(Template);
				self.model = $.extend({}, self.model);

				// initialize AbstractView and AbstractButtonView

				self.abstractView = new AbstractView({
					view: self,
					autoShow: self.options.autoShow !== false,
				});

				self.abstractButtonView = new AbstractButtonView({
					abstractView: self.abstractView,
				});
			},

			// render

			onAnimateClick: function(){

				var self = this;
				var $wrapper = $('#' + self.$el.data('this-wrapper'));
				var $swapTarget = $('#' + self.$el.data('swap-target'));

				$wrapper.removeClass('display-none').addClass('display-none');
				
				_.each($swapTarget.children(), function(swapChild, i){
					
					var $swapChild = $(swapChild);

					TweenMax.fromTo($swapChild, 0.6, {opacity:0}, {opacity:1, delay:i * 0.16, ease:Quad.easeInOut});
					TweenMax.fromTo($swapChild, 0.8, {y:12}, {y:0, delay:i * 0.16, ease:Quint.easeOut});
				});

				$swapTarget.removeClass('display-none');
				
				$(window).resize();
			},

			// ---------------------------------------------------------------------------------  /

		});

	return ButtonTemplateView;
});
