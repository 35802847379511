
define(
	'modules/global-scripted-buttons/views/ModalTriggerButtonView',[
		'backbone',
		'utils/AbstractView',
		'utils/AbstractButtonView',
	], 
	function(Backbone, AbstractView, AbstractButtonView, Template){

		var ModalTriggerButtonView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			options: null,
			abstractView: null,
			abstractButtonView: null,

			// vars

			// init

			initialize: function(options){

				var self = this;

				// options vars

				self.options = options;
				self.$container = self.options.$container;

				// template config

				//self.template = _.template(Template);
				self.model = $.extend({}, self.model);

				// initialize AbstractView and AbstractButtonView

				self.abstractView = new AbstractView({
					view: self,
					autoShow: self.options.autoShow !== false,
				});

				self.abstractButtonView = new AbstractButtonView({
					abstractView: self.abstractView,
				});
			},

			// render

			onAnimateClick: function(){

				var self = this;
				var modalId = self.$el.data('modal-id');
				var contentParams = self.$el.data('modal-params') || '';
				var longModal = self.$el.data('long-modal') || false;
				var whiteVeil = self.$el.data('white-veil') || false;

				// turn params from string to object

				var paramsObj = {};

				if(contentParams){

					var split = contentParams.split('&');

					for(var i=0; i<split.length; i++){
						
						var param = split[i];

						if(param){						
							var paramSplit = param.split('=');
							paramsObj[paramSplit[0]] = paramSplit[1];
						}
					}
				}

				window.$vent.trigger('showSpinner',self.$el[0]);
				//

				console.log('ModalTriggerButtonView onAnimateClick', self.$el[0], 'modalId=', modalId, 'contentParams=', contentParams, '->', paramsObj, 'longModal=', longModal, 'whiteVeil=', whiteVeil);
				
				window.$vent.trigger('switchModal', {modalId:modalId, contentParams:paramsObj, longModal:longModal, whiteVeil:whiteVeil});
			},

			// ---------------------------------------------------------------------------------  /

		});

	return ModalTriggerButtonView;
});
