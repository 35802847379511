
define(
	'modules/sticky-nav/views/SimpleStickyNavView',[
		'backbone',
		'utils/AbstractView',
	], 
	function(Backbone, AbstractView){

		var SimpleStickyNavView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			options: null,
			abstractView: null,

			// vars

			$hero: null,
			heroBottom: null,

			winHeight: null,

			isTicked: false,
			isShowing: false,

			// init

			initialize: function(options){

				var self = this;

				// options vars

				self.options = options;
				self.$container = self.options.$container;
				self.$hero = self.options.$hero;

				// template config

				//self.template = _.template(Template);
				self.model = $.extend({}, self.model);

				// initialize AbstractView

				self.abstractView = new AbstractView({
					view: self,
					autoShow: true,
				});
			},

			// dom ready

			onDomReady: function(){

				var self = this;
				self.stickyNavHeight = self.$el.outerHeight();

				TweenMax.set(self.$el, {y:-self.stickyNavHeight});
				TweenMax.set(self.$el.find('li'), {y:self.stickyNavHeight});
			},

			// add listeners

			onAddListeners: function(){

				var self = this;
				
				$(window).on('scroll', $.proxy(self._onScroll, self));
				$(window).mousewheel($.proxy(self._onMouseWheel, self));

				self._onScroll();
			},

			_onMouseWheel: function(e){

				var self = this;
				self.scrollTick();
			},

			_onScroll: function(){

				var self = this;
				self.scrollTick();
			},

			scrollTick: function(){

				var self = this;

				if(!self.isTicked){
					self.isTicked = true;
					window.requestAnimationFrame(self.scrollNow.bind(self));
				}
			},

			// scroll listener

			scrollNow: function(){

				var self = this;
				var scrollTop = $(window).scrollTop();

				if(scrollTop >= self.heroBottom - self.stickyNavHeight){

					if(!self.isShowing){
						
						TweenMax.to(self.$el, 0.6, {y:0, ease:Quint.easeOut});
						
						_.each(self.$el.find('li'), function(li, i){
							TweenMax.to($(li), 0.5, {y:0, delay:0.1 + 0.02 * i, ease:Quint.easeOut});
						});

						self.$el.removeClass('hidden');
						
						self.isShowing = true;
					}
				}
				else {

					if(self.isShowing){
						
						TweenMax.to(self.$el, 0.5, {y:-self.stickyNavHeight, ease:Cubic.easeInOut, onComplete:function(){
							TweenMax.set(self.$el.find('li'), {y:self.stickyNavHeight});
							self.$el.removeClass('hidden').addClass('hidden');
						}});

						self.isShowing = false;
					}
				}

				self.isTicked = false;
			},

			// window resize

			onWindowResize: function(){

				var self = this;

				self.winHeight = $(window).innerHeight();
				self.heroBottom = self.$hero.offset().top + self.$hero.innerHeight();

				self.scrollTick();
			},

		});

	return SimpleStickyNavView;
});
