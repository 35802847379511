define(
    'modules/guests/views/GuestsMenuButtonsView',[
        'backbone',
        'utils/AbstractView',

        'modules/guests/views/GuestsMenuView'

        //'text!../../../config/hero-filters.json',
    ],
    function (Backbone, AbstractView, GuestsMenuView) {

        var GuestsMenuButtonsView = Backbone.View.extend({

            // core vars

            $el: null,
            $container: null,
            options: null,
            abstractView: null,

            //

            filtersConfig: null,

            $context: null,
            $mobileContainer: null,
            $menuContainer: null,

            overlayMenu: null,

            isModalOpen: false,

            // init

            initialize: function (options) {

                var self = this;

                self.options = options;
                self.$container = self.options.$container;
                self.$context = self.options.$context;
                self.$mobileContainer = self.options.$mobileContainer;
                self.$desktopContainer = self.options.$desktopContainer;
                self.selectedGuests = self.options.selectedGuests;

                if (!self.$mobileContainer.length) {
                    self.$mobileContainer = self.$el;
                }
                if (!self.$desktopContainer.length) {
                    self.$desktopContainer = null;
                }

                self.loadHeroConfig();

                //self.filtersConfig = JSON.parse(HeroFiltersConfig);

                //self.template = _.template(Template);
                self.model = $.extend({}, self.model);

                //

                self.abstractView = new AbstractView({
                    view: self,
                    autoShow: true,
                });

                //

                $(window).on('resize', $.proxy(self._onWindowResize, self));
                self._onWindowResize();
            },

            loadHeroConfig: function () {

                var self = this;
                var dfd = $.Deferred();

                $.ajax({
                    type: 'GET',
                    url: "/global/searchConfig",
                    async: false,
                    jsonpCallback: 'callBack',
                    contentType: 'application/json',
                    dataType: 'jsonp',

                    success: function (json) {
                        self.filtersConfig = json.results;
                        console.log('Hero config loaded', self.filtersConfig);
                        window.$vent.trigger('heroFiltersReady');
                        dfd.resolve(json.results);
                    },
                    error: function (e) {
                        console.log('JSON Load Error', self);
                        console.log(e.message);
                        dfd.reject();
                    }
                });

                return dfd.promise();
            },

            //

            onDomReady: function () {

                var self = this;
                if (window.innerWidth < 768) {
                    if (self.selectedGuests && self.selectedGuests.total != 0) {
                        //Set input with default guests
                        var adults = parseInt(self.selectedGuests.adults);
                        var children = parseInt(self.selectedGuests.children);
                        var infants = parseInt(self.selectedGuests.infants);
                        var total = adults + children + infants;
                        self.serverQuery = adults + ".adults," + children + ".children," + infants + ".infants";
                        self.shortResults = $('#lang').text() == "es" ? (total + " Persona" + (total === 1 ? '' : 's')) : (total + " Guest" + (total === 1 ? '' : 's'));

                        self.updateFilterButtonText('guests-input', {
                            target: self,
                            query: self.serverQuery,
                            results: self.shortResults
                        });
                    } else {
                        self.isGuestSelected = false;
                    }
                }
            },

            onAddListeners: function () {

                var self = this;

                self.$menuContainer = self.getContainer();
                self.overlayMenu = new GuestsMenuView({
                    $context: self.$context,
                    $container: self.$menuContainer,
                    model: self.filtersConfig,
                    selectedGuests: self.selectedGuests,
                    autoShow: false
                });

                self.overlayMenu.on('optionSelected', self._onOptionSelected, self);
                self.overlayMenu.on('closeMenu', self._onCloseMenu, self);
                self.overlayMenu.on('hide', self._onMenuClosed, self);

                // individual button listeners

                self.$el.on('click', $.proxy(self._onClick, self));
            },

            _onClick: function (e) {

                var self = this;

                self.showOverlayMenu();
                e.preventDefault();

                window.requestAnimationFrame(function () {
                    $(':focus').blur();
                });
            },

            // listener methods

            showOverlayMenu: function () {

                var self = this;

                if (!self.isModalOpen) {
                    var guestsData = self.$el.data('guests');
                    //

                    self.isModalOpen = true;

                    self.overlayMenu.triggerOption(guestsData);
                    self.overlayMenu.show();

                    self.$menuContainer.removeClass('display-none');

                    //

                    $(document).off('click', $.proxy(self._onOutClick, self));
                    $(document).on('click', $.proxy(self._onOutClick, self));

                    window.$vent.trigger('openFiltersMenu');

                    if (window.innerWidth < 768) {

                        if (self.$el.data('mobile-match-scrolltop') !== false) {
                            TweenMax.set(self.overlayMenu.$el, {top: $(window).scrollTop() + 60});
                        }

                        var scrollOffset = $('#main-menu-wrapper').hasClass('absolute-nav') ? 0 : 60;
                        window.$vent.trigger('seekToPosition', {scrollY: self.overlayMenu.$el.offset().top - scrollOffset});
                    }
                }
            },

            _onOptionSelected: function (e) {
                var self = this;

                self.isDateSelected = true;
                self.dateQuery = e.query.split('=')[1];

                if (e.query) {
                    self.$el.attr("data-guests", e.query);
                }

                Utils.MiscUtils.setCookie('guests', e.query, window.SEARCHFILTERS_COOKIE_EXPIRES);
                self.updateFilterButtonText('guests-input', e);
            },

            _onCloseMenu: function (e) {

                var self = this;
                self.closeCurrentMenu();
            },

            _onMenuClosed: function (e) {

                var self = this;
            },

            _onOutClick: function (e) {

                var self = this;

                if (e) {

                    if (!self.$el[0].contains(e.target) && !self.$menuContainer[0].contains(e.target) && $('body')[0].contains(e.target)) {
                        self.closeCurrentMenu();
                    }
                }
            },

            // close menu

            closeCurrentMenu: function (e) {

                var self = this;

                if (self.isModalOpen) {

                    if (self.$menuContainer[0] != self.$el[0]) {
                        self.$menuContainer.removeClass('display-none').addClass('display-none');
                    }

                    self.overlayMenu.hide();
                    self.isModalOpen = false;

                    $(document).off('click', $.proxy(self._onOutClick, self));
                }
            },

            // hide / restore clicked filter button values

            updateFilterButtonText: function (inputClass, e, text) {
                var self = this;
                var text = text || e.results;

                var $filterButton = self.$el.find('.' + inputClass);
                var $filterInput = $filterButton.find('input');

                if (!$filterInput.length && $filterButton.is('input')) {
                    $filterInput = $filterButton;
                }

                if (text) {

                    if ($filterInput.length) {
                        $filterInput.attr('value', text);
                        self.$el.attr('data-guests', e.query);
                    }
                    else {
                        $filterButton.html(text);
                    }

                    $filterButton.removeClass('selected').addClass('selected');
                }
                else {

                    if ($filterInput.length) {
                        $filterInput.attr('value', '');
                    }
                    else {
                        $filterButton.html('');
                    }

                    $filterButton.removeClass('selected');
                }
            },

            //

            getContainer: function () {

                var self = this;

                if (self.winWidth >= window.MOBILE_WIDTH) {
                    if (self.$desktopContainer && self.$desktopContainer.length) {
                        return self.$desktopContainer;
                    }
                    return self.$el.find('.filter-overlays-container');
                }
                else {
                    return self.$mobileContainer;
                }

                return self.$el;
            },

            // window resizing

            _onWindowResize: function (e) {

                var self = this;

                self.winWidth = window.innerWidth;
                self.winHeight = window.innerHeight;
                self.docHeight = $(document).outerHeight();

                if (self.winWidth >= window.MOBILE_WIDTH) {

                    if (self.isMobile !== false)
                        self.onAddListeners();

                    self.isMobile = false;
                }
                else {

                    if (self.isMobile !== true)
                        self.onAddListeners();

                    self.isMobile = true;
                }
            },

            //

        });

        return GuestsMenuButtonsView;
    });
