define(
    'modules/global-modals/app',[
        'backbone',
        'modules/global-modals/views/GenericModalView',
        'modules/global-modals/views/CreateABlockModalView',
        'modules/global-modals/views/AirportTransferModalView',
    ],
    function (Backbone, GenericModalView, CreateABlockModalView, AirportTransferModalView) {

        var AppView = Backbone.View.extend({

            // core vars

            $modalContainer: null,
            $modalVeil: null,

            currentModal: null,
            currentModalId: null,

            isModalLoading: false,

            // init

            initialize: function (options) {

                var self = this;
                window.$vent.trigger('globalModalsReady');
            },

            // start module --------------------------------------------------------------------  /

            start: function () {

                var self = this;

                $(function () {
                    self.onDomReady();
                });
            },

            onDomReady: function () {

                var self = this;

                self.$modalContainer = $('#modal-content-wrapper');

                window.$vent.on('openModal', $.proxy(self._onOpenModal, self));
                window.$vent.on('closeModal', $.proxy(self._onCloseModal, self));
                window.$vent.on('switchModal', $.proxy(self._onSwitchModal, self));
                window.$vent.on('submitModalForm', $.proxy(self._onSubmitModalForm, self));
                window.$vent.on('submitPayment', $.proxy(self._onSubmitPayment, self));
                window.$vent.on('signUpForm', $.proxy(self._onSignUpForm, self));
                window.$vent.on('resetPassword', $.proxy(self._onResetPassword, self));

                $(window).on('resize', $.proxy(self.onWindowResize, self));
            },

            // modal event listeners

            _onOpenModal: function (e, params) {

                var self = this;
                var modalId = null;

                console.log('_onOpenModal', e, params);

                if (_.isString(params)) {
                    modalId = params;
                    params = {};
                }
                else {
                    modalId = params.modalId;
                }

                if (modalId && modalId != self.currentModalId) {
                    self.currentModalId = modalId;
                    self.loadModalTemplate(modalId, params);
                    //window.$vent.trigger('formLoaded');
                }
            },

            _onSubmitModalForm: function (e, data) {
                var self = this;

                self.showSuccesMessage(data.sucessMessage);
                setTimeout(function () {
                    window.$vent.trigger('closeModal');
                }, 3000);
            },

            _onSignUpForm: function (e, data) {
                console.log('_onSignUpForm', data);

                $.ajax({
                    async: true,
                    type: 'GET',
                    url: '/user/saveUserSession',
                    success: function (response) {
                        console.log('user session saved');
                        console.log("response", response.message);
                        window.dataLayer.push({
                          'event': 'track',
                          'trackAction': 'signup',
                          'trackCategory': 'Signup Success',
                          'trackLabel': { 'user_id': response.message }
                        });
                    },
                    error: function (e) {
                        console.log('user session not saved');
                    }
                });

                window.location.href = data.urlRedirect;
            },

            _onResetPassword: function (e, data) {
                var self = this;

                window.$vent.trigger('switchModal', {modalId: '/global/forgotPasswordComplete'});
            },

            _onCloseModal: function (e) {

                var self = this;
                self.removeModal();
            },

            _onSwitchModal: function (e, params) {

                var self = this;
                var modalId = null;

                if (_.isString(params)) {
                    modalId = params;
                    params = {};
                }
                else {
                    modalId = params.modalId;
                }

                if (modalId && modalId != self.currentModalId) {

                    var d = 0;

                    if (self.currentModalId) {
                        d = 0.3;
                        self.removeModal(true);
                    }

                    self.currentModalId = modalId;

                    TweenMax.delayedCall(d, function () {
                        self.loadModalTemplate(modalId, params);
                    });
                }
            },

            // load modal template

            loadModalTemplate: function (modalId, params) {

                var self = this;
                var suffix = (!_.isUndefined(params) && _.isString(params.contentId)) ? '?v=' + params.contentId : '';
                var modalURL;

                if (modalId.indexOf('/') >= 0) {
                    modalURL = modalId;
                }
                if (modalId.indexOf('.') >= 0) {
                    modalURL = modalURL + suffix;
                } // allow custom extension
                else {
                    modalURL = modalURL + suffix;
                }

                console.log('loadModalTemplate', modalURL, params);

                if (!self.isModalLoading) {

                    self.isModalLoading = true;

                    if (self.currentModal) {
                        self.currentModal.exit();
                    }

                    $.ajax({

                        type: 'GET',
                        url: modalURL,
                        context: document.body,
                        async: true,
                        data: params.contentParams,

                        success: function (data) {

                            self.createModalView(modalId, params, data);
                            self.isModalLoading = false;
                        },

                        error: function (e) {

                            console.log('Template Load Error');
                            console.log(e.message);

                            self.isModalLoading = false;
                        }
                    });
                }
            },

            // create modal object

            createModalView: function (modalId, params, template) {

                var self = this;
                var split = modalId.split('/');
                modalId = split[split.length - 1];

                console.log("createModalView", modalId, params);

                switch (modalId) {

                    case 'CreateABlock':
                        self.currentModal = new CreateABlockModalView({
                            $container: self.$modalContainer,
                            template: template,
                            model: params || {},
                            autoShow: false,
                        });
                        break;

                    case 'AirportTransfer':
                        self.currentModal = new AirportTransferModalView({
                            $container: self.$modalContainer,
                            template: template,
                            model: params || {},
                            autoShow: false,
                        });
                        break;

                    default:
                        self.currentModal = new GenericModalView({
                            $container: self.$modalContainer,
                            template: template,
                            model: params || {},
                            autoShow: false,
                        });
                }

                self.currentModal.on('closeClick', self._onModalCloseClick, self);
                self.showModal();
            },

            _onModalCloseClick: function () {

                var self = this;
                self.removeModal();
            },

            // show / hide

            showModal: function () {

                var self = this;
                self.currentModal.show();
                window.$vent.trigger('hideSpinner');


                var containerVeil = $(self.$modalContainer).find('.modal');
                self.$modalVeil = $('<div class="modal-veil veil"></div>').appendTo(containerVeil);
                self.$modalVeil.on('click', $.proxy(self._onModalCloseClick, self));

                if (self.currentModal.model.whiteVeil) {
                    self.$modalVeil.addClass('white');
                }


                /* unable scroll */
                $('body').addClass('no-scroll');

                TweenMax.killTweensOf(self.$modalVeil);
                TweenMax.to(self.$modalVeil, 0.8, {opacity: 1, ease: Cubic.easeOut});

                var tweenObj = {scrollTop: $(window).scrollTop()};
                TweenMax.to(tweenObj, 0.8, {
                    scrollTop: 0, ease: Cubic.easeOut, onUpdate: function () {
                        //$(window).scrollTop(tweenObj.scrollTop);
                    }
                });

                $(window).resize();

                window.$vent.trigger('domUpdate');

                TweenMax.delayedCall(1, function () {
                    $(window).resize();
                });
            },

            hideModal: function () {

                var self = this;


                self.currentModal.hide();

                TweenMax.killTweensOf(self.$modalVeil);
                TweenMax.to(self.$modalVeil, 0.8, {opacity: 0, ease: Cubic.easeOut});
            },

            // remove

            removeModal: function (switching) {

                var self = this;
                $('body').removeClass('no-scroll');

                if (self.currentModal) {
                    self.currentModal.off('closeClick', self._onModalCloseClick, self);
                    self.currentModal.exit();
                    self.currentModal = null;
                    self.currentModalId = null;
                }

                if (switching !== true) {

                    self.$modalVeil.off('click', $.proxy(self._onModalCloseClick, self));

                    TweenMax.killTweensOf(self.$modalVeil);
                    TweenMax.to(self.$modalVeil, 0.8, {
                        opacity: 0, ease: Cubic.easeOut, onComplete: function () {
                            self.$modalVeil.remove();
                            self.$modalVeil = null;
                        }
                    });
                }
            },

            // window resize

            onWindowResize: function () {

                var self = this;
                var windowHeight = window.innerHeight;

                if (self.currentModal) {

                    var modalHeight = self.currentModal.$el.find('.inner-container').outerHeight();

                    if (modalHeight >= windowHeight) {
                        //TweenMax.set(self.$modalContainer, {position:'absolute'});
                    }
                    else {
                        //TweenMax.set(self.$modalContainer, {position:'fixed'});
                    }
                }
            },

            showSuccesMessage: function (e, data) {
                var self = this;

                var successMessage = data.succesMessage;
                console.log('self.currentModal', self.currentModal);
                console.log('succesMessage', successMessage);
                var container = self.currentModal.$el.find(".messagesContainer");

                TweenMax.to(container, 0.3, {
                    opacity: 0, ease: Cubic.easeInOut, onComplete: function () {

                        container.html(successMessage);
                        TweenMax.to(container, 0.3, {opacity: 1, ease: Cubic.easeOut});

                        window.$vent.trigger('domUpdate');
                    }
                });
            },

            _onSubmitPayment: function (e, data) {
                var self = this;
                console.log('submitting payment form');
                $("#formhidden").submit();
            }

            // ---------------------------------------------------------------------------------  /

        });

        return AppView;
    }
);

