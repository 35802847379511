define(
	'modules/mobile-interactions/app',[
		'backbone',
		'jqueryhammer',
		'bootstrap',
		'modules/mobile-interactions/views/MobileGuideSelectorView',
	], 
	function(Backbone, hammer, carousel, MobileGuideSelectorView){

		var AppView = Backbone.View.extend({

			// core vars

			$context: null,

			winWidth: null,
			modules: null,

			// init

			initialize: function(options){

				var self = this;
				self.$context = $;
				self.modules = [];

				window.$vent.trigger('mobileInteractionsReady');
			},

			// start module 
			start: function(){

				var self = this;
				window.$vent.on('domUpdate', $.proxy(self._onDomUpdate, self));
				$(window).on('resize', $.proxy(self._onWindowResize, self));
				
				$(function(){
					self.onDomReady();
				});
			},

			onDomReady: function(){

				var self = this;
				self._onWindowResize();
			},

			_onDomUpdate: function(){

				var self = this;

				if ($('form')) {
					window.$vent.trigger('formLoaded');
				}

				self.refreshModules();
			},

			_onWindowResize: function(){

				var self = this;
				self.winWidth = window.innerWidth;
				self.refreshModules();
			},

			refreshModules: function(){

				var self = this;

				if(self.winWidth <= 992){

					// carousel

					_.each(self.$el.find('.mobile-gi-carousel'), function(module, i){
						
						if(!self.checkExisting(module, 'Carousel')){
							
							var $module = window.$rootContext(module);
							
							var $module = window.$rootContext(module);
							var $moduleParent = $(module).parent();

							var options = { preventDefault: true };

							$moduleParent.hammer(options).on('swiperight', function(){
								$module.carousel('prev');
							});

							$moduleParent.hammer(options).on('swipeleft', function(){
								$module.carousel('next');
							});
							
							self.modules.push({
								type: 'Carousel',
								el: $module
							});

							$module.carousel();

							if($module.find('.carousel-counter').length){

								var totalItems = $module.find('.item').length;
								$module.find('.count').html('1/'+totalItems+'');
								$module.$module = $module;

								$module.find('.carousel-btn.left').on('click', function(){
									$module.carousel('prev');
								});

								$module.find('.carousel-btn.right').on('click', function(){
									$module.carousel('next');
								});

								$module.on('slid.bs.carousel', function(){
								   var currentIndex = $module.find('.active').index() + 1;
								   $module.find('.count').html(''+currentIndex+'/'+totalItems+'');
								});
							}
						}
					});
				}
				
				if(self.winWidth < 768){

					// ACTIONS OCCURRING WHEN WINDOW IS MOBILE SIZE
		
					// guide selector

					_.each(self.$el.find('#mobile-guide-selector'), function(module, i){
						
						if(!self.checkExisting(module, 'MobileGuideSelectorView')){
							
							var $module = window.$rootContext(module);
							
							self.modules.push({
								module: new MobileGuideSelectorView({$menu:$module}),
								type: 'MobileGuideSelectorView',
								el: $module
							});
						}
					});
				}
				else {

					// BACKUP ACTIONS WHEN WINDOW IS LARGER THAN MOBILE					
					_.each(self.$el.find('#mobile-guide-selector'), function(module, i){
						
						var $guideSelector = $(module);
						var defaultId = $guideSelector.data('default');

						if(defaultId){
							
							var $defaultContainer = $('#' + defaultId);
							var defaultTemplate = _.template($('#' + defaultId + '-template').html());
							
							if(!$defaultContainer.html()){

								$defaultContainer.html(defaultTemplate());

								TweenMax.set($defaultContainer, {display:'block'});

								window.$vent.trigger('domUpdate');
							}
						}
					});					
				}
			},

			// check for existing modules ------------------------------------------------------  /

			checkExisting: function(el, type){

				var self = this;
				var exists = false;

				if(typeof(el.context) !== 'undefined'){ el = el[0]; }

				_.each(self.modules, function(moduleObj, i){

					var moduleEl = moduleObj.el;

					if(typeof(moduleEl.context) !== 'undefined'){ moduleEl = moduleEl[0]; }

					if(moduleEl === el && type === moduleObj.type){
						exists = true;
					}
				});

				return exists;
			},

			// ---------------------------------------------------------------------------------  /

		});

		return AppView;
	}
);
