
define(
	'pagecontrollers/area-map/views/CuratedGuideItemView',[
		'backbone',
		
		'utils/AbstractView',
		'utils/AbstractButtonView',

		'text!../templates/CuratedGuideItemTemplate.html'
	], 
	function(Backbone, AbstractView, AbstractButtonView, Template){

		var CuratedGuideItemView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			options: null,
			abstractView: null,
			abstractButtonView: null,

			// vars

			// init

			initialize: function(options){

				var self = this;

				self.options = options;
				self.$container = self.options.$container;
				self.el = self.options.el;

				//

				self.template = _.template(Template);
				self.model = $.extend({

				}, self.model);

				// 

				self.abstractView = new AbstractView({
					view: self,
					autoShow: true
				});

				self.abstractButtonView = new AbstractButtonView({
					abstractView: self.abstractView,
				});
			},

			// render

			onRender: function(){

				var self = this;
			},

			onAnimateClick: function(){

				var self = this;
				window.$vent.trigger('changeMapLocation', self.model.location);

				if(self.model.location.title){ window.$vent.trigger('neighborhoodSelected', self.model.location); }
				else { window.$vent.trigger('citySelected', self.model.location); }
			},

			// ---------------------------------------------------------------------------------  /

		});

	return CuratedGuideItemView;
});
