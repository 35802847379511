
define(
	'pagecontrollers/area-map/views/CuratedGuideView',[
		'backbone',
		'utils/AbstractView',
		'pagecontrollers/area-map/views/CuratedGuideItemView',
		'text!../templates/CuratedGuideTemplate.html',
	], 
	function(Backbone, AbstractView, CuratedGuideItemView, Template){

		var CuratedGuideView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			options: null,
			abstractView: null,

			//

			$guideListContainer: null,
			$loadMoreButton: null,

			guidesModel: null,
			guides: null,
			guidesLimit: 6,

			// init

			initialize: function(options){

				var self = this;

				self.options = options;
				self.$container = self.options.$container;
				self.el = self.options.el;

				self.template = _.template(Template);
				self.model = $.extend({

				}, self.model);

				// 

				self.abstractView = new AbstractView({
					view: self,
					autoShow: true
				});
			},

			// rendering

			onRender: function(){

				var self = this;

				self.$guideListContainer = self.$el.find('.big-cguides');
				self.$loadMoreButton = self.$el.find('.btn-loadmore-guides');

				self.loadGuideList();
			},

			onDomReady: function(){

				var self = this;
			},

			// listener methods

			onAddListeners: function(){

				var self = this;
				self.$loadMoreButton.on('click', $.proxy(self._onLoadMoreClick, self));
			},

			_onLoadMoreClick: function(){

				var self = this;
				self.loadMoreGuides();
			},

			// load guides

			loadGuideList: function(){

				var self = this;

				$.ajax({

					type: 'GET',
					url: self.model.queryURL,
					async: false,
					jsonpCallback: 'callBack',
					contentType: 'application/json',
					dataType: 'jsonp',

					success: function(json) {
						self._onGuidesLoaded(json.results);
					},
					error: function(e) {
						console.log('JSON Load Error', self);
						console.log(e.message);
						self._onPropertyConfigFail(e);
					}
				});
			},

			_onGuidesLoaded: function(json){

				var self = this;
				
				self.guidesModel = json;
				self.loadMoreGuides();

				if(self.guidesModel.length > self.guidesLimit){
					self.showMoreButton();
				}
			},

			// load more guides

			loadMoreGuides: function(){

				var self = this;

				if(!self.guides){ self.guides = []; }

				for(var i=0; i<self.guidesLimit; i++){

					var index = self.guides.length;

					if(index >= self.guidesModel.length){ 
						self.hideMoreButton();
						break; 
					}

					var guideItemView = new CuratedGuideItemView({
						$container: self.$guideListContainer,
						model: self.guidesModel[index],
					});

					//console.log(self.guidesModel[index].location.pinCount);

					self.guides.push(guideItemView);
				}

				window.$vent.trigger('domUpdate');
			},

			// 

			showMoreButton: function(){

				var self = this;

				TweenMax.fromTo(self.$loadMoreButton, 0.5, {opacity:0}, {opacity:1, ease:Cubic.easeOut});
				self.$loadMoreButton.removeClass('hidden');
			},

			hideMoreButton: function(){

				var self = this;

				TweenMax.to(self.$loadMoreButton, 0.4, {opacity:0, ease:Cubic.easeOut, onComplete:function(){
					self.$loadMoreButton.removeClass('hidden').addClass('hidden');
				}});
			},

			//

			onHide: function(){

				var self = this;
				TweenMax.to(self.$el, 0.4, {opacity:0, ease:Cubic.easeInOut, onComplete:function(){
					self.trigger('hideComplete');
				}});
			},

			onDestroyComplete: function(){
				window.$vent.trigger('domUpdate');
			},

		});

	return CuratedGuideView;
});
