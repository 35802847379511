define(
	'modules/big-hero/app',[
		'backbone',
		'modules/big-hero/views/BigHeroView',
	], 
	function(Backbone, BigHeroView){

		var AppView = Backbone.View.extend({

			// core vars

			$modules: null,
			modules: null,

			// init

			initialize: function(options){

				var self = this;
			},

			// start module --------------------------------------------------------------------  /

			start: function(){

				var self = this;
				self.modules = [];
				
				window.$vent.on('domUpdate', $.proxy(self._onDomUpdate, self));
				$(window).on('resize', $.proxy(self._onWindowResize, self));
				
				$(function(){
					self.onDomReady();
				});
				
				window.$vent.trigger('bigHeroReady');
			},

			onDomReady: function(){

				var self = this;
				self.refreshModules();
			},

			_onDomUpdate: function(){

				var self = this;
				self.refreshModules();
			},

			_onWindowResize: function(){

				var self = this;
				self.winWidth = window.innerWidth;
				self.refreshModules();
			},

			refreshModules: function(){

				var self = this;
				
				self.$modules = $('.big-hero');		

				_.each(self.$modules, function(module, i){
					
					var $module = $(module);

					if(!self.checkExisting(module, 'BigHeroView')){

						self.modules.push({
							module: new BigHeroView({el:$module}),
							type: 'BigHeroView',
							el: $module
						});
					}
				});	
			},

			// check for existing modules ------------------------------------------------------  /

			checkExisting: function(el, type){

				var self = this;
				var exists = false;

				if(typeof(el.context) !== 'undefined'){ el = el[0]; }

				_.each(self.modules, function(moduleObj, i){

					var moduleEl = moduleObj.el;

					if(typeof(moduleEl.context) !== 'undefined'){ moduleEl = moduleEl[0]; }

					if(moduleEl === el && type === moduleObj.type){
						exists = true;
					}
				});

				return exists;
			},
			
			// ---------------------------------------------------------------------------------  /

		});

		return AppView;
	}
);
