define(
	'modules/listings-items/app',[
		'backbone',
		'modules/listings-items/views/ListingsItemView',
	], 
	function(Backbone, ListingsItemView){

		var AppView = Backbone.View.extend({

			// core vars

			$itemsContainer: null,
			expandedTemplate: null,

			items: null,
			tweenDelay: null,

			// init

			initialize: function(options){

				var self = this;
	
				self.expandedTemplate = self.$el.find('#expanded-availability-template').html();
				self.$el.find('#expanded-availability-template').remove();
	
				window.$vent.trigger('listingsItemsReady');
			},

			// start module --------------------------------------------------------------------  /

			start: function(){

				var self = this;
				
				$(function(){
					self.onDomReady();
				});
			},

			onDomReady: function(){

				var self = this;

				self.$itemsContainer = $('.listings');
				self.items = [];

				_.each(self.$itemsContainer, function(listingsItem, i){
					
					var $itemsContainer = $(listingsItem);
					var $items = $itemsContainer.find('.listing-item');

					for(var i=0; i<$items.length; i++){

						var $listingsItem = $($items[i]);
						var listingsItemView = new ListingsItemView({
							el: $listingsItem,
							$container: $itemsContainer,
							expandedTemplate: self.expandedTemplate
						});

						listingsItemView.on('mouseenter', self._onMouseEnter, self);
						listingsItemView.on('mouseleave', self._onMouseLeave, self);

						self.items.push(listingsItemView);
					}
				});	

				self.$items = self.$itemsContainer.find('li');

				window.$vent.trigger('domUpdate', {view:self, type:'listings-items'});
			},

			_onMouseEnter: function(e){

				var self = this;
				var $target = e.target.$el;
				
				if(!$target.hasClass('over')){
					
					$target.removeClass('over').addClass('over');

					_.each(self.items, function(item, i){			
						
						var $item = item.$el;

						if(!$item.hasClass('over')){

							var $veil = $item.find('.image-veil');
							TweenMax.set($veil, {opacity:1});
							
							if(self.tweenDelay){ self.tweenDelay.kill(); }	

							$veil.removeClass('hidden');
							$item.addClass('over');
						}
					});
				}
				
			},

			_onMouseLeave: function(e){

				var self = this;		
				var $target = e.target.$el;
				
				if($target.hasClass('over')){
					
					$target.removeClass('over');

					_.each(self.items, function(item, i){
						
						var $item = item.$el;

						if($item.hasClass('over')){

							var $veil = $item.find('.image-veil');
							TweenMax.set($veil, {opacity:0});
							
							if(self.tweenDelay){ self.tweenDelay.kill(); }

							self.tweenDelay = TweenMax.delayedCall(0.6, function(){
								$veil.removeClass('hidden').addClass('hidden');
							});

							$item.removeClass('over');
						}
					});
				}
			},
			
			// ---------------------------------------------------------------------------------  /

		});

		return AppView;
	}
);
