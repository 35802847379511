
define(
	'modules/global-scripted-buttons/views/MorePhotosButtonView',[
		'backbone',
		'utils/AbstractView',
		'utils/AbstractButtonView',
	], 
	function(Backbone, AbstractView, AbstractButtonView, Template){

		var MorePhotosButtonView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			options: null,
			abstractView: null,
			abstractButtonView: null,

			// vars

			$additionalPhotos: null,
			$photosContainer: null,

			// init

			initialize: function(options){

				var self = this;

				// options vars

				self.options = options;
				self.$container = self.options.$container;

				// template config

				//self.template = _.template(Template);
				self.model = $.extend({}, self.model);

				// initialize AbstractView and AbstractButtonView

				self.abstractView = new AbstractView({
					view: self,
					autoShow: self.options.autoShow !== false,
				});

				self.abstractButtonView = new AbstractButtonView({
					abstractView: self.abstractView,
				});
			},

			// render

			onAnimateClick: function(){

				var self = this;
				self.$photosContainer = $('#' + self.$el.data('container-id'));

				if(self.$el.hasClass('selected') || self.$photosContainer.hasClass('more-photos')){
					
					var $additionalPhotos = self.$photosContainer.find('.additional-photo');
					$additionalPhotos.remove();

					self.$photosContainer.removeClass('more-photos');
					self.$el.removeClass('selected');
				}
				else {
					
					var $additionalPhotos = self.$photosContainer.find('.additional-photo');
					if(!$additionalPhotos.length){

						var photosTemplate = $('#' + self.$el.data('template-id')).html();
						$additionalPhotos = $(photosTemplate);

						console.log('$additionalPhotos',$additionalPhotos);

						_.each($additionalPhotos, function(photo){
							$(photo).removeClass('additional-photo').addClass('additional-photo');
						});

						self.$photosContainer.append($additionalPhotos);
					}

					self.$photosContainer.addClass('more-photos');
					self.$el.addClass('selected');
				}

				window.$vent.trigger('domUpdate');
			}

			// ---------------------------------------------------------------------------------  /

		});

	return MorePhotosButtonView;
});
