define(
	'modules/properties-grid/app',[
		'backbone',
		'modules/properties-grid/views/GridItemView',
	], 
	function(Backbone, GridItemView){

		var AppView = Backbone.View.extend({

			// core vars

			$propertiesGrid: null,
			$propertiesGridItems: null,

			propertiesGrid: null,
			tweenDelay: null,

			// init

			initialize: function(options){

				var self = this;

				window.$vent.trigger('propertiesGridReady');
			},

			// start module --------------------------------------------------------------------  /

			start: function(){

				var self = this;
				
				$(window).on('resize', $.proxy(self._onWindowResize, self));

				$(function(){
					self.onDomReady();
				});
			},

			onDomReady: function(){

				var self = this;

				self.$propertiesGrid = $('.properties-grid');
				self.propertiesGrid = [];

				_.each(self.$propertiesGrid, function(propertiesGrid, i){
					
					var queryString = self.$propertiesGrid.data('query');
					var $propertiesGrid = $(propertiesGrid);
					var $gridItems = $propertiesGrid.find('.property-grid-item');

					for(var i=0; i<$gridItems.length; i++){

						var $gridItem = $($gridItems[i]);
						var gridItemView = new GridItemView({
							el: $gridItem,
							$container: self.$propertiesGrid
						});

						gridItemView.$el.on('mouseenter', $.proxy(self._onMouseEnter, self));
						gridItemView.$el.on('mouseleave', $.proxy(self._onMouseLeave, self));

						self.propertiesGrid.push(gridItemView);
					}
				});	

				self.$propertiesGridItems = self.$propertiesGrid.find('li');

				window.$vent.trigger('domUpdate', {view:self, type:'property-grid'});

				window.requestAnimationFrame(function(){
					$(window).resize();
				});
			},

			_onMouseEnter: function(e){

				var self = this;
				var $target = $(e.currentTarget);

				if(!$target.hasClass('over')){
					
					var $lastTarget = self.$propertiesGridItems.find('.over');
					$lastTarget.removeClass('over');
					$target.addClass('over');

					var $gridContainer = $target.parent();
					var $gridItems = $gridContainer.find('li');

					_.each($gridItems, function(gridItem, i){						
						
						var $gridItem = $(gridItem);

						if(!$gridItem.hasClass('over') && window.innerWidth >= 768){

							var $veil = $gridItem.find('.image-veil');
							
							TweenMax.set($veil, {opacity:1});
							if(self.tweenDelay){ self.tweenDelay.kill(); }
							
							$veil.removeClass('hidden');
						}
					});
				}
			},

			_onMouseLeave: function(e){

				var self = this;
				var $target = $(e.currentTarget);
				
				if($target.hasClass('over')){
					
					$target.removeClass('over');

					var $gridContainer = $target.parent();
					var $gridItems = $gridContainer.find('li');

					_.each($gridItems, function(gridItem, i){			
						
						var $gridItem = $(gridItem);

						if(!$gridItem.hasClass('over')){

							var $veil = $gridItem.find('.image-veil');
						
							TweenMax.set($veil, {opacity:0});
							
							if(self.tweenDelay){ self.tweenDelay.kill(); }
							self.tweenDelay = TweenMax.delayedCall(0.6, function(){
								$veil.removeClass('hidden').addClass('hidden');
							});
						}
					});
				}
			},

			_onWindowResize: function(){

				var self = this;
				var galleryWidth = 0;
				var galleryHeight = 0;

				_.each(self.propertiesGrid, function(gridItem, i){

					if(i === 0){
						galleryWidth = parseInt(gridItem.$el.width());
						galleryHeight = parseInt(galleryWidth/gridItem.galleryRatio);
					}

					if(gridItem.$gallery){
						TweenMax.set(gridItem.$gallery, {width:galleryWidth, height:galleryHeight});
					}
				});
			},
			
			// ---------------------------------------------------------------------------------  /

		});

		return AppView;
	}
);
