define('modules/global-form-helpers/app',[
			'backbone', 
			'modules/global-form-helpers/views/ContactFormView',
			'modules/global-form-helpers/views/RadioButtonsGroupListenerView'
		], 
		
		function(Backbone, ContactFormView, RadioButtonsGroupListenerView){

		var AppView = Backbone.View.extend({

			// core vars

			modules: null,

			// init

			initialize: function(options){

				var self = this;
				window.$vent.trigger('globalFormHelpersReady');
			},

			// start module --------------------------------------------------------------------  /

			start: function(){

				var self = this;
				window.$vent.on('domUpdate', $.proxy(self._onDomUpdate, self));
				
				$(function(){
					self.onDomReady();
				});
			},

			onDomReady: function(){

				var self = this;
				self.modules = [];
				self._onDomUpdate();
			},

			_onDomUpdate: function(){

				var self = this;
				self.refreshModules();
			},

			refreshModules: function(){

				var self = this;

				_.each(self.$el.find('#contact-form'), function(module, i){

					if(!self.checkExisting(module, 'ContactFormView')){						
						var $module = $(module);
						self.modules.push({
							type: 'ContactFormView',
							btn: new ContactFormView({el: $module})
						});
					}
				});

				_.each(self.$el.find('.radio-buttons-group'), function(module, i){
					
					if(!self.checkExisting(module, 'RadioButtonsGroupListenerView')){						
						var $module = $(module);
						self.modules.push({
							type: 'RadioButtonsGroupListenerView',
							btn: new RadioButtonsGroupListenerView({el: $module})
						});
					}
				});
			},

			// check for existing modules ------------------------------------------------------  /

			checkExisting: function(el, type){

				var self = this;
				var exists = false;

				if(typeof(el.context) !== 'undefined'){ el = el[0]; }

				_.each(self.modules, function(module, i){

					var moduleEl = module.btn.el;
					if(typeof(moduleEl.context) !== 'undefined'){ moduleEl = moduleEl[0]; }

					if(moduleEl === el && type === module.type){
						exists = true;
					}
				});

				return exists;
			},

			// ---------------------------------------------------------------------------------  /

		});

		return AppView;
	}
);
