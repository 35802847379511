
define(
	'modules/global-form-helpers/views/ContactFormView',[
		'backbone',
		'utils/AbstractView',
	], 
	function(Backbone, AbstractView){

		var ContactFormView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			options: null,
			abstractView: null,

			// vars

			$descriptionContainer: null,
			$formContainer: null,

			// init

			initialize: function(options){

				var self = this;

				// options vars

				self.options = options;
				self.$container = self.options.$container;

				// template config

				self.model = $.extend({}, self.model);

				// initialize AbstractView

				self.abstractView = new AbstractView({
					view: self,
					autoShow: self.options.autoShow !== false,
				});
			},

			onDomReady: function(){

				var self = this;

				self.$el = $("#contact-landing-page");

				self.$descriptionContainer = self.$el.find('.form-description-container');
				self.$formContainer = self.$el.find('.mf-form-contents-container');

				window.$vent.on('submitContactForm', $.proxy(self._onSubmit, self));

				self.showIntroDescription();
			},

			showIntroDescription: function(){

				var self = this;
				var introTemplate = _.template($('#intro-description-template').html());

				var $introContent = window.$rootContext(introTemplate());
				$introContent.find('select').selectBoxIt();

				TweenMax.to(self.$descriptionContainer, 0.3, {opacity:0, ease:Cubic.easeInOut, onComplete:function(){
					
					self.$descriptionContainer.html($introContent);
					TweenMax.to(self.$descriptionContainer, 0.3, {opacity:1, ease:Cubic.easeOut});

					window.$vent.trigger('domUpdate');
				}});
			},

			showSuccessDescription: function(){


				var self = this;
				var successTemplate = _.template($('#success-description-template').html());

				var $successContent = window.$rootContext(successTemplate());
				$successContent.find('select').selectBoxIt();

				TweenMax.to(self.$descriptionContainer, 0.3, {opacity:0, ease:Cubic.easeInOut, onComplete:function(){
					
					self.$descriptionContainer.html($successContent);
					TweenMax.to(self.$descriptionContainer, 0.3, {opacity:1, ease:Cubic.easeOut});

					window.$vent.trigger('domUpdate');
				}});
			},

			removeForm: function(){

				var self = this;

				TweenMax.to(self.$formContainer, 0.3, {opacity:0, ease:Cubic.easeInOut, onComplete:function(){
					self.$formContainer.empty();
					TweenMax.set(self.$formContainer, {opacity:1});
					
					window.$vent.trigger('domUpdate');
				}});

			},

			_showMobileConfirmation: function () {


				var mobform = $(".hscont-section-mobform");
				var successTemplate = _.template($('#success-description-template').html());
				var $successContent = window.$rootContext(successTemplate());
				
				mobform.html($successContent);
				mobform.find('select').selectBoxIt();

			},

			_onSubmit: function(e){


				var self = this;

				self.removeForm();
				self.showSuccessDescription();

				e.preventDefault();
				return false;
			},

		});

	return ContactFormView;
});
