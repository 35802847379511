define(
	'modules/guests/app',[
		'backbone',
		'modules/guests/views/GuestsMenuView',
		'modules/guests/views/GuestsMenuButtonsView',
	], 
	function(Backbone, GuestsMenuView, GuestsMenuButtonsView){

		var AppView = Backbone.View.extend({

			// core vars

			$context: null,

			$guestContainers: null,
			$guestButtonContainers: null,

			guests: null,
			guestsButtons: null,

			// init

			initialize: function(options){
				var self = this;
				self.$context = $;
			},

			// start module --------------------------------------------------------------------  /

			start: function(){

				var self = this;						
				self.guests = [];
				self.guestsButtons = [];

				window.$vent.on('setSelectedGuestsMobile', $.proxy(self._onsetSelectedGuestsMobile, self));
				window.$vent.trigger('guestsMobileLoaded');
				self._onDomUpdate();
				
				window.$vent.trigger('guestsReady');
				window.$vent.on('domUpdate', $.proxy(self._onDomUpdate, self));

			},

			_onDomUpdate: function(){

				var self = this;
				self.checkModules();
				self.checkModuleButtons();
			},

			_onsetSelectedGuestsMobile: function(e,data){
				var self = this;

				if(data.guestsSelected){
					console.log("guestsSelected ",data.guestsSelected);
					self.guestsResults = data.guestsSelected;
				}

			},
			
			// guests

			checkModules: function(){

				var self = this;
				self.$guestContainers = $('.guests-container');

				_.each(self.$guestContainers, function(guestsContainer, i){

					var $guestsContainer = $(guestsContainer);
										
					if(!self.checkExisting($guestsContainer, self.guests, true)){
						var guestsMenu = new GuestsMenuView({
							$container: $guestsContainer,
							$context: self.$context,
							selectedGuests: self.guestsResults,
							autoShow: true
						});

						self.guests.push(guestsMenu);
					}
				});	
			},

			// guests buttons

			checkModuleButtons: function(){

				var self = this;
				self.$guestButtonContainers = $('.guests-buttons');

				_.each(self.$guestButtonContainers, function(guestsButtonsContainer, i){

					var $guestsButtonsContainer = $(guestsButtonsContainer);
										
					if(!self.checkExisting($guestsButtonsContainer, self.guestsButtons)){

						var $mobileContainer = $('#' + $guestsButtonsContainer.data('mobile-overlay-id'));
						var $desktopContainer = $('#' + $guestsButtonsContainer.data('desktop-overlay-id'));

						var guestsButtons = new GuestsMenuButtonsView({
							el: $guestsButtonsContainer,
							$context: self.$context,
							$desktopContainer: $desktopContainer,
							selectedGuests: self.guestsResults,
							$mobileContainer: $mobileContainer
						});

						self.guestsButtons.push(guestsButtons);
					}
				});	
			},

			// check for existing buttons ------------------------------------------------------  /

			checkExisting: function(el, searchArray, useContainer){

				var self = this;
				var exists = false;

				if(typeof(el.context) !== 'undefined'){ el = el[0]; }

				_.each(searchArray, function(view, i){

					var viewEl = view.$el[0];
					if(useContainer === true){ viewEl = view.$container[0]; }

					if(typeof(viewEl.context) !== 'undefined'){ viewEl = viewEl[0]; }

					if(viewEl === el){
						exists = true;
					}
				});

				return exists;
			},

			// ---------------------------------------------------------------------------------  /

		});

		return AppView;
	}
);
