
define(
	'modules/global-scripted-buttons/views/TooltipButtonView',[
		'backbone',
		'utils/AbstractView',
		'utils/AbstractButtonView',
		
		'text!../templates/TooltipTemplate.html',
	], 
	function(Backbone, AbstractView, AbstractButtonView, Template){

		var TooltipButtonView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			options: null,
			abstractView: null,
			abstractButtonView: null,

			// vars

			$rollOver: null,

			// init

			initialize: function(options){

				var self = this;

				// options vars

				self.options = options;
				self.$container = self.options.$container;

				// template config

				//self.template = _.template(Template);

				self.model = $.extend({}, self.model);

				self.$rollOver = $(_.template(Template)(self.model));

				// initialize AbstractView and AbstractButtonView

				self.abstractView = new AbstractView({
					view: self,
					autoShow: true
				});

				self.abstractButtonView = new AbstractButtonView({
					abstractView: self.abstractView,
				});
			},

			// listeners

			onAddListeners: function(){
				
				var self = this;

			},

			_onOutClick: function(e){

				var self = this;

				if(!self.$el[0].contains(e.target)){
					self.exit();
					e.preventDefault();
				}
			},

			// animate 

			onAnimateOver: function(){

				var self = this;

				TweenMax.killTweensOf(self.$rollOver);
				TweenMax.fromTo(self.$rollOver, 0.2, {opacity:0}, {opacity:1, ease:Quint.easeOut});

				if(!self.$rollOver.parent().length){
					self.$el.append(self.$rollOver);
				}
			},

			onAnimateOut: function(){

				var self = this;

				TweenMax.killTweensOf(self.$rollOver);
				TweenMax.to(self.$rollOver, 0.2, {opacity:0, ease:Quint.easeOut, onComplete:function(){
					self.$rollOver.remove();
				}});
			},

			onAnimateSelect: function(){

				var self = this;

				window.requestAnimationFrame(function(){
					$(document).off('click', $.proxy(self._onOutClick, self));
					$(document).on('click', $.proxy(self._onOutClick, self));
				});
			},

			onAnimateDeselect: function(){

				var self = this;
				$(document).off('click', $.proxy(self._onOutClick, self));
			},

			onWindowResize: function(){

				var self = this;
			},

		});

	return TooltipButtonView;
});
