
define(
	'modules/calendar-single/views/CalendarSingleMenuView',[
		'backbone',
		'utils/AbstractView',
		
		'modules/property-detail-bar/views/ToolTipView',

		'text!../templates/CalendarSingleMenuTemplate.html',
		'text!../templates/CalendarSingleTemplate.html',
	], 
	function(Backbone, AbstractView, ToolTipView, Template, CalendarTemplate){

		var CalendarMenuView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			options: null,
			abstractView: null,

			// vars

			$context: null,

			clndr: null,
			multidayArray: null,
			isDateSelected: false,
			queryString: null,

			endDate: null,
			startDate: null,

			$singleCalendarContainer: null,
			$clearDatesButton: null,
			$closeButton: null,

			singleCalendar: null,
			singleMonth: null,

			toolTip: null,
			toolTipTimeout: null,

			serverQuery: null,
			availabilityData: null,
			selectedDates: null,
			queryObj: null,

			minNights: null,
			maxNights: null,

			showScrollTop: 0,

			isShortDate: false,
			isReadOnly: false,
			isMonthChange: false,
			isOwnerListing: false,
			isEditBlock: false,
			isFirstShow: true,

			hasDayPrices: false,
			selectRange: false,

			// init

			initialize: function(options){

				var self = this;

				self.options = options;
				self.$container = self.options.$container;
				self.$context = self.options.$context || $;

				self.isShortDate = self.options.shortDate === true;
				self.selectedDates = self.options.selectedDates; 

				self.template = _.template(Template);
				self.model = $.extend({}, self.model);

				// defaults in case of availability data
				
				self.occupiedBlocks = [];
				self.minNights = 0;
				self.maxNights = 0;

				// default start / end dates
				
				self.startDate = moment(moment().format('YYYY-MM-DD'));
				self.endDate = moment().add(24, 'months');

				// allow / disallow multiple range selection

				self.selectRange = self.$container.data('select-range') === true;

				// block index (for editing owner listings)

				self.selectedBlockIndex = self.$container.data('block-index');
				
				if(_.isNumber(self.selectedBlockIndex) && self.selectedBlockIndex >= 0){ 
					self.isEditBlock = true; 
				}

				// 

				self.abstractView = new AbstractView({
					view: self,
					autoShow: (self.options.autoShow === true),
					hideClass: self.options.hideClass || 'display-none'
				});

				//

				$(window).on('resize', $.proxy(self._onWindowResize, self));
				self._onWindowResize();
			},

			// initialize ----------------------------------------------------------------------  /

			onInitialize: function(){

				var self = this;

				self.clndr = {};
				self.multidayArray = [];
				self.isDateSelected = false;
				self.queryString = '';
			},

			// onRender ------------------------------------------------------------------------  /

			onRender: function(){

				var self = this;	

				if(self.$container.hasClass('read-only') || self.$container.data('read-only')){ self.isReadOnly = true; }
				if(self.$container.hasClass('owner-listing') || self.$container.data('owner-listing')){ self.isOwnerListing = true; }

				self.resetEvents();

				// combine all occupied blocks

				window.$vent.on('setAvailabilityData', $.proxy(self._onSetAvailabilityData, self));

				var availabilityData = self.model.availability;
				if(availabilityData){ self.configureAvailabilityData(availabilityData); }
				else { 
					window.$vent.trigger('availabilityDataRequest', {target:self}); 					
					if(self.$container.data('pending-data') !== true){ self.reRenderCalendar(); }
				}

				// clear dates button

				self.$clearDatesButton = self.$container.find('.clear-dates');
				self.$closeButton = self.$el.find('.fa.close').add(self.$el.find('.close-btn'));
			},

			reRenderCalendar: function(){

				var self = this;

				//console.log('>>> reRenderCalendar', self.availabilityData);

				if(self.singleCalendar){ self.$singleCalendarContainer.empty(); }

				// start dates

				var firstRange = self.getFirstAvailableDate();
				var startDate = moment(firstRange.startDate);

				if(self.isReadOnly){
					startDate = moment(moment().format('YYYY-MM-DD')).add(1, 'months');
				}

				self.singleMonth = startDate.format('MM');

				// append calendars

				var $calendarsContainer = self.$el.find('.calendar-inner-container');
				if(!$calendarsContainer.length) $calendarsContainer = self.$el;

				// clndr object

				self.$singleCalendarContainer = self.$context('<div class="calendar-container"></div>').appendTo($calendarsContainer);
				self.singleCalendar = self.clndr.passInATemplate = self.$singleCalendarContainer.clndr({
					template: CalendarTemplate,
					weekOffset: 1,
					constraints: {
						startDate: startDate,
						endDate: self.endDate
					},
					forceSixRows: true,
					events: [], //multidayArray,
					clickEvents: {
						click: self._onCalendarClick.bind(self),
						nextMonth: self._onNextMonth.bind(self),
						previousMonth: self._onPrevMonth.bind(self),
					},
					extras: {
						header: 'Check In',
						multidayArray: self.multidayArray,
						availabilityData: self.availabilityData,
						traverseDateRange: self.traverseDateRange,
						isOwnerListing: self.isOwnerListing,
						isEditBlock: self.isEditBlock,
						selectedBlockIndex: self.selectedBlockIndex,
					}
				});

				//

				//console.log('Calendar Config: isReadOnly:', self.isReadOnly, 'isOwnerListing:', self.isOwnerListing, 'selectedBlockIndex:', self.selectedBlockIndex);

				//

				self.tweakCalendar();
			},


			// listeners -----------------------------------------------------------------------  /

			onAddListeners: function(){

				var self = this;

				window.$vent.on('requestCalendarQuery', $.proxy(self._onRequestCalendarQuery, self));

				self.$clearDatesButton.on('click', $.proxy(self._onClearDatesClick, self));
				self.$closeButton.on('click', $.proxy(self._onCloseClick, self));
			},

			onRemoveListeners: function(){

				var self = this;

				if(self.$clearDatesButton){
					self.$clearDatesButton.off('click', $.proxy(self._onClearDatesClick, self));
				}

				if(self.$closeButton){
					self.$closeButton.off('click', $.proxy(self._onCloseClick, self));
				}
			},

			// listener methods ----------------------------------------------------------------  /

			triggerOption: function(checkInDate, checkOutDate){

				var self = this;

				if(!checkOutDate){ checkOutDate = checkInDate; }

				if(checkInDate && checkOutDate){

					self.singleCalendar.setMonth(moment(checkInDate).format('MM')-1);

					self.changeStartDate({}, moment(checkInDate), moment(checkOutDate));
					self.tweakCalendar();
				}
			},

			_onCalendarClick: function(target){

				var self = this;
				var $target = $(target.element);

				if(!$target.hasClass('inactive') && !$target.hasClass('disabled')){

					var startDate, endDate, lastMonth;
					var success = false;

					if(self.selectRange){

						endDate = moment(target.date);
						startDate = moment(self.multidayArray[0].endDate);
						lastMonth = startDate.format('MM');
						
						success = self.changeEndDate(target, startDate, endDate);

						if(success){
							startDate = success.startDate;
							endDate = success.endDate;
						}
					}
					else {

						startDate = endDate = moment(target.date);
						lastMonth = startDate.format('MM');

						self.multidayArray[0].startDate = startDate;
						self.multidayArray[0].endDate = endDate;

						success = true;
					}

					if(success){

						if(lastMonth != startDate.format('MM')){
							self.singleCalendar.setMonth(startDate.format('MM')-1);
						}

						window.requestAnimationFrame(function(){
							self.isMonthChange = true;
							self.tweakCalendar();
							self.closeMenu();
						});
					}
				}
			},

			_onNextMonth: function(e){

				var self = this;

				if(self.isReadOnly){

					if(e.target === self.singleCalendar){
						self.singleCalendar.forward();
					}
				}

				self._onMonthChange(e);
			},

			_onPrevMonth: function(e){

				var self = this;

				if(self.isReadOnly){

					if(e.target === self.singleCalendar){
						self.singleCalendar.back();
					}
				}

				self._onMonthChange(e);
			},

			_onMonthChange: function(e){

				var self = this;

				window.$vent.trigger('calendarMonthClick');

				self.isMonthChange = true;
				self.tweakCalendar();
			},

			_onClearDatesClick: function(){

				var self = this;
				self.clearDates();
			},

			_onRequestCalendarQuery: function(e, obj){

				var self = this;

				if(obj.$container[0].contains(self.$el[0])){
					self.sendQueryObj();
				}
			},

			_onCloseClick: function(){

				var self = this;
				self.closeMenu();
			},

			//

			closeMenu: function(){

				var self = this;
				self.trigger('closeMenu');
			},

			// date selection blocks -----------------------------------------------------------  /

			changeStartDate: function(target, startDate, endDate){

				var self = this;
				var $target = $(target.element);

				var minEndDate = moment(startDate).add(self.minNights, 'days');
				var minStartDate = moment(endDate).subtract(self.maxNights, 'days');
				var maxStartDate = moment(endDate).subtract(self.minNights, 'days');

				if(self.availabilityData){

					// disable blocked off dates if data is provided

					var startNextOccupiedDate = self.getNextOccupiedDate(startDate.format('x'));
					var endNextOccupiedDate = self.getNextOccupiedDate(endDate.format('x'));

					if(!self.isAvailableDate(startDate.format('x'))){

						self.showToolTip($target, 'Min1 ' + self.minNights + ' Nights', 'red');
						return false;

					} else if(startNextOccupiedDate.format('x') === endNextOccupiedDate.format('x') && startDate.format('x') <= endDate.format('x')){

						// within same date range

						if(startDate.format('x') < minStartDate.format('x')){

							// invalid, too far

							self.showToolTip($target, 'Max ' + self.maxNights + ' Nights', 'red');
							return false;
						}
						else if(startDate.format('x') > maxStartDate.format('x')){

							// invalid, too close

							self.showToolTip($target, 'Min2 ' + self.minNights + ' Nights', 'red');
							return false;
						}
						else { 

							// valid, no further changes needed

							self.multidayArray[0].startDate = startDate;
							self.multidayArray[0].endDate = endDate;
							
							return {startDate: startDate, endDate: endDate};
						}

					} else {

						// creating block somewhere new, need to check everything

						return self.changeEndDate(target, startDate, minEndDate);
					}
				}
				else {

					// no data, assume no blockout dates

					if(endDate.format('x') >= minEndDate.format('x')){
						
						self.multidayArray[0].startDate = startDate;
						self.multidayArray[0].endDate = endDate;
						
						return {startDate: startDate, endDate: endDate};
					}
					else {

						self.showToolTip($target, 'Min3 ' + self.minNights + ' Nights', 'red');
						return false;
					}
				}

				return {startDate: startDate, endDate: endDate};
			},

			changeEndDate: function(target, startDate, endDate){

				var self = this;
				var $target = $(target.element);

				var maxStartDate = moment(endDate).subtract(self.minNights, 'days');
				var minEndDate = moment(startDate).add(self.minNights, 'days');
				var maxEndDate = moment(startDate).add(self.maxNights, 'days');

				if(self.availabilityData){

					var startPrevOccupiedDate = self.getPrevOccupiedDate(startDate.format('x'), startDate);
					var endPrevOccupiedDate = self.getPrevOccupiedDate(endDate.format('x'), endDate);

					if(!self.isAvailableDate(endDate.format('x'))){

						self.showToolTip($target, 'Day Not Available', 'red');
						return false;

					} else if(startPrevOccupiedDate.format('x') === endPrevOccupiedDate.format('x') && startDate.format('x') <= endDate.format('x')){

						// within same date range

						if(endDate.format('x') > maxEndDate.format('x')){

							// invalid, too far

							self.showToolTip($target, 'Max ' + self.maxNights + ' Nights', 'red');
							return false;
						}
						else if(endDate.format('x') < minEndDate.format('x')){

							// invalid, too close

							self.showToolTip($target, 'Min 1' + self.minNights + ' Nights', 'red');
							return false;
						}
						else { 

							// valid, no further changes needed

							self.multidayArray[0].startDate = startDate;
							self.multidayArray[0].endDate = endDate;
							
							return {startDate: startDate, endDate: endDate};
						}

					} else {

						// creating block somewhere new, need to check everything

						return self.changeStartDate(target, maxStartDate, endDate);
					}

				} else {

					// no data, assume no blockout dates

					if(endDate.format('x') >= minEndDate.format('x')){
						
						self.multidayArray[0].startDate = startDate;
						self.multidayArray[0].endDate = endDate;

						return {startDate: startDate, endDate: endDate};
					}
					else {

						self.showToolTip($target, 'Min ' + self.minNights + ' Nights', 'red');
						return false;
					}
				}

				return {startDate: startDate, endDate: endDate};
			},

			showToolTip: function($target, message, color, width){

				var self = this;

				if(self.toolTip && self.toolTip.$el[0] !== $target[0]){
					self.removeToolTip();
					self.toolTip = null;
				}

				if(self.toolTipTimeout){ window.clearTimeout(self.toolTipTimeout); }

				self.toolTipTimeout = window.setTimeout(function(){
					self.showToolTipNow($target, message, color, width);
				}, 25);
			},

			showToolTipNow: function($target, message, color, width){

				var self = this;

				self.toolTip = new ToolTipView({
					$container: $target,
					model: {text: message},
					color: color,
					width: width,
					autoShow: true,
				});
			},

			removeToolTip: function(){

				var self = this;

				if(self.toolTip){
					self.toolTip.exit();
					self.toolTip = null;
				}
			},

			// clndr event object --------------------------------------------------------------  /

			clearDates: function(){

				var self = this;
				self.resetEvents(true);	

				var $eventDays = self.$singleCalendarContainer.find('.day.event');

				_.each($eventDays, function(eventDay){
					self.animateDayOut($(eventDay));
				});
				
				self.trigger('clearDates', {target:self});

				TweenMax.delayedCall(0.2, function(){

					var curMonth = parseInt(moment().format('MM'));
					var curYear = parseInt(moment().format('YYYY'));

					self.singleCalendar.setEvents(self.multidayArray);
					self.singleCalendar.setYear(curYear);
					self.singleCalendar.setMonth(curMonth-1);
					
					self.tweakCalendar();
				});
			},

			resetEvents: function(clear){

				var self = this;
				var firstRange = self.isOwnerListing ? self.getSelectedOwnersBlock() : self.getFirstAvailableDate();
				var eventStartDate = firstRange.startDate;
				var eventEndDate = firstRange.endDate;

				if(clear !== true && self.selectedDates){
					eventStartDate = moment(self.selectedDates.checkInDate);
					eventEndDate = moment(self.selectedDates.checkOutDate);
				}

				self.multidayArray = [
					{ 
						startDate: eventStartDate, 
						endDate: eventEndDate, 
						title: 'Your Itinerary' 
					},
				];

				//console.log('resetEvents', eventStartDate.format('MM-DD-YYYY'), eventEndDate.format('MM-DD-YYYY'));
				//console.log('(cont)', self.isOwnerListing, firstRange);

				self.isDateSelected = true;
			},

			getSingleEvent: function(){
				
				var self = this;
				return [{date:self.multidayArray[0].startDate, title:'Check-In Date'}];
			},

			setEventClasses: function($days, maxNights){

				var self = this;
				maxNights = maxNights || $days.length;

				var eventStart = moment(self.multidayArray[0].startDate);
				var eventEnd = moment(self.multidayArray[0].endDate);
				var eventDayCount = 0;

				for(var i=0; i<$days.length; i++){

					var $day = $($days[i]);
					var dayClasses = $day.attr('class');
					var classIndex = dayClasses.indexOf('calendar-day-');
					var dateClass = dayClasses.slice(classIndex, dayClasses.indexOf(' ', classIndex));
					var dayDate = moment(dateClass.split('calendar-day-')[1]);

					if(dayDate.format('x') >= eventStart.format('x') && dayDate.format('x') <= eventEnd.format('x') && eventDayCount < maxNights){
						$day.removeClass('event').addClass('event');
					 	eventDayCount++; 
					}
					else {
						$day.removeClass('event');
					}
				}
			},

			//

			animateDayIn: function($day, i){
				
				var self = this;
				i = i || 0;

				var $eventBg = $day.find('figure.bg');
				var $eventText = $day.find('figcaption');

				TweenMax.set($eventBg, {transformOrigin:'50% 100%', opacity:1});
				TweenMax.to($eventText, 0.2, {color:'#fff', delay:0.002 * i, ease:Sine.easeInOut});

				$day.removeClass('animated').addClass('animated');
				$eventBg.removeClass('hidden');
			},

			animateDayOut: function($day, i){

				var self = this;

				var $eventBg = $day.find('figure.bg');
				var $lastBg = $day.find('figure.last-bg');
				var $eventText = $day.find('figcaption');

				$day.removeClass('event-out').addClass('event-out');
				$day.removeClass('animated');

				TweenMax.to($lastBg, 0.2, {opacity:0, ease:Cubic.easeOut});

				TweenMax.set($eventBg, {transformOrigin:'50% 100%', opacity:0});
				TweenMax.to($eventBg, 0.3, {ease:Cubic.easeInOut, onComplete:function(){
					$day.removeClass('event-out');
					$eventBg.removeClass('hidden').addClass('hidden');
				}});

				TweenMax.to($eventText, 0.1, {color:'#808081', ease:Cubic.easeOut});
			},

			tweakCalendar: function(){

				var self = this;

				//console.log('>>> tweakCalendar');

				if(self.singleCalendar){

					self.singleMonth = self.singleCalendar.month.format('MM');

					//

					var $daysContainer = self.$singleCalendarContainer.find('.days');
					var $days = $daysContainer.find('.day');

					for(var i=0; i<$days.length; i++){

						if(self.availabilityData){

							$day.attr('data-price', self.availabilityData.defaultPrice);
							
							// remove old listeners

							$day.off('mouseenter', $.proxy(self._onDayOver, self));
							$day.off('mouseleave', $.proxy(self._onDayOut, self));

							// add new listeners

							$day.on('mouseenter', $.proxy(self._onDayOver, self));
							$day.on('mouseleave', $.proxy(self._onDayOut, self));
						}
					}

					if(!self.isReadOnly){ self.setEventClasses($days); }

					// mark availability

					if(self.availabilityData){

						// occupied dates

						if(self.availabilityData.myBlockDates){

							for(var i=0; i<self.availabilityData.myBlockDates.length; i++){

								var dateRange = self.availabilityData.myBlockDates[i];

								self.traverseDateRange(dateRange, function(date, formattedDate){

									var $occupiedDay = $daysContainer.find('.calendar-day-' + formattedDate);
									$occupiedDay.removeClass('blocked').addClass('blocked');
									$occupiedDay.removeClass('disabled').addClass('disabled');
								});
							}
						}

						if(self.availabilityData.bookedOccupiedDates){

							for(var i=0; i<self.availabilityData.bookedOccupiedDates.length; i++){

								var dateRange = self.availabilityData.bookedOccupiedDates[i];

								self.traverseDateRange(dateRange, function(date, formattedDate){

									var $occupiedDay = $daysContainer.find('.calendar-day-' + formattedDate);
									$occupiedDay.removeClass('occupied').addClass('occupied');
									$occupiedDay.removeClass('disabled').addClass('disabled');
								});
							}
						}

						if(self.availabilityData.holdOccupiedDates){

							for(var i=0; i<self.availabilityData.holdOccupiedDates.length; i++){

								var dateRange = self.availabilityData.holdOccupiedDates[i];

								self.traverseDateRange(dateRange, function(date, formattedDate){

									var $occupiedDay = $daysContainer.find('.calendar-day-' + formattedDate);
									$occupiedDay.removeClass('on-hold').addClass('on-hold');
									$occupiedDay.removeClass('disabled').addClass('disabled');
								});
							}
						}

						if(self.availabilityData.ownerOccupiedDates){
						
							for(var i=0; i<self.availabilityData.ownerOccupiedDates.length; i++){

								if(!self.isOwnerListing || (
									self.isOwnerListing && !self.isEditBlock && i !== self.selectedBlockIndex)){

									var dateRange = self.availabilityData.ownerOccupiedDates[i];

									self.traverseDateRange(dateRange, function(date, formattedDate){

										var $occupiedDay = $daysContainer.find('.calendar-day-' + formattedDate);
										$occupiedDay.removeClass('owner').addClass('owner');
										$occupiedDay.removeClass('disabled').addClass('disabled');
									});
								}
							}
						}

						// price ranges

						if(self.availabilityData.dateRangePrices){

							for(var i=0; i<self.availabilityData.dateRangePrices.length; i++){

								var dateRange = self.availabilityData.dateRangePrices[i];

								self.traverseDateRange(dateRange, function(date, formattedDate, dateRange){

									if(date.format('MM') === self.singleMonth){
										var $day = $daysContainer.find('.calendar-day-' + formattedDate);
										$day.attr('data-price', dateRange.price);
										self.minNights = dateRange.minNightsStay;
									}

								});
							}
						}
					}

					//

					self.animateCalendarEvents();

					// update click to open buttons

					var calendarDate = moment(self.multidayArray[0].startDate).format('MMM Do, YYYY');
					var calendarDateShort = moment(self.multidayArray[0].startDate).format('DD/MM/YYYY');
					var calendarDateParam = moment(self.multidayArray[0].startDate).format('MM/DD/YYYY');

					if(self.isShortDate){
						calendarDate = calendarDateShort;
					}

					if(self.isDateSelected){

						// get costs based on days
						
						var priceBreakdown = {};

						priceBreakdown.ranges = [];
						priceBreakdown.totalNights = 0;
						priceBreakdown.totalPrice = 0;

						if(self.availabilityData){

							var rangePrice = null;
							var rangeStartDate = null;
							var currentRange = null;

							self.traverseDateRange(self.multidayArray[0], function(eventDate, formattedDate, eventRange){

								var eventDateTime = eventDate.format('x');
								var matchedRange = null;

								if(self.availabilityData.dateRangePrices){

									for(var i=0; i<self.availabilityData.dateRangePrices.length; i++){

										var dateRange = self.availabilityData.dateRangePrices[i];
										var rangeStartTime = moment(dateRange.startDate).format('x');
										var rangeEndTime = moment(dateRange.endDate).format('x');

										if(eventDateTime >= rangeStartTime && eventDateTime <= rangeEndTime){
											matchedRange = dateRange;
											rangePrice = dateRange.price;
											break;
										}
									}

									self.hasDayPrices = true;
								}

								if(!matchedRange){ rangePrice = self.availabilityData.defaultPrice; }

								if(!currentRange || rangePrice != currentRange.price){

									if(currentRange){ 									
										
										currentRange.endDate = eventDate; 
										currentRange.nights = parseInt((currentRange.endDate.format('x') - currentRange.startDate.format('x'))/86400000);
										currentRange.totalPrice = currentRange.nights * currentRange.price;

										priceBreakdown.totalNights += currentRange.nights;
										priceBreakdown.totalPrice += currentRange.totalPrice;
									}

									currentRange = { 
										startDate: eventDate,
										price: rangePrice
									};

									priceBreakdown.ranges.push(currentRange);
								}

								if(eventDate.format('x') === eventRange.endDate.format('x')){

									currentRange.endDate = eventRange.endDate;
									currentRange.nights = parseInt((currentRange.endDate.format('x') - currentRange.startDate.format('x'))/86400000);
									currentRange.totalPrice = currentRange.nights * currentRange.price;

									priceBreakdown.totalNights += currentRange.nights;
									priceBreakdown.totalPrice += currentRange.totalPrice;
								}
							});
		
							var lastPos = priceBreakdown.ranges.length-1;
							if(!priceBreakdown.ranges[lastPos].length){
								priceBreakdown.ranges.splice(lastPos);
							}
						}

						//

						self.serverQuery = 'calendarDate=' + calendarDate;
						self.queryObj = {

							target: self, 
							query: self.serverQuery, 
							calendarQuery: calendarDateParam,
							calendarQueryLong: calendarDate,
							results: calendarDate, 
							date: calendarDateShort,
							resultsObj: {
								calendarDate: moment(self.multidayArray[0].startDate),
								priceBreakdown: priceBreakdown,
							}
						};

						self.trigger('optionSelected', self.queryObj);	
						window.$vent.trigger('calendarQuery', self.queryObj);			
					}

					self.isMonthChange = false;
				}
			},

			animateCalendarEvents: function(){

				var self = this;

				if(!self.isReadOnly){

					// animate calendar

					var $removedDays = self.$singleCalendarContainer.find('.day.animated').not('.event');

					_.each($removedDays, function(eventDay, i){
						self.animateDayOut($(eventDay), i);
					});

					var $eventDays = self.$singleCalendarContainer.find('.day.event').not('.animated');

					_.each($eventDays, function(eventDay, i){
						self.animateDayIn($(eventDay), i);				
					});
				
					// mark last day in calendar

					if(self.multidayArray){

						var $lastDay = self.$singleCalendarContainer.find('.calendar-day-' + moment(self.multidayArray[0].endDate).format('YYYY-MM-DD'));
						var $prevLastDay = self.$singleCalendarContainer.find('.last');

						if($prevLastDay[0] != $lastDay[0]){

							$prevLastDay.removeClass('last');
							TweenMax.to($prevLastDay.find('.last-bg'), 0.3, {opacity:0, ease:Cubic.easeOut});

							if($lastDay.length){

								$lastDay.removeClass('last').addClass('last');

								var d = 0.01 * $eventDays.length;

								TweenMax.set($lastDay.find('.last-bg'), {transformOrigin:'50% 100%'});
								TweenMax.fromTo($lastDay.find('.last-bg'), 0.3, {opacity:0}, {opacity:1, delay:d, ease:Cubic.easeOut});
								
								if(!self.isMonthChange){
									TweenMax.fromTo($lastDay.find('.last-bg'), 0.3, {scaleY:0}, {scaleY:1, delay:d, ease:Sine.easeIn});
									TweenMax.to($lastDay.find('.last-bg'), 0.3, {scaleY:1, delay:d + 0.1, ease:Quint.easeOut});
								}
							}
						}
					}
				}
			},

			sendQueryObj: function(){

				var self = this;
				window.$vent.trigger('calendarQuery', self.queryObj);				
			},

			traverseDateRange: function(dateRange, callback){

				var self = this;
				var startDate = dateRange['startDate'];
				var endDate = dateRange['endDate'];

				if(_.isString(startDate)){ startDate = moment(self.formatDate(startDate)); }
				if(_.isString(endDate)){ endDate = moment(self.formatDate(endDate)); }

				var startDateTime = startDate.format('x');
				var endDateTime = endDate.format('x');

				var currentDate = moment(startDate);
				var currentDateTime = startDate.format('x');

				while(currentDateTime <= endDateTime){

					callback.apply(null, [moment(currentDate), currentDate.format('YYYY-MM-DD'), dateRange]);
					
					currentDate.add(1, 'day');
					currentDateTime = currentDate.format('x');
				}
			},

			// config availability data --------------------------------------------------------  /

			_onSetAvailabilityData: function(e, obj){

				var self = this;
				self.setAvailabilityData(obj);
			},

			setAvailabilityData: function(json){

				var self = this;
				self.configureAvailabilityData(json);

				//console.log("setAvailabilityData", json);

				if(self.isEditBlock){

					var dateRange = self.availabilityData.ownerOccupiedDates[self.selectedBlockIndex];
					var startDate = moment(dateRange['startDate']);
					var endDate = moment(dateRange['endDate']);

					self.singleCalendar.setMonth(endDate.format('MM') - 1);
					
					self.multidayArray[0].startDate = startDate.format('YYYY-MM-DD');
					self.multidayArray[0].endDate = endDate.format('YYYY-MM-DD');
				}

				self.reRenderCalendar();
			},

			configureAvailabilityData: function(availabilityData){

				var self = this;

				if(availabilityData){

					self.occupiedBlocks = [];
					self.availabilityData = self.formatAvailabilityData(availabilityData);

					if(self.availabilityData.bookedOccupiedDates){ self.occupiedBlocks = self.occupiedBlocks.concat(self.availabilityData.bookedOccupiedDates); }
					if(self.availabilityData.holdOccupiedDates){ self.occupiedBlocks = self.occupiedBlocks.concat(self.availabilityData.holdOccupiedDates); }
					if(self.availabilityData.ownerOccupiedDates){ self.occupiedBlocks = self.occupiedBlocks.concat(self.availabilityData.ownerOccupiedDates); }

					self.occupiedBlocks.sort(
						function(a,b){

							var aStartTime = moment(a.startDate).format('x');
							var aEndTime = moment(a.endDate).format('x');
							var bStartTime = moment(b.startDate).format('x');
							var bEndTime = moment(b.endDate).format('x');

							if(aStartTime > bEndTime){ return 1; }
							if(aEndTime < bStartTime){ return -1; }

							return 0;
						}
					);

					self.minNights = _.isNumber(self.availabilityData.minNightsStay) ? parseInt(self.availabilityData.minNightsStay) : 0;
					self.maxNights = _.isNumber(self.availabilityData.maxNightsStay) ? parseInt(self.availabilityData.maxNightsStay) : 0;
				}
			},

			formatAvailabilityData: function(availabilityData){

				var self = this;

				if(availabilityData){

					_.each(availabilityData, function(obj){

						if(_.isArray(obj)){
							for(var i = 0; i < obj.length; i++){
								var arrayItem = obj[i];
								for(var prop in arrayItem){
									if(prop === 'startDate' || prop === 'endDate'){
										arrayItem[prop] = self.formatDate(arrayItem[prop]);
									}
								}
							}
						}

					});
				}

				return availabilityData;
			},

			formatDate: function(dateString){

				var self = this;

				if(dateString && _.isString(dateString)){

					var split = dateString.split('-');

					for(var i=0; i<split.length; i++){
						if(split[i].length < 2){ 
							split[i] = '0' + split[i];
						}
					}

					return split.join('-');
				}

				return dateString;
			},

			// calculate next occupied date 

			getNextOccupiedDate: function(dateTime, minDate){

				var self = this;

				if(self.availabilityData){

					minDate = minDate || self.endDate;

					minDate = self.getMinRangeTime(self.availabilityData.bookedOccupiedDates, dateTime, minDate);
					minDate = self.getMinRangeTime(self.availabilityData.holdOccupiedDates, dateTime, minDate);
					minDate = self.getMinRangeTime(self.availabilityData.ownerOccupiedDates, dateTime, minDate);

					return minDate;
				}

				return false;
			},

			getMinRangeTime: function(rangeObj, dateTime, minDate){

				var self = this;
				var minDateTime = minDate ? minDate.format('x') : false;

				for(var i=0; i<rangeObj.length; i++){

					var isSelectedBlock = (self.isOwnerListing && rangeObj === self.availabilityData.ownerOccupiedDates && i === self.selectedBlockIndex);

					if(!isSelectedBlock){

						var dateRange = rangeObj[i];
						var startDate = moment(dateRange['startDate']);
						var rangeStartTime = startDate.format('x');

						if(rangeStartTime > dateTime && (rangeStartTime < minDateTime || !minDateTime)){
							minDate = startDate;
							minDateTime = dateTime;
						}
					}				
				}

				return minDate;
			},

			getPrevOccupiedDate: function(dateTime, maxDate){

				var self = this;

				if(self.availabilityData){

					maxDate = maxDate || self.startDate;

					maxDate = self.getMaxRangeTime(self.availabilityData.bookedOccupiedDates, dateTime, maxDate);
					maxDate = self.getMaxRangeTime(self.availabilityData.holdOccupiedDates, dateTime, maxDate);
					maxDate = self.getMaxRangeTime(self.availabilityData.ownerOccupiedDates, dateTime, maxDate);

					return maxDate;
				}

				return false;
			},

			getMaxRangeTime: function(rangeObj, dateTime, maxDate){

				var self = this;
				var maxDateTime = maxDate ? maxDate.format('x') : false;

				for(var i=0; i<rangeObj.length; i++){

					var isSelectedBlock = (self.isOwnerListing && rangeObj === self.availabilityData.ownerOccupiedDates && i === self.selectedBlockIndex);

					if(!isSelectedBlock){

						var dateRange = rangeObj[i];
						var endDate = moment(dateRange['endDate']);
						var rangeEndTime = endDate.format('x');

						if(rangeEndTime < dateTime && (rangeEndTime > maxDateTime || !maxDateTime)){
							maxDate = endDate;
							maxDateTime = dateTime;
						}	
					}			
				}

				return maxDate;
			},

			isAvailableDate: function(dateTime){

				var self = this;

				var isInBooked = self.isWithinDateRange(self.availabilityData.bookedOccupiedDates, dateTime);
				var isInHold = self.isWithinDateRange(self.availabilityData.holdOccupiedDates, dateTime);
				var isInOwner = self.isWithinDateRange(self.availabilityData.ownerOccupiedDates, dateTime);

				return !(isInBooked || isInHold || isInOwner);
			},

			isWithinDateRange: function(rangeObj, dateTime){
				
				var self = this;

				for(var i=0; i<rangeObj.length; i++){

					var dateRange = rangeObj[i];
					var startDateTime = moment(dateRange['startDate']).format('x');
					var endDateTime = moment(dateRange['endDate']).format('x');
					
					if(dateTime >= startDateTime && dateTime <= endDateTime){
						return true;
					}
				}

				return false;
			},

			getSelectedOwnersBlock: function(){

				var self = this;			
				var blockIndex = self.selectedBlockIndex || 0;

				var selectedOwnersBlock = self.availabilityData.ownerOccupiedDates[blockIndex];
				var startDate = moment(selectedOwnersBlock.startDate);
				var endDate = moment(selectedOwnersBlock.endDate);

				return {startDate:startDate, endDate:endDate};
			},

			getFirstAvailableDate: function(){

				var self = this;
				var startDate = moment(moment().format('YYYY-MM-DD')).add(1, 'days'); // round day down

				for(var i=0; i<self.occupiedBlocks.length; i++){

					var occupiedBlock = self.occupiedBlocks[i];
					var startTime = startDate.format('x');
					var occupiedStartTime = moment(occupiedBlock.startDate).format('x');
					var occupiedEndTime = moment(occupiedBlock.endDate).format('x');
					
					if(occupiedStartTime >= startTime){

						if((occupiedStartTime - startTime) / 86400000 > self.minNights){
							endDate = moment(occupiedBlock.startDate);
							break;
						}
						else {
							startDate = moment(occupiedBlock.endDate);
						}
					}
					else if(startTime <= occupiedEndTime){
						startDate = moment(occupiedBlock.endDate).add(1, 'days');
					}
				}

				return {startDate:startDate, endDate:moment(startDate).add(self.minNights, 'days')};
			},

			// day even listeners

			_onDayOver: function(e){
				
				var self = this;
				var $target = $(e.currentTarget);

				if(!$target.hasClass('disabled') && !$target.hasClass('inactive') && self.hasDayPrices){
					self.showToolTip($target, self.availabilityData.currencySymbol + $target.data('price'), 'black', 56);
				}
			},

			_onDayOut: function(e){

				var self = this;
				var $target = $(e.currentTarget);

				self.removeToolTip();
			},

			// initial intro

			onShow: function(){

				var self = this;

				if(self.isFirstShow){
					self.animateCalendarEvents();
					self.isFirstShow = false;
				}

				self.showScrollTop = $(window).scrollTop();

				if(window.innerWidth < 768){
					
					window.requestAnimationFrame(function(){

						self._onWindowResize();
					
						window.$vent.trigger('lockScrollZone', {$target:self.$el});
						self.trigger('showComplete');
					});
				}
				else {

					TweenMax.fromTo(self.$el, 0.3, {opacity:0, y:4}, {opacity:1, y:0, ease:Cubic.easeOut, onComplete:function(){
						self.trigger('showComplete');
					}});
				}

				self._onWindowResize();
			},

			onHide: function(){

				var self = this;

				if(window.innerWidth < 768){
					
					window.$vent.trigger('restoreScrolling', {$target:self.$el, hideMobileWrapper:self.options.hideMobileWrapper});
					self.trigger('hideComplete');
				}
				else {

					TweenMax.to(self.$el, 0.3, {opacity:0, y:0, ease:Cubic.easeOut, onComplete:function(){
						self.trigger('hideComplete');
					}});
				}
			},

			// window resizing

			_onWindowResize: function(e){

				var self = this;
				self.winWidth = window.innerWidth;
				self.winHeight = window.innerHeight;
				
				if(self.winWidth < 768){
					TweenMax.set(self.$el, {'min-height' : Math.max(self.winHeight, self.$el.find('.inner-container').height())});
				}
				else {
					TweenMax.set(self.$el, {clearProps:'min-height'});
				}
			},

			// ---------------------------------------------------------------------------------  /

		});

	return CalendarMenuView;
});
