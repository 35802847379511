define(
	'modules/property-lightbox/app',[
		'backbone',
		'modules/property-lightbox/views/LightboxView',
	], 
	function(Backbone, LightboxView){

		var AppView = Backbone.View.extend({

			// vars

			lightbox: null,
			$veil: null,

			galleryData: null,
			galleryStartIndex: 0,

			isPendingData: true,

			// init

			initialize: function(options){

				var self = this;
				self.$el = self.el = $('#property-lightbox-container');
			},

			// start module --------------------------------------------------------------------  /

			start: function(){

				var self = this;
				self.isPendingData = true;

				$(function(){
					self._onDomReady();
				});

				// 

				window.$vent.trigger('propertyLightboxReady');
			},

			// dom ready

			_onDomReady: function(){

				var self = this;
				self.addListeners();
			},

			// add listeners

			addListeners: function(){

				var self = this;

				window.$vent.on('openImageLightbox', $.proxy(self._onOpenImageLightbox, self));
				window.$vent.on('closeImageLightbox', $.proxy(self._onCloseImageLightbox, self));
				window.$vent.on('openImageLightboxToPhoto', $.proxy(self._onOpenImageLightboxToPhoto, self));
			},

			// listener methods

			_onOpenImageLightbox: function(e, queryURL){

				var self = this;
				self.openLightbox(queryURL, 0);
			},

			_onCloseImageLightbox: function(e, obj){

				var self = this;
			},

			_onOpenImageLightboxToPhoto: function(e, obj){

				var self = this;
				var split = obj.split(',');
				self.openLightbox(split[0], split[1] || 0);
			},

			openLightbox: function(queryURL, startIndex){

				var self = this;
				
				self.$el.removeClass('display-none');

				self.galleryStartIndex = startIndex || 0;
				self.loadPropertyImageData(queryURL);
			},

			// 

			createVeil: function(){

				var self = this;
				self.$veil = $('<figure class="color-block veil"></figure>').appendTo(self.$el);

				TweenMax.fromTo(self.$veil, 0.6, {opacity:0}, {opacity:1, ease:Cubic.easeOut});
			},

			removeVeil: function(){

				var self = this;
				
				if(self.$veil){

					TweenMax.to(self.$veil, 0.4, {opacity:0, ease:Cubic.easeOut, onComplete:function(){
						self.$veil.remove();
						self.$veil = null;
						self.$el.addClass('display-none');
					}});
				}
			},

			createLightbox: function(){

				var self = this;

				if(!self.lightbox){

					self.lightbox = new LightboxView({
						$container: self.$el,
						model: self.galleryData,
						index: self.galleryStartIndex,
						autoShow: true,
					});

					self.lightbox.on('closeModal', self._onCloseModal, self);
				}
			},

			_onCloseModal: function(){
			
				var self = this;
				self.lightbox.off('closeModal', self._onCloseModal, self);
				self.removeLightbox();
			},

			removeLightbox: function(){

				var self = this;

				if(self.lightbox){
					self.lightbox.exit();
					self.lightbox = null;
				}

				self.removeVeil();
			},

			// load property configuration

			loadPropertyImageData: function(queryURL){

				var self = this;
				
				self.isPendingData = true;

				$.ajax({

					type: 'GET',
					url: queryURL,
					async: true,
					jsonpCallback: 'callBack',
					contentType: 'application/json',
					dataType: 'jsonp',

					success: function(json) {
						window.$vent.trigger('hideSpinner');
						self.createVeil();
						self._onImageDataLoadComplete(json.results);
					},
					error: function(e) {
						window.$vent.trigger('hideSpinner');
						console.log('JSON Load Error', self);
						console.log(e.message);
						self._onImageLoadError(e);
					}
				});
			},

			_onImageDataLoadComplete: function(json){

				var self = this;
				self.galleryData = json;
				self.isPendingData = false;
				self.createLightbox();
			},

			_onImageLoadError: function(e){

				var self = this;
			},

			// ---------------------------------------------------------------------------------  /

		});

		return AppView;
	}
);
