
define(
	'modules/global-scripted-buttons/views/AVReqItemButtonView',[
		'backbone',
		'utils/AbstractView',
		'utils/AbstractButtonView',
	], 
	function(Backbone, AbstractView, AbstractButtonView){

		var ButtonTemplateView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			options: null,
			abstractView: null,
			abstractButtonView: null,

			// vars

			// init

			initialize: function(options){

				var self = this;

				// options vars

				self.options = options;
				self.$container = self.options.$container;

				// template config

				//self.template = _.template(Template);
				self.model = $.extend({}, self.model);

				// initialize AbstractView and AbstractButtonView

				self.abstractView = new AbstractView({
					view: self,
					autoShow: self.options.autoShow !== false,
				});

				self.abstractButtonView = new AbstractButtonView({
					abstractView: self.abstractView,
				});
			},

			// render

			onRender: function(){

				var self = this;
			},

			onAddListeners: function(){
				
				var self = this;
				console.log("add listeners");
				self.$el.find('.avr-close').on('click', $.proxy(self._onXClick, self));
			},

			removeQuery: function(){

				var self = this;

				$.ajax({

					type: 'GET',
					url: self.$el.find('.avr-close').data('remove-query'),
					async: false,
					jsonpCallback: 'callBack',
					contentType: 'application/json',
					dataType: 'jsonp',

					success: function(json) {
						self.exit();
					},
					error: function(e) {
						console.log('JSON Load Error', self);
						console.log(e.message);
						self.exit();
					}
				});
			},

			_onXClick: function(e){

				var self = this;
				self.hide();
				self.removeQuery();
			}

			// ---------------------------------------------------------------------------------  /

		});

	return ButtonTemplateView;
});
