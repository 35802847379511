
define(
	'modules/detail-carousel/views/CarouselItemView',[
		'backbone',
		'utils/AbstractView',
		'text!../../../assets/svg/quote.svg',
	], 
	function(Backbone, AbstractView, QuoteSVG){

		var CarouselItemView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			options: null,
			abstractView: null,

			//

			$image: null,
			imageRatio: null,

			// init

			initialize: function(options){

				var self = this;

				self.options = options;
				self.$container = self.options.$container;
				self.el = self.options.el;

				// 

				self.abstractView = new AbstractView({
					view: self,
					autoShow: false
				});
			},

			// 1374 / 484;

			onRender: function(){

				var self = this;

				self.$image = self.$el.find('img.carousel-image');
				self.$image.attr('src', self.model.imageURL);
			},

		});

	return CarouselItemView;
});
