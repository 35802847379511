
define(
	'modules/global-scripted-buttons/views/FavoriteButtonView',[
		'backbone',
		'utils/AbstractView',
		'utils/AbstractButtonView',
	], 
	function(Backbone, AbstractView, AbstractButtonView){

		var FavoriteButtonView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			options: null,
			abstractView: null,
			button: null,

			// vars

			isWaitingForDeselect: false,

			// init

			initialize: function(options){

				var self = this;

				self.options = options;
				self.$container = self.options.$container;
				self.el = self.options.el;

				// 

				self.abstractView = new AbstractView({
					view: self,
					autoShow: true
				});

				if ($("#userIsLogged").length > 0) {
					self.button = new AbstractButtonView({
						abstractView: self.abstractView,
						animateInteractionsWhenSelected: true,
						selectable: true,
					});
				} else {
					self.button = new AbstractButtonView({
						abstractView: self.abstractView,
						animateInteractionsWhenSelected: false,
						selectable: false,
					});
				}

				if($('.not-favorite').hasClass( "hidden" )) {
					self.isWaitingForDeselect = true;
					self.button.isSelected = true;
					//$('#isFav').text('true');
				} else {
					self.isWaitingForDeselect = false;
					self.button.isSelected = false;
					//$('#isFav').text('false');
				}
			},

			// render

			onRender: function(){

				var self = this;
			},

			//

			onShow: function(){

				var self = this;
				if(self.$el.hasClass('selected')){
					self.select();
				}

				self.trigger('showComplete');
			},

			//

			onAnimateOver: function(e){

				var self = this;

				if(!self.button.isSelected){
					TweenMax.killTweensOf(self.$el);
					TweenMax.to(self.$el, 0.4, {opacity:1, ease:Cubic.easeOut});
				}
			},

			onAnimateOut: function(e){

				var self = this;

				if(!self.button.isSelected && !self.isWaitingForDeselect){
					TweenMax.killTweensOf(self.$el);
					TweenMax.to(self.$el, 0.4, {opacity:0.25, ease:Cubic.easeOut});
				}
			},

			onAnimateSelect: function(e){

				var self = this;
				var $notFavButton = self.$el.find('.not-favorite');	
				var $notFavText = self.$el.find('.not-favorite .button-content');
				var $favButton = self.$el.find('.favorite');	
				var $favButtonText = $favButton.find('.button-content');	
				var $heart = $favButton.find('.icon');		
				
				TweenMax.killTweensOf(self.$el);
				TweenMax.to(self.$el, 0.4, {opacity:1, ease:Cubic.easeOut});

				TweenMax.to($notFavButton, 0.3, {opacity:0, ease:Cubic.easeInOut});
				TweenMax.to($notFavText, 0.4, {y:12, opacity:0, ease:Cubic.easeIn});
				TweenMax.fromTo($favButton, 0.22, {opacity:1, y:self.$el.outerHeight()}, {y:0, ease:Quint.easeOut, delay:0.12, onComplete:function(){
					$notFavButton.removeClass('hidden').addClass('hidden');
				}});
				TweenMax.fromTo($favButtonText, 0.4, {opacity:0, y:6}, {opacity:1, y:0, delay:0.24, ease:Cubic.easeOut});
				TweenMax.fromTo($heart, 0.3, {scale:0}, {scale:1, delay:0.24, ease:Back.easeOut});

				$favButton.removeClass('hidden');
			},

			onAnimateDeselect: function(e){

				var self = this;

				var $notFavButton = self.$el.find('.not-favorite');	
				var $notFavText = self.$el.find('.not-favorite .button-content');
				var $favButton = self.$el.find('.favorite');	
				var $favButtonText = $favButton.find('.button-content');

				self.isWaitingForDeselect = true;

				TweenMax.to($favButton, 0.3, {opacity:0, ease:Cubic.easeOut, onComplete:function(){
					$favButton.removeClass('hidden').addClass('hidden');
				}});

				TweenMax.fromTo($notFavText, 0.3, {opacity:0, y:12}, {opacity:1, y:0, delay:0.32, ease:Quint.easeOut});
				TweenMax.fromTo($notFavButton, 0.8, {opacity:0.1}, {opacity:1, delay:0.12, ease:Cubic.easeInOut, onComplete:function(){
					self.isWaitingForDeselect = false;
					if(!self.button.isOver){ self.onAnimateOut(); }
				}});

				$notFavButton.removeClass('hidden');
			},

			// ---------------------------------------------------------------------------------  /

		});

	return FavoriteButtonView;
});
