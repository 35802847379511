
define(
	'modules/property-lightbox/views/LightboxImageView',[
		'backbone',
		'utils/AbstractView',
	], 
	function(Backbone, AbstractView){

		var LightboxImageView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			options: null,
			abstractView: null,

			// vars

			$caption: null,

			// init

			initialize: function(options){

				var self = this;

				self.options = options;
				self.$container = self.options.$container;
				self.el = self.options.el;

				//self.template = _.template(Template);
				self.model = $.extend({

				}, self.model);

				// 

				self.$caption = self.options.$caption;

				// 

				self.abstractView = new AbstractView({
					view: self,
					autoShow: self.options.autoShow === true,
				});
			},

		});

	return LightboxImageView;
});
