define(
	'modules/accordian/app',[
		'backbone',
		'modules/accordian/views/AccordianView',
	], 
	function(Backbone, AccordianView){

		var AppView = Backbone.View.extend({

			// core vars

			$accordians: null,
			accordians: null,

			// init

			initialize: function(options){

				var self = this;
			},

			// start module --------------------------------------------------------------------  /

			start: function(){

				var self = this;
				self.accordians = [];
				
				window.$vent.on('domUpdate', $.proxy(self._onDomUpdate, self));
				$(window).on('resize', $.proxy(self._onWindowResize, self));
				
				$(function(){
					self.onDomReady();
				});
				
				window.$vent.trigger('accordianReady');
			},

			onDomReady: function(){

				var self = this;
				self.refreshModules();
			},

			_onDomUpdate: function(){

				var self = this;
				self.refreshModules();
			},

			_onWindowResize: function(){

				var self = this;
				self.winWidth = window.innerWidth;
				self.refreshModules();
			},

			refreshModules: function(){

				var self = this;
				
				self.$accordians = $('.accordian');		

				_.each(self.$accordians, function(accordian, i){
					
					var $accordian = $(accordian);

					if(!self.checkExisting(accordian, 'AccordianView')){

						self.accordians.push({
							module: new AccordianView({el:$accordian}),
							type: 'AccordianView',
							el: $accordian
						});
					}
				});	
			},

			// check for existing modules ------------------------------------------------------  /

			checkExisting: function(el, type){

				var self = this;
				var exists = false;

				if(typeof(el.context) !== 'undefined'){ el = el[0]; }

				_.each(self.accordians, function(moduleObj, i){

					var moduleEl = moduleObj.el;

					if(typeof(moduleEl.context) !== 'undefined'){ moduleEl = moduleEl[0]; }

					if(moduleEl === el && type === moduleObj.type){
						exists = true;
					}
				});

				return exists;
			},
			
			// ---------------------------------------------------------------------------------  /

		});

		return AppView;
	}
);
