define(
	'modules/payment-buttons/app',[
		'backbone',
		'modules/payment-buttons/views/PaymentButtonView',
	], 
	function(Backbone, PaymentButtonView){

		var AppView = Backbone.View.extend({

			// core vars

			$buttons: null,
			buttons: null,

			addCardTemplate: null,
			addCardMobileTemplate: null,

			addBankTemplate: null,
			addBankMobileTemplate: null,

			isFormLoading: false,

			// init

			initialize: function(options){

				var self = this;
			},

			// start module --------------------------------------------------------------------  /

			start: function(){

				var self = this;
				
				self.$buttons = $('.payment-button');		
				self.buttons = [];

				_.each(self.$buttons, function(button, i){
					
					var paymentButtonView = new PaymentButtonView({
						el: button
					});

					self.buttons.push(paymentButtonView);
				});	

				self.addCardTemplate = $('#add-credit-template').html();
				self.addCardMobileTemplate = $('#add-credit-mobile-template').html();

				self.addBankTemplate = $('#add-bank-transfer-template').html();
				self.addBankMobileTemplate = $('#add-bank-transfer-mobile-template').html();
				
				self.addFormToButtons();

				window.$vent.trigger('paymentButtonsReady');
			},

			// enable buttons to use form

			addFormToButtons: function(){

				var self = this;

				_.each(self.buttons, function(button, i){
					button.addPaymentForms(self.addCardTemplate, self.addCardMobileTemplate, self.addBankTemplate, self.addBankMobileTemplate);
				});
			},
			
			// ---------------------------------------------------------------------------------  /

		});

		return AppView;
	}
);
