define(
	'modules/city-vertical-gallery/app',[
		'backbone',
		'utils/components/app/ScrollTriggeredElement',
	], 
	function(Backbone){

		var AppView = Backbone.View.extend({

			// vars

			$cityIntros: null,
			cityIntros: null,

			scrollTriggeredElements: [],

			// init

			initialize: function(options){

				var self = this;
			},

			// start module --------------------------------------------------------------------  /

			start: function(){

				var self = this;
				
				self.$cityVerticalGalleries = $('.city-vertical-gallery');

				self.$cityVerticalGalleries.on('mouseenter', $.proxy(self._onMouseEnter, self));
				self.$cityVerticalGalleries.on('mouseleave', $.proxy(self._onMouseLeave, self));

				// 

				console.log('loaded city-vertical-gallery');

				window.$vent.trigger('cityVerticalGalleryReady');
			},

			_onMouseEnter: function(e){

				console.log('enter');

				var self = this;
				var $target = $(e.currentTarget);
				var $hover = $target.find('.vg-caption');				
				var $quote = $hover.find('p');
				var $link = $hover.find('.view-more-link');
				var $button = $hover.find('.btn-more-container');

				TweenMax.killTweensOf($hover);

				TweenMax.fromTo($hover, 0.3, {opacity:0}, {opacity:1, ease:Cubic.easeOut});
				TweenMax.fromTo($quote, 0.4, {opacity:0, y:8}, {opacity:1, y:0, ease:Sine.easeOut});
				TweenMax.fromTo($link, 0.6, {opacity:0, y:8}, {opacity:1, y:0, delay:0.06, ease:Cubic.easeOut});
				TweenMax.fromTo($button, 0.6, {opacity:0, y:8}, {opacity:1, y:0, delay:0.2, ease:Cubic.easeOut});

				$hover.removeClass('hidden');
			},

			_onMouseLeave: function(e){

				var self = this;
				var $target = $(e.currentTarget);
				var $hover = $target.find('.vg-caption');	

				TweenMax.killTweensOf($hover);

				TweenMax.to($hover, 0.3, {opacity:0, ease:Cubic.easeOut, onComplete:function(){
					$hover.removeClass('hidden').addClass('hidden');
				}});
			},

			// ---------------------------------------------------------------------------------  /

		});

		return AppView;
	}
);
