define(
	'modules/reservation-sidebar/app',[
		'backbone',
	], 
	function(Backbone){

		var AppView = Backbone.View.extend({

			// core vars

			// vars

			$el: null,
			$context: null,
			$pageFooter: null,

			docWidth: null,
			winHeight: null,
			docHeight: null,
			sidebarHeight: null,
			footerHeight: null,
			scrollTop: null,

			lastYPos: 0,
			isTicked: false,

			// init

			initialize: function(options){

				var self = this;
				self.$context = $;

				window.$vent.trigger('reservationSidebarReady');
			},

			// start module --------------------------------------------------------------------  /

			start: function(){

				var self = this;
				
				$(function(){
					self.onDomReady();
				});
			},

			onDomReady: function(){

				var self = this;
				self.$el = $('#reservation-sidebar');
				self.$pageFooter = $('#page-footer');

				//

				window.$vent.on('reservationContentResize', $.proxy(self._onWindowResize, self));

				$(window).on('resize', $.proxy(self._onWindowResize, self));
				$(window).on('scroll', $.proxy(self._onScroll, self));
				$(window).mousewheel($.proxy(self._onMouseWheel, self));

				self._onWindowResize();
			},

			// recalculate costs

			recalculate: function(){

				var self = this;
			},

			// scroll --------------------------------------------------------------------------  /

			_onMouseWheel: function(e){

				var self = this;
				self.scrollTick();
			},

			_onScroll: function(){

				var self = this;
				self.scrollTick();
			},

			scrollTick: function(){

				var self = this;

				if(!self.isTicked){
					self.isTicked = true;
					window.requestAnimationFrame(self.scrollNow.bind(self));
				}
			},

			scrollNow: function(){

				var self = this;
				self.scrollTop = $(document).scrollTop();
				
				self.checkPosition();
				self.isTicked = false;
			},

			// check position - should bar be fixed or absolute

			checkPosition: function(){

				var self = this;
				var availableScreenHeight = Math.min(self.winHeight, self.docHeight - self.footerHeight - self.scrollTop);
				var yPos = 0 - self.scrollTop;

				if(availableScreenHeight >= self.sidebarHeight){ 
					yPos = 0; 
				}
				else if(yPos + self.sidebarHeight <= availableScreenHeight){ 
					yPos = availableScreenHeight - self.sidebarHeight;
				}

				if(self.lastYPos != yPos){

					TweenMax.set(self.$el, {
						force3D: true,
						y: yPos
					});

					self.lastYPos = yPos;
				}
			},

			// window resize -------------------------------------------------------------------  /

			_onWindowResize: function(){

				var self = this;
				
				self.docWidth = $(document).width();
				self.winHeight = window.innerHeight;
				self.docHeight = $(document).outerHeight();
				self.sidebarHeight = self.$el.outerHeight();
				self.footerHeight = self.$pageFooter.outerHeight();

				var $stickyNav = $('#sticky-nav');
				if($stickyNav.length){
					TweenMax.set($stickyNav, {width:self.docWidth - self.$el.outerWidth()});
				}

				self.scrollTick();
				self.checkPosition();
			},
			
			// ---------------------------------------------------------------------------------  /

		});

		return AppView;
	}
);
