
define(
	'modules/multi-form/views/MultiFormView',[
		'backbone',
		'utils/AbstractView',
	], 
	function(Backbone, AbstractView){

		var MultiFormView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			options: null,
			abstractView: null,

			// vars

			$formSelect: null,
			$formContentsContainer: null,
			$context: null,

			templateId: null,
			$currentTemplate: null,

			// init

			initialize: function(options){

				var self = this;

				// options vars

				self.options = options;
				self.$container = self.options.$container;
				self.$context = self.options.$context;

				// template config

				//self.template = _.template(Template);
				self.model = $.extend({}, self.model);

				// initialize AbstractView

				self.abstractView = new AbstractView({
					view: self,
					autoShow: true,
				});
			},

			onDomReady: function(){

				var self = this;

				self.$formSelect = window.$rootContext(self.$el.find('select.mf-form-switch'));
				self.$formContentsContainer = self.$el.find('.mf-form-contents-container');

				self.checkFormValue();
			},

			onAddListeners: function(){

				var self = this;				
				self.$formSelect.on('changed', $.proxy(self._onFormSelectChanged, self));
			},

			_onFormSelectChanged: function(e, obj){

				var self = this;
				self.checkFormValue();
			},

			checkFormValue: function(){

				var self = this;
				var selectValue = self.$formSelect.val();

				if(selectValue && selectValue != self.templateId){
					
					self.$formContentsContainer.empty();

					var template = _.template($('#' + selectValue + '-template').html());
					self.$currentTemplate = $(template({}));

					window.$rootContext(self.$currentTemplate.find('select')).selectBoxIt();

					self.$formContentsContainer.append(self.$currentTemplate);
					self.templateId = selectValue;

					window.requestAnimationFrame(function(){
						window.$vent.trigger('domUpdate');
					});
				}
			},

		});

	return MultiFormView;
});
