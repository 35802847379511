
define(
	'pagecontrollers/area-map/views/MapMenuExpandedView',[
		'backbone',
		'utils/AbstractView',
	], 
	function(Backbone, AbstractView){

		var BaseViewTemplate = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			options: null,
			abstractView: null,

			// vars

			$expandedTabPanes: null,

			currentTabIndex: 0,

			// init

			initialize: function(options){

				var self = this;

				self.options = options;
				self.$container = self.options.$container;
				self.el = self.options.el;

				//self.template = _.template(Template);
				self.model = $.extend({

				}, self.model);

				// 

				self.abstractView = new AbstractView({
					view: self,
					autoShow: self.options.autoShow === true,
				});
			},

			//

			onDomReady: function(){
				
				var self = this;
				self.$expandedTabPanes = self.$el.find('.tab-pane');
			},

			// listeners

			onAddListeners: function(){

				var self = this;

				self.$el.find('.close-btn').on('click', $.proxy(self._onCloseClick, self));
				self.$el.find('.veil').on('click', $.proxy(self._onCloseClick, self));
				self.$el.find('a.city-link').on('click', $.proxy(self._onCityClick, self));
				self.$el.find('a.neighborhood-link').on('click', $.proxy(self._onNeighborhoodClick, self));
				self.$el.find('.small-cguide a').on('click', $.proxy(self._onSmallGuideClick, self));
				self.$el.find('#filters-container .rec-category').on('click', $.proxy(self._onFilterClick, self));
			},

			onRemoveListeners: function(){

				var self = this;
				self.$el.find('.close-btn').off('click', $.proxy(self._onCloseClick, self));
				self.$el.find('.veil').off('click', $.proxy(self._onCloseClick, self));
				self.$el.find('a.city-link').off('click', $.proxy(self._onCityClick, self));
				self.$el.find('a.neighborhood-link').off('click', $.proxy(self._onNeighborhoodClick, self));
				self.$el.find('.small-cguide a').off('click', $.proxy(self._onSmallGuideClick, self));
				self.$el.find('#filters-container .rec-category').off('click', $.proxy(self._onFilterClick, self));
			},

			// listener methods

			_onCloseClick: function(e){

				var self = this;
				window.$vent.trigger('closeMenu');
			},

			_onCityClick: function(e){

				var self = this;
				var $target = $(e.currentTarget);

				window.$vent.trigger('changeMapLocation', {
					lat: $target.data('latitude'),
					lng: $target.data('longitude'),
					zoom: $target.data('zoom'),
					queryURL: $target.data('query-url')
				});

				window.$vent.trigger('citySelected', {
					target: self,
					id: $target.data('id'),
					cityTitle: $target.data('city-title'),
					queryURL: $target.data('query-url')
				});

				e.preventDefault();
			},

			_onNeighborhoodClick: function(e){

				var self = this;
				var $target = $(e.currentTarget);

				window.$vent.trigger('changeMapLocation', {
					lat: $target.data('latitude'),
					lng: $target.data('longitude'),
					zoom: $target.data('zoom'),
					queryURL: $target.data('query-url')
				});

				window.$vent.trigger('neighborhoodSelected', {
					target: self,
					id: $target.data('id'),
					title: $target.data('title'),
					cityTitle: $target.data('city-title'),
					queryURL: $target.data('query-url')
				});

				e.preventDefault();
			},

			_onSmallGuideClick: function(e){

				var self = this;
				var $target = $(e.currentTarget);

				var lat = $target.data('lat');
				var lng = $target.data('lng');
				var zoom = $target.data('zoom');
				var id = $target.data('id');
				var title = $target.data('neighborhood-title');
				var cityTitle = $target.data('city-title');
				var queryURL = $target.data('query-url');

				window.$vent.trigger('changeMapLocation', {
					lat: lat,
					lng: lng,
					zoom: zoom,
					queryURL: queryURL
				});

				if(title){

					window.$vent.trigger('neighborhoodSelected', {
						target: self,
						id: id,
						title: title,
						cityTitle: cityTitle,
						queryURL: queryURL
					});
				}
				else {

					window.$vent.trigger('citySelected', {
						target: self,
						id: id,
						cityTitle: cityTitle,
						queryURL: queryURL
					});
				}

				e.preventDefault();
			},

			_onFilterClick: function(e){

				var self = this;
				var $target = $(e.currentTarget);
				var title = $target.data('title');
				$('.tm-recommendation').find('span').html(title);								
				
				window.$vent.trigger('typeFilterQuery', {queryURL: $target.data('query-url'), typeTitle: title});
			},

			// 

			changeTab: function(tabIndex){

				var self = this;
				var wasClosed = !self.abstractView.isShowing;

				if(!self.abstractView.isShowing){ self.show(); }

				if(tabIndex != self.currentTabIndex || wasClosed){
					
					var lastTabIndex = self.currentTabIndex;
					self.currentTabIndex = tabIndex;

					// hide last tab

					if(lastTabIndex != self.currentTabIndex){
						var $lastTabContent = $(self.$expandedTabPanes[lastTabIndex]);
						$lastTabContent.removeClass('display-none').addClass('display-none');
					}

					// show new tab

					var $tabContent = $(self.$expandedTabPanes[tabIndex]);
					$tabContent.removeClass('display-none');

					// show menu

					self.$el.removeClass('hidden');
				}

				window.$vent.trigger('seekToTop');
			},

			//

			shouldClose: function(tabIndex){

				var self = this;
				
				if(self.abstractView.isShowing && self.currentTabIndex === tabIndex){
					return true;
				}

				return false;
			},

			// 

			onShow: function(){

				var self = this;
				self.trigger('showComplete');
			},

			onHide: function(){

				var self = this;

				var $tabContent = $(self.$expandedTabPanes[self.currentTabIndex]);
				$tabContent.removeClass('display-none').addClass('display-none');

				self.$el.removeClass('hidden').addClass('hidden');

				self.trigger('hideComplete');
			},

		});

	return BaseViewTemplate;
});
