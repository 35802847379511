define(
	'modules/blog-header-carousel/views/CarouselView',[
		'backbone',
		'utils/AbstractView',

		'modules/blog-header-carousel/views/CarouselItemView',
		'modules/blog-header-carousel/views/RadioButtonView',
	], 
	function(Backbone, AbstractView, CarouselItemView, RadioButtonView){

		var CarouselView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			options: null,
			abstractView: null,

			// vars

			$radioButtonsContainer: null,
			$carouselImagesContainer: null,

			carouselImages: null,
			radioButtons: null,

			winWidth: null,
			winHeight: null,
			containerWidth: null,
			containerHeight: null,

			currentIndex: -1,
			currentCarouselImage: null,
			$currentCarouselImage: null,

			$textContent: null,
			$textBg: null,

			imageRatio: null,
			totalItems: null,

			// init

			initialize: function(options){

				var self = this;

				self.options = options;
				self.$container = self.options.$container;
				self.el = self.options.el;

				// ready abstractView connection 

				self.abstractView = new AbstractView({
					view: self,
					autoShow: false
				});

				//

				var imageWidth = self.$container.data('image-width') || 1420;
				var imageHeight = self.$container.data('image-height') || 500;

				self.imageRatio = imageWidth/imageHeight;

				//

				self.show();
			},

			onRender: function(){

				var self = this;

				self.carouselImages = [];
				self.radioButtons = [];

				self.$carouselImagesContainer = self.$el.find('.carousel-images-container');
				self.$carouselDescriptionsContainer = self.$el.find('.blog-description-wrapper');
				self.$radioButtonsContainer = self.$el.find('.radio-buttons-container');

				self.$carouselImages = self.$carouselImagesContainer.find('li');
				self.$carouselDescriptions = self.$carouselDescriptionsContainer.find('li');
				self.$radioButtons = self.$radioButtonsContainer.find('li');

				self.totalItems = self.$carouselImages.length;

				for(var i=0; i<self.totalItems; i++){

					var itemModel = {
						index: i,
					};

					var carouselItem = new CarouselItemView({						
						el: self.$carouselImages[i],
						$description: $(self.$carouselDescriptions[i]),
						model: itemModel,		
						index: i
					});

					TweenMax.set(carouselItem.$el, {force3D:true});

					self.carouselImages.push(carouselItem);

					// radio buttons

					var radioButton = new RadioButtonView({					
						el: self.$radioButtons[i],
						model: itemModel,
						index: i
					});

					self.radioButtons.push(radioButton);
				}
			},

			// 

			onShow: function(){

				var self = this;
				var winWidth = window.innerWidth;

				self.onWindowResize();

				var d = 0;

				if(winWidth >= 768){ d = 0.8; }

				TweenMax.fromTo(self.$el, 0.3, {opacity:0}, {opacity:1, delay:d, ease:Cubic.easeOut, onStart:function(){
					self.seekByIndex(0);
				}});

				self.trigger('showComplete');
			},

			// listeners

			onAddListeners: function(){

				var self = this;

				_.each(self.radioButtons, function(radioButton, i){
					radioButton.on('click', self._onRadioButtonClick, self);
				});

				var $leftArrow = self.$el.find('.arrow-buttons-container .arrow-button.left');
				var $rightArrow = self.$el.find('.arrow-buttons-container .arrow-button.right');

				$leftArrow.on('click', $.proxy(self._onArrowClick, self));
				$rightArrow.on('click', $.proxy(self._onArrowClick, self));
			},

			// listener events

			_onRadioButtonClick: function(e){

				var self = this;
				self.seekByIndex(e.target.index);
			},

			_onArrowClick: function(e){

				var self = this;
				var $target = $(e.currentTarget);

				if($target.hasClass('left')){ self.prevItem(); }
				else { self.nextItem(); }
			},

			// carousel controls

			nextItem: function(){

				var self = this;
				var newIndex = self.currentIndex+1;

				self.seekByIndex(newIndex);
			},

			prevItem: function(){

				var self = this;
				var newIndex = self.currentIndex-1;

				self.seekByIndex(newIndex);
			},

			seekByIndex: function(index){

				var self = this;

				if(self.currentIndex != index){
					self.changeActiveItem(index);
				}
			},

			//

			changeActiveItem: function(newIndex){

				var self = this;
				var lastIndex = self.currentIndex;
				var lastCarouselImage = self.currentCarouselImage;
				var $lastCarouselImage = self.$currentCarouselImage;

				if(newIndex >= self.totalItems){ newIndex = 0; }
				else if(newIndex < 0){ newIndex = self.totalItems-1; }

				var direction = (newIndex > self.currentIndex) ? 1 : -1;

				self.currentIndex = newIndex;
				self.currentCarouselImage = self.carouselImages[newIndex];
				self.$currentCarouselImage = self.carouselImages[newIndex].$el;

				if(lastIndex >= 0){ self.radioButtons[lastIndex].deselect(); }
				self.radioButtons[self.currentIndex].select();

				if(lastCarouselImage){

					var $curEl = self.currentCarouselImage.$el;
					var $lastEl = lastCarouselImage.$el;

					TweenMax.to($lastCarouselImage, 0.4, {x:0-self.containerWidth * direction, force3D:true, ease:Sine.easeIn});
					TweenMax.to($lastCarouselImage, 0.6, {x:0-self.containerWidth * direction, delay:0.2, ease:Cubic.easeOut, onComplete:function(){
						$lastCarouselImage.removeClass('hidden').addClass('hidden');
						lastCarouselImage.hide();
					}});

					lastCarouselImage.hideDescription();

					var d = 0.3;

					TweenMax.fromTo(self.$currentCarouselImage, 0.4, {x:self.containerWidth * direction, force3D:true}, {x:0, ease:Sine.easeIn});
					TweenMax.to(self.$currentCarouselImage, 0.6, {x:0, delay:0.2, ease:Cubic.easeOut});
					
					self.$currentCarouselImage.removeClass('hidden');
					self.currentCarouselImage.show();
				}
				else {
					self.$currentCarouselImage.removeClass('hidden');
					self.currentCarouselImage.show();
				}

				self.onWindowResize();
			},

			// window resizing

			onWindowResize: function(){

				var self = this;

				self.winWidth = window.innerWidth;
				self.winHeight = window.innerHeight;

				self.containerWidth = self.$el.outerWidth();
				self.containerHeight = self.$el.outerHeight();

				var imageHeight = Math.round(self.containerWidth / self.imageRatio);

				TweenMax.set(self.$el, {height: imageHeight + 230});
				TweenMax.set(self.$carouselImagesContainer, {height: imageHeight});
			},
			
			// ---------------------------------------------------------------------------------  /

		});

		return CarouselView;
	}
);
