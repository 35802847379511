define(
	'modules/neighborhood-map/app',[
		'backbone',
		'modules/neighborhood-map/views/MapModalView',
		'modules/neighborhood-map/views/MapMarkerView',

		'text!../../config/map-styles.json',
		
		'utils/utils/ArrayUtils',

	], 
	function(Backbone, MapModalView, MapMarkerView, MapStyles){

		var AppView = Backbone.View.extend({

			// vars

			mapStyleData: null,
			
			map: null,
			markers: null,
			markerViews: null,
			dyingMarkerViews: null,

			mapModal: null,
			selectedMarkerView: null,

			$mapContainer: null,
			$mapCanvas: null,
			$pinsContainer: null,
			$modalContainer: null,
			$autoRefreshCheckbox: null,

			resizeTimeout: null,
			dragTimeout: null,
			initialBoundsListener: null, 

			listingsArray: null,
			clickedMarker: null,
			panTweenObj: null,

			lastRefreshTime: 0,
			lastStartIndex: 0,
			lastEndIndex: 0,

			inBoundsListings: null,
			paginatedListings: null,
			lastInBoundsListings: null,

			isPendingRefresh: true,
			isPendingZoomRestore: false,
			isAutoRefresh: false,
			isRefreshing: false,
			isInitialMapPopulation: true,
			isDraggingMap: false,
			isIgnoreResize: false,
			isResultsUpdate: false,

			// init

			initialize: function(){

				var self = this;

				self.mapStyleData = JSON.parse(MapStyles);
				
				self.$el = $('#neighborhood-map');
				self.$mapCanvas = self.$el.find('#map-canvas');
				self.$pinsContainer = self.$el.find('#map-pins-container');
				self.$modalContainer = self.$el.find('#map-modals-container');

				self.$mapButtons = self.$el.find('.map-buttons');
				self.$zoomInButton = self.$mapButtons.find('.plus');
				self.$zoomOutButton = self.$mapButtons.find('.minus');
				self.$refreshButton = self.$mapButtons.find('.map-refresh');

				self.panTweenObj = {lat:0, lng:0};
				self.inBoundsListings = [];
				self.markers = [];

				self.dyingMarkerViews = [];
			},

			// start

			addListeners: function(){

				var self = this;

				self.$zoomInButton.on('click', function(){
					self.hideMarkers();
					self.closeOpenModal();
					self.isPendingZoomRestore = true;
					self.map.setZoom(self.map.zoom + 1);
				});

				self.$zoomOutButton.on('click', function(){
					self.hideMarkers();
					self.closeOpenModal();
					self.isPendingZoomRestore = true;
					self.map.setZoom(self.map.zoom - 1);
				});

				self.$refreshButton.on('click', function(){

					self.isAutoRefresh = !self.isAutoRefresh;
					
					if(self.isAutoRefresh){
						self.$refreshButton.removeClass('selected').addClass('selected');
						self.refreshListings();
					}
					else {
						self.$refreshButton.removeClass('selected');
					}
				});
			},

			start: function(){

				var self = this;

				// global event listeners ------------------------------------------------------  /

				window.$vent.on('reloadListings', $.proxy(self._onQueryListings, self));
				window.$vent.on('paginateListings', $.proxy(self._onPaginateListings, self));
				window.$vent.on('paginate', $.proxy(self._onPaginate, self));
				window.$vent.on('listingsQueryComplete', $.proxy(self._onListingsQueryComplete, self));
				window.$vent.on('changeMapLocation', $.proxy(self._onChangeMapLocation, self));
				window.$vent.on('rebuildMapListings', $.proxy(self._onRebuildMapListings, self));
				window.$vent.on('mapIgnoreResize', $.proxy(self._onIgnoreResize, self));				
				window.$vent.on('resizeMap', $.proxy(self._onResizeMap, self));

				$(window).on('resize', $.proxy(self._onResize, self));

				// listeners -------------------------------------------------------------------  /

				self.onWindowResize();

				// search for auto refresh checkbox

				self.$autoRefreshCheckbox = $('#map-refresh-toggle');

				if(self.$autoRefreshCheckbox.length){ 
					self.$autoRefreshCheckbox.on('click', $.proxy(self._onRefreshToggleClick, self));
					self.checkRefreshToggleStatus();
				}

				// load maps -- weird fix thanks to https://mattsurabian.github.io/requirejs-projects-and-asynchronously-loading-the-google-maps-api/

				window.google_maps_loaded = function(){ self.initMaps(); };
				//require(['https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyCw0OtSU7EUkXGh2KU9OX-SVYrqJdnW7OE&libraries=places&callback=google_maps_loaded']);
				require([window.__ss_map_api_key]);

				// trigger ready

				$(function(){					
					self.addListeners();
					window.$vent.trigger('neighborhoodMapReady');
				});
			},

			initMaps: function(){

				var self = this;
				var stylesArray = self.mapStyleData.results;
				var latitude = self.$el.data('lat') || 40.4379543;
				var longitude = self.$el.data('lng') || -3.6795366;
				var zoom = self.$el.data('zoom') || 14;
				var zoomLocked = !!self.$el.data('zoom-locked');
				var latlng = new google.maps.LatLng(latitude, longitude);

				var myOptions = {
					styles: stylesArray,
					zoom: zoom,
					center: latlng,
					disableDoubleClickZoom: zoomLocked,
					mapTypeId: google.maps.MapTypeId.ROADMAP,
					disableDefaultUI: true,
					scaleControl: true,
					scrollwheel: false,
				};

				self.map = new google.maps.Map(document.getElementById("map-canvas"), myOptions);

				//

				self.markerViews = [];
				self.markers = [];

				// TEMP - click to get lat/lng -------------------------------------------------  /

				google.maps.event.addListener(self.map, 'click', function(event) {
					displayCoordinates(event.latLng);               
					self.closeOpenModal();
				});

				function displayCoordinates(pnt) {

					var lat = pnt.lat();
					lat = lat.toFixed(4);
					var lng = pnt.lng();
					lng = lng.toFixed(4);
					console.log("Latitude: " + lat + "  lngitude: " + lng);
				}

				// -----------------------------------------------------------------------------  /

				// map listeners

				google.maps.event.addListener(self.map, 'center_changed', function(event) {
					self.updateModalPos();
					self.updateMarkersPos();
				});

				google.maps.event.addListener(self.map, 'dblclick', function(event) {
					self.hideMarkers();
					self.isPendingZoomRestore = true;
				});

				google.maps.event.addListener(self.map, 'zoom_changed', function(event) {
					self.updateModalPos();
					self.updateMarkersPos();
				});

				google.maps.event.addListener(self.map, 'dragstart', function(event) {
					self.isDraggingMap = true;
				});

				google.maps.event.addListener(self.map, 'dragend', function(event) {					
					self.isDraggingMap = false;
				});

				google.maps.event.addListener(self.map, 'drag', function(event) {
					
					if(self.isAutoRefresh){
						self.removeOldMarkers();
					}
				});

				google.maps.event.addListener(self.map, 'idle', function(event) {

					if(self.isPendingZoomRestore){
						self.updateMarkersPos();
						self.showMarkers();
						self.isPendingZoomRestore = false;
					}
					else {

						if(!self.isDraggingMap && (self.isAutoRefresh || self.isPendingRefresh)){ 
							self.refreshListings(true, false); 
						}
					}
				});

				// if data query exists, load map data

				var dataQuery = self.$el.data('query');
				if(dataQuery){ self.loadDataQuery(dataQuery); }

				// get listings now that map is ready

				window.$vent.trigger('requestListings');
				window.$vent.trigger('requestLocation');
			},

			// listener methods ----------------------------------------------------------------  /

			_onResize: function(){

				var self = this;
				self.onWindowResize();
			},

			_onResizeMap: function(){

				var self = this;
				self.onWindowResize();
			},

			_onQueryListings: function(){
				
				var self = this;

				self.closeOpenModal();

				if(self.initialBoundsListener){
					google.maps.event.removeListener(self.initialBoundsListener);
					self.initialBoundsListener = null;
				}
			},

			_onPaginate: function(e, params){

				var self = this;
			},

			_onPaginateListings: function(e, params){

				var self = this;
				var startIndex = params.startIndex;
				var endIndex = params.endIndex;

				self.displayListingsByRange(startIndex, endIndex);
			},

			_onListingsQueryComplete: function(e, params){
				
				var self = this;
				console.log('test:',params);
				self.listingsArray = _.uniq(params);
				self.filterListingsInBounds();				
			},

			_onIgnoreResize: function(e, isActive){

				var self = this;
				self.isIgnoreResize = isActive;
			},

			// load map query independently of page (if data attribute set) --------------------  /

			loadDataQuery: function(query){

				var self = this;

				$.ajax({

					type: 'GET',
					url: query,
					async: false,
					jsonpCallback: 'callBack',
					contentType: 'application/json',
					dataType: 'jsonp',

					success: function(json) {
						self._onListingsQueryComplete(null, json.results);
					},
					error: function(e) {
						console.log('JSON Load Error', self);
						console.log(e.message);
					}
				});
			},

			// update listings ---------------------------------------------------------------------  /

			refreshListings: function(forceCheck, forceRebuild){

				var self = this;	
				var currentTime = (new Date()).getTime();

				if(forceCheck === true || currentTime - self.lastRefreshTime > 333){
					self.lastRefreshTime = currentTime;
					self.filterListingsInBounds(forceRebuild);
					self.isPendingRefresh = false;
				}
			},

			filterListingsInBounds: function(forceRebuild){

				var self = this;
				var winWidth = window.innerWidth;
				
				self.removeAllMarkers();

				if(winWidth >= 992){ 

					if(typeof(self.map) !== 'undefined' && self.map && self.map.getBounds() && self.listingsArray){

						var oldInBoundsListings = self.inBoundsListings.concat();

						// reset 

						self.inBoundsListings = [];
						self.markers = [];

						// 

						for(var i=0; i<self.listingsArray.length; i++){

							var listing = self.listingsArray[i];
							var coords = new google.maps.LatLng(listing.lat, listing.lng);

							if(self.map.getBounds().contains(coords)){

								// google markers (invisible)
								var shape = {
										coords: [4,11, 39,11, 39,60, 39,39, 4,49],
										type: 'poly'
									};
								
								if(listing.pinType == 'marker') { var pinType = '/assets/common/marker-marker.png'; }
								else { var pinType = '/assets/common/marker-hit-area.png'; }
								
								var marker = new google.maps.Marker({
									position: coords,
									map: self.map,
									title: listing.title,
									listingId: listing.id,
									icon: {
										url: pinType,
										anchor: new google.maps.Point(39,50), 
										size: new google.maps.Size(80,60),
										origin: new google.maps.Point(0,0), 
									},
									shape: shape
								});

								self.markers.push(marker);
								self.inBoundsListings.push(listing);
							}
						}
						
						if(self.listingsArray.length == 1){
							self.map.setCenter(coords);
						}
						
						if(!Utils.ArrayUtils.matchingContents(oldInBoundsListings, self.inBoundsListings)){
							self.lastStartIndex = -1;
							self.lastEndIndex = -1;
							self.isResultsUpdate = true;
						}
						else { }

						window.$vent.trigger('mapFilteredListings', [self.inBoundsListings.concat(), self.isDraggingMap]);
					}
				}
				else {

					// no map, just return all results

					self.inBoundsListings = self.listingsArray.concat();
					self.isResultsUpdate = true;

					window.$vent.trigger('mapFilteredListings', [self.listingsArray.concat(), false]);
				}
			},

			displayListingsByRange: function(startIndex, endIndex){

				var self = this;
				var winWidth = window.innerWidth;
				var listings = winWidth >= 992 ? self.inBoundsListings.concat() : self.listingsArray.concat();
				
				if(!listings.length){ 
					window.$vent.trigger('mapPaginationResults', [[], self.isDraggingMap]); 
				}
				else {

					if((!self.markerViews.length && listings.length) || self.isResultsUpdate || startIndex != self.lastStartIndex || endIndex != self.lastEndIndex){

						endIndex = Math.min(endIndex, self.inBoundsListings.length);

						var visibleMarkers = [];
						var d = 0.6; //self.markerViews.length ? 0.6 : 0;

						self.lastStartIndex = startIndex;
						self.lastEndIndex = endIndex;

						self.displayListings(listings.slice(startIndex, endIndex));
					}
					else {

						window.$vent.trigger('mapResultsUnchanged', [listings, self.isDraggingMap]);
					}
				}
			},

			displayListings: function(listings, silent){

				var self = this;
				var winWidth = window.innerWidth;
				
				if(Utils.ArrayUtils.matchingContents(listings, self.paginatedListings)){

				}
				else {

					if(winWidth >= 992){

						if(self.markers && self.markers.length){

							var visibleMarkers = [];
							var d = 0.6; //self.markerViews.length ? 0.6 : 0;

							self.paginatedListings = [];
							self.removeOldMarkers();

							for(var i=0; i<listings.length; i++){

								var listing = listings[i];

								var marker = _.findWhere(self.markers, {listingId:listing.id});
								marker.setMap(self.map);

								self.paginatedListings.push(listing);
								visibleMarkers.push(marker);

								// make marker clickable

								google.maps.event.addListener(marker, 'click', function(){
									
									var marker = this;
									self.openInfoModal(marker);

									window.$vent.trigger('markerSelect', [marker, marker.listingId]);
								});

								// marker views (visible part)

								var markerImageURL;

								switch(listing.pinType){

									case 'marker':
									markerImageURL = '/assets/common/marker-marker.png';
									break;

									case 'food':
									markerImageURL = 'modules/assets/images/marker-food.png';
									break;

									case 'culture':
									markerImageURL = 'modules/assets/images/marker-culture.png';
									break;

									case 'shopping':
									markerImageURL = 'modules/assets/images/marker-shopping.png';
									break;

									case 'entertainment':
									markerImageURL = 'modules/assets/images/marker-entertainment.png';
									break;

									default: 
									markerImageURL = '/assets/common/marker-marker.png';
									break;
								};

								var markerModel = {
									id: listing.id,
									markerImageURL: markerImageURL
								};
								
								var markerPos = marker.getPosition();

								var markerView = new MapMarkerView({
									$container: self.$pinsContainer,
									delay: d,
									model: markerModel,
									marker: marker,
									listingId: listing.id,
									lat: listing.lat,
									lng: listing.lng,
									glat: markerPos.lat(),
									glng: markerPos.lng()
								});

								self.markerViews.push(markerView);
							}
							
							self.isResultsUpdate = false;

							if(!silent){ window.$vent.trigger('mapPaginationResults', [self.paginatedListings.concat(), self.isDraggingMap]); }

							self.updateMarkersPos();
						}
						else {
							if(!silent){ window.$vent.trigger('mapPaginationResults', [[], self.isDraggingMap]); }
						}
					}
					else {

						if(!Utils.ArrayUtils.matchingContents(listings, self.paginatedListings)){

							self.paginatedListings = [];

							for(var i=0; i<listings.length; i++){

								var listing = listings[i];
								self.paginatedListings.push(listing);
							}

							self.isResultsUpdate = false;

							if(!silent){ window.$vent.trigger('mapPaginationResults', [self.paginatedListings.concat(), self.isDraggingMap]); }
						}
						else {
							if(!silent){ window.$vent.trigger('mapResultsUnchanged', [self.paginatedListings.concat(), self.isDraggingMap]); }

						}
					}
				}
			},

			autoCenter: function(markers){
				
				var self = this;
				
				// Thanks! http://stackoverflow.com/questions/10736653/google-map-api-v3-centre-map-on-markers
				
				var limits = new google.maps.LatLngBounds();
				
				$.each(markers, function (index, marker){
					limits.extend(marker.position);
				});

				self.map.fitBounds(limits);
			},

			hideMarkers: function(){

				var self = this;
				var oldMarkerViews = _.uniq(self.markerViews);

				for(var i=0; i<oldMarkerViews.length; i++){

					var markerView = oldMarkerViews[i];
					var marker = markerView.marker;

					if(marker.getMap()){
						markerView.hide();
					}
				}
			},

			showMarkers: function(){

				var self = this;
				var oldMarkerViews = _.uniq(self.markerViews);

				for(var i=0; i<oldMarkerViews.length; i++){

					var markerView = oldMarkerViews[i];
					var marker = markerView.marker;

					if(marker.getMap()){
						markerView.show();
					}
				}
			},

			removeOldMarkers: function(continueTracking){

				var self = this;
				var oldMarkerViews = _.uniq(self.markerViews);

				continueTracking = (continueTracking !== false);

				for(var i=0; i<oldMarkerViews.length; i++){

					var markerView = oldMarkerViews[i];
					var marker = markerView.marker;

					if(marker.getMap()){

						markerView.on('destroyComplete', self._onMarkerDead, self);

						if(continueTracking){ 
							self.dyingMarkerViews.push(markerView); 
						}

						markerView.exit();
					}
					else {
						markerView.exitImmediately();
					}

					marker.setMap(null);
				}

				self.closeOpenModal();
				self.markerViews = [];
			},

			_onMarkerDead: function(e, f){

				var self = this;
				var pos = self.dyingMarkerViews.indexOf(e.target);

				if(pos >= 0){
					self.dyingMarkerViews.splice(pos,1);
				}				
			},

			removeAllMarkers: function(){

				var self = this;

				for(var i=0; i<self.markers.length; i++){
					self.markers[i].setMap(null);
				}

				self.markers = [];

				// remove marker views

				self.removeOldMarkers();
				self.markerViews = [];

				// 

				self.paginatedListings = [];
			},

			openInfoModal: function(marker){

				var self = this;
				var listing = _.findWhere(self.listingsArray, {id:marker.listingId});

				if(listing){

					self.closeOpenModal();

					//

					var point = self.getMarkerPixelPos(marker); 
					var modalModel = $.extend({
						pinURL: self.getPinImageByType(listing.pinType)
					}, listing);

					var newModal = new MapModalView({
						$container: self.$modalContainer,
						model: modalModel,
						x: point.x,
						y: point.y,
					});

					//

					self.selectedMarkerView = _.findWhere(self.markerViews, {marker:marker});
					TweenMax.set(self.selectedMarkerView.$el, {zIndex:1000});

					//

					self.openModal = newModal;
					self.clickedMarker = marker;

					//

					newModal.show();

					window.requestAnimationFrame(function(){
						self.updateModalPos();
					});
				}
			},
			
			getPinImageByType: function(pinType, stacked){

				var self = this;
				var markerImageURL;

				if(stacked){
					markerImageURL = 'modules/assets/images/marker-stacked.png';
				}
				else {

					switch(pinType){

						case 'marker':
						markerImageURL = '/assets/common/marker-marker.png';
						break;

						case 'food':
						markerImageURL = 'modules/assets/images/marker-food.png';
						break;

						case 'culture':
						markerImageURL = 'modules/assets/images/marker-culture.png';
						break;

						case 'shopping':
						markerImageURL = 'modules/assets/images/marker-shopping.png';
						break;

						case 'entertainment':
						markerImageURL = 'modules/assets/images/marker-entertainment.png';
						break;

						default: 
						markerImageURL = '/assets/common/marker-marker.png';
						break;
					};
				}

				return markerImageURL;
			},

			getMarkerPixelPos: function(marker) {

				var self = this;
				var scale = Math.pow(2, self.map.getZoom());
				var nw = new google.maps.LatLng(
					self.map.getBounds().getNorthEast().lat(),
					self.map.getBounds().getSouthWest().lng()
				);
				var worldCoordinateNW = self.map.getProjection().fromLatLngToPoint(nw);
				var worldCoordinate = self.map.getProjection().fromLatLngToPoint(marker.getPosition());
				var pixelOffset = new google.maps.Point(
					Math.floor((worldCoordinate.x - worldCoordinateNW.x) * scale),
					Math.floor((worldCoordinate.y - worldCoordinateNW.y) * scale)
				);
			
				return pixelOffset;
			},

			updateModalPos: function(){

				var self = this;

				if(self.openModal && self.clickedMarker){

					var point = self.getMarkerPixelPos(self.clickedMarker);

					TweenMax.set(self.openModal.$el, {
						x: point.x,
						y: point.y,
					});
				}
			},

			updateMarkersPos: function(){

				var self = this;

				for(i=0; i<self.markerViews.length; i++){
					var markerView = self.markerViews[i];
					self.moveMarkerView(markerView);
				}

				for(i=0; i<self.dyingMarkerViews.length; i++){
					var markerView = self.dyingMarkerViews[i];
					self.moveMarkerView(markerView);
				}
			},

			moveMarkerView: function(markerView){

				var self = this;

				if(markerView){

					var point = self.getMarkerPixelPos(markerView.marker);

					TweenMax.set(markerView.$el, {
						x: point.x,
						y: point.y
					});

					if(markerView.$el.css('z-index') <= 10){
						markerView.$el.css('z-index', parseInt(point.y) + 10);
					}
				}
			},

			closeOpenModal: function(){

				var self = this;

				if(self.openModal){ self.openModal.exit(); }
				if(self.selectedMarkerView){ 
					TweenMax.set(self.selectedMarkerView.$el, {clearProps:'z-index'});
				}

				self.openModal = null;
				self.clickedMarker = null;
			},

			// auto refresh toggle -------------------------------------------------------------  /

			_onRefreshToggleClick: function(e){

				var self = this;
				self.checkRefreshToggleStatus();
			},

			checkRefreshToggleStatus: function(){

				var self = this;

				if(self.$autoRefreshCheckbox.length){

					window.requestAnimationFrame(function(){

						if(!!self.$el.data('auto-refresh') || self.$autoRefreshCheckbox.hasClass('selected')){
							self.isAutoRefresh = true;
						}
						else { self.isAutoRefresh = false; }
					});
				}
			},

			// change map location -------------------------------------------------------------  /

			_onChangeMapLocation: function(e, obj){

				var self = this;

				if(obj){
					self.moveMapTo(obj.lat, obj.lng, obj.zoom);
				}
			},

			moveMapTo: function(lat, lng, zoom){

				var self = this;

				self.map.setZoom(zoom);
				self.map.panTo(new google.maps.LatLng(lat, lng));				
			},

			// rebuild visible map markers -----------------------------------------------------  /

			_onRebuildMapListings: function(e, obj){

				var self = this;
				var listings = obj.listings;

				self.displayListings(listings, true);
			},

			// window resize -------------------------------------------------------------------  /

			onWindowResize: function(e){

				var self = this;
				var winHeight = $(window).innerHeight();

				if(self.$el.data('height')){
					TweenMax.set(self.$el, {height:self.$el.data('height')});
				}

				if(!self.isIgnoreResize){

					if(typeof(google) !== 'undefined' && self.map){

						google.maps.event.trigger(self.map, "resize");

						if((e || self.isInitialMapPopulation) && typeof(google) !== 'undefined'){ 
							
							self.initialBoundsListener = google.maps.event.addListener(self.map, 'bounds_changed', self._onMapResize.bind(self));
							self.isInitialMapPopulation = false;
						}
					}

					if(self.listingsArray && (self.isAutoRefresh || self.isFirstRefresh)){
						//self.filterListingsInBounds(); 
					}
				}
			},

			_onMapResize: function(){
				
				var self = this;

				if(!self.isIgnoreResize && !self.isDraggingMap && (self.isAutoRefresh || self.isFirstRefresh)){ 
					//self.refreshListings(); 
				}
			},

			// ---------------------------------------------------------------------------------  /

		});

		return AppView;
	}
);
