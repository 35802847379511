
define(
	'modules/calendars/views/CalendarMenuView',[
		'backbone',
		'utils/AbstractView',
		
		'modules/property-detail-bar/views/ToolTipView',

		'text!../templates/CalendarMenuTemplateEn.html',
		'text!../templates/CalendarMenuTemplateEs.html',
		'text!../templates/CalendarTemplate.html',
	], 
	function(Backbone, AbstractView, ToolTipView, TemplateEn, TemplateEs,  CalendarTemplate){

		var CalendarMenuView = Backbone.View.extend({

			// core vars
			lang:null,

			$el: null,
			$container: null,
			options: null,
			abstractView: null,

			// vars

			$context: null,

			clndr: null,
			multidayArray: null,
			isDateSelected: false,
			queryString: null,

			endDate: null,
			startDate: null,

			$leftCalendarContainer: null,
			$rightCalendarContainer: null,
			$clearDatesButton: null,
			$closeButton: null,

			leftCalendar: null,
			rightCalendar: null,

			leftMonth: null,
			rightMonth: null,

			toolTip: null,
			toolTipTimeout: null,

			serverQuery: null,
			availabilityData: null,
			selectedDates: null,
			queryObj: null,

			minNights: null,
			maxNights: null,

			isShortDate: false,
			isReadOnly: false,
			isMonthChange: false,
			isOwnerListing: false,
			isEditBlock: false,
			isFirstShow: true,

			autoPopulate: false,
			hasDayPrices: false,

			// init

			initialize: function(options){

				var self = this;
				self.options = options;
				self.$container = self.options.$container;
				self.$context = self.options.$context || $;
				/*self.lang = options.lang;*/
				self.lang = $('#lang').text();

				self.isShortDate = self.options.shortDate === true;
				self.selectedDates = self.options.selectedDates;

				self.searchFilters = {};

				//Internationalization of moment
				if (self.lang == "es") {
					moment.locale("es");
				} else {
					moment.locale("en");
				}

				if(self.lang == "en"){
					self.template = _.template(TemplateEn);
				}else{
					self.template = _.template(TemplateEs);
				}

				self.model = $.extend({}, self.model);


				// defaults in case of availability data
				
				self.occupiedBlocks = [];
				self.minNights = 1;
				self.maxNights = 0;

				// default start / end dates
				
				self.startDate = moment(moment().format('YYYY-MM-DD'));
				self.endDate = moment().add(24, 'months');

				//

				self.selectedBlockIndex = self.$container.data('block-index');
				
				if(_.isNumber(self.selectedBlockIndex) && self.selectedBlockIndex >= 0){ 
					self.isEditBlock = true; 
				}

				// 

				self.abstractView = new AbstractView({
					view: self,
					autoShow: (self.options.autoShow === true),
					hideClass: self.options.hideClass || 'display-none'
				});

				//

				$(window).on('resize', $.proxy(self._onWindowResize, self));
				self._onWindowResize();
			},

			// initialize ----------------------------------------------------------------------  /

			onInitialize: function(){

				var self = this;

				self.clndr = {};
				self.multidayArray = [];
				self.isDateSelected = false;
				self.queryString = '';
			},

			// onRender ------------------------------------------------------------------------  /

			onRender: function(){

				var self = this;	

				// clear dates button

				self.$clearDatesButton = self.$container.find('.clear-dates');
				self.$closeButton = self.$el.find('.fa.close').add(self.$el.find('.close-btn'));

				//

				if(self.$container.hasClass('read-only') || self.$container.data('read-only')){ self.isReadOnly = true; }
				if(self.$container.hasClass('owner-listing') || self.$container.data('owner-listing')){ self.isOwnerListing = true; }

				self.resetEvents();

				// combine all occupied blocks

				window.$vent.on('setAvailabilityData', $.proxy(self._onSetAvailabilityData, self));

				var availabilityData = self.model.availability;
				if(availabilityData){ self.configureAvailabilityData(availabilityData); }
				else { 
					window.$vent.trigger('availabilityDataRequest', {target:self}); 					
					if(self.$container.data('pending-data') !== true){ self.reRenderCalendars(); }
				}

				// if no availability data, disable right calendar

				if(!self.isReadOnly && (!availabilityData || !self.autoPopulate)){
					self.disableRightCalendar();
				}
			},

			reRenderCalendars: function(){

				var self = this;

				if(self.leftCalendar){ self.$leftCalendarContainer.remove(); }
				if(self.rightCalendar){ self.$rightCalendarContainer.remove(); }

				// start dates

				var firstRange = self.isEditBlock ? self.getSelectedOwnersBlock() : self.getFirstAvailableDate();
				var leftStartDate = self.selectedDates ? moment(self.selectedDates.checkInDate) : moment(moment().format('YYYY-MM-DD')); // moment(firstRange.startDate);
				var rightStartDate = self.selectedDates ? moment(self.selectedDates.checkOutDate): moment(moment().format('YYYY-MM-DD')); //moment(firstRange.startDate).add(self.minNights, 'days');
				
				if(self.multidayArray && self.multidayArray.length){
					//rightStartDate = moment(self.multidayArray[0].startDate).add(self.minNights, 'days');
				}

				if(self.isReadOnly){
					rightStartDate = moment(moment().format('YYYY-MM-DD')).add(1, 'months');
				}

				self.leftMonth = leftStartDate.format('MM');
				self.rightMonth = rightStartDate.format('MM');

				// append calendars

				var $calendarsContainer = self.$el.find('.calendars-inner-container');
				if(!$calendarsContainer.length) $calendarsContainer = self.$el;

				// left (check-in)

				self.$leftCalendarContainer = self.$context('<div class="calendar-container left"></div>').appendTo($calendarsContainer);
				self.leftCalendar = self.clndr.passInATemplate = self.$leftCalendarContainer.clndr({
					template: CalendarTemplate,
					events: [],
					multiDayEvents: {
						startDate: 'startDate',
						endDate: 'endDate'
					},
					startWithMonth: firstRange.startDate,
					forceSixRows: true,
					weekOffset: 1,
					constraints: {
						startDate: leftStartDate,
						endDate: self.endDate
					},
					clickEvents: {
						click: self._onLeftCalendarClick.bind(self),
						nextMonth: self._onNextMonth.bind(self),
						previousMonth: self._onPrevMonth.bind(self),
					},
					lang:lang.innerText,
					extras: {
						header: self.lang=="es"?'Entrada':'Check In',
						multidayArray: self.multidayArray,
						availabilityData: self.availabilityData,
						traverseDateRange: self.traverseDateRange,
						isOwnerListing: self.isOwnerListing,
						isEditBlock: self.isEditBlock,
						selectedBlockIndex: self.selectedBlockIndex,
					}
				});

				// right (check-out)

				self.$rightCalendarContainer = self.$context('<div class="calendar-container right"></div>').appendTo($calendarsContainer);
				self.rightCalendar = self.clndr.passInATemplate = self.$rightCalendarContainer.clndr({
					template: CalendarTemplate,
					events: [], 
					multiDayEvents: {
						startDate: 'startDate',
						endDate: 'endDate'
					},
					startWithMonth: firstRange.startDate,
					forceSixRows: true,
					weekOffset: 1,
					constraints: {
					  startDate: rightStartDate,
					  endDate: self.endDate
					},
					clickEvents: {
						click: self._onRightCalendarClick.bind(self),
						nextMonth: self._onNextMonth.bind(self),
						previousMonth: self._onPrevMonth.bind(self),
					},
					lang:self.lang,
					extras: {
						header: self.lang=="es"?'Salida':'Check Out',
						multidayArray: self.multidayArray,
						availabilityData: self.availabilityData,
						traverseDateRange: self.traverseDateRange,
						isOwnerListing: self.isOwnerListing,
						isEditBlock: self.isEditBlock,
						selectedBlockIndex: self.selectedBlockIndex,
					}
				});

				//
				self.tweakCalendar();
			},


			// listeners -----------------------------------------------------------------------  /

			onAddListeners: function(){

				var self = this;

				window.$vent.on('requestCalendarQuery', $.proxy(self._onRequestCalendarQuery, self));

				self.$clearDatesButton.on('click', $.proxy(self._onClearDatesClick, self));
				self.$closeButton.on('click', $.proxy(self._onCloseClick, self));
			},

			onRemoveListeners: function(){

				var self = this;

				if(self.$clearDatesButton){
					self.$clearDatesButton.off('click', $.proxy(self._onClearDatesClick, self));
				}

				if(self.$closeButton){
					self.$closeButton.off('click', $.proxy(self._onCloseClick, self));
				}
			},

			// listener methods ----------------------------------------------------------------  /

			triggerOption: function(checkInDate, checkOutDate){

				var self = this;

				if(checkInDate && checkOutDate){
					Utils.MiscUtils.setCookie('checkIn', checkInDate, window.SEARCHFILTERS_COOKIE_EXPIRES);
					Utils.MiscUtils.setCookie('checkOut', checkOutDate, window.SEARCHFILTERS_COOKIE_EXPIRES);
					self.changeStartDate({}, moment(checkInDate));
					self.changeEndDate({}, moment(checkOutDate));
					self.tweakCalendar();
				}
			},

			_onLeftCalendarClick: function(target){

				var self = this;
				var $target = $(target.element);

				if(!$target.hasClass('inactive') && !$target.hasClass('disabled')){

					var startDate = moment(target.date);
					var success = self.changeStartDate(target, startDate);

					var lastLeftMonth = startDate.format('MM');
					var lastRightMonth = self.multidayArray.length && self.multidayArray[0].endDate ? self.multidayArray[0].endDate.format('MM') : '';

					if(success){

						if(lastLeftMonth != success.startDate.format('MM')){
							self.leftCalendar.setMonth(success.startDate.format('MM')-1);
						}

						if(lastRightMonth != success.endDate.format('MM')){
							self.rightCalendar.setMonth(success.endDate.format('MM')-1);
						}

						window.requestAnimationFrame(function(){
							
							self.isMonthChange = true
							if(self.lang == "en"){
								self.showInstruction('Select Check-Out Date');
							}else{
								self.showInstruction('Selecciona fecha de salida');
							}
							self.disableLeftCalendar();
							self.enableRightCalendar();

							self.tweakCalendar();
						});
					}
				}
			},

			_onRightCalendarClick: function(target){

				var self = this;
				var $target = $(target.element);

				if(!$target.hasClass('inactive') && !$target.hasClass('disabled')){

					var startDate = moment(self.multidayArray[0].startDate);
					var endDate = moment(target.date);					
					var success = self.changeEndDate(target, endDate);

					var lastLeftMonth = startDate.format('MM');
					var lastRightMonth = endDate.format('MM');

					if(success){

						if(lastLeftMonth != success.startDate.format('MM')){
							self.leftCalendar.setMonth(success.startDate.format('MM')-1);
						}

						if(lastRightMonth != success.endDate.format('MM')){
							self.rightCalendar.setMonth(success.endDate.format('MM')-1);
						}

						window.requestAnimationFrame(function(){
							
							self.isMonthChange = true;
							self.isDateSelected = true;

							self.completeInstruction();
							self.tweakCalendar();
							self.closeMenu();
						});
					}
				}
			},

			_onNextMonth: function(e){

				var self = this;

				if(self.isReadOnly){

					if(e.target === self.leftCalendar){
						self.rightCalendar.forward();
					}
					else if(e.target === self.rightCalendar){
						self.leftCalendar.forward();
					}
				}

				self._onMonthChange(e);
			},

			_onPrevMonth: function(e){

				var self = this;

				if(self.isReadOnly){

					if(e.target === self.leftCalendar){
						self.rightCalendar.back();
					}
					else if(e.target === self.rightCalendar){
						self.leftCalendar.back();
					}
				}

				self._onMonthChange(e);
			},

			_onMonthChange: function(e){

				var self = this;

				window.$vent.trigger('calendarMonthClick');

				self.isMonthChange = true;
				self.tweakCalendar();
			},

			_onClearDatesClick: function(e){

				var self = this;
				var $target = $(e.currentTarget);

				if(!$target.hasClass('disabled')){
					self.clearDates();
				}
			},

			_onRequestCalendarQuery: function(e, obj){

				var self = this;

				if(obj.$container[0].contains(self.$el[0])){
					self.sendQueryObj();
				}
			},

			_onCloseClick: function(){

				var self = this;
				self.trigger('closeMenu');
			},

			closeMenu: function(){

				var self = this;
				self.trigger('closeMenu');
			},

			// date selection blocks -----------------------------------------------------------  /

			changeStartDate: function(target, startDate){

				var self = this;
				var $target = $(target.element);

				var minEndDate = moment(startDate).add(self.minNights, 'days');
				
				// check for conflicts with occupied dates

				if(self.availabilityData){

					var nextOccupiedDate = self.getNextOccupiedDate(startDate);

					if((nextOccupiedDate.format('x') - startDate.format('x'))/86400000 < self.minNights){

						self.showToolTip($target, 'Min ' + self.minNights + ' ' + self.model.texts.nights, 'red');
						return false;
					}
				}

				// if valid, continue

				if(!self.multidayArray.length){ self.multidayArray.push({}); }
				self.multidayArray[0].startDate = startDate;

				return {startDate: startDate, endDate: minEndDate};
			},

			changeEndDate: function(target, endDate){

				var self = this;
				var $target = $(target.element);
				var startDate = self.multidayArray[0].startDate;

				var maxStartDate = moment(endDate).subtract(self.minNights, 'days');
				var minEndDate = moment(startDate).add(self.minNights, 'days');
				var maxEndDate = moment(startDate).add(self.maxNights, 'days');

				if(self.availabilityData){

					var nextOccupiedDate = self.getNextOccupiedDate(startDate);

					if(!self.isAvailableDate(endDate.format('x'))){

						self.showToolTip($target, self.model.texts.tooltips.dayNotAvailable, 'red');
						return false;/**/

					} else {

						// within same date range

						if(endDate.format('x') > maxEndDate.format('x')){

							// invalid, too far

							self.showToolTip($target, 'Max ' + self.maxNights + ' ' + self.model.texts.nights, 'red');
							return false;
						}
						else if(endDate.format('x') < startDate.format('x')){

							self.showToolTip($target, self.model.texts.tooltips.dayNotAvailable, 'red');
							return false;
						}
						else if(endDate.format('x') < minEndDate.format('x')){

							// invalid, too close

							self.showToolTip($target, 'Min ' + self.minNights + ' ' + self.model.texts.nights, 'red');
							return false;
						}
						else if(endDate.format('x') > nextOccupiedDate.format('x')){

							// invalid, occupied block in between

							self.showToolTip($target, self.model.texts.tooltips.dayNotAvailable, 'red');
							return false;
						}
						else { 

							// valid, no further changes needed

							self.multidayArray[0].endDate = endDate;
							self.isDateSelected = true;						
							return {startDate: startDate, endDate: endDate};
						}

					} 

				} else {

					// no data, assume no blockout dates

					if(endDate.format('x') >= minEndDate.format('x')){
						
						self.multidayArray[0].endDate = endDate;
						self.isDateSelected = true;
						return {startDate: startDate, endDate: endDate};
					}
					else {

						self.showToolTip($target, 'Min ' + self.minNights + ' ' + self.model.texts.nights, 'red');
						return false;
					}
				}

				return {startDate: startDate, endDate: endDate};
			},

			// instructions text ---------------------------------------------------------------  /

			resetClearButton: function(){

				var self = this;

				if(self.multidayArray && self.multidayArray.length && self.multidayArray[0].startDate){
					if(self.lang == "en"){
						self.showInstruction('Select Check-Out Date', 0);
					}else{
						self.showInstruction('Selecciona fecha de salida', 0);
					}
				}
				else {
					if(self.lang == "en"){
						self.showInstruction('Select Check-In Date', 0);
					}else{
						self.showInstruction('Selecciona fecha de entrada', 0);;
					}
				}
			},

			showInstruction: function(instructionMessage, delay){

				var self = this;
				var $messageText = self.$clearDatesButton.find('h3');	
				var d = delay || 0;
				
				TweenMax.to($messageText, 0.3, {opacity:0, ease:Cubic.easeOut, onComplete:function(){
					self.$clearDatesButton.removeClass('disabled').addClass('disabled');
					$messageText.html(instructionMessage);
					TweenMax.fromTo($messageText, 0.3, {y:6}, {y:0, opacity:1, delay:d, ease:Sine.easeOut});
				}});
			},

			completeInstruction: function(){

				var self = this;
				var $messageText = self.$clearDatesButton.find('h3');	

				TweenMax.to($messageText, 0.3, {opacity:0, ease:Cubic.easeOut, onComplete:function(){
					
					self.$clearDatesButton.removeClass('disabled');
					
					if(self.isEditBlock){
						if(self.lang == "en"){
							$messageText.html('reset dates');
						}else{
							$messageText.html('restablecer fechas');
						}
					}
					else {
						if(self.lang == "en"){
							$messageText.html('clear dates');
						}else{
							$messageText.html('borrar fechas');
						}
					}
					
					TweenMax.fromTo($messageText, 0.3, {y:6}, {y:0, opacity:1, ease:Sine.easeOut});
				}});
			},

			// tooltips ------------------------------------------------------------------------  /

			showToolTip: function($target, message, color, width){

				var self = this;

				if(self.toolTip && self.toolTip.$el[0] !== $target[0]){
					self.removeToolTip();
					self.toolTip = null;
				}

				if(self.toolTipTimeout){ window.clearTimeout(self.toolTipTimeout); }

				self.toolTipTimeout = window.setTimeout(function(){
					self.showToolTipNow($target, message, color, width);
				}, 25);
			},

			showToolTipNow: function($target, message, color, width){

				var self = this;

				self.toolTip = new ToolTipView({
					$container: $target,
					model: {text: message},
					color: color,
					width: width,
					autoShow: true,
				});
			},

			removeToolTip: function(){

				var self = this;

				if(self.toolTip){
					self.toolTip.exit();
					self.toolTip = null;
				}
			},

			// clndr event object --------------------------------------------------------------  /

			clearDates: function(){

				var self = this;
				self.resetEvents(true);	

				var $eventDays = self.$leftCalendarContainer.find('.day.event');
				$eventDays = $eventDays.add(self.$rightCalendarContainer.find('.day.event'));

				_.each($eventDays, function(eventDay){
					self.animateDayOut($(eventDay));
				});

				// reset text fields if not edit block
				if(!self.isEditBlock){					
					self.trigger('clearDates', {target:self});
					window.$vent.trigger('calendarClearDates', {target:self});
				}

				TweenMax.delayedCall(0.2, function(){

					var curMonth = parseInt(moment().format('MM'));
					var curYear = parseInt(moment().format('YYYY'));

					self.leftCalendar.setEvents(self.getSingleEvent());
					self.leftCalendar.setYear(curYear);
					self.leftCalendar.setMonth(curMonth-1);

					self.rightCalendar.setEvents(self.multidayArray);
					self.rightCalendar.setYear(curYear);
					self.rightCalendar.setMonth(curMonth-1);
					
					self.reRenderCalendars();
					self.enableLeftCalendar();
					self.disableRightCalendar();

					self.resetClearButton();
					self.tweakCalendar();
				});
			},

			resetEvents: function(clear){

				var self = this;

				console.log('resetEvents self.isDateSelected',self.isDateSelected);


				if(!clear && (self.autoPopulate || self.availabilityData || self.selectedDates)){

					console.log('resetEvents clear',clear);

					var firstRange = self.isOwnerListing ? self.getSelectedOwnersBlock() : self.getFirstAvailableDate();
					var eventStartDate = firstRange.startDate;
					var eventEndDate = firstRange.endDate;

					if(clear !== true && self.selectedDates){
						console.log('resetEvents self.selectedDates',self.selectedDates);
						eventStartDate = moment(self.selectedDates.checkInDate);
						eventEndDate = moment(self.selectedDates.checkOutDate);
					}

					self.multidayArray = [
						{ 
							startDate: eventStartDate, 
							endDate: eventEndDate, 
							title: 'Your Itinerary' 
						},
					];

					self.isDateSelected = true;
				}
				else {
					if (self.selectedDates) {
						self.selectedDates = null
					}

					if(self.isEditBlock && self.availabilityData){

						console.log('**** self.isEditBlock', self.isEditBlock);

						var dateRange = self.availabilityData.ownerOccupiedDates[self.selectedBlockIndex];
						var startDate = moment(dateRange['startDate']);
						var endDate = moment(dateRange['endDate']);
						
						// set event

						if(!self.multidayArray.length){ self.multidayArray.push({}); }

						self.multidayArray[0].startDate = startDate;
						self.multidayArray[0].endDate = endDate;
					}
					else {
						self.multidayArray = [];
						self.isDateSelected = false;
					}
				}
			},

			getSingleEvent: function(){
				
				var self = this;

				if(self.multidayArray && self.multidayArray.length){ 
					return [{date:self.multidayArray[0].startDate, title:'Check-In Date'}];
				}

				return [];
			},

			setEventClasses: function($days, maxNights){

				var self = this;
				maxNights = maxNights || $days.length;

				if(self.multidayArray.length > 0){

					var eventStart = self.multidayArray[0].startDate ? moment(self.multidayArray[0].startDate) : null;
					var eventEnd = self.multidayArray[0].endDate ? moment(self.multidayArray[0].endDate) : moment(eventStart).add(Math.max(self.minNights,0), 'days');

					var eventDayCount = 0;
					for(var i=0; i<$days.length; i++){

						var $day = $($days[i]);
						var dayClasses = $day.attr('class');
						var classIndex = dayClasses.indexOf('calendar-day-');
						var dateClass = dayClasses.slice(classIndex, dayClasses.indexOf(' ', classIndex));
						var dayDate = moment(dateClass.split('calendar-day-')[1]);

						if(dayDate.format('x') >= eventStart.format('x') && (dayDate.format('x') <= eventEnd.format('x') || !eventEnd) && eventDayCount < maxNights && eventEnd.format('x') != eventStart.format('x')){
							
							$day.removeClass('event').addClass('event');
							
							if(eventDayCount === 0){ 
								$day.removeClass('first-event-day').addClass('first-event-day'); 
							}
						 	
						 	eventDayCount++;
							if (dayDate.format('x') == eventEnd.format('x') && self.multidayArray[0].endDate) {
								$day.removeClass('last').addClass('last');
							}
						}
						else {
							$day.removeClass('event');
						}
					}
				}
			},

			//

			animateDayIn: function($day, i){
				
				var self = this;
				i = i || 0;

				var $eventBg = $day.find('figure.bg');
				var $eventText = $day.find('figcaption');

				TweenMax.fromTo($eventBg, 0.3, {opacity:0}, {opacity:1, delay:0.01 * i, ease:Sine.easeInOut});
				TweenMax.to($eventText, 0.3, {color:'#fff', delay:0.01 * i, ease:Sine.easeInOut});

				$day.removeClass('animated').addClass('animated');
				$eventBg.removeClass('hidden');
			},

			animateDayOut: function($day, i){

				var self = this;

				var $eventBg = $day.find('figure.bg');
				var $lastBg = $day.find('figure.last-bg');
				var $eventText = $day.find('figcaption');

				$day.removeClass('event-out').addClass('event-out');
				$day.removeClass('animated');

				TweenMax.to($lastBg, 0.2, {opacity:0, ease:Cubic.easeOut});

				TweenMax.set($eventBg, {transformOrigin:'50% 100%', opacity:0});
				TweenMax.to($eventBg, 0.3, {ease:Cubic.easeInOut, onComplete:function(){
					$day.removeClass('event-out');
					$eventBg.removeClass('hidden').addClass('hidden');
				}});

				TweenMax.to($eventText, 0.1, {color:'#808081', ease:Cubic.easeOut});
			},

			tweakCalendar: function(){

				var self = this;

				if(self.leftCalendar && self.rightCalendar){

					self.leftMonth = self.leftCalendar.month.format('MM');
					self.rightMonth = self.rightCalendar.month.format('MM');

					// add proper classes to date items for styling
					// left

					var $leftDaysContainer = self.$leftCalendarContainer.find('.days');
					var $leftDays = $leftDaysContainer.find('.day');

					for(var i=0; i<$leftDays.length; i++){
						
						var $day = $($leftDays[i]);

						if(self.availabilityData){
							
							$day.attr('data-price', self.availabilityData.defaultPrice);
							
							// remove old listeners

							$day.off('mouseenter', $.proxy(self._onDayOver, self));
							$day.off('mouseleave', $.proxy(self._onDayOut, self));

							// add new listeners

							$day.on('mouseenter', $.proxy(self._onDayOver, self));
							$day.on('mouseleave', $.proxy(self._onDayOut, self));
						}
					}

					if(!self.isReadOnly){ self.setEventClasses($leftDays); }

					// right

					var $rightDaysContainer = self.$rightCalendarContainer.find('.days');
					var $rightDays = $rightDaysContainer.find('.day');

					for(var i=0; i<$rightDays.length; i++){

						$day = $($rightDays[i]);

						if(self.availabilityData){

							$day.attr('data-price', self.availabilityData.defaultPrice);
							
							// remove old listeners

							$day.off('mouseenter', $.proxy(self._onDayOver, self));
							$day.off('mouseleave', $.proxy(self._onDayOut, self));

							// add new listeners

							$day.on('mouseenter', $.proxy(self._onDayOver, self));
							$day.on('mouseleave', $.proxy(self._onDayOut, self));
						}
					}

					if(!self.isReadOnly){ self.setEventClasses($rightDays); }

					// mark availability

					if(self.availabilityData){

						// occupied dates
						if(self.availabilityData.myBlockDates){
							for(var i=0; i<self.availabilityData.myBlockDates.length; i++){
								var dateRange = self.availabilityData.myBlockDates[i];
								self.traverseDateRange(dateRange, function(date, formattedDate, dateRange, position){
									var $leftoccupiedDay = $leftDaysContainer.find('.calendar-day-' + formattedDate);
									var $rightoccupiedDay = $rightDaysContainer.find('.calendar-day-' + formattedDate);
									self.setBlockClasses($leftoccupiedDay, $rightoccupiedDay, 'blocked', position);
								});
							}
						}

						if(self.availabilityData.bookedOccupiedDates){
							for(var i=0; i<self.availabilityData.bookedOccupiedDates.length; i++){
								var dateRange = self.availabilityData.bookedOccupiedDates[i];
								self.traverseDateRange(dateRange, function(date, formattedDate, dateRange, position){
									var $leftoccupiedDay = $leftDaysContainer.find('.calendar-day-' + formattedDate);
									var $rightoccupiedDay = $rightDaysContainer.find('.calendar-day-' + formattedDate);
									self.setBlockClasses($leftoccupiedDay, $rightoccupiedDay, 'occupied', position);
								});
							}
						}

						if(self.availabilityData.holdOccupiedDates){
							for(var i=0; i<self.availabilityData.holdOccupiedDates.length; i++){
								var dateRange = self.availabilityData.holdOccupiedDates[i];
								self.traverseDateRange(dateRange, function(date, formattedDate, dateRange, position){
									var $leftoccupiedDay = $leftDaysContainer.find('.calendar-day-' + formattedDate);
									var $rightoccupiedDay = $rightDaysContainer.find('.calendar-day-' + formattedDate);
									self.setBlockClasses($leftoccupiedDay, $rightoccupiedDay, 'on-hold', position);
								});
							}
						}

						if(self.availabilityData.ownerOccupiedDates){
							for(var i=0; i<self.availabilityData.ownerOccupiedDates.length; i++){
								if(!self.isOwnerListing || (
									self.isOwnerListing && !self.isEditBlock && i !== self.selectedBlockIndex)){
									var dateRange = self.availabilityData.ownerOccupiedDates[i];
									self.traverseDateRange(dateRange, function(date, formattedDate, dateRange, position){
										var $leftoccupiedDay = $leftDaysContainer.find('.calendar-day-' + formattedDate);
										var $rightoccupiedDay = $rightDaysContainer.find('.calendar-day-' + formattedDate);
										self.setBlockClasses($leftoccupiedDay, $rightoccupiedDay, 'owner', position);
									});
								}
							}
						}

						// price ranges

						if(self.availabilityData.dateRangePrices){

							var rangePrice = null;
							var rangeStartDate = null;
							var currentRange = null;

							for(var i=0; i<self.availabilityData.dateRangePrices.length; i++){

								var dateRange = self.availabilityData.dateRangePrices[i];

								self.traverseDateRange(dateRange, function(date, formattedDate, dateRange){

									var eventDateTime = date.format('x');
									var matchedRange = null;

									if(date.format('MM') === self.leftMonth){
										var $leftDay = $leftDaysContainer.find('.calendar-day-' + formattedDate);
										$leftDay.attr('data-price', dateRange.price);
										self.minNights = dateRange.minNightsStay;
									}

									if(date.format('MM') === self.rightMonth){
										var $rightDay = $rightDaysContainer.find('.calendar-day-' + formattedDate);
										$rightDay.attr('data-price', dateRange.price);
									}

									var rangeStartTime = moment(dateRange.startDate).format('x');
									var rangeEndTime = moment(dateRange.endDate).format('x');

									if (eventDateTime >= rangeStartTime && eventDateTime <= rangeEndTime) {
										matchedRange = dateRange;
										rangePrice = dateRange.price;
									}
								});

							}

							self.hasDayPrices = true;


						}
					}

					//

					self.animateCalendarEvents();

					// update click to open buttons

					var checkInDate, checkOutDate, checkInDateShort, checkOutDateShort, checkInDateParam, checkOutDateParam;

					if(self.multidayArray && self.multidayArray.length){

						if(self.lang == "en"){
							checkInDate = moment(self.multidayArray[0].startDate).format('MMM Do, YYYY');
							checkOutDate = moment(self.multidayArray[0].endDate).format('MMM Do, YYYY');
						}else{
							checkInDate = moment(self.multidayArray[0].startDate).format('DD MMM YYYY');
							checkOutDate = moment(self.multidayArray[0].endDate).format('DD MMM YYYY');
						}

						checkInDateShort = moment(self.multidayArray[0].startDate).format('DD/MM/YY');
						checkOutDateShort = moment(self.multidayArray[0].endDate).format('DD/MM/YY');

						checkInDateParam = moment(self.multidayArray[0].startDate).format('MM/DD/YY');
						checkOutDateParam = moment(self.multidayArray[0].endDate).format('MM/DD/YY');

						if(self.isShortDate){
							checkInDate = checkInDateShort;
							checkOutDate = checkOutDateShort;
						}
					}

					console.log('isDateSelected', self.isDateSelected);

					if(self.isDateSelected){

						// get costs based on days
						
						var priceBreakdown = {};

						priceBreakdown.ranges = [];
						priceBreakdown.totalNights = 0;
						priceBreakdown.totalPrice = 0;

						if(self.availabilityData){

							var rangePrice = null;
							var rangeStartDate = null;
							var currentRange = null;

							self.traverseDateRange(self.multidayArray[0], function(eventDate, formattedDate, eventRange){

								var eventDateTime = eventDate.format('x');
								var matchedRange = null;

								if(self.availabilityData.dateRangePrices){

									for(var i=0; i<self.availabilityData.dateRangePrices.length; i++){

										var dateRange = self.availabilityData.dateRangePrices[i];
										var rangeStartTime = moment(dateRange.startDate).format('x');
										var rangeEndTime = moment(dateRange.endDate).format('x');

										if(eventDateTime >= rangeStartTime && eventDateTime <= rangeEndTime){
											matchedRange = dateRange;
											rangePrice = dateRange.price;
											break;
										}
									}

									self.hasDayPrices = true;
								}

								if(!matchedRange){ rangePrice = self.availabilityData.defaultPrice; }

								if(!currentRange || rangePrice != currentRange.price){

									if(currentRange){ 									
										
										currentRange.endDate = eventDate; 
										currentRange.nights = parseInt((currentRange.endDate.format('x') - currentRange.startDate.format('x'))/86400000);
										currentRange.totalPrice = currentRange.nights * currentRange.price;

										priceBreakdown.totalNights += currentRange.nights;
										priceBreakdown.totalPrice += currentRange.totalPrice;
									}

									currentRange = { 
										startDate: eventDate,
										price: rangePrice
									};

									priceBreakdown.ranges.push(currentRange);
								}

								if(eventDate.format('x') === eventRange.endDate.format('x')){

									currentRange.endDate = eventRange.endDate;
									currentRange.nights = parseInt((currentRange.endDate.format('x') - currentRange.startDate.format('x'))/86400000);
									currentRange.totalPrice = currentRange.nights * currentRange.price;

									priceBreakdown.totalNights += currentRange.nights;
									priceBreakdown.totalPrice += currentRange.totalPrice;
								}
							});
		
							var lastPos = priceBreakdown.ranges.length-1;
							if(!priceBreakdown.ranges[lastPos].length){
								priceBreakdown.ranges.splice(lastPos);
							}
						}


						//
						self.serverQuery = 'checkIn=' + checkInDateParam + '&checkOut=' + checkOutDateParam;
						self.queryObj = {
							target: self, 
							query: 'checkIn=' + checkInDate + '&checkOut=' + checkOutDate,
							checkInQuery: checkInDateParam,
							checkOutQuery: checkOutDateParam,
							checkInQueryLong: checkInDate,
							checkOutQueryLong: checkOutDate,
							results: checkInDate, 
							results2: checkOutDate, 
							resultsObj: {
								checkInDate: moment(self.multidayArray[0].startDate),
								checkOutDate: moment(self.multidayArray[0].endDate),
								priceBreakdown: priceBreakdown,
							}
						};

						self.trigger('optionSelected', self.queryObj);
						window.$vent.trigger('dateSelectedInMobile', [{datesSelected:self.queryObj},self]);
						window.$vent.trigger('calendarQuery', self.queryObj);

					/*	if(window.innerWidth < 768) {
							var mobileQuery = self.queryObj;
							mobileQuery.type = "mobile";
							window.$vent.trigger('calendarQueryMobile', mobileQuery);
						}*/
					}

					self.isMonthChange = false;
				}
			},
			setBlockClasses: function(leftoccupiedDay, rightoccupiedDay, className, position) {
				var self = this

				if (position == 'start') {
					leftoccupiedDay.removeClass(className+'-first').addClass(className+'-first');
					leftoccupiedDay.removeClass('disabled');
					rightoccupiedDay.removeClass(className+'-first').addClass(className+'-first');
					rightoccupiedDay.removeClass('disabled');
				} else if (position == 'end') {
					leftoccupiedDay.removeClass(className+'-last').addClass(className+'-last');
					leftoccupiedDay.removeClass('disabled');
					rightoccupiedDay.removeClass(className+'-last').addClass(className+'-last');
					rightoccupiedDay.removeClass('disabled');
				} else {
					leftoccupiedDay.removeClass(className).addClass(className);
					leftoccupiedDay.removeClass('disabled').addClass('disabled');
					rightoccupiedDay.removeClass(className).addClass(className);
					rightoccupiedDay.removeClass('disabled').addClass('disabled');
				}

				if (self.lang == 'es') {
					switch(className) {
						case 'occupied':
							leftoccupiedDay.removeAttr('title').attr('title','Reserva');
							rightoccupiedDay.removeAttr('title').attr('title','Reserva');
							break;
						case 'on-hold':
							leftoccupiedDay.removeAttr('title').attr('title','Pre-Reserva');
							rightoccupiedDay.removeAttr('title').attr('title','Pre-Reserva');
							break;
						case 'owner':
							leftoccupiedDay.removeAttr('title').attr('title','Bloqueo');
							rightoccupiedDay.removeAttr('title').attr('title','Bloqueo');
							break;
					}

				} else {
					switch(className) {
						case 'occupied':
							leftoccupiedDay.removeAttr('title').attr('title','Booked');
							rightoccupiedDay.removeAttr('title').attr('title','Booked');
							break;
						case 'on-hold':
							leftoccupiedDay.removeAttr('title').attr('title','On Hold');
							rightoccupiedDay.removeAttr('title').attr('title','On Hold');
							break;
						case 'owner':
							leftoccupiedDay.removeAttr('title').attr('title','Blocked');
							rightoccupiedDay.removeAttr('title').attr('title','Blocked');
							break;
					}
				}
			},

			animateCalendarEvents: function(){

				var self = this;

				if(!self.isReadOnly){

					// animate left calendar

					var $removeLeftDays = self.$leftCalendarContainer.find('.day.animated').not('.event');

					_.each($removeLeftDays, function(eventDay, i){
						self.animateDayOut($(eventDay), i);
					});

					var $leftEventDays = self.$leftCalendarContainer.find('.day.event').not('.animated');

					_.each($leftEventDays, function(eventDay, i){
						self.animateDayIn($(eventDay), i);			
					});

					// animate right calendar

					var $removedRightDays = self.$rightCalendarContainer.find('.day.animated').not('.event').not('.on-hold');
					_.each($removedRightDays, function(eventDay, i){
						self.animateDayOut($(eventDay), i);
					});

					var $rightEventDays = self.$rightCalendarContainer.find('.day.event, .day.on-hold').not('.animated');
					_.each($rightEventDays, function(eventDay, i){
						self.animateDayIn($(eventDay), i);				
					});
				
					// mark last day in calendar

					if(self.multidayArray && self.multidayArray.length && self.multidayArray[0].endDate){

						var $lastDay = self.$rightCalendarContainer.find('.calendar-day-' + moment(self.multidayArray[0].endDate).format('YYYY-MM-DD'));
						var $prevLastDay = self.$rightCalendarContainer.find('.last');

						if($prevLastDay[0] != $lastDay[0]){

							$prevLastDay.removeClass('last');
							TweenMax.to($prevLastDay.find('.last-bg'), 0.3, {opacity:0, ease:Cubic.easeOut});

							if($lastDay.length){

								$lastDay.removeClass('last').addClass('last');

								var d = 0.01 * $rightEventDays.length;

								TweenMax.set($lastDay.find('.last-bg'), {transformOrigin:'50% 100%'});
								TweenMax.fromTo($lastDay.find('.last-bg'), 0.3, {opacity:0}, {opacity:1, delay:d, ease:Cubic.easeOut});
								
								if(!self.isMonthChange){
									TweenMax.fromTo($lastDay.find('.last-bg'), 0.3, {scaleY:0}, {scaleY:1, delay:d, ease:Sine.easeIn});
									TweenMax.to($lastDay.find('.last-bg'), 0.3, {scaleY:1, delay:d + 0.1, ease:Quint.easeOut});
								}
							}
						}
					}
				}
			},

			sendQueryObj: function(){

				var self = this;
				window.$vent.trigger('calendarQuery', self.queryObj);				
			},

			traverseDateRange: function(dateRange, callback){

				var self = this;
				var startDate = dateRange['startDate'];
				var endDate = dateRange['endDate'];

				if(_.isString(startDate)){ startDate = moment(self.formatDate(startDate)); }
				if(_.isString(endDate)){ endDate = moment(self.formatDate(endDate)); }

				var startDateTime = startDate.format('x');
				var endDateTime = endDate.format('x');

				var currentDate = moment(startDate);
				var currentDateTime = startDate.format('x');
				var position;

				while(currentDateTime <= endDateTime){

					if (currentDateTime == startDateTime) {
						position = 'start'
					} else if (currentDateTime == endDateTime) {
						position = 'end'
					} else {
						position = 'middle'
					}

					callback.apply(null, [moment(currentDate), currentDate.format('YYYY-MM-DD'), dateRange, position]);

					currentDate.add(1, 'day');
					currentDateTime = currentDate.format('x');
				}
			},

			// enable / disable calendars

			disableLeftCalendar: function(){

				var self = this;
				if(self.$leftCalendarContainer && self.$leftCalendarContainer.length){
					self.$leftCalendarContainer.removeClass('disabled').addClass('disabled');
				}
			},

			enableLeftCalendar: function(){

				var self = this;
				if(self.$leftCalendarContainer && self.$leftCalendarContainer.length){
					self.$leftCalendarContainer.removeClass('disabled');
				}
			},

			disableRightCalendar: function(){

				var self = this;
				if(self.$rightCalendarContainer && self.$rightCalendarContainer.length){
					self.$rightCalendarContainer.removeClass('disabled').addClass('disabled');
				}
			},

			enableRightCalendar: function(){

				var self = this;
				if(self.$rightCalendarContainer && self.$rightCalendarContainer.length){
					self.$rightCalendarContainer.removeClass('disabled');
				}
			},

			// config availability data --------------------------------------------------------  /

			_onSetAvailabilityData: function(e, obj){

				var self = this;
				self.setAvailabilityData(obj);
			},

			setAvailabilityData: function(json){

				var self = this;
				self.configureAvailabilityData(json);

				if(self.isEditBlock){

					var dateRange = self.availabilityData.ownerOccupiedDates[self.selectedBlockIndex];
					var startDate = moment(dateRange['startDate']);
					var endDate = moment(dateRange['endDate']);
					
					// set event

					if(!self.multidayArray.length){ self.multidayArray.push({}); }

					self.multidayArray[0].startDate = startDate;
					self.multidayArray[0].endDate = endDate;

					self.isDateSelected = true;

					// if calendars are on-screen, move months

					if(self.leftCalendar && self.rightCalendar){
						self.leftCalendar.setMonth(startDate.format('MM') - 1);
						self.rightCalendar.setMonth(endDate.format('MM') - 1);
					}
				}

				self.enableRightCalendar();
				self.reRenderCalendars();
			},

			configureAvailabilityData: function(availabilityData){

				var self = this;

				if(availabilityData){

					self.occupiedBlocks = [];
					self.availabilityData = self.formatAvailabilityData(availabilityData);

					if(self.availabilityData.myBlockDates){ self.occupiedBlocks = self.occupiedBlocks.concat(self.availabilityData.myBlockDates); }
					if(self.availabilityData.bookedOccupiedDates){ self.occupiedBlocks = self.occupiedBlocks.concat(self.availabilityData.bookedOccupiedDates); }
					if(self.availabilityData.holdOccupiedDates){ self.occupiedBlocks = self.occupiedBlocks.concat(self.availabilityData.holdOccupiedDates); }
					if(self.availabilityData.ownerOccupiedDates){ self.occupiedBlocks = self.occupiedBlocks.concat(self.availabilityData.ownerOccupiedDates); }

					self.occupiedBlocks.sort(
						function(a,b){

							var aStartTime = moment(a.startDate).format('x');
							var aEndTime = moment(a.endDate).format('x');
							var bStartTime = moment(b.startDate).format('x');
							var bEndTime = moment(b.endDate).format('x');

							if(aStartTime > bEndTime){ return 1; }
							if(aEndTime < bStartTime){ return -1; }

							return 0;
						}
					);

					console.log('self.availabilityData',self.availabilityData);

					self.minNights = _.isNumber(self.availabilityData.minNightsStay) ? parseInt(self.availabilityData.minNightsStay) : 1;
					self.maxNights = _.isNumber(self.availabilityData.maxNightsStay) ? parseInt(self.availabilityData.maxNightsStay) : 0;
				}
			},

			formatAvailabilityData: function(availabilityData){

				var self = this;

				if(availabilityData){

					_.each(availabilityData, function(obj){

						if(_.isArray(obj)){
							for(var i = 0; i < obj.length; i++){
								var arrayItem = obj[i];
								for(var prop in arrayItem){
									if(prop === 'startDate' || prop === 'endDate'){
										arrayItem[prop] = self.formatDate(arrayItem[prop]);
									}
								}
							}
						}

					});
				}

				return availabilityData;
			},

			formatDate: function(dateString){

				var self = this;

				if(dateString && _.isString(dateString)){

					var split = dateString.split('-');

					for(var i=0; i<split.length; i++){
						if(split[i].length < 2){ 
							split[i] = '0' + split[i];
						}
					}

					return split.join('-');
				}

				return dateString;
			},

			// calculate next occupied date  ---------------------------------------------------  /

			getNextOccupiedDate: function(dateTime, minDate){

				var self = this;

				if(self.availabilityData){

					minDate = minDate || self.endDate;
					minDate = self.getMinRangeTime(self.availabilityData.myBlockDates, dateTime, minDate);
					minDate = self.getMinRangeTime(self.availabilityData.bookedOccupiedDates, dateTime, minDate);
					minDate = self.getMinRangeTime(self.availabilityData.holdOccupiedDates, dateTime, minDate);
					minDate = self.getMinRangeTime(self.availabilityData.ownerOccupiedDates, dateTime, minDate);

					return minDate;
				}

				return false;
			},

			getMinRangeTime: function(rangeObj, dateTime, minDate){

				var self = this;
				var minDateTime = minDate ? minDate.format('x') : false;

				for(var i=0; i<rangeObj.length; i++){

					var isSelectedBlock = (self.isOwnerListing && rangeObj === self.availabilityData.ownerOccupiedDates && i === self.selectedBlockIndex);

					if(!isSelectedBlock){

						var dateRange = rangeObj[i];
						var startDate = moment(dateRange['startDate']);
						var rangeStartTime = startDate.format('x');

						if(rangeStartTime >= dateTime && (rangeStartTime <= minDateTime || !minDateTime)){
							minDate = startDate;
							minDateTime = dateTime;
						}
					}				
				}

				return minDate;
			},

			getPrevOccupiedDate: function(dateTime, maxDate){

				var self = this;

				if(self.availabilityData){

					maxDate = maxDate || self.startDate;
					maxDate = self.getMinRangeTime(self.availabilityData.myBlockDates, dateTime, maxDate);
					maxDate = self.getMaxRangeTime(self.availabilityData.bookedOccupiedDates, dateTime, maxDate);
					maxDate = self.getMaxRangeTime(self.availabilityData.holdOccupiedDates, dateTime, maxDate);
					maxDate = self.getMaxRangeTime(self.availabilityData.ownerOccupiedDates, dateTime, maxDate);

					return maxDate;
				}

				return false;
			},

			getMaxRangeTime: function(rangeObj, dateTime, maxDate){

				var self = this;
				var maxDateTime = maxDate ? maxDate.format('x') : false;

				for(var i=0; i<rangeObj.length; i++){

					var isSelectedBlock = (self.isOwnerListing && rangeObj === self.availabilityData.ownerOccupiedDates && i === self.selectedBlockIndex);

					if(!isSelectedBlock){

						var dateRange = rangeObj[i];
						var endDate = moment(dateRange['endDate']);
						var rangeEndTime = endDate.format('x');

						if(rangeEndTime < dateTime && (rangeEndTime > maxDateTime || !maxDateTime)){
							maxDate = endDate;
							maxDateTime = dateTime;
						}	
					}			
				}

				return maxDate;
			},

			isAvailableDate: function(dateTime){

				var self = this;
				var isInBlocked = self.isWithinDateRange(self.availabilityData.myBlockDates, dateTime);
				var isInBooked = self.isWithinDateRange(self.availabilityData.bookedOccupiedDates, dateTime);
				var isInHold = self.isWithinDateRange(self.availabilityData.holdOccupiedDates, dateTime);
				var isInOwner = self.isWithinDateRange(self.availabilityData.ownerOccupiedDates, dateTime);

				return !(isInBooked || isInHold || isInOwner || isInBlocked);
			},

			isWithinDateRange: function(rangeObj, dateTime){
				
				var self = this;

				for(var i=0; i<rangeObj.length; i++){

					var dateRange = rangeObj[i];
					var startDateTime = moment(dateRange['startDate']).format('x');
					var endDateTime = moment(dateRange['endDate']).format('x');
					
					if(dateTime > startDateTime && dateTime < endDateTime){
						return true;
					}
				}

				return false;
			},

			getSelectedOwnersBlock: function(){

				var self = this;			
				var blockIndex = self.selectedBlockIndex || 0;

				var selectedOwnersBlock = self.availabilityData.ownerOccupiedDates[blockIndex];
				var startDate = moment(selectedOwnersBlock.startDate);
				var endDate = moment(selectedOwnersBlock.endDate);

				return {startDate:startDate, endDate:endDate};
			},

			getFirstAvailableDate: function(){

				var self = this;
				var startDate = moment(moment().format('YYYY-MM-DD')).add(1, 'days'); // round day down

				for(var i=0; i<self.occupiedBlocks.length; i++){

					var occupiedBlock = self.occupiedBlocks[i];
					var startTime = startDate.format('x');
					var occupiedStartTime = moment(occupiedBlock.startDate).format('x');
					var occupiedEndTime = moment(occupiedBlock.endDate).format('x');
					
					if(occupiedStartTime >= startTime){

						if((occupiedStartTime - startTime) / 86400000 > self.minNights){
							endDate = moment(occupiedBlock.startDate);
							break;
						}
						else {
							startDate = moment(occupiedBlock.endDate);
						}
					}
					else if(startTime <= occupiedEndTime){
						startDate = moment(occupiedBlock.endDate).add(1, 'days');
					}
				}

				return {startDate:startDate, endDate:moment(startDate).add(self.minNights, 'days')};
			},

			// day even listeners

			_onDayOver: function(e){

				var self = this;
				var $target = $(e.currentTarget);

				if(!$target.hasClass('disabled') && !$target.hasClass('inactive') && self.hasDayPrices){
					var toolTipMessage;
					if (self.availabilityData.lang == 'en') {
						toolTipMessage = $target.data('price') ?
								$target.data('price').toLocaleString(self.availabilityData.lang, { style: 'currency', currency: self.availabilityData.currencySymbol, maximumFractionDigits: 2 }).slice(0,-3) : '-'
					} else {
						toolTipMessage = $target.data('price') ?
						$target.data('price').toLocaleString(self.availabilityData.lang, { style: 'currency', currency: self.availabilityData.currencySymbol, maximumFractionDigits: 2 }).slice(0,-5)
						+ $target.data('price').toLocaleString(self.availabilityData.lang, { style: 'currency', currency: self.availabilityData.currencySymbol, maximumFractionDigits: 2 }).slice(-2) : ''
					}

					self.showToolTip($target, toolTipMessage, 'black', 70);
				}
			},

			_onDayOut: function(e){

				var self = this;
				var $target = $(e.currentTarget);

				self.removeToolTip();
			},

			// initial intro

			onShow: function(){

				var self = this;

				if(self.isFirstShow){
					self.animateCalendarEvents();
					self.isFirstShow = false;
				}

				if(self.$clearDatesButton && self.$clearDatesButton[0]){
					TweenMax.set(self.$clearDatesButton.find('h3'), {opacity:0});
				}

				if(self.isDateSelected){ self.completeInstruction(); }
				else { self.resetClearButton(); }

				if(window.innerWidth < 768){
					
					window.requestAnimationFrame(function(){

						self._onWindowResize();
					
						if(!self.options.isEmbedded){ 
							window.$vent.trigger('lockScrollZone', {$target:self.$el});
						}

						self.trigger('showComplete');
					});
				}
				else {

					TweenMax.fromTo(self.$el, 0.5, {opacity:0, y:0}, {opacity:1, y:0, ease:Cubic.easeOut, onComplete:function(){
						self.trigger('showComplete');
					}});
				}
			},	

			onHide: function(){

				var self = this;

				if(!self.isDateSelected){
					self.clearDates();
				}				

				if(window.innerWidth < 768){
					
					if(!self.options.isEmbedded){ 
						window.$vent.trigger('restoreScrolling', {$target:self.$el, hideMobileWrapper:self.options.hideMobileWrapper});
					}

					self.trigger('hideComplete');
				}
				else {

					TweenMax.to(self.$el, 0.3, {opacity:0, y:0, ease:Cubic.easeOut, onComplete:function(){
						self.trigger('hideComplete');
					}});
				}
			},

			_onScroll: function(e){
				
				var self = this;

				if(self.abstractView.isShowing){
					e.preventDefault();
					e.stopPropagation();
					return false;
				}
			},

			// window resizing

			_onWindowResize: function(e){

				var self = this;
				var docHeight = $(document).height();
				self.winWidth = window.innerWidth;
				self.winHeight = window.innerHeight;

				if(self.winWidth < 768 && !self.options.isEmbedded){
					TweenMax.set(self.$el, {'min-height' : Math.max(self.winHeight, self.$el.find('.inner-container').height())});
				}
				else {
					TweenMax.set(self.$el, {clearProps:'min-height'});
				}
			},

			// ---------------------------------------------------------------------------------  /

		});

	return CalendarMenuView;
});
