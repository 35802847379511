
define(
	'modules/property-detail-bar/views/ToolTipView',[
		'backbone',
		'utils/AbstractView',
		'text!../templates/ToolTipTemplate.html',
	], 
	function(Backbone, AbstractView, Template){

		var ToolTipView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			options: null,
			abstractView: null,

			// vars

			color: null,
			width: null,

			// init

			initialize: function(options){

				var self = this;

				// options vars

				self.options = options;
				self.$container = self.options.$container;
				self.color = self.options.color || 'black';
				self.width = self.options.width || 135;

				// template config

				self.template = _.template(Template);
				self.model = $.extend({}, self.model);

				// initialize AbstractView

				self.abstractView = new AbstractView({
					view: self,
					autoShow: self.options.autoShow !== false,
				});
			},

			onRender: function(){

				var self = this;
				self.$el.removeClass(self.color).addClass(self.color);

				TweenMax.set(self.$el, {
					width: self.width,
					marginLeft: Math.round(0 - self.width/2 + 18),
					bottom: 40
				});
			},

			// animate 

			onShow: function(){

				var self = this;

				$(document).off('click', $.proxy(self._onOutClick, self));
				$(document).on('click', $.proxy(self._onOutClick, self));

				TweenMax.killTweensOf(self.$el);
				TweenMax.fromTo(self.$el, 0.2, {opacity:0}, {opacity:1, ease:Quint.easeOut, onComplete:function(){
					self.trigger('showComplete');
				}});				
			},

			_onOutClick: function(e){

				var self = this;

				if(!self.$el[0].contains(e.target)){
					self.exit();
					e.preventDefault();
				}
			},

			onHide: function(){

				var self = this;
				$(document).off('click', $.proxy(self._onOutClick, self));

				TweenMax.killTweensOf(self.$el);
				TweenMax.to(self.$el, 0.2, {opacity:0, ease:Quint.easeOut, onComplete:function(){
					self.trigger('hideComplete');
				}});
			},

		});

	return ToolTipView;
});
