/* 

** ScrollTriggeredElement.js **

Enables floating parallax for an element.


Not AMD. But once declared will work everywhere.

--

Dependencies:

- jQuery
- Utils > DOMUtils
- TweenMax
- AppVars

Todo:

- Create AMD version.

--

Last update 05.10.15 by David Robbins

*/

if(typeof(ScrollTriggeredElement) === 'undefined'){ 

	function ScrollTriggeredElement(el, container, options){

		this.el = el;
		this.$el = $(this.el);

		this.container = container;
		this.$container = $(this.container);

		// default options

		this.defaults = {
			
			onTriggerCallback: null, // useless without this
			onTopTriggerCallback: null, // directional callback
			onBottomTriggerCallback: null, // directional callback

			onResetCallback: null, // when going outside visible zone
			onShowCallback: null, // when on screen
			onHideCallback: null, // when off screen

			triggerFromTop: false, // will fire trigger event when user is scrolling up
			triggerFromBottom: true, // will fire trigger event when user is scrolling down

			hideOffscreen: true,
			hideClass: 'hidden', // hidden, display-none, opacity-0, or something custom

			triggerDelay: 0,
			useScrollController: true, // listen for eased scrolling rather than scroll event
			triggerGutter: 100, // pixels on screen required before activation
			renderGutter: 200, // pixels outside screen to hide/show for performance
			autoInit: true,
			gpuAccelerate: true,
			fps: 10, // update speed
			minWidth: 992,
		};

		// private vars

		this._initialized = false;
		this._interval = null;
		this._delay = null;

		this._useScrollController = false;

		// public vars

		this.options = null;
		this.lastYPos = null;

		this.isEnabled = true;
		this.isTriggered = false;
		this.isPendingTrigger = false;
		this.isInitialConfig = true;
		this.isOut = null;
		this.isTicked = false;

		// run init (or wait for user) ---------------------------------------------------------  /

		this._firstRun = function(){		
			this._fillMissingOptions();
			this.init();
		};

		this._onInitialTimeoutComplete = function(){
			this.isTriggerDelayComplete = true;
			if(this.options.autoInit === true){ this.init(); }
		};

		/* private methods --------------------------------------------------------------------- */

		// scale based on options

		this._config = function(){

			this._adjustedY = this.options.bleed;

			if(this.options.gpuAccelerate === true){
				TweenMax.set(this.$el, {force3D: true});
			}

			this.hide();
			this.isInitialConfig = false;
		};

		// parallaxing

		this._createInterval = function(){

			if(this._interval){ window.clearInterval(this._interval); }
			this._interval = window.setInterval($.proxy(this._onInterval, this), parseInt(1000/this.options.fps));
			this._onInterval();
		};

		this._onInterval = function(){

			this.checkScrollPosition();
		};

		this._killInterval = function(){

			if(this._interval){
				window.clearInterval(this._interval);
				this._interval = null;
			}
		};

		this._onScroll = function(){

			this.checkScrollPosition();
		};

		// 

		this.checkScrollPosition = function(){

			if(!this.isTicked){
				this.isTicked = true;
				window.requestAnimationFrame(this.checkScrollPositionNow.bind(this));
			}
		};

		this.checkScrollPositionNow = function(){

			var scrollPos;

			var winHeight = (typeof(AppVars) !== 'undefined') ? AppVars.winHeight : parseInt(window.innerHeight);
			var winWidth = (typeof(AppVars) !== 'undefined') ? AppVars.winWidth : parseInt(window.innerWidth);

			if(winWidth >= this.options.minWidth){

				if(this.$container){

					if(typeof(AppControllers) !== 'undefined' && this.options.useScrollController){
						scrollPos = AppControllers.scrollController.scrollbarPos;
					} else {
						scrollPos = this.$container.scrollTop();
					}

					//

					var elOffset = this.$el.offset();

					if(elOffset){

						var elPos = {x:elOffset.left, y:elOffset.top - scrollPos};
						var elHeight = this.$el.outerHeight();
						
						var elTop = elPos.y;
						var elBottom = elTop + elHeight;

						var triggerRegionTop = 0 + this.options.triggerGutter;
						var triggerRegionBottom = winHeight - this.options.triggerGutter;

						var renderRegionTop = 0 - this.options.renderGutter;
						var renderRegionBottom = winHeight + this.options.renderGutter;

						var isFirstCheck = false;

						 // provide additional rendering buffer if not animated

						if(this.options.triggerFromTop !== true){ triggerRegionTop = renderRegionTop; }
						if(this.options.triggerFromBottom !== true){ triggerRegionBottom = renderRegionBottom; }

						// make sure lastYPos is set

						if(this.lastYPos === null){ 
							isFirstCheck = true;
							this.lastYPos = elPos.y; 
						}

						// check if on screen at all
						// render gutter

						if(elBottom >= renderRegionTop && elTop <= renderRegionBottom){
								
							if(this.isOut !== false && this.isTriggered && !this.isPendingTrigger){ 

								this.show(); 
							}
						}

						// check for triggers

						if((elBottom >= triggerRegionTop || scrollPos <= triggerRegionTop) && elTop <= triggerRegionBottom){
								
							var triggerDirection;

							if(this.lastYPos > elPos.y){ triggerDirection = 'bottom'; }
							else if(this.lastYPos < elPos.y){ triggerDirection = 'top'; }
							else { triggerDirection = 'static'; }

							if(!this.isPendingTrigger && !this.isTriggered){
								this.triggerIn(triggerDirection);
							}
						}
						else if(elTop >= renderRegionBottom || elBottom <= renderRegionTop) {

							if(this.isOut !== true){ this.hide(); }

							if(this.options.hideOffscreen){

								var outDirection;

								if(elBottom <= renderRegionTop){ outDirection = 'top'; }
								else if(elTop >= renderRegionBottom){ outDirection = 'bottom'; }

								if(outDirection && (this.isTriggered || this.isPendingTrigger)){
									this.triggerOut(outDirection);
								}
							}
						}
						
						this.lastYPos = elPos.y;
					}
				}
			
			} else {
				this.show();
			}

			this.isTicked = false;
		};

		// show / hide -------------------------------------------------------------------------  /

		this.show = function(){
			
			this.isOut = false;

			if(this.$el){

				if(this.options.onShowCallback){
					this.options.onShowCallback.apply(this, [{target:this, $el:this.$el}]);
				}

				if(this.options.hideClass){
					this.$el.removeClass(this.options.hideClass);
				}
			}
			else {
				console.log("ScrollTriggerElement Warning: $el not defined.", this);
			}
		};

		this.hide = function(){

			this.isOut = true;
			this.isPendingTrigger = false;

			if(this.options.onHideCallback && !this.isInitialConfig){
				this.options.onHideCallback.apply(this, [{target:this, $el:this.$el}]);
			}

			if(this.options.hideClass){
				this.$el.removeClass(this.options.hideClass).addClass(this.options.hideClass);
			}
		};

		this.triggerIn = function(direction){

			var self = this;

			this.isPendingTrigger = true;

			if(this._delay){ this._delay.kill(); }
			this._delay = TweenMax.delayedCall(this.options.triggerDelay, function(){

				self.isTriggered = true;
				self.isPendingTrigger = false;

				var validTriggerDirection = true;

				if(!self.options.triggerFromTop && direction == 'top'){ validTriggerDirection = false; }
				if(!self.options.triggerFromBottom && direction == 'bottom'){ validTriggerDirection = false; }

				if(self.options.onTriggerCallback && validTriggerDirection){
					self.options.onTriggerCallback.apply(self, [{target:self, $el:self.$el, triggerDirection:direction}]);
				}

				if((direction == 'bottom' || direction == 'static') && self.options.onBottomTriggerCallback && validTriggerDirection){
					self.options.onBottomTriggerCallback.apply(self, [{target:self, $el:self.$el, triggerDirection:direction}]);
				}

				if(direction == 'top' && self.options.onTopTriggerCallback && validTriggerDirection){
					self.options.onTopTriggerCallback.apply(self, [{target:self, $el:self.$el, triggerDirection:direction}]);
				}

				if(self.isOut){ self.show(); }

				self._delay = null;
			});

			if(!this.options.hideOffscreen){
				this._killInterval();
			}
		};

		this.triggerOut = function(direction){

			var self = this;
				
			this.isTriggered = false;
			this.isPendingTrigger = false;

			if(this._delay){ this._delay.kill(); }
			this._delay = TweenMax.delayedCall(this.options.triggerDelay, function(){
				
				if(self.options.onResetCallback){
					self.options.onResetCallback.apply(this, [{target:self, $el:self.$el, outDirection:direction}]);
				}

				self._delay = null;
			});
		};

		// fill any missing options with defaults ----------------------------------------------  /

		this._fillMissingOptions = function(){

			var modifiedOptions = $.extend({}, options);

			for(var key in modifiedOptions){
				var val = (modifiedOptions[key] === 'undefined') ? null : modifiedOptions[key];
				if(val == undefined || val == null){ modifiedOptions[key] = this.defaults[key]; }
			}

			this.options = $.extend({}, this.defaults, modifiedOptions);

			return modifiedOptions;
		};

		// manual trigger delay complete

		this.setTriggerDelayComplete = function(){
			this.isTriggerDelayComplete = true;
		};

		/* public methods ---------------------------------------------------------------------- */

		this.init = function(){
			
			var self = this;

			if(!this._initialized){				
				
				this._initialized = true;
				this._config();

				if(typeof(AppControllers) !== 'undefined' && this.options.useScrollController){
					this._useScrollController = true; 
					this._createInterval();
				} 
				else { 
					this._useScrollController = false; 
					this.$container.on('scroll', $.proxy(this._onScroll, this));
				}

				requestAnimationFrame(function(){
					self.checkScrollPosition();
				});
			}
		};

		this.disable = function(forced){

			if(this.isEnabled || forced){
				
				this.isEnabled = false;
				this._killInterval();

				this.$container.off('scroll', $.proxy(this._onScroll, this));
			}
		};

		this.enable = function(){

			if(!this.isEnabled){
				
				this.isEnabled = true;
				this._createInterval();

				this.$container.off('scroll', $.proxy(this._onScroll, this));
				this.$container.on('scroll', $.proxy(this._onScroll, this));
			}
		};

		this.update = function(){
			this.checkScrollPosition();
		};

		// destroy component, clear all listeners ----------------------------------------------  /

		this.destroy = function(){
			
			this.disable(true);

			this.$el = null;
			this.$container = null;

			this.options.onTriggerCallback = null;
			this.options.onResetCallback = null;
		};

		// firstRun -- stay on bottom ----------------------------------------------------------  /

		this._firstRun();

		// -------------------------------------------------------------------------------------  /
	}
}
;
define("utils/components/app/ScrollTriggeredElement", function(){});

