
define(
	'modules/global-scripted-buttons/views/HideDetailsButtonView',[
		'backbone',
		'utils/AbstractView',
		'utils/AbstractButtonView',
	], 
	function(Backbone, AbstractView, AbstractButtonView){

		var HideDetailsButtonView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			options: null,
			abstractView: null,
			button: null,

			// vars

			$targetContainer: null,

			isWaitingForDeselect: false,

			// init

			initialize: function(options){

				var self = this;

				self.options = options;
				self.$container = self.options.$container;

				// 

				self.abstractView = new AbstractView({
					view: self,
					autoShow: false
				});

				self.button = new AbstractButtonView({
					abstractView: self.abstractView,
					animateInteractionsWhenSelected: true,
					selectable: true,
				});

				self.abstractView.show();
			},

			// render

			onRender: function(){

				var self = this;
			},

			//

			onShow: function(){

				var self = this;

				if(self.$el.data('container-id')){
					self.$targetContainer = $('#' + self.$el.data('container-id'));
				}
				else {
					self.$targetContainer = self.$el.parent();
				}

				if(self.$el.hasClass('selected')){
					self.select();
				}

				self.trigger('showComplete');
			},

			//

			onAnimateSelect: function(e){

				var self = this;
				var $showState = self.$el.find('.show-state');
				var $hideState = self.$el.find('.hide-state');

				$showState.removeClass('hide').addClass('hide');
				$hideState.removeClass('hide');
			
				self.$targetContainer.find('.collapsible-content').removeClass('hide').addClass('hide');

			},

			onAnimateDeselect: function(e){

				var self = this;
				var $showState = self.$el.find('.show-state');
				var $hideState = self.$el.find('.hide-state');

				$showState.removeClass('hide');
				$hideState.removeClass('hide').addClass('hide');

				self.$targetContainer.find('.collapsible-content').removeClass('hide');

			},

			// ---------------------------------------------------------------------------------  /

		});

	return HideDetailsButtonView;
});
