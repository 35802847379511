
define(
	'modules/global-modals/views/AirportTransferModalView',[
		'backbone',
		'utils/AbstractView',
	], 
	function(Backbone, AbstractView){

		var AirportTransferModalView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			$innerContainer: null,

			options: null,
			abstractView: null,

			// vars

			$nowButton: null,
			$laterButton: null,

			initialScrollTop: 0,

			// init

			initialize: function(options){

				var self = this;

				self.options = options;
				self.$container = self.options.$container;
				self.el = self.options.el;

				self.template = _.template(self.options.template);
				self.model = $.extend({
					
				}, self.model);

				// 

				self.abstractView = new AbstractView({
					view: self,
					autoShow: self.options.autoShow === true,
				});
			},

			onRender: function(){

				var self = this;
				
				self.$innerContainer = self.$el.find('.inner-container');
				self.$nowButton = self.$el.find('.options-buttons-wrapper .now');
				self.$laterButton = self.$el.find('.options-buttons-wrapper .later');

				self.initialScrollTop = $(window).scrollTop();

				window.$rootContext(self.$el.find('select')).selectBoxIt();
			},

			onAddListeners: function(){

				var self = this;				
				self.$el.on('click', $.proxy(self._onElClick, self));

				self.$el.find('.close-modal').on('click', $.proxy(self._onCloseClick, self));
				self.$el.find('.btn-close').on('click', $.proxy(self._onCloseClick, self));

				self.$nowButton.on('click', $.proxy(self._onNowClick, self));
				self.$laterButton.on('click', $.proxy(self._onLaterClick, self));
			},

			// listener methods

			_onElClick: function(e){

				var self = this;

				if(!self.$innerContainer[0].contains(e.target)){
					self.closeModal();
					e.preventDefault();
				}
			},

			_onCloseClick: function(e){

				var self = this;
				self.closeModal();

				e.preventDefault();
			},

			_onNowClick: function(e){

				var self = this;
				var winHeight = window.innerHeight;

				self.$el.find('.details').removeClass('hide');

				window.$vent.trigger('domUpdate');
				window.$vent.trigger('seekToPosition', {scrollY:winHeight});
			},

			_onLaterClick: function(e){

				var self = this;

				self.$el.find('.details').removeClass('hide').addClass('hide');

				window.$vent.trigger('domUpdate');
				window.$vent.trigger('seekToPosition', {scrollY:0});
			},

			// close modal

			closeModal: function(){

				var self = this;
				self.trigger('closeClick');
			},

			// show / hide

			onShow: function(){

				var self = this;
				
				window.$vent.trigger('seekToPosition', {scrollY:0});

				TweenMax.fromTo(self.$innerContainer, 0.6, {y:10, opacity:0}, {y:0, opacity:1, delay:0.12, ease:Cubic.easeOut, onComplete:function(){
					self.trigger('showComplete');
				}});
			},

			// window resize

			onWindowResize: function(){

				var self = this;
				var modalHeight = self.$innerContainer.outerHeight() + 40;
				var winHeight = window.innerHeight;
				var modalContainerHeight = Math.max(winHeight, modalHeight);

				TweenMax.set(self.$el.find('.options'), {height:winHeight});

				if(modalHeight > winHeight){ 

					TweenMax.set(self.$el, {clearProps:'position'}); 

					TweenMax.set(self.$innerContainer, {
						top: Math.round((modalContainerHeight - modalHeight)/2) + self.initialScrollTop,
					});
				}
				else { 
					TweenMax.set(self.$el, {position:'fixed'}); 

					TweenMax.set(self.$innerContainer, {
						top: 0 //Math.round((modalContainerHeight - modalHeight)/2),
					});
				}
			},

		});

	return AirportTransferModalView;
});
