
define(
	'modules/promo/views/PromoView',[
		'backbone',
		'utils/AbstractView',
	], 
	function(Backbone, AbstractView){

		var MultiFormView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			options: null,
			abstractView: null,

			// vars

			$inactive: null,
			$input: null,
			$applied: null,

			// init

			initialize: function(options){

				var self = this;

				// options vars

				self.options = options;
				self.$container = self.options.$container;
				self.$context = self.options.$context;

				// template config

				//self.template = _.template(Template);
				self.model = $.extend({}, self.model);

				// initialize AbstractView

				self.abstractView = new AbstractView({
					view: self,
					autoShow: true,
				});
			},

			onDomReady: function(){

				var self = this;

				self.$inactive = self.$el.find('.promo-inactive');
				self.$input = self.$el.find('.promo-input');
				self.$applied = self.$el.find('.promo-applied');

				if(self.$el.hasClass('applied')){ self.showApplied(); }
				else { self.showInactive(); }
			},

			onAddListeners: function(){

				var self = this;
				
				self.$inactive.on('click', $.proxy(self._onInactiveClick, self));
				self.$input.find('.apply').on('click', $.proxy(self._onInputApplyClick, self));
				self.$input.find('.close').on('click', $.proxy(self._onInputCloseClick, self));
				self.$applied.find('.close').on('click', $.proxy(self._onAppliedCloseClick, self));
			},

			// listener methods

			_onInactiveClick: function(){

				var self = this;
				self.showInput();
			},

			_onInputApplyClick: function(){

				var self = this;
				self.validatePromoCode();
			},

			_onInputCloseClick: function(){

				var self = this;
				self.showInactive();
			},

			_onAppliedCloseClick: function(){

				var self = this;
				self.showInactive();
			},

			//

			promoQuery: function(){

				var self = this;
				var dfd = $.Deferred();
				var queryURL = self.$el.data('promo-query-url');

				$.ajax({

					type: 'GET',
					url: queryURL,
					async: false,
					jsonpCallback: 'callBack',
					contentType: 'application/json',
					dataType: 'jsonp',

					success: function(json) {
						dfd.resolve(json);
					},
					error: function(e) {
						console.log('JSON Load Error', self);
						console.log(e.message);
						dfd.reject();
					}
				});

				return dfd.promise();
			},

			//

			showInactive: function(){

				var self = this;

				self.$inactive.removeClass('hide');
				self.$input.removeClass('hide').addClass('hide');
				self.$applied.removeClass('hide').addClass('hide');

				window.$vent.trigger('domUpdate');
			},

			showInput: function(){

				var self = this;

				self.$inactive.removeClass('hide').addClass('hide');
				self.$input.removeClass('hide');
				self.$applied.removeClass('hide').addClass('hide');

				self.$input.removeClass('error');
				self.$input.find('.apply .message-apply').removeClass('hide');
				self.$input.find('.apply .message-error').removeClass('hide').addClass('hide');

				window.$vent.trigger('domUpdate');
			},

			showError: function(){

				var self = this;

				self.$inactive.removeClass('hide').addClass('hide');
				self.$input.removeClass('hide');
				self.$applied.removeClass('hide').addClass('hide');

				self.$input.removeClass('error').addClass('error');
				self.$input.find('.apply .message-apply').removeClass('hide').addClass('hide');
				self.$input.find('.apply .message-error').removeClass('hide');

				window.$vent.trigger('domUpdate');
			},

			showApplied: function(){

				var self = this;

				self.$inactive.removeClass('hide').addClass('hide');
				self.$input.removeClass('hide').addClass('hide');
				self.$applied.removeClass('hide');

				window.$vent.trigger('domUpdate');
			},

			validatePromoCode: function(){

				var self = this;

				$.when($.proxy(self.promoQuery, self)()).then(function(e){
					if(e.valid){ self.showApplied(); }
					else { self.showError(); }
				});
			},

		});

	return MultiFormView;
});
