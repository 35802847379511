define(
	'modules/featured-properties/app',[
		'backbone',
		'modules/featured-properties/views/FeaturedPropertyView',
	], 
	function(Backbone, FeaturedPropertyView){

		var AppView = Backbone.View.extend({

			// core vars

			$featuredPropertiesContainer: null,

			featuredProperties: null,
			tweenDelay: null,

			// init

			initialize: function(options){

				var self = this;
				window.$vent.trigger('featuredPropertiesReady');
			},

			// start module --------------------------------------------------------------------  /

			start: function(){

				var self = this;
				
				$(function(){
					self.onDomReady();
				});
			},

			onDomReady: function(){

				var self = this;
				self.$featuredPropertiesContainer = $('.featured-properties');
				self.featuredProperties = [];

				_.each(self.$featuredPropertiesContainer, function(featuredProperty, i){
					
					var $featuredPropertiesContainer = $(featuredProperty);
					var $featuredProperties = $featuredPropertiesContainer.find('.featured-property-container');

					for(var i=0; i<$featuredProperties.length; i++){

						var $featuredProperty = $($featuredProperties[i]);
						var featuredPropertyView = new FeaturedPropertyView({
							el: $featuredProperty,
							$container: self.$featuredPropertiesContainer
						});

						featuredPropertyView.$el.on('mouseenter', $.proxy(self._onMouseEnter, self));
						featuredPropertyView.$el.on('mouseleave', $.proxy(self._onMouseLeave, self));

						self.featuredProperties.push(featuredPropertyView);
					}
				});	

				self.$featuredProperties = self.$featuredPropertiesContainer.find('li');

				window.$vent.trigger('domUpdate', {view:self, type:'featured-properties'});
			},

			_onMouseEnter: function(e){

				var self = this;
				var $target = $(e.currentTarget);

				if(!$target.hasClass('over')){

					var $featuredPropertyContainer = $target.parent();
					var $featuredProperties = $featuredPropertyContainer.find('li.featured-property-container');					
					var $lastTarget = $featuredPropertyContainer.find('.over');

					$lastTarget.removeClass('over');
					$target.addClass('over');

					_.each($featuredProperties, function(featuredProperty, i){						
						
						var $featuredProperty = $(featuredProperty);

						if(!$featuredProperty.hasClass('over') && window.innerWidth >= 768){

							var $veil = $featuredProperty.find('.image-veil');
							
							TweenMax.set($veil, {opacity:1});
							if(self.tweenDelay){ self.tweenDelay.kill(); }
							
							$veil.removeClass('hidden');
						}
					});
				}
			},

			_onMouseLeave: function(e){

				var self = this;
				var $target = $(e.currentTarget);
				
				if($target.hasClass('over')){

					var $featuredPropertyContainer = $target.parent();
					var $featuredProperties = $featuredPropertyContainer.find('li.featured-property-container');
					
					$target.removeClass('over');

					_.each($featuredProperties, function(featuredProperty, i){			
						
						var $featuredProperty = $(featuredProperty);

						if(!$featuredProperty.hasClass('over')){

							var $veil = $featuredProperty.find('.image-veil');
						
							TweenMax.set($veil, {opacity:0});
							
							if(self.tweenDelay){ self.tweenDelay.kill(); }
							self.tweenDelay = TweenMax.delayedCall(0.6, function(){
								$veil.removeClass('hidden').addClass('hidden');
							});
						}
					});
				}
			},
			
			// ---------------------------------------------------------------------------------  /

		});

		return AppView;
	}
);
