define(
	'modules/global-modals/views/CreateABlockModalView',[
		'backbone',
		'utils/AbstractView',
	], 
	function(Backbone, AbstractView){

		var CreateABlockModalView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			$innerContainer: null,

			options: null,
			abstractView: null,

			// vars

			initialScrollTop: 0,
			checkInDate: null,
			checkOutDate: null,

			isMonthTick: false,

			// init

			initialize: function(options){

				var self = this;

				self.options = options;
				self.$container = self.options.$container;
				self.el = self.options.el;

				self.template = _.template(self.options.template);
				self.model = $.extend({

				}, self.model);

				console.log('CreateABlockModalView', self.model);

				// 

				self.abstractView = new AbstractView({
					view: self,
					autoShow: self.options.autoShow === true,
				});
			},

			onRender: function(){

				var self = this;
				self.$innerContainer = self.$el.find('.inner-container');
				self.initialScrollTop = $(window).scrollTop();

				window.$rootContext(self.$el.find('select')).selectBoxIt();
				self._onWindowResize();
			},

			onAddListeners: function(){

				var self = this;
				
				window.$vent.on('calendarMonthClick', $.proxy(self._onCalendarMonthClick, self));
				window.$vent.on('calendarQuery', $.proxy(self._onCalendarQuery, self));
				window.$vent.on('calendarClearDates', $.proxy(self._onClearDates, self));
				window.$vent.trigger('requestCalendarQuery', {$container:self.$el});

				self.$el.on('click', $.proxy(self._onElClick, self));
				self.$el.find('.close-modal').on('click', $.proxy(self._onCloseClick, self));
				self.$el.find('.btn-close').on('click', $.proxy(self._onCloseClick, self));

				$(window).on('resize', $.proxy(self._onWindowResize, self));	
				window.$vent.on('domUpdate', $.proxy(self._onDomUpdate, self));	
				
				window.requestAnimationFrame(function(){
					self._onWindowResize();
				});
			},

			// listener methods

			_onElClick: function(e){

				var self = this;

				if(!self.$innerContainer[0].contains(e.target) && !self.isMonthTick){
					self.closeModal();
					e.preventDefault();
				}
			},

			_onCalendarMonthClick: function(){

				var self = this;
				self.isMonthTick = true;

				window.requestAnimationFrame(function(){
					self.isMonthTick = false;
				});
			},

			_onCalendarQuery: function(e, obj){

				var self = this;

				console.log('_onCalendarQuery', e, obj);

				if(obj && (self.checkInDate != obj.checkInQueryLong || self.checkOutDate != obj.checkOutQueryLong)){

					var isFirst = (!self.checkInDate || !self.checkOutDate);

					self.checkInDate = obj.checkInQueryLong;
					self.checkOutDate = obj.checkOutQueryLong;

					if(isFirst){ 
						self.showNewSelectedDate();
						self.showNoteAndSubmit();
					}
					else { self.changeSelectedDate(); }
				}
			},

			_onClearDates: function(){

				var self = this;
				var $selectionSuccess = self.$el.find('.selection-success');
				$selectionSuccess.removeClass('hide').addClass('hide');				
			},

			_onCloseClick: function(e){

				var self = this;
				self.closeModal();

				e.preventDefault();
			},

			// 

			changeSelectedDate: function(){

				var self = this;
				var $selectionParagraph = self.$el.find('.selection-paragraph');

				TweenMax.killTweensOf($selectionParagraph);
				TweenMax.to($selectionParagraph, 0.3, {opacity:0, ease:Cubic.easeOut, onComplete:function(){
					self.showNewSelectedDate();
				}});
			},

			showNewSelectedDate: function(){

				var self = this;
				var $selectionParagraph = self.$el.find('.selection-paragraph');

				$selectionParagraph.find('.date').html(self.checkInDate + ' - ' + self.checkOutDate);

				TweenMax.killTweensOf($selectionParagraph);
				TweenMax.fromTo($selectionParagraph, 0.4, {opacity:0}, {opacity:1, ease:Cubic.easeInOut});
				$selectionParagraph.removeClass('hidden');

				window.requestAnimationFrame(function(){
					window.$vent.trigger('domUpdate');
				});
			},

			showNoteAndSubmit: function(){

				var self = this;
				var $selectionSuccess = self.$el.find('.selection-success');
				var $noteAndSubmit = self.$el.find('.note-and-submit');
				$selectionSuccess.removeClass('hide');

				TweenMax.killTweensOf($noteAndSubmit);
				TweenMax.fromTo($noteAndSubmit, 0.4, {opacity:0}, {opacity:1, ease:Cubic.easeInOut});
				$noteAndSubmit.removeClass('hidden');

				window.requestAnimationFrame(function(){
					window.$vent.trigger('domUpdate');
				});
			},

			// close modal

			closeModal: function(){

				var self = this;
				self.trigger('closeClick');
			},

			// show / hide

			onShow: function(){

				var self = this;
				
				if(window.innerWidth < 768){

					self.trigger('showComplete');
					window.$vent.trigger('lockScrollZone', {$target:self.$el});
				}
				else {

					TweenMax.fromTo(self.$innerContainer, 0.6, {y:10, opacity:0}, {y:0, opacity:1, delay:0.12, ease:Cubic.easeOut, onComplete:function(){
						self.trigger('showComplete');
					}});
				}

				var $closeButton = self.$el.find('.close-modal');
				TweenMax.fromTo($closeButton, 0.6, {opacity:0}, {opacity:1, delay:0.4, ease:Cubic.easeOut});
			},

			onHide: function(){

				var self = this;

				if(window.innerWidth < 768){

					window.$vent.trigger('restoreScrolling', {$target:self.$el});
					self.trigger('hideComplete');
				}
				else {

					TweenMax.to(self.$el, 0.4, {opacity:0, ease:Cubic.easeOut, onComplete:function(){
						TweenMax.set($('body'), {'clearProps' : 'min-height'});
						self.trigger('hideComplete');
					}});
				}
			},

			// window resize

			_onDomUpdate: function(){

				var self = this;
				self.isDomUpdate = true;

				TweenMax.set(self.$innerContainer, {clearProps: 'min-height'});

				window.requestAnimationFrame(function(){
					self.isDomUpdate = false;
					self._onWindowResize();
				});
			},

			// window resize

			_onWindowResize: function(){

				var self = this;
				var modalHeight = self.$innerContainer.outerHeight();
				var winWidth = window.innerWidth;
				var winHeight = window.innerHeight;
				var modalContainerHeight = Math.max(winHeight, modalHeight);
				var minHeight = 'initial';
				
				if(self.$el.hasClass('mobile-only') && winWidth >= 768){
					self.closeModal();
				}

				if(!self.isDomUpdate){

					if(self.isLongModal || self.$el.hasClass('full-height')){
						modalHeight = Math.max(modalHeight, winHeight);
						minHeight = modalHeight + 'px';
					}

					if(winWidth < 768 && self.$el.hasClass('full-height-mobile')){
						modalHeight = Math.max(modalHeight, winHeight);
						minHeight = modalHeight + 'px';
					}

					TweenMax.set(self.$innerContainer, {'min-height': minHeight});

					window.requestAnimationFrame(function(){
						
						var topY = 0;

						modalHeight = self.$innerContainer.outerHeight() + 60;
						if(minHeight != 'initial'){ modalHeight = Math.max(modalHeight, winHeight); }

						TweenMax.set(self.$el, {'min-height': modalHeight});

						if(winWidth < 768){}
						else {

							if(modalHeight > winHeight){ 

								TweenMax.set(self.$el, {clearProps:'position'}); 

								topY = Math.round((modalContainerHeight - modalHeight)/2) + self.initialScrollTop;
								TweenMax.set(self.$el, {top:topY});
							}
							else { 

								topY = Math.round((winHeight - modalHeight)/2) + self.initialScrollTop;
								TweenMax.set(self.$el, {top: topY});
							}

							var minScrollHeight = parseInt(minHeight) + topY;

							if($('body').height() < parseInt(minScrollHeight)){
								TweenMax.set($('body'), {'min-height':minScrollHeight + topY});
							}
						}

						window.$vent.trigger('updateScrollZone');
					});
				}
			},

		});

	return CreateABlockModalView;
});
