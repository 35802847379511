define(
	'modules/property-filters/views/TagItemView',[
		'backbone',
		'utils/AbstractView',
		'text!../templates/TagItemTemplate.html',
	], 
	function(Backbone, AbstractView, Template){

		var TagItemView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			options: null,
			abstractView: null,

			// config

			autoShow: true,

			// options

			type: null,
			id: null,

			// flags

			isShowing: false,
			isListening: false,

			// vars

			$closeButton: null,
			clickClosed: false,

			// init

			initialize: function(options){

				var self = this;

				self.options = options;
				self.$container = self.options.$container;
				self.type = self.options.type;
				self.id = self.options.id;

				self.template = _.template(Template);
				self.model = $.extend({

				}, self.model);

				// 

				self.abstractView = new AbstractView({
					view: self,
					autoShow: true
				});
			},

			onDomReady: function(){

				var self = this;
				var $closeButton = self.$el.find('button.close-button');
				$closeButton.on('click', $.proxy(self.onCloseClick, self));
			},

			onCloseClick: function(e){

				var self = this;
				self.clickClosed = true;
				self.exit();
			},

		});

	return TagItemView;
});
