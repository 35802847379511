define(
	'modules/detail-carousel/views/DetailCarouselView',[
		'backbone',
		'utils/AbstractView',

		'modules/detail-carousel/views/CarouselItemView',
		'modules/detail-carousel/views/RadioButtonView',
	], 
	function(Backbone, AbstractView, CarouselItemView, RadioButtonView){

		var AppView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			options: null,
			abstractView: null,

			// vars

			$radioButtonsContainer: null,
			$carouselItemsContainer: null,
			$carouselImagesContainer: null,

			carouselItems: null,
			carouselImages: null,
			radioButtons: null,

			winWidth: null,
			winHeight: null,
			containerWidth: null,
			containerHeight: null,

			currentIndex: -1,
			currentCarouselItem: null,
			$currentCarouselImage: null,

			$textContent: null,
			$textBg: null,

			imageRatio: null,
			totalItems: null,

			autoChangeTimeout: null,

			isOnScreen: false,

			// init

			initialize: function(options){

				var self = this;

				self.options = options;
				self.$container = self.options.$container;
				self.el = self.options.el;

				// ready abstractView connection 

				self.abstractView = new AbstractView({
					view: self,
					autoShow: false
				});

				//

				var imageWidth = self.$container.data('image-width') || 1420;
				var imageHeight = self.$container.data('image-height') || 500;

				self.imageRatio = imageWidth/imageHeight;

				//

				self.show();
			},

			onRender: function(){

				var self = this;

				self.carouselItems = [];
				self.carouselImages = [];
				self.radioButtons = [];

				self.$carouselItemsContainer = self.$el.find('.carousel-items-container');
				self.$carouselImagesContainer = self.$el.find('.carousel-images-container');
				self.$radioButtonsContainer = self.$el.find('.radio-buttons-container');

				self.$carouselItems = self.$carouselItemsContainer.find('li');
				self.$carouselImages = self.$carouselImagesContainer.find('li');
				self.$radioButtons = self.$radioButtonsContainer.find('li');

				self.totalItems = self.$carouselItems.length;

				for(var i=0; i<self.totalItems; i++){

					var itemModel = {
						index: i,
					};

					var carouselItem = new CarouselItemView({						
						el: self.$carouselItems[i],
						model: itemModel,		
						index: i
					});

					self.carouselItems.push(carouselItem);

					// image

					var $image = $(self.$carouselImages[i]);
					self.carouselImages.push($image);

					TweenMax.set($image, {force3D:true});

					// radio buttons

					var radioButton = new RadioButtonView({					
						el: self.$radioButtons[i],
						model: itemModel,
						index: i
					});

					self.radioButtons.push(radioButton);
				}

				self.$textContent = self.$carouselItemsContainer.find('.text-content');
				self.$textBg = self.$el.find('.carousel-text-bg');

				self.resetTimeout();
			},

			// 

			onShow: function(){

				var self = this;
				self.onWindowResize();

				TweenMax.to(self.$el, 0.3, {opacity:1, ease:Cubic.easeOut});

				self.seekByIndex(0);
				self.trigger('showComplete');
			},

			// listeners

			onAddListeners: function(){

				var self = this;

				_.each(self.radioButtons, function(radioButton, i){
					radioButton.on('click', self._onRadioButtonClick, self);
				});

				var $leftArrow = self.$el.find('.arrow-buttons-container .arrow-button.left');
				var $rightArrow = self.$el.find('.arrow-buttons-container .arrow-button.right');

				$leftArrow.on('click', $.proxy(self._onArrowClick, self));
				$rightArrow.on('click', $.proxy(self._onArrowClick, self));

				self.$el.find('.radio-button').on('mouseenter', $.proxy(self._onMouseEnter, self));
				self.$el.find('.radio-button').on('mouseleave', $.proxy(self._onMouseLeave, self));
			},

			// listener events

			_onRadioButtonClick: function(e){

				var self = this;
				self.seekByIndex(e.target.index);
			},

			_onArrowClick: function(e){

				var self = this;
				var $target = $(e.currentTarget);

				if($target.hasClass('left')){ self.prevItem(); }
				else { self.nextItem(); }
			},

			_onMouseEnter: function(){

				var self = this;
				self.clearTimeout();
			},

			_onMouseLeave: function(){

				var self = this;
				self.resetTimeout();
			},

			// carousel controls

			nextItem: function(){

				var self = this;
				var newIndex = self.currentIndex+1;

				self.seekByIndex(newIndex);
			},

			prevItem: function(){

				var self = this;
				var newIndex = self.currentIndex-1;

				self.seekByIndex(newIndex);
			},

			seekByIndex: function(index){

				var self = this;

				if(self.currentIndex != index){
					self.changeActiveItem(index);
				}
			},

			//

			resetTimeout: function(){

				var self = this;
				self.clearTimeout();
				self.autoChangeTimeout = window.setTimeout(self._onAutoChangeTimeout.bind(self), 4000);
			},

			clearTimeout: function(){

				var self = this;

				if(self.autoChangeTimeout){ window.clearTimeout(self.autoChangeTimeout); }
			},

			_onAutoChangeTimeout: function(){
				
				var self = this;
				self.resetTimeout();
				
				var winHeight = window.innerHeight;
				var scrollTop = $(window).scrollTop();
				var offset = self.$el.offset();
				offset.top -= scrollTop;
				offset.bottom = offset.top + self.$el.outerHeight();

				if(offset.top < winHeight - winHeight * 0.25 && offset.bottom > winHeight * 0.25){
					self.isOnScreen = true;
				}
				else {
					self.isOnScreen = false;
				}

				if(self.isOnScreen){
					self.nextItem();
				}
			},

			//

			changeActiveItem: function(newIndex){

				var self = this;
				var lastIndex = self.currentIndex;
				var lastCarouselItem = self.currentCarouselItem;
				var $lastCarouselImage = self.$currentCarouselImage;

				if(newIndex >= self.totalItems){ newIndex = 0; }
				else if(newIndex < 0){ newIndex = self.totalItems-1; }

				var direction = (newIndex > self.currentIndex) ? 1 : -1;

				self.currentIndex = newIndex;
				self.currentCarouselItem = self.carouselItems[newIndex];
				self.$currentCarouselImage = self.carouselImages[newIndex];

				if(lastIndex >= 0){ self.radioButtons[lastIndex].deselect(); }
				self.radioButtons[self.currentIndex].select();

				if(lastCarouselItem){

					var $curEl = self.currentCarouselItem.$el;
					var $lastEl = lastCarouselItem.$el;

					var $curText = $curEl.find('.text-content');				
					var $lastText = $lastEl.find('.text-content');

					var $quoteMark = $curText.find('.quote-p');
					var $quoteQuote = $curText.find('.quote-text');
					var $quoteAuthor = $curText.find('.quote-name');
					var $quoteDetails = $curText.find('.quote-desc');

					TweenMax.to($lastText, 0.5, {opacity:0, ease:Cubic.easeOut});

					TweenMax.to($lastCarouselImage, 0.4, {x:0-self.containerWidth * direction, force3D:true, ease:Sine.easeIn});
					TweenMax.to($lastCarouselImage, 0.6, {x:0-self.containerWidth * direction, delay:0.2, ease:Cubic.easeOut, onComplete:function(){
						$lastCarouselImage.removeClass('hidden').addClass('hidden');
						lastCarouselItem.hide();
					}});

					var d = 0.3;

					TweenMax.fromTo($curText, 0.5, {opacity:0}, {opacity:1, ease:Cubic.easeOut, delay:d + 0.0});
					TweenMax.fromTo($quoteMark, 0.5, {opacity:0, y:10}, {opacity:1, y:0, ease:Cubic.easeOut, delay:d + 0.1});
					TweenMax.fromTo($quoteQuote, 0.6, {opacity:0, y:10}, {opacity:1, y:0, ease:Cubic.easeOut, delay:d + 0.2});
					TweenMax.fromTo($quoteAuthor, 0.5, {opacity:0, y:8}, {opacity:1, y:0, ease:Cubic.easeOut, delay:d + 0.32});
					TweenMax.fromTo($quoteDetails, 0.5, {opacity:0, y:8}, {opacity:1, y:0, ease:Cubic.easeOut, delay:d + 0.36});
					
					TweenMax.fromTo(self.$currentCarouselImage, 0.4, {x:self.containerWidth * direction, force3D:true}, {x:0, ease:Sine.easeIn});
					TweenMax.to(self.$currentCarouselImage, 0.6, {x:0, delay:0.2, ease:Cubic.easeOut});
					
					self.$currentCarouselImage.removeClass('hidden');
					self.currentCarouselItem.show();
				}
				else {
					self.$currentCarouselImage.removeClass('hidden');
					self.currentCarouselItem.show();
				}

				self.onWindowResize();
			},

			// window resizing

			onWindowResize: function(){

				var self = this;

				self.winWidth = window.innerWidth;
				self.winHeight = window.innerHeight;

				self.containerWidth = self.$el.outerWidth();
				self.containerHeight = self.$el.outerHeight();

				var imageHeight = Math.round(self.containerWidth / self.imageRatio);

				TweenMax.set(self.$el, {height: imageHeight + 230});
				TweenMax.set(self.$carouselImagesContainer, {height: imageHeight + 230});

				var $arrows = self.$el.find('.arrow-buttons-container button.arrow-button');

				TweenMax.set($arrows, {top: Math.round(imageHeight/2)});

				//

				var textScale = 1;
				var radioButtonShiftScale = 1;

				if(self.winWidth < 1390 && self.winWidth >= 768){

					var minTextScale = (768 - 80) / 1200;
					var minRadioButtonShiftScale = 0.8;
					var widthDiff = 1390 - 768;

					textScale = (widthDiff - (1390 - self.winWidth)) / widthDiff * (1 - minTextScale) + minTextScale;
					radioButtonShiftScale = (widthDiff - (1390 - self.winWidth)) / widthDiff * (1 - minRadioButtonShiftScale) + minRadioButtonShiftScale;
				}

				TweenMax.set([self.$textBg, self.$textContent], {x:'-50%', transformOrigin:'50% 50%', scale:textScale});
				TweenMax.set([self.$radioButtonsContainer], {x:'-50%', transformOrigin:'50% 0%', marginTop:340 * radioButtonShiftScale + 'px'});
			},
			
			// ---------------------------------------------------------------------------------  /

		});

		return AppView;
	}
);
