
define(
	'modules/reservation-sticky-nav/views/ReservationStickyNavView',[
		'backbone',
		'utils/AbstractView',
	], 
	function(Backbone, AbstractView){

		var ReservationStickyNavView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			$reservationContainer: null,
			options: null,
			abstractView: null,

			// vars

			$scrollPositions: null,
			scrollPositions: null,
			$textHolder: null,

			winHeight: null,

			isTicked: false,
			isShowing: false,

			// init

			initialize: function(options){

				var self = this;

				// options vars

				self.options = options;
				self.$container = self.options.$container;

				self.$reservationContainer = self.options.reservationContainer;

				// template config

				//self.template = _.template(Template);
				self.model = $.extend({}, self.model);

				// initialize AbstractView

				self.abstractView = new AbstractView({
					view: self,
					autoShow: true,
				});

				$(function () {
					self.onDomReady();
				});

			},

			// dom ready

			onDomReady: function(){

				var self = this;
				self.$scrollPositions = self.$el.find("#steps li");
				self.$textHolder = self.$el.find('.sticky-text-holder');

				//set initial scroll position
				self.trigger('_pushScroll');
			},

			// add listeners

			onAddListeners: function(){

				var self = this;

				self.$reservationContainer.on('click', '.btn-submit', $.proxy(self._pushScroll, self));
				self.$reservationContainer.on('click', '.btn-back', $.proxy(self._popScroll, self));

			},

			_pushScroll: function () {

				var self = this,
					currentScrollPosition = self._getScrollPosition();

					//position is unset
					if(
						currentScrollPosition == '' 
						|| typeof currentScrollPosition == "undefined" 
						|| typeof currentScrollPosition == undefined
					){
						currentScrollPosition = 0;
					}
					//position is not unset
					else {

						currentScrollPosition = parseInt(currentScrollPosition);
						
						console.log(currentScrollPosition);

						//do nothing on the last position
						if(currentScrollPosition == self.$scrollPositions.length)
							return false;
						
						//increment the position
						currentScrollPosition += 1;

						var currentPositionText = $( self.$scrollPositions[currentScrollPosition] ).data('sticky-text');
						self.$textHolder.html( currentPositionText );
					}
					self.$el.attr('data-position-index', currentScrollPosition );
			},

			_popScroll: function () {
				var self = this,
					currentScrollPosition = self._getScrollPosition();

					//position is unset
					if(
						currentScrollPosition == '' 
						|| typeof currentScrollPosition == "undefined" 
						|| typeof currentScrollPosition == undefined
					){
						currentScrollPosition = 0;
					}
					//position is not unset
					else {

						currentScrollPosition = parseInt(currentScrollPosition);
						
						console.log(currentScrollPosition);

						//do nothing on the first position
						if(currentScrollPosition == 0)
							return false;
						
						//decrement the position
						currentScrollPosition -= 1;

						var currentPositionText = $( self.$scrollPositions[currentScrollPosition] ).data('sticky-text');
						self.$textHolder.html( currentPositionText );
					}
					self.$el.attr('data-position-index', currentScrollPosition );
					window.$vent.trigger('stickyGoBack', currentScrollPosition);
			},

			_getScrollPosition: function () {

				var self = this;
				return self.$el.attr('data-position-index');
			}

		});

	return ReservationStickyNavView;
});
