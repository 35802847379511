
if(typeof(Utils) === 'undefined' || !Utils){ Utils = {}; }

Utils.StringUtils = {

	// string utils ---------------------------------------------------------------  /

	toFormalCase: function(str){

		var split = str.split(' ');
		for(var i=0; i<split.length; i++){
			split[i] = split[i].charAt(0).toUpperCase() + split[i].substr(1);
		}

		return split.join(' ');
	},

	// check to see if string is valid variable name ---------------------------------  /

	isLegalIdentifier: function(str){
		return /^\w+$/.test(str);
	},

	// omit selected characters ------------------------------------------------------  /

	omit: function(str, strToOmit, firstMatchOnly){

		var returnStr = str;
		var pos = str.indexOf(strToOmit);
		var count = strToOmit.length;
		var i = 0;

		while(pos != -1 && !(firstMatchOnly === true && i > 0)){
			returnStr = returnStr.slice(0, pos) + returnStr.slice(pos + count);
			pos = returnStr.indexOf(strToOmit);
			i++;
		}

		return returnStr;
	},

	// remove leading and trailing slashes -------------------------------------------  /

	removeWrappedSlashes: function(str){
		return str.replace(/^\/|\/$/g, '');
	},

	// -------------------------------------------------------------------------------  /
};
define("utils/utils/StringUtils", function(){});

