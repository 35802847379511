define(
	'modules/blog-detail-carousel/app',[
		'backbone',
		'modules/blog-detail-carousel/views/CarouselView',
	], 
	function(Backbone, CarouselView){

		var AppView = Backbone.View.extend({

			// core vars

			$carousels: null,
			carousels: null,

			// init

			initialize: function(options){

				var self = this;
			},

			// start module --------------------------------------------------------------------  /

			start: function(){

				var self = this;
				
				self.$carousels = $('.blog-detail-carousel');		
				self.carousels = [];

				_.each(self.$carousels, function(carousel, i){
					
					var carouselView = new CarouselView({
						el: carousel
					});

					self.carousels.push(carouselView);
				});	
				
				window.$vent.trigger('blogDetailCarouselReady');
			},
			
			// ---------------------------------------------------------------------------------  /

		});

		return AppView;
	}
);
