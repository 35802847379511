
if(typeof(Utils) === 'undefined' || !Utils){ Utils = {}; }

Utils.MiscUtils = {

    setCookie: function (name, obj, expires) {

        var value= obj;

        var now = new Date();
        var time = now.getTime();
        var expireTime = time + expires;
        now.setTime(expireTime);

        console.log("======== set cookie ========", obj);
        document.cookie = name + '=' + value + ';expires='+now.toUTCString()+';path=/';

        return document.cookie

    },

    getCookie: function (key) {

        var name = key + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                console.log("======== get cookie ========", c.substring(name.length, c.length));
                return c.substring(name.length, c.length);
            }
        }
        console.log("======== no cookie ========");
        return null;

    },

    deleteCookie: function (key) {
        document.cookie = key + "=deleted;" + "expires=Thu, 01 Jan 1970 00:00:00 UTC;" + ";path=/";
        console.log("======== delete cookie ========", key);
    }

};
define("utils/utils/MiscUtils", function(){});

