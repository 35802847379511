
define(
	'modules/mobile-interactions/views/MobileGuideSelectorView',[
		'backbone',
		'utils/AbstractView',
		'jquerymousewheel',
	], 
	function(Backbone, AbstractView){

		var MobileGuideSelectorView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			options: null,
			abstractView: null,

			// vars

			$menu: null,
			$guidesContainer: null,
			$guideButtons: null,
			$guides: null,

			$nextPanel: null,
			$lastPanel: null,

			$stickyMenu: null,
			$backButton: null,

			isMenu: true,

			// init

			initialize: function(options){

				var self = this;

				// options vars

				self.options = options;
				self.$container = self.options.$container;
				self.$menu = self.options.$menu;

				// template config

				//self.template = _.template(Template);
				self.model = $.extend({}, self.model);

				// initialize AbstractView

				self.abstractView = new AbstractView({
					view: self,
					autoShow: self.options.autoShow !== false,
				});
			},

			onDomReady: function(){

				var self = this;

				self.$guidesContainer = $('.guides-wrapper');
				self.$stickyMenu = $('#mobile-sticky-menu');
				self.$backButton = self.$stickyMenu.find('.mobile-back-button');
			},

			// events

			onAddListeners: function(){

				var self = this;

				$(window).on('resize', $.proxy(self._onWindowResize, self));
				$(window).on('scroll', $.proxy(self._onScroll, self));
				$(window).mousewheel($.proxy(self._onMouseWheel, self));

				self._onWindowResize();

				self.$backButton.on('click', $.proxy(self._onBackClick, self));
				window.$vent.on('guideBack', $.proxy(self._onBackClick, self));

				self.$nextPanel = self.$menu;
				self.addGuideListeners();

				if(self.$menu.data('deep-link')){

					var defaultId = self.$menu.data('default');
					var $defaultTarget;

					_.each(self.$menu.find('li'), function(menuLink){

						var $menuLink = $(menuLink).find('a');
						
						if($menuLink.length){
							if($menuLink.attr('href').substr(1) + '-guide' === defaultId){
								$defaultTarget = $menuLink;
							}
						}
					});

					if($defaultTarget.length){

						self.$lastPanel = self.$menu;

						var nextTemplate = _.template($('#' + defaultId + '-template').html());
						var nextTemplateModel = self.getGuideModel(defaultId, $defaultTarget) || {};

						self.$nextPanel = $('#' + defaultId);
						self.$nextPanel.html(nextTemplate(nextTemplateModel));

						window.$rootContext(self.$nextPanel.find('select')).selectBoxIt();

						TweenMax.set(self.$nextPanel, {display: 'block'});
						TweenMax.set(self.$guidesContainer, {display:'block'});
						TweenMax.set(self.$menu, {display:'none', position:'absolute'});

						window.$vent.trigger('domUpdate');
					}
				}
				
				var section = Utils.DOMUtils.getHashParameter('section');
				if(section){ 
				
					var $guide = $('#mobile-guide-selector').find('a[href="#'+section+'"]');
					if($guide && $guide.length){ self.openPanel($guide) }; 
				}
			},

			// event listeners

			_onBackClick: function(e){

				var self = this;
				self.goBack();
			},

			// scroll --------------------------------------------------------------------------  /

			_onMouseWheel: function(e){

				var self = this;
				self.scrollTick();
			},

			_onScroll: function(){

				var self = this;
				self.scrollTick();
			},

			scrollTick: function(){

				var self = this;

				if(!self.isTicked){
					self.isTicked = true;
					window.requestAnimationFrame(self.scrollNow.bind(self));
				}
			},

			scrollNow: function(){

				var self = this;
				self.scrollTop = $(document).scrollTop();
				
				TweenMax.set(self.$stickyMenu, {y:self.scrollTop});

				self.isTicked = false;
			},

			//

			openPanel: function($target){

				var self = this;
				var guideId = $target.data('guide-id');
				var containerWidth = $('#main-wrapper').outerWidth();
				
				// remove listeners from last guide

				self.$lastPanel = self.$nextPanel;
				self.removeGuideListeners();

				// open next guide

				var nextTemplate = _.template($('#' + guideId + '-template').html());
				var nextTemplateModel = self.getGuideModel(guideId, $target) || {};

				self.$nextPanel = $('#' + guideId);
				self.$nextPanel.html(nextTemplate(nextTemplateModel));

				window.$rootContext(self.$nextPanel.find('select')).selectBoxIt();

				TweenMax.set(self.$nextPanel, {display: 'block'});

				// change name in sticky panel

				self.$stickyMenu.find('.guide-title').html(self.$nextPanel.find('h3:first-child').html());

				// move page contents

				var $pageWrapper = $('#page-content-wrapper');

				if(self.isMenu){

					TweenMax.to($pageWrapper, 0.6, {x:-containerWidth, force3D:true, ease:Cubic.easeInOut, onComplete:function(){
						
						TweenMax.set(self.$lastPanel, {position:'absolute', display:'none'});
						TweenMax.set(self.$guidesContainer, {position:'relative', x:0, y:0});
						TweenMax.set($pageWrapper, {x:0});
						
						self.$guidesContainer.removeClass('animating');
						self.addGuideListeners();
					}});

					TweenMax.set(self.$guidesContainer, {display:'block', position:'absolute', x:containerWidth, y:0});
					self.$guidesContainer.removeClass('animating').addClass('animating');
				}
				else {

					TweenMax.to(self.$lastPanel, 0.6, {x:-containerWidth, force3D:true, ease:Cubic.easeInOut});
					TweenMax.fromTo(self.$nextPanel, 0.6, {x:containerWidth, force3D:true}, {x:0, ease:Cubic.easeInOut, onComplete:function(){						
						self.$lastPanel.removeClass('animating');
						TweenMax.set(self.$lastPanel, {display:'none'});
					}});

					TweenMax.set(self.$lastPanel, {display:'block', position:'absolute'});
					TweenMax.set(self.$nextPanel, {display:'block', position:'relative'});
					
					self.$lastPanel.removeClass('animating').addClass('animating');
				}

				self.isMenu = false;
				window.$vent.trigger('domUpdate');
				window.$vent.trigger('seekToTop');
				self._onWindowResize();

				self.$stickyMenu = $('#mobile-sticky-menu');
				self.$backButton = self.$stickyMenu.find('.mobile-back-button');

				self.$backButton.on('click', $.proxy(self._onBackClick, self));
				window.$vent.on('guideBack', $.proxy(self._onBackClick, self));

				$('.trans-date').html(nextTemplateModel.date);
				$('.trans-name').html(nextTemplateModel.title);
				$('.trans-type').html(nextTemplateModel.type);
				$('.trans-price').html(nextTemplateModel.price);
				$('.trans-docUrl').attr('href',nextTemplateModel.docUrl);
				$('#make-payment-mobile-link').attr('data-modal-id', nextTemplateModel.queryParams);
			},

			// go back -------------------------------------------------------------------------  /

			goBack: function(){

				var self = this;
				var containerWidth = $('#main-wrapper').outerWidth();
				var $pageWrapper = $('#page-content-wrapper');
				
				if(self.$lastPanel && self.$nextPanel[0] != self.$lastPanel[0]){

					var $outgoingPanel = self.$nextPanel;

					if(self.$lastPanel[0] === self.$menu[0]){ 

						self.$nextPanel = self.$menu;
						self.isMenu = true; 
					}
					else {

						self.$nextPanel = self.$lastPanel;

						// remove listeners from last guide

						self.$lastPanel = self.$menu;
						self.isMenu = false;
					}
					
					self.addGuideListeners();

					if(self.isMenu){

						TweenMax.fromTo($pageWrapper, 0.6, {x:-containerWidth, force3D:true}, {x:0, ease:Cubic.easeInOut, onComplete:function(){
							TweenMax.set($outgoingPanel, {display:'none'});							
							self.$guidesContainer.removeClass('animating');
							$outgoingPanel.html('');
						}});

						TweenMax.set(self.$menu, {display:'block', position:'relative'});
						TweenMax.set(self.$guidesContainer, {display:'block', position:'absolute', x:containerWidth, y:0});

						self.$guidesContainer.removeClass('animating').addClass('animating');
					}
					else {

						TweenMax.to($outgoingPanel, 0.6, {x:containerWidth, force3D:true, ease:Cubic.easeInOut});
						TweenMax.fromTo(self.$nextPanel, 0.6, {x:-containerWidth, force3D:true}, {x:0, ease:Cubic.easeInOut, onComplete:function(){
					
							$outgoingPanel.removeClass('animating');
							TweenMax.set($outgoingPanel, {display:'none'});
							$outgoingPanel.html('');
						}});

						TweenMax.delayedCall(0.3, function(){
							//TweenMax.killTweensOf([$outgoingPanel, self.$nextPanel]);
						});

						TweenMax.set($outgoingPanel, {display:'block', position:'absolute'});
						TweenMax.set(self.$nextPanel, {display:'block', position:'relative'});
					
						$outgoingPanel.removeClass('animating').addClass('animating');
					}

					window.$vent.trigger('domUpdate');
					window.$vent.trigger('seekToTop');
					self._onWindowResize();
				}
			},

			// exception - payment guide link --------------------------------------------------  /

			getGuideModel: function(guideId, $target){

				var self = this;
				var returnModel = {}

				if(guideId === 'payment-detail-guide'){

					returnModel = {
						date: $target.find('.trans-date').html(),
						title: $target.find('.trans-name').html(),
						type: $target.find('.trans-type').html(),
						price: $target.find('.trans-price').html(),
						docUrl: $target.find('.trans-docUrl').html(),
						queryParams: $target.data('query-params')
					};
				}

				return returnModel;
			},

			// add / remove guide listeners ----------------------------------------------------  /

			addGuideListeners: function(){

				var self = this;
				var $guideButtons = self.$nextPanel.find('a.guide-link');
				
				$guideButtons.off('click', $.proxy(self._onGuideButtonClick, self));
				$guideButtons.on('click', $.proxy(self._onGuideButtonClick, self));

				_.each($guideButtons, function(guideButton, i){
					
					var $guideButton = $(guideButton);
					var guideId = $guideButton.data('guide-id') || $guideButton.attr('href').substr(1) + '-guide';
					
					var $guide = $('#' + guideId);
					$guideButton.attr('data-guide-id', guideId);
				});
			},

			removeGuideListeners: function(){

				var self = this;

				if(self.$lastPanel){
					var $guideButtons = self.$lastPanel.find('a.guide-link');
					$guideButtons.off('click', $.proxy(self._onGuideButtonClick, self));
				}
			},

			// guide button listeners ----------------------------------------------------------  /			

			_onGuideButtonClick: function(e){

				var self = this;
				var $target = $(e.currentTarget);

				self.openPanel($target);
				e.preventDefault();
			},

			// window resize

			_onWindowResize: function(e){

				var self = this;
				var winWidth = window.innerWidth;
				var winHeight = window.innerHeight;
				var contentHeight = winHeight - 60;

				TweenMax.set(self.$guidesContainer, {
					minHeight: contentHeight
				});

				if(winWidth >= 480){ contentHeight -= 100; }
				else { contentHeight -= 50; }

				if(self.$nextPanel && self.$nextPanel[0] !== self.$menu[0]){

					TweenMax.set(self.$nextPanel, {
						minHeight: contentHeight
					});
				}
			},

			// ---------------------------------------------------------------------------------  /

		});

	return MobileGuideSelectorView;
});
