define(
	'modules/neighborhood-map/views/MapModalView',[
		'backbone',
		'utils/AbstractView',
		'text!../templates/MapModalTemplate.html',
	], 
	function(Backbone, AbstractView, Template){

		var MapModalView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			options: null,

			// config

			autoShow: false,

			// abstract

			abstractView: null,

			// flags

			isShowing: false,
			isListening: false,

			// init

			initialize: function(options){

				var self = this;

				self.options = options;
				self.$container = self.options.$container;
				self.el = self.options.el;

				self.template = _.template(Template);
				self.model = $.extend({

				}, self.model);

				// 

				self.abstractView = new AbstractView({
					view: self,
					autoShow: false
				});
			},

			onDomReady: function(){

				var self = this;

				TweenMax.set(self.$el, {x:self.options.x, y:self.options.y, force3D:true});
			},

		});

	return MapModalView;
});
