define('modules/global-positioning-helpers/app',['backbone', 
			'modules/global-positioning-helpers/views/MatchingVerticalRowView'
		], 
		
		function(Backbone, MatchingVerticalRowView){

		var AppView = Backbone.View.extend({

			// core vars

			modules: null,

			// init

			initialize: function(options){

				var self = this;
				window.$vent.trigger('globalPositioningHelpersReady');
			},

			// start module --------------------------------------------------------------------  /

			start: function(){

				var self = this;
				window.$vent.on('domUpdate', $.proxy(self._onDomUpdate, self));
				
				$(function(){
					self.onDomReady();
				});
			},

			onDomReady: function(){

				var self = this;
				self.modules = [];
				self._onDomUpdate();
			},

			_onDomUpdate: function(){

				var self = this;
				self.refreshModules();
			},

			refreshModules: function(){

				var self = this;

				_.each(self.$el.find('.pos-vert-match-row'), function(module, i){
					
					if(!self.checkExisting(module, 'MatchingVerticalRowView')){						
						var $module = $(module);
						self.modules.push({
							type: 'MatchingVerticalRowView',
							btn: new MatchingVerticalRowView({el: $module})
						});
					}
				});
			},

			// check for existing modules ------------------------------------------------------  /

			checkExisting: function(el, type){

				var self = this;
				var exists = false;

				if(typeof(el.context) !== 'undefined'){ el = el[0]; }

				_.each(self.modules, function(module, i){

					var moduleEl = module.btn.el;
					if(typeof(moduleEl.context) !== 'undefined'){ moduleEl = moduleEl[0]; }

					if(moduleEl === el && type === module.type){
						exists = true;
					}
				});

				return exists;
			},

			// ---------------------------------------------------------------------------------  /

		});

		return AppView;
	}
);
