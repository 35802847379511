
define(
	'modules/blog-header-carousel/views/RadioButtonView',[
		'backbone',
		'utils/AbstractView',
		'utils/AbstractButtonView',
	], 
	function(Backbone, AbstractView, AbstractButtonView){

		var RadioButtonView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			options: null,
			abstractView: null,
			abstractButtonView: null,

			// vars

			index: null,

			$circle: null,
			$outline: null,

			isSelected: false,

			// init

			initialize: function(options){

				var self = this;

				self.options = options;
				self.$container = self.options.$container;
				self.el = self.options.el;

				//

				self.index = self.options.index;

				// 

				self.abstractView = new AbstractView({
					view: self,
					autoShow: true
				});

				self.abstractButtonView = new AbstractButtonView({
					abstractView: self.abstractView,
					selectable: true,
				});
			},

			// render

			onRender: function(){

				var self = this;
				self.$circle = self.$el.find('.circle');
				self.$outline = self.$el.find('.outline');

				TweenMax.set(self.$circle, {scale:0, opacity:0});
			},

			// 

			onAnimateOver: function(){

				var self = this;
				TweenMax.to(self.$circle, 0.3, {scale:1, opacity:1, ease:Quint.easeOut});
			},

			onAnimateOut: function(){

				var self = this;
				TweenMax.to(self.$circle, 0.3, {scale:0, opacity:0, ease:Quint.easeOut});
			},

			//
		});

	return RadioButtonView;
});
