
define(
	'modules/neighborhood-map/views/MapMarkerView',[
		'backbone',
		'utils/AbstractView',
		'utils/AbstractButtonView',
		
		'text!../templates/MapMarkerTemplate.html',
	], 
	function(Backbone, AbstractView, AbstractButtonView, Template){

		var MapMarkerView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			options: null,
			abstractView: null,
			abstractButtonView: null,

			// vars

			marker: null,
			listingId: null,
			delay: 0,
			lat: null,
			lng: null,
			glat: null,
			glng: null,

			isFirstShow: true,

			// init

			initialize: function(options){

				var self = this;

				// options vars

				self.options = options;
				self.$container = self.options.$container;

				self.marker = self.options.marker;
				self.delay = self.options.delay || 0;
				self.listingId = self.options.listingId;
				self.lat = self.options.lat;
				self.lng = self.options.lng;
				self.glat = self.options.glat;
				self.glng = self.options.glng;

				// template config

				self.template = _.template(Template);
				self.model = $.extend({}, self.model);

				// initialize AbstractView and AbstractButtonView

				self.abstractView = new AbstractView({
					view: self,
					autoShow: self.options.autoShow !== false,
				});

				self.abstractButtonView = new AbstractButtonView({
					abstractView: self.abstractView,
				});
			},

			// render

			onRender: function(){

				var self = this;
			},

			onShow: function(){

				var self = this;
				var d = self.isFirstShow ? self.delay : 0;

				TweenMax.killTweensOf(self.$el);
				TweenMax.fromTo(self.$el, 0.6, {opacity:0}, {opacity:1, delay:d, ease:Cubic.easeOut});
				
				self.isFirstShow = false;

				window.requestAnimationFrame(function(){
					self.trigger('showComplete');
				});
			},

			onHide: function(){

				var self = this;

				TweenMax.to(self.$el, 0.05, {opacity:0, ease:Quint.easeOut});
				TweenMax.delayedCall(0.05, function(){
					self.trigger('hideComplete');
				});
			},

			// ---------------------------------------------------------------------------------  /

		});

	return MapMarkerView;
});
