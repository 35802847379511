define(
    'modules/property-detail-bar/views/GuestsMenuView',[
        'backbone',
        'utils/AbstractView',
        'text!../templates/GuestsMenuTemplate.html',
    ],
    function(Backbone, AbstractView, Template){

        var GuestsMenuView = Backbone.View.extend({

            // core vars

            $el: null,
            $container: null,
            options: null,
            abstractView: null,

            // vars

            selectedGuests: null,

            serverQuery: null,
            shortResults: null,
            totalGuests: null,
            guestsLimit: -1,

            $addButton: null,
            $removeButton: null,

            isMobile: false,

            // init

            initialize: function(options){

                var self = this;

                self.options = options;
                self.$container = self.options.$container;
                self.selectedGuests = self.options.selectedGuests;

                self.template = _.template(Template);
                self.model = $.extend({

                }, self.model);

                self.totalGuests = 0;

                if(self.model.maxGuests){
                    self.guestsLimit = parseInt(self.model.maxGuests);
                }

                //

                self.abstractView = new AbstractView({
                    view: self,
                    autoShow: false
                });
            },

            // render

            onRender: function(){

                var self = this;

                // calculate total guests

                self.isGuestSelected = false;

                self.$addButton = self.$el.find('li button.add');
                self.$removeButton = self.$el.find('li button.remove');
                self.$closeButton = self.$el.find('.apply-cta');

                window.requestAnimationFrame(function(){

                    if(self.selectedGuests){

                        for(var prop in self.selectedGuests){
                            var count = parseInt(self.selectedGuests[prop]);
                            if(prop === 'adults'){ count--; }
                            self.addGuests(self.$el.find('.' + prop), count);
                        }
                    }
                });
            },

            // listeners

            onAddListeners: function(){

                var self = this;

                self.$addButton.on('click', $.proxy(self._onAddClick, self));
                self.$removeButton.on('click', $.proxy(self._onRemoveClick, self));
                self.$closeButton.on('click', $.proxy(self._onCloseClick, self));
            },

            onRemoveListeners: function(){

                var self = this;

                self.$addButton.off('click', $.proxy(self._onAddClick, self));
                self.$removeButton.off('click', $.proxy(self._onRemoveClick, self));
            },

            // listener methods

            _onRemoveClick: function(e){

                var self = this;

                var $target = $(e.currentTarget);
                var $guestOption = $target.parent().parent();
                self.removeGuests($guestOption);
            },

            _onAddClick: function(e){

                var self = this;

                var $target = $(e.currentTarget);
                var $guestOption = $target.parent().parent();
                self.addGuests($guestOption);
            },

            _onCloseClick: function () {
                var self = this;
                self.trigger('closeMenu');
            },

            removeGuests: function ($guestOption, count) {

                var self = this;
                var count = _.isNumber(count) ? count : 1;

                var $add = $guestOption.find('.add');
                var $remove = $guestOption.find('.remove');
                var $number = $guestOption.find('.number');

                var numValue = parseInt($number.find('h2').html()) - count;
                var minValue = parseInt($guestOption.data('min'));
                var maxValue = parseInt($guestOption.data('max'));

                numValue = Math.max(numValue, minValue);
                if(maxValue >= 0){ numValue = Math.min(numValue, maxValue); }

                if(numValue === minValue){ $remove.removeClass('disabled').addClass('disabled'); }
                else { $remove.removeClass('disabled'); }

                if(numValue === maxValue){ $add.removeClass('disabled').addClass('disabled'); }
                else { $add.removeClass('disabled'); }

                $number.find('h2').html(numValue);
                $guestOption.data('count', numValue);

                console.log($number, $guestOption, numValue);

                self.tabulateGuests();
            },

            addGuests: function($guestOption, count){

                var self = this;
                var count = _.isNumber(count) ? count : 1;

                if(self.guestsLimit > 0 && self.totalGuests + count > self.guestsLimit){
                    count = self.guestsLimit - self.totalGuests;
                }

                var $add = $guestOption.find('.add');
                var $remove = $guestOption.find('.remove');
                var $number = $guestOption.find('.number');

                var numValue = parseInt($number.find('h2').html()) + count;
                var minValue = parseInt($guestOption.data('min'));
                var maxValue = parseInt($guestOption.data('max'));

                numValue = Math.max(numValue, minValue);
                if(maxValue >= 0){ numValue = Math.min(numValue, maxValue); }

                if(numValue === minValue){ $remove.removeClass('disabled').addClass('disabled'); }
                else { $remove.removeClass('disabled'); }

                if(numValue === maxValue){ $add.removeClass('disabled').addClass('disabled'); }
                else { $add.removeClass('disabled'); }

                $number.find('h2').html(numValue);
                $guestOption.data('count', numValue);

                self.tabulateGuests();
            },

            // return total guests

            tabulateGuests: function(){

                var self = this;
                var totalGuests = 0;
                var newQuery = '';
                var resultsObj = {};

                _.each(self.$el.find('li'), function(guest, i){
                    var $guest = $(guest);
                    totalGuests += $guest.data('count');
                    newQuery += (i > 0 ? ',' : '') + $guest.data('count') + '.' + $guest.data('id');
                    resultsObj[$guest.data('id')] = $guest.data('count');
                });

                resultsObj.total = totalGuests;

                self.serverQuery = newQuery;
                if ($('#lang').text() == "en") {
                    self.shortResults = totalGuests + ' Guest' + ((totalGuests === 1) ? '' : 's');
                } else {
                    self.shortResults = totalGuests + ' Persona' + ((totalGuests === 1) ? '' : 's');
                }

                //self.shortResults = totalGuests + ' Guest' + ((totalGuests === 1) ? '' : 's');
                self.totalGuests = totalGuests;

                self.trigger('optionSelected', {
                    target: self,
                    query: self.serverQuery,
                    results: self.shortResults,
                    resultsObj: resultsObj
                });
            },

            onShow: function(){

                var self = this;
                var d = 0.0;

                _.each(self.$el.find('li'), function(guest, i){

                    var $guest = $(guest);
                    var $guestContent = $guest.find('.label').add($guest.find('.number')).add($guest.find('.buttons'));
                    var $guestBorder = $guest.find('.border');

                    TweenMax.fromTo($guestContent, 0.2, {opacity:0, y:4}, {opacity:1, y:0, delay:i * 0.02 + d + 0.1, ease:Cubic.easeOut});
                    TweenMax.fromTo($guestBorder, 0.5, {opacity:0}, {opacity:1, delay:i * 0.02 + d + 0.1, ease:Cubic.easeOut});
                });

                TweenMax.fromTo(self.$el, 0.5, {opacity:0, y:0}, {opacity:1, y:0, ease:Cubic.easeOut, onComplete:function(){
                    self.trigger('showComplete');
                }});

                self.tabulateGuests();
            },

            onWindowResize: function(){

                var self = this;
                var winWidth = window.innerWidth;

                if(winWidth >= window.MOBILE_WIDTH){

                    TweenMax.set(self.$addButton, {clearProps:'left'});
                    self.isMobile = false;
                }
                else {

                    TweenMax.set(self.$addButton, {left:winWidth-64});
                    self.isMobile = true;
                }
            }

            // ---------------------------------------------------------------------------------  /

        });

        return GuestsMenuView;
    });
