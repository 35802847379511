
define(
	'utils/AbstractButtonView',[
		'backbone',
	], 
	function(Backbone){

		var AbstractButtonView = Backbone.View.extend({

			// core vars

			options: null,
			abstractView: null,

			// options

			selectable: false,
			animateInteractionsWhenSelected: false,
			configViewMethods: true,

			// flags

			isOver: false,
			isSelected: false,
			isDisabled: false,
			isClickTick: false,

			// vars

			$hitArea: null,

			// init

			initialize: function(options){

				var self = this;

				self.options = options || {};
				self.abstractView = self.options.abstractView;

				if(typeof(self.options.selectable) !== 'undefined'){ self.selectable = self.options.selectable; }
				if(typeof(self.options.configViewMethods) !== 'undefined'){ self.configViewMethods = self.options.configViewMethods; }
				if(typeof(self.options.animateInteractionsWhenSelected) !== 'undefined'){ self.animateInteractionsWhenSelected = self.options.animateInteractionsWhenSelected; }

				// bind to abstractView listeners

				if(self.abstractView){

					if(self.configViewMethods){
						self.abstractView.view.select = self.select.bind(self);
						self.abstractView.view.deselect = self.deselect.bind(self);
						self.abstractView.view.enable = self.enable.bind(self);
						self.abstractView.view.disable = self.disable.bind(self);
					}

					self.abstractView.on('render', self._onRender, self);
					self.abstractView.on('addListeners', self._onAddListeners, self);
					self.abstractView.on('removeListeners', self._onRemoveListeners, self);

					if(self.abstractView.isRendered){ self._onRender(); }
					if(self.abstractView.isListening){ self._onAddListeners(); }
				}
				else { console.log("ERROR: Required abstractView not defined.", self); }
			},

			// actions bound to AbstractView ---------------------------------------------------  /

			_onRender: function(){

				var self = this;
				self.$hitArea = self.$el.find('.hit-area');
			},

			// listeners

			_onAddListeners: function(){

				var self = this;
				
				self.abstractView.view.$el.on('mouseenter', $.proxy(self._onMouseOver, self));
				self.abstractView.view.$el.on('mouseleave', $.proxy(self._onMouseLeave, self));
				self.abstractView.view.$el.on('click', $.proxy(self._onClick, self));

				self.abstractView.view.$el.on('select', $.proxy(self._onSelect, self));
				self.abstractView.view.$el.on('deselect', $.proxy(self._onDeselect, self));
			},

			_onRemoveListeners: function(){

				var self = this;

				self.abstractView.view.$el.off('mouseenter', $.proxy(self._onMouseOver, self));
				self.abstractView.view.$el.off('mouseleave', $.proxy(self._onMouseLeave, self));
				self.abstractView.view.$el.off('click', $.proxy(self._onClick, self));

				self.abstractView.view.$el.off('select', $.proxy(self._onSelect, self));
				self.abstractView.view.$el.off('deselect', $.proxy(self._onDeselect, self));
			},

			// listener methods ----------------------------------------------------------------  /

			_onMouseOver: function(e){

				var self = this;
				self.isOver = true;

				if(!self.isDisabled && (!self.selectable || !self.isSelected || self.animateInteractionsWhenSelected)){	
					
					var wrapperClass = self.abstractView.view.$el.data('wrapper-class');	
					var overClass = self.abstractView.view.$el.data('over-class');	
					
					if(wrapperClass){
						var wrapper = self.abstractView.view.$el.closest('.'+wrapperClass);
						if(wrapper && !wrapper.hasClass(overClass)){ wrapper.addClass(overClass); }
					}			
							
					self.animateOver();
					self.triggerEvent('mouseenter');
				}
			},
			
			_onMouseLeave: function(e){

				var self = this;
				self.isOver = false;

				if(!self.isDisabled && (!self.selectable || !self.isSelected || self.animateInteractionsWhenSelected)){
					self.animateOut();
					self.triggerEvent('mouseleave');
				}
			},

			_onClick: function(e){
				
				var self = this;

				if(!self.isDisabled && !self.isClickTick && (!self.selectable || !self.isSelected || self.animateInteractionsWhenSelected)){
					
					self.animateClick();

					if(self.selectable){ 
						if(!self.isSelected){ self.select(); }
						else { self.deselect(); }
					}

					self.triggerEvent('click');
					self.isClickTick = true;

					window.requestAnimationFrame(function(){
						self.isClickTick = false;
					});
				}
			},

			_onSelect: function(e){

				var self = this;
				if(self.selectable){ self.select(); }
			},

			_onDeselect: function(e){

				var self = this;
				if(self.selectable){ self.deselect(); }
			},

			// animations ----------------------------------------------------------------------  /

			animateOver: function(){

				var self = this;

				if(typeof(self.abstractView.view.onAnimateOver) === 'undefined'){ }
				else { self.abstractView.view.onAnimateOver(); }

				self.triggerEvent('animateOver');
			},

			animateOut: function(){

				var self = this;

				if(typeof(self.abstractView.view.onAnimateOut) === 'undefined'){ }
				else { self.abstractView.view.onAnimateOut(); }

				self.triggerEvent('animateOut');
			},

			animateClick: function(){

				var self = this;

				if(typeof(self.abstractView.view.onAnimateClick) === 'undefined'){ }
				else { self.abstractView.view.onAnimateClick(); }

				self.triggerEvent('animateClick');
			},

			animateSelect: function(){

				var self = this;

				if(!self.isOver){ self.animateOver(); }

				if(typeof(self.abstractView.view.onAnimateSelect) === 'undefined'){ }
				else { self.abstractView.view.onAnimateSelect(); }

				self.triggerEvent('animateSelect');
			},

			animateDeselect: function(){

				var self = this;
				
				if(!self.isOver){ self.animateOut(); }

				if(typeof(self.abstractView.view.onAnimateDeselect) === 'undefined'){ }
				else { self.abstractView.view.onAnimateDeselect(); }

				self.triggerEvent('animateDeselect');
			},

			animateEnable: function(){

				var self = this;

				if(typeof(self.abstractView.view.onAnimateEnable) === 'undefined'){ }
				else { self.abstractView.view.onAnimateEnable(); }

				self.triggerEvent('animateEnable');
			},

			animateDisable: function(){

				var self = this;

				if(typeof(self.abstractView.view.onAnimateDisable) === 'undefined'){ }
				else { self.abstractView.view.onAnimateDisable(); }

				self.triggerEvent('animateDisable');
			},

			// select / deselect ---------------------------------------------------------------  /

			select: function(){

				var self = this;

				if(!self.isSelected){ 

					self.isSelected = true; 
					self.animateSelect();

					if(typeof(self.abstractView.view.onSelect) === 'undefined'){ }
					else { self.abstractView.view.onSelect(); }

					self.triggerEvent('select');
				}
			},

			deselect: function(){

				var self = this;

				if(self.isSelected){ 

					self.isSelected = false; 
					self.animateDeselect();

					if(typeof(self.abstractView.view.onDeselect) === 'undefined'){ }
					else { self.abstractView.view.onDeselect(); }

					self.triggerEvent('deselect');
				}
			},

			// enable / disable ----------------------------------------------------------------  /

			enable: function(){

				var self = this;

				if(!self.isDisabled){ 

					self.isDisabled = false; 
					self.animateEnable();

					if(typeof(self.abstractView.view.onEnable) === 'undefined'){ }
					else { self.abstractView.view.onEnable(); }

					self.triggerEvent('enable');
				}
			},

			disable: function(){

				var self = this;

				if(self.isDisabled){ 

					self.isDisabled = true; 
					self.animateDisable();

					if(typeof(self.abstractView.view.onDisable) === 'undefined'){ }
					else { self.abstractView.view.onDisable(); }

					self.triggerEvent('disable');
				}
			},

			// trigger events ------------------------------------------------------------------  /

			triggerEvent: function(eventName, options){

				var self = this;

				self.trigger(eventName, {target:self});
				self.abstractView.view.trigger(eventName, {target:self.abstractView.view});
			},

			// ---------------------------------------------------------------------------------  /

		});

	return AbstractButtonView;
});
