define(
	'modules/calendar-single/app',[
		'backbone',
		'modules/calendar-single/views/CalendarSingleMenuView',
		'modules/calendar-single/views/CalendarSingleMenuButtonView',

		'clndr',
		'moment'
	], 
	function(Backbone, CalendarSingleMenuView, CalendarSingleMenuButtonsView){

		var AppView = Backbone.View.extend({

			// core vars

			$context: null,

			$calendarContainers: null,
			$calendarButtonContainers: null,

			calendars: null,
			calendarButtons: null,

			// init

			initialize: function(options){

				var self = this;
				self.$context = $;
			},

			// start module --------------------------------------------------------------------  /

			start: function(){

				var self = this;						
				self.calendars = [];
				self.calendarButtons = [];

				self._onDomUpdate();
				
				window.$vent.trigger('calendarSingleReady');
				window.$vent.on('domUpdate', $.proxy(self._onDomUpdate, self));

			},

			_onDomUpdate: function(){

				var self = this;
				self.checkCalendars();
				self.checkCalendarButtons();
			},
			
			// calendars

			checkCalendars: function(){

				var self = this;
				self.$calendarContainers = $('.single-calendar-container');

				_.each(self.$calendarContainers, function(calendarContainer, i){

					var $calendarContainer = $(calendarContainer);
										
					if(!self.checkExisting($calendarContainer, self.calendars, true)){

						var calendarMenu = new CalendarSingleMenuView({
							$container: $calendarContainer,
							$context: self.$context,
							autoShow: true,
							model: { 
								noMobile: $calendarContainer.data('no-mobile') === true
							}
						});

						self.calendars.push(calendarMenu);
					}
				});	
			},

			// calendar buttons

			checkCalendarButtons: function(){

				var self = this;
				self.$calendarButtonContainers = $('.single-calendar-button');

				_.each(self.$calendarButtonContainers, function(calendarButtonsContainer, i){

					var $calendarButtonsContainer = $(calendarButtonsContainer);
										
					if(!self.checkExisting($calendarButtonsContainer, self.calendarButtons)){
							
						var $calendarsContainer = $('#' + $calendarButtonsContainer.data('mobile-overlay-id'));

						var calendarButtons = new CalendarSingleMenuButtonsView({
							el: $calendarButtonsContainer,
							$context: self.$context,
							$calendarsContainer: $calendarsContainer,
							model: { 
								noMobile: $calendarButtonsContainer.hasClass('no-mobile')
							}
						});

						self.calendarButtons.push(calendarButtons);
					}
				});	
			},

			// check for existing buttons ------------------------------------------------------  /

			checkExisting: function(el, searchArray, useContainer){

				var self = this;
				var exists = false;

				if(typeof(el.context) !== 'undefined'){ el = el[0]; }

				_.each(searchArray, function(view, i){

					var viewEl = view.$el[0];
					if(useContainer === true){ viewEl = view.$container[0]; }

					if(typeof(viewEl.context) !== 'undefined'){ viewEl = viewEl[0]; }

					if(viewEl === el){
						exists = true;
					}
				});

				return exists;
			},

			// ---------------------------------------------------------------------------------  /

		});

		return AppView;
	}
);
