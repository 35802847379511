
define(
	'modules/bottom-image-links/views/BottomImageLinkView',[
		'backbone',
		'utils/AbstractView',
		'utils/AbstractButtonView',
	], 
	function(Backbone, AbstractView, AbstractButtonView){

		var BottomImageLinkView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			options: null,
			abstractView: null,
			abstractButtonView: null,

			// vars

			// init

			initialize: function(options){

				var self = this;

				self.options = options;
				self.$container = self.options.$container;
				self.el = self.options.el;

				// 

				self.abstractView = new AbstractView({
					view: self,
					autoShow: true
				});

				self.abstractButtonView = new AbstractButtonView({
					abstractView: self.abstractView,
				});
			},

			// render

			onRender: function(){

				var self = this;
			},

			// animated states

			onAnimateOver: function(){

				var self = this;
				var $textBlock = self.$el.find('.gi-text-over');
				var $bgBlock = $textBlock.find('.bg-block');
				var $supTitle = $textBlock.find('.suptitle');
				var $title = $textBlock.find('.title');

				TweenMax.killTweensOf([$supTitle, $title, $bgBlock]);

				TweenMax.to($supTitle, 0.3, {y:-10, delay:0.00, ease:Cubic.easeOut});
				TweenMax.to($title, 0.3, {y:-10, delay:0.00, ease:Cubic.easeOut});
				TweenMax.to($bgBlock, 0.42, {y:-10, delay:0.00, ease:Quint.easeOut});
			},

			onAnimateOut: function(){

				var self = this;
				var $textBlock = self.$el.find('.gi-text-over');
				var $bgBlock = $textBlock.find('.bg-block');
				var $supTitle = $textBlock.find('.suptitle');
				var $title = $textBlock.find('.title');

				TweenMax.killTweensOf([$supTitle, $title, $bgBlock]);

				TweenMax.to($supTitle, 0.4, {y:0, ease:Cubic.easeInOut});
				TweenMax.to($title, 0.4, {y:0, ease:Cubic.easeInOut});
				TweenMax.to($bgBlock, 0.4, {y:0, ease:Cubic.easeInOut});
			},

			// ---------------------------------------------------------------------------------  /

		});

	return BottomImageLinkView;
});
