
define(
	'modules/big-hero/views/BigHeroView',[
		'backbone',
		'utils/AbstractView',
	], 
	function(Backbone, AbstractView){

		var BigHeroView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			options: null,
			abstractView: null,

			// init

			initialize: function(options){

				var self = this;

				self.options = options;
				self.$container = self.options.$container;
				self.el = self.options.el;

				//self.template = _.template(Template);
				self.model = $.extend({

				}, self.model);

				// 

				self.abstractView = new AbstractView({
					view: self,
					autoShow: true
				});

				$(window).on('resize', $.proxy(self._onWindowResize, self));
			},

			_onWindowResize: function(){

				var self = this;
				var winWidth = $(document).innerWidth();
				var winHeight = window.innerHeight;

				var galleryWidth = self.$el.width();
				var galleryHeight = Math.round(winHeight * 0.5);
				var ratio = galleryWidth / galleryHeight;

				if(self.$el.hasClass('full-width')){
					galleryWidth = winWidth;
				}

				if(ratio < 1.3){
					galleryHeight = Math.round(galleryWidth / 1.3);
				}
				else if(ratio > 2){
					galleryHeight = Math.round(galleryWidth / 2);
				}

				// set width / height

				TweenMax.set(self.$el, {height:galleryHeight});

				if(self.$el.hasClass('full-width')){
					TweenMax.set(self.$el, {width:galleryWidth});
				}
				else {
					TweenMax.set(self.$el, {clearProps:'width'});
				}
			},

		});

	return BigHeroView;
});
