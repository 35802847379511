define(
	'modules/bottom-image-links/app',[
		'backbone',
		'modules/bottom-image-links/views/BottomImageLinkView',
	], 
	function(Backbone, BottomImageLinkView){

		var AppView = Backbone.View.extend({

			// core vars

			$itemsContainer: null,

			items: null,
			tweenDelay: null,

			// init

			initialize: function(options){

				var self = this;
				window.$vent.trigger('bottomImageLinksReady');
			},

			// start module --------------------------------------------------------------------  /

			start: function(){

				var self = this;
				
				$(function(){
					self.onDomReady();
				});
			},

			onDomReady: function(){

				var self = this;
				self.$itemsContainer = $('.bottom-image-links');
				self.items = [];

				_.each(self.$itemsContainer, function(favoriteProperty, i){
					
					var $itemsContainer = $(favoriteProperty);
					var $items = $itemsContainer.find('.bottom-image-link');

					for(var i=0; i<$items.length; i++){

						var $favoriteProperty = $($items[i]);
						var bottomImageLinkView = new BottomImageLinkView({
							el: $favoriteProperty,
							$container: $itemsContainer
						});

						bottomImageLinkView.on('mouseenter', self._onMouseEnter, self);
						bottomImageLinkView.on('mouseleave', self._onMouseLeave, self);

						self.items.push(bottomImageLinkView);
					}
				});	

				self.$items = self.$itemsContainer.find('li');

				window.$vent.trigger('domUpdate', {view:self, type:'favorite-properties'});
			},

			_onMouseEnter: function(e){

				var self = this;
				var $target = e.target.$el;		
				
				if(!$target.hasClass('over')){
					
					$target.removeClass('over').addClass('over');

					_.each(self.items, function(item, i){			
						
						var $item = item.$el;

						if(!$item.hasClass('over') && window.innerWidth >= 768){

							var $veil = $item.find('.image-veil');						
							TweenMax.set($veil, {opacity:1});
							
							if(self.tweenDelay){ self.tweenDelay.kill(); }	

							$veil.removeClass('hidden');
							$item.addClass('over');
						}
					});
				}
				
			},

			_onMouseLeave: function(e){

				var self = this;		
				var $target = e.target.$el;		
				
				if($target.hasClass('over')){
					
					$target.removeClass('over');

					_.each(self.items, function(item, i){			
						
						var $item = item.$el;

						if($item.hasClass('over')){

							var $veil = $item.find('.image-veil');						
							TweenMax.set($veil, {opacity:0});
							
							if(self.tweenDelay){ self.tweenDelay.kill(); }

							self.tweenDelay = TweenMax.delayedCall(0.6, function(){
								$veil.removeClass('hidden').addClass('hidden');
							});

							$item.removeClass('over');
						}
					});
				}
			},
			
			// ---------------------------------------------------------------------------------  /

		});

		return AppView;
	}
);
