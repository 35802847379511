define(
    'pagecontrollers/property-listing/app',[
        'backbone',
        'pagecontrollers/property-listing/views/GridItemView',
        'pagecontrollers/property-listing/views/PaginationView',
        'text!../../config/propertyListingMessages-es.json',
        'text!../../config/propertyListingMessages-en.json',
        'utils/components/app/ScrollTriggeredElement',

        'utils/components/app/ScrollTriggeredElement',
        'moment',
        'jqueryhammer',
        'jquerymousewheel',
        'clndr',
        'selectboxit',
        'tagging',
        'rangeslider',
    ],
    function (Backbone, GridItemView, PaginationView, MessagesEs, MessagesEn) {

        var AppView = Backbone.View.extend({

            // vars

            winWidth: 0,
            winHeight: 0,
            scrollTop: 0,
            isSticky: false,
            lang: null,

            HEADER_HEIGHT: 60,
            MAX_THUMBS_PER_PAGE: 10,

            heightOffset: 0,

            scriptsArray: null,
            scriptsLoaded: 0,
            isReady: false,

            $contentWrapper: null,
            $gridContainer: null,
            $resultsGrid: null,
            $resultsText: null,
            $noResults: null,
            $contactModalBtn: null,

            gridItems: null,
            resizeTimeout: null,

            isQuerying: false,
            isPendingRefresh: false,

            paginationView: null,
            paginationIndex: 0,

            queryStartTime: 0,
            queryDelay: null,
            queryResult: null,
            filterQuery: null,
            listingsArray: null,

            scrollTriggeredElements: null,

            isTicked: false,
            isNoResults: false,

            messages: null,

            // initialize ----------------------------------------------------------------------  /

            initialize: function () {

                var self = this;
                self.$el = $('#property-listing-page');
                self.lang = $('#lang').text();

                window.$vent.trigger('propertyListingPageReady');
            },

            // start ---------------------------------------------------------------------------  /

            start: function () {

                var self = this;

                self.$contentWrapper = $('.pl-content-wrapper');
                self.$pageFooter = $('.bottom-hero-wrapper');

                self.paginationView = new PaginationView({
                    $container: self.$el.find('.pl-pagination-wrapper'),
                    lang: self.lang
                });

                self.scrollTriggeredElements = [];
                self.addListeners();

                $(function () {
                    self.loadScripts();
                    self.onDomReady();
                });
            },

            onDomReady: function () {

                var self = this;

                self.$lang = $('#lang').html();
                if (self.$lang = "es") {
                    self.messages = JSON.parse(MessagesEs);
                } else {
                    self.messages = JSON.parse(MessagesEn);
                }
            },

            // script loading

            loadScripts: function () {

                var self = this;
                self.scriptsArray = [];

                window.$vent.on('propertyFiltersReady', $.proxy(self._onScriptLoaded, self));

                self.checkScriptLoadStatus();
            },

            checkScriptLoadStatus: function () {

                var self = this;

                if (!self.isReady && (!self.scriptsArray.length || self.scriptsLoaded / self.scriptsArray.length === 1)) {
                    self.isReady = true;
                    self._onModulesLoaded();
                }
            },

            _onScriptLoaded: function () {

                var self = this;
                self.scriptsLoaded++;
                self.checkScriptLoadStatus();
            },

            _onModulesLoaded: function () {

                var self = this;
            },

            // listeners

            addListeners: function () {

                var self = this;

                self.$gridContainer = $('#prop-grid-container');
                self.$resultsGrid = $('#results-grid');
                self.$resultsText = self.$gridContainer.find('.grid-subtitle');
                self.$contactModalBtn = self.$el.find('.btn-contact');

                self.$contactModalBtn.on('click', $.proxy(self._onContactClick, self));

                window.$vent.on('paginate', $.proxy(self._onPaginate, self));
                window.$vent.on('filterResults', $.proxy(self._onFilterResults, self));
                window.$vent.on('openFiltersMenu', $.proxy(self._onOpenFiltersMenu, self));
                window.$vent.on('closeFiltersMenu', $.proxy(self._onCloseFiltersMenu, self));
                window.$vent.on('filterQueryUpdate', $.proxy(self._onFilterQueryUpdate, self));
                window.$vent.on('requestListings', $.proxy(self._onListingsRequest, self));
                window.$vent.on('paginateListings', $.proxy(self._onPaginateListings, self));
                window.$vent.on('paginationResults', $.proxy(self._onPaginationResults, self));
                window.$vent.on('listingsQueryComplete', $.proxy(self._onListingsQueryComplete, self));

                $(window).on('resize', $.proxy(self._onWindowResize, self));
                $(window).on('scroll', $.proxy(self._onScroll, self));
                $(window).mousewheel($.proxy(self._onMouseWheel, self));

                self._onWindowResize();
            },

            //
            _onContactClick: function (e) {
                alert("contactmodal");
                /*self.trigger('openModal', 'views/modals/ContactUsModal')
                 e.preventDefault();*/
            },

            _onPaginate: function (e, index) {

                var self = this;

                if (index != self.paginationIndex) {

                    var startIndex = index * self.MAX_THUMBS_PER_PAGE;
                    var endIndex = startIndex + self.MAX_THUMBS_PER_PAGE;

                    self.paginationIndex = index;
                    var currentUrl = window.location.search;
                    if (self.urlParam(currentUrl, 'page')) {
                        currentUrl = self.removeParam(currentUrl, 'page');
                    }

                    var newUrl = currentUrl + "&page=" + self.paginationIndex;
                    window.history.replaceState({}, "", newUrl);

                    window.$vent.trigger('paginateListings', {startIndex: startIndex, endIndex: endIndex});
                }

                window.$vent.trigger('seekToTop');
            },

            _onPaginateListings: function (e, params) {

                var self = this;
                var startIndex = params.startIndex;
                var endIndex = params.endIndex;

                console.log("_onPaginateListings");

                self.paginatedListings = [];

                for (var i = startIndex; i < endIndex; i++) {

                    var listing = self.inBoundsListings[i];
                    self.paginatedListings.push(listing);
                }

                window.$vent.trigger('paginationResults', [self.paginatedListings.concat(), self.isDraggingMap]);
            },

            _onFilterResults: function () {

                var self = this;
            },

            _onListingsQueryComplete: function (e, inBoundsListings, isDraggingMap) {

                var self = this;
                self.inBoundsListings = inBoundsListings;
                self.paginationIndex = 0;

                var page = self.urlParam(window.location.href, 'page');
                var pageRatio = self.inBoundsListings.length / self.MAX_THUMBS_PER_PAGE;
                if (page && pageRatio <= (page + 1)) {
                    self.paginationIndex = self.urlParam(window.location.href, 'page');
                } else {
                    if (self.urlParam(window.location.href, 'page')) {
                        var currentUrl = self.removeParam(window.location.search, 'page');
                        var newUrl = currentUrl + "&page=0";
                        window.history.replaceState({}, "", newUrl);
                    }
                }
                console.log('_onListingsQueryComplete', inBoundsListings);

                window.$vent.trigger('paginateListings', {startIndex: 0, endIndex: self.MAX_THUMBS_PER_PAGE});
            },

            _onPaginationResults: function (e, inBoundsListings, isDraggingMap) {

                var self = this;
                console.log('_onPaginationResults');

                if (isDraggingMap) {
                    self.prepListingsForRefresh();
                }
                else {
                    self.rebuildListingsGrid();
                }
            },

            _onUnchangedMapResults: function () {

                var self = this;
                console.log('_onUnchangedMapResults');
                self.restorePreppedListings();
            },

            _onOpenFiltersMenu: function () {

                var self = this;
                self.prepListingsForRefresh();
            },

            _onCloseFiltersMenu: function () {

                var self = this;
                self.restorePreppedListings();
            },

            _onFilterQueryUpdate: function (e, query) {
                console.log("------------- _onFilterQueryUpdate ---------------", query);
                var self = this;
                self.filterQuery = query;

                console.log("Query for filters ", query);
                self.reloadListings();
            },

            _onListingsRequest: function () {

                var self = this;

                if (self.listingsArray) {
                    window.$vent.trigger('listingsQueryComplete', [self.listingsArray]);
                }
            },

            // filter controls ---------------------------------------------------------------------  /

            reloadListings: function () {
                console.log("------------- reloadListings---------------");

                var self = this;
                var minTime = 1.6;

                if (!self.isQuerying) {

                    window.$vent.trigger('reloadListings');

                    self.isQuerying = true;
                    self.queryStartTime = (new Date()).getTime();

                    TweenMax.to(self.$resultsText, 0.3, {
                        opacity: 0, ease: Cubic.easeOut, onComplete: function () {
                            if (self.lang == "en") {
                                self.$resultsText.html('Searching...');
                            } else {
                                self.$resultsText.html('Buscando...');
                            }
                            TweenMax.fromTo(self.$resultsText, 0.3, {y: 6}, {y: 0, opacity: 1, ease: Sine.easeOut});
                        }
                    });

                    self.prepListingsForRefresh();

                    $.when($.proxy(self.queryListings, self)(self.filterQuery)).then(function (listingsArray) {

                        self.listingsArray = listingsArray;
                        // Get areas from properties
                        self.getPropertyAreas(self.listingsArray).then(function (areas) {
                            //Create a dropdown with the areas in filter section
                            console.log("The areas are: ", areas);
                            window.$vent.trigger('createAreaFilter', [areas, self]);
                            return areas;
                        }).then(function (areas) {
                            // Filter properties by area just in case the area param is in the array of areas
                            var area = Utils.DOMUtils.getTagsParameterFromString('areas', self.filterQuery);
                            if (area && areas) {
                                var areasFormat = areas.map(function(area){
                                   return area.toLowerCase().split(' ').join('-');
                                });
                                if ($.inArray(area, areasFormat) > -1) {
                                    self.listingsArray = self.filterPropertiesByArea(listingsArray, area);
                                }
                            }
                        }).then(function () {
                            //Show message results and results
                            if (self.listingsArray.length > 0) {
                                var d = Math.max((minTime - ((new Date()).getTime() - self.queryStartTime) / 1000), 0);
                                TweenMax.to(self.$resultsText, 0.3, {
                                    opacity: 0, ease: Sine.easeOut, delay: d, onComplete: function () {

                                        self.queryResult = 'We&#8217;ve found <span class="strong-class">' + listingsArray.length + '</span> Villas in Ibiza';
                                        self.$resultsText.html(self.queryResult);

                                        TweenMax.fromTo(self.$resultsText, 0.3, {y: 6}, {
                                            y: 0,
                                            opacity: 1,
                                            ease: Sine.easeOut
                                        });
                                    }
                                });

                            }
                            /* Hide apply params */
                            window.$vent.trigger('hideApplyFiltersButton');
                            self.isQuerying = false;
                            window.$vent.trigger('listingsQueryComplete', [self.listingsArray]);
                        });

                    }, function (listingsArray) {
                        self.listingsArray = listingsArray;
                        console.log("Error en el API");
                    });

                }
            },

            queryListings: function () {

                var self = this;
                var dfd = $.Deferred();
                var queryString = (self.filterQuery) ? '?' + self.filterQuery : '';
                queryString = '/propertyListing/propertyListingResults' + queryString;

                console.log('queryListings', queryString);

                $.ajax({

                    type: 'GET',
                    url: queryString,
                    async: true,
                    jsonpCallback: 'callBack',
                    contentType: 'application/json',
                    dataType: 'jsonp',

                    success: function (json) {
                        var listingsArray = json.results;
                        dfd.resolve(listingsArray);
                    },
                    error: function (e) {
                        console.log('JSON Load Error', self);
                        console.log(e.message);
                        listingsArray = {};
                        dfd.reject(listingsArray);
                    }
                });

                return dfd.promise();
            },

            //

            prepListingsForRefresh: function () {

                var self = this;

                if (!self.isPendingRefresh) {

                    self.isPendingRefresh = true;
                    self.paginationView.hide();

                    _.each(self.gridItems, function (gridItem, i) {
                        gridItem.showVeil();
                    });
                }
            },

            restorePreppedListings: function () {

                var self = this;

                if (self.isPendingRefresh) {

                    self.isPendingRefresh = false;
                    self.paginationView.show();

                    _.each(self.gridItems, function (gridItem, i) {
                        gridItem.hideVeil();
                    });
                }
            },

            // rebuild grid

            rebuildListingsGrid: function () {

                var self = this;

                if (!self.inBoundsListings.length) {
                    self.showNoResults();
                }
                else {

                    if (self.gridItems && self.gridItems.length) {

                        // validate pagination bounds

                        if (self.paginationIndex < 0) {
                            self.paginationIndex = 0;
                        }
                        else if (self.paginationIndex * self.MAX_THUMBS_PER_PAGE > self.inBoundsListings.length) {
                            self.paginationIndex = parseInt(self.inBoundsListings.length / self.MAX_THUMBS_PER_PAGE);
                        }

                        // remove existing items

                        self.removeListingsGrid(true);
                        self.isPendingRefresh = true;
                    }
                    else {
                        self.createListingsGrid(self.inBoundsListings);
                    }
                }
            },

            removeListingsGrid: function (rebuild) {

                var self = this;

                if (self.gridItems && self.gridItems.length) {

                    TweenMax.to(self.$resultsText, 0.3, {opacity: 0, ease: Sine.easeOut});

                    for (var i = 0; i < self.gridItems.length; i++) {

                        var gridItem = self.gridItems[i];

                        if (i === self.gridItems.length - 1) {

                            gridItem.on('hideComplete', function () {

                                TweenMax.delayedCall(0.3, function () {

                                    gridItem.exitImmediately();
                                    self.$resultsGrid.find('.desktop_clear').remove();

                                    if (rebuild === true) {
                                        self.createListingsGrid(self.inBoundsListings);
                                    }
                                });
                            });
                        }

                        gridItem.hide();
                    }
                }
            },

            createListingsGrid: function () {

                var self = this;

                self.gridItems = [];
                self.$resultsGrid.empty();

                var startIndex = self.paginationIndex * self.MAX_THUMBS_PER_PAGE;
                var endIndex = Math.min(startIndex + self.MAX_THUMBS_PER_PAGE, self.inBoundsListings.length);
                var count = 0;

                if (!endIndex) {
                    self.showNoResults();
                }
                else {

                    $.when($.proxy(self.hideNoResults, self)()).then(function () {

                        var visibleListings = [];

                        for (var i = startIndex; i < endIndex; i++) {

                            var listing = self.inBoundsListings[i];
                            listing.location = self.urlParam(window.location.href, 'location') ? self.urlParam(window.location.href, 'location') : '';
                            //listing.checkIn = self.urlParam(window.location.href, 'checkIn') && self.urlParam(window.location.href, 'checkIn') != 0 ? self.urlParam(window.location.href, 'checkIn') : '';
                            //listing.checkOut = self.urlParam(window.location.href, 'checkOut') && self.urlParam(window.location.href, 'checkOut') != 0 ? self.urlParam(window.location.href, 'checkOut') : '';
                            //listing.guests = self.urlParam(window.location.href, 'guests') ? self.urlParam(window.location.href, 'guests') : '';
                            listing.tags = self.urlParam(window.location.href, 'tags') ? self.urlParam(window.location.href, 'tags') : '';
                            listing.page = self.urlParam(window.location.href, 'page') ? self.urlParam(window.location.href, 'page') : 0;
                            listing.sort = self.urlParam(window.location.href, 'sort') ? self.urlParam(window.location.href, 'sort') : 0;

                            var gridItem = new GridItemView({
                                $container: self.$resultsGrid,
                                model: listing,
                                index: count,
                            });

                            gridItem.show();
                            self.gridItems.push(gridItem);

                            visibleListings.push(listing);
                            count++;
                        }

                        // refresh listing grid

                        if (self.inBoundsListings.length % 2 !== 0) {
                            self.$resultsGrid.append('<li class="desktop_clear"></li>');
                        }


                        TweenMax.killTweensOf(self.$resultsText);
                        TweenMax.to(self.$resultsText, 0.3, {
                            opacity: 0, ease: Sine.easeOut, onComplete: function () {

                                console.log("currentLocationRaw", currentLocationRaw);
                                var currentLocationRaw = (window.vars.currentLocation) ? window.vars.currentLocation.split("(") : '';
                                var currentLocation = currentLocationRaw[0];

                                if (!currentLocationRaw) {
                                    if (self.lang == "en") {
                                        self.queryResult = "We've found <span class='strong-class'>" + self.inBoundsListings.length + "</span> Villas";
                                    } else {
                                        self.queryResult = "Hemos encontrado <span class='strong-class'>" + self.inBoundsListings.length + "</span> Villas";
                                    }
                                } else {
                                    if (self.lang == "en") {
                                        self.queryResult = "We've found <span class='strong-class'>" + self.inBoundsListings.length + "</span> Villas for " + Utils.StringUtils.toFormalCase(currentLocation);
                                    } else {
                                        self.queryResult = 'Hemos encontrado <span class="strong-class">' + self.inBoundsListings.length + '</span> Villas para ' + Utils.StringUtils.toFormalCase(currentLocation);
                                    }

                                }

                                self.$resultsText.html(self.queryResult);

                                TweenMax.fromTo(self.$resultsText, 0.3, {y: 6}, {y: 0, opacity: 1, ease: Sine.easeOut});
                            }
                        });

                        //
                        window.$vent.trigger('mapIgnoreResize', true);
                        window.$vent.trigger('domUpdate');

                        var currentUrl = window.location.search;
                        if (self.urlParam(currentUrl, 'page')) {
                            self.paginationIndex = self.urlParam(currentUrl, 'page');
                            currentUrl = self.removeParam(currentUrl, 'page');
                        }

                        var newUrl = currentUrl + "&page=" + self.paginationIndex;
                        window.history.replaceState({}, "", newUrl);
                        self.paginationView.rebuild(self.paginationIndex, self.inBoundsListings.length, self.MAX_THUMBS_PER_PAGE);
                        self._onWindowResize();

                        self.isQuerying = false;
                        self.isPendingRefresh = false;

                    });
                }

                window.$vent.trigger('domUpdate');
            },

            showNoResults: function () {

                var self = this;

                if (!self.$noResults) {

                    window.$vent.trigger('seekToTop');

                    $('#results-grid').removeClass('hide').addClass('hide');
                    $('#summary-and-filter-row').removeClass('hide').addClass('hide');
                    $('#pagination-wrapper').removeClass('hide').addClass('hide');
                    $('#mobile-bottom-hero').removeClass('hide').addClass('hide');

                    var text = self.messages.searchListing.noPropertiesFound;

                    self.$noResults = $('#no-results-container').html(text);
                    TweenMax.fromTo(self.$noResults, 0.4, {opacity: 0}, {opacity: 1, ease: Cubic.easeOut});

                    window.$vent.trigger('domUpdate');
                }
            },

            hideNoResults: function () {

                var self = this;
                var dfd = $.Deferred();

                if (!self.$noResults) {
                    dfd.resolve();
                }
                else {

                    window.$vent.trigger('seekToTop');

                    TweenMax.to(self.$noResults, 0.4, {
                        opacity: 0, ease: Cubic.easeOut, onComplete: function () {

                            /* self.$noResults.remove();*/
                            self.$noResults = null;

                            $('#no-results-container').html();
                            $('#results-grid').removeClass('hide');
                            $('#summary-and-filter-row').removeClass('hide');
                            $('#pagination-wrapper').removeClass('hide');
                            $('#mobile-bottom-hero').removeClass('hide');

                            window.$vent.trigger('domUpdate');

                            dfd.resolve();

                        }
                    });
                }

                return dfd.promise();
            },

            getPropertyAreas: function (listingsArray) {
                var self = this;
                return new Promise(function (resolve) {
                    var allAreasArray = $.map(listingsArray, function (prop, i) {
                        if (prop.area !== "" && prop.area != null) {
                            return prop.area;
                        }
                    });
                    var noRepeatedAreasArray = $.unique(allAreasArray);
                    resolve(noRepeatedAreasArray);
                });
            },

            filterPropertiesByArea: function (listingsArray, area) {
                var self = this;
                var propertiesFiltered = listingsArray.filter(function(property){
                    if(property.area){
                        return property.area.toLowerCase().split(' ').join('-') == area ;
                    }
                });

                return propertiesFiltered;

            },

            // check map boundaries

            checkMapBounds: function () {

                var self = this;
                window.$vent.trigger('resizeMap');
            },

            // scroll --------------------------------------------------------------------------  /

            _onMouseWheel: function (e) {

                var self = this;
                self.scrollTick();
            },

            _onScroll: function () {

                var self = this;
                self.scrollTick();
            },

            scrollTick: function () {

                var self = this;

                if (!self.isTicked) {
                    self.isTicked = true;
                    window.requestAnimationFrame(self.scrollNow.bind(self));
                }
            },

            scrollNow: function (e) {

                var self = this;
                self.scrollTop = $(window).scrollTop();

                var availableScreenHeight = Math.min(self.winHeight, self.docHeight - self.footerHeight - self.scrollTop);
                var yPos = 0 - self.scrollTop;

                if (self.lastYPos != yPos) {

                }

                self.isTicked = false;
            },

            _onWindowResize: function (e) {

                var self = this;
                var lastWidth = self.winWidth;
                var lastHeight = self.winHeight;

                self.winWidth = window.innerWidth;
                self.winHeight = window.innerHeight;
                self.docHeight = $(document).outerHeight();
                self.footerHeight = self.docHeight - self.$pageFooter.offset().top;

                self._onScroll();

                var $leftContent = $('.property-listing-inner');
                var leftContentWidth = $leftContent.offset().left + $leftContent.outerWidth();

                TweenMax.set(self.$contentWrapper, {
                    minHeight: self.winHeight,
                });
            },
            //Function to get params from the url
            urlParam: function (url, name) {
                var results = new RegExp('[?&]' + name + '=([^&#]*)').exec(url);
                if (results) {
                    return results[1]
                } else {
                    return 0
                }
            },

            removeParam: function (url, parameter) {
                return url
                    .replace(new RegExp('[?&]' + parameter + '=[^&#]*(#.*)?$'), '$1')
                    .replace(new RegExp('([?&])' + parameter + '=[^&]*&'), '$1');
            }

        });

        return AppView;
    }
);

