
define(
	'modules/global-scripted-buttons/views/EventTriggerButtonView',[
		'backbone',
		'utils/AbstractView',
		'utils/AbstractButtonView',
	], 
	function(Backbone, AbstractView, AbstractButtonView){

		var EventTriggerButtonView = Backbone.View.extend({

			// core vars

			$el: null,
			$container: null,
			options: null,
			abstractView: null,
			button: null,

			// vars

			$targetContainer: null,
			targetClass: null,
			targetId: null,

			selectedEvent: null,
			deselectedEvent: null,

			matchThisSelectionState: false,
			reverseThisSelectionState: false,

			isWaitingForDeselect: false,

			// init

			initialize: function(options){

				var self = this;

				self.options = options;
				self.$container = self.options.$container;
				self.matchThisSelectionState = (self.options.matchThisSelectionState === true);
				self.reverseThisSelectionState = (self.options.reverseThisSelectionState === true);

				// 

				self.abstractView = new AbstractView({
					view: self,
					autoShow: true
				});

				self.button = new AbstractButtonView({
					abstractView: self.abstractView,
					animateInteractionsWhenSelected: true,
					selectable: true,
				});
			},

			// render

			onRender: function(){

				var self = this;
			},

			//

			onShow: function(){

				var self = this;

				if(self.$el.data('container-id')){
					self.$targetContainer = $('#' + self.$el.data('container-id'));
				}
				else {
					self.$targetContainer = $('body');
				}

				self.targetClass = self.$el.data('target-class') || '';
				self.targetId = self.$el.data('target-id') || '';
				self.selectedEvent = self.$el.data('selected-event') || '';
				self.deselectedEvent = self.$el.data('deselected-event') || '';

				self.matchThisSelectionState = !!self.$el.data('match-selected-state') || false;
				self.reverseThisSelectionState = !!self.$el.data('reverse-selected-state') || false;

				if(self.$el.hasClass('selected')){
					self.select();
				}

				self.trigger('showComplete');
			},

			//

			onAnimateClick: function(){

				var self = this;

				if(self.targetId){
					self.triggerTarget(self.$targetContainer.find('#' + self.targetId));
				}

				if(self.targetClass){

					var $targets = self.$targetContainer.find('.' + self.targetClass);

					_.each($targets, function(target, i){
						var $target = $(target);
						self.triggerTarget($target);
					});
				}
			},

			triggerTarget: function($target){

				var self = this;

				console.log("triggerTarget", $target, self.selectedEvent, self.deselectedEvent);
				
				if(self.reverseThisSelectionState){

					// if this is selected, fire the deselected event

					if(self.button.isSelected){ 
						if(self.selectedEvent){
							$target.trigger(self.selectedEvent, {target:self});
						}
					}
					else { 
						if(self.deselectedEvent){
							$target.trigger(self.deselectedEvent, {target:self});
						}
					}
				}
				else {
						
					// if this is selected, fire the selected event

					if(!self.button.isSelected){ 
						if(self.selectedEvent){
							$target.trigger(self.selectedEvent, {target:self});
						}
					}
					else { 
						if(self.deselectedEvent){
							$target.trigger(self.deselectedEvent, {target:self});
						}
					}
				}
			},

			// ---------------------------------------------------------------------------------  /

		});

	return EventTriggerButtonView;
});
