define(
		'modules/reservation-flow/app',[
			'backbone',
			'selectboxit'
		],
		function (Backbone) {

			var AppView = Backbone.View.extend({
				// core vars

				// vars

				$context: null,
				$contentArea: null,
				$sidebar: null,
				$pageFooter: null,
				winHeight: null,
				docHeight: null,
				sidebarHeight: null,
				footerHeight: null,
				scrollTop: null,
				$currentSection: null,
				currentSection: null,
				stepIndex: 0,
				lastYPos: 0,
				reservationParams: {}, isTicked: false,
				clientType: null,
				//Horrible hack
				resId: null,
				rewriteUserInfo: null,
				firstName: null,
				lastName: null,
				email: null,
				phoneAreaCode: null,
				phone: null,
				email2: null,
				phoneAreaCode2: null,
				phone2: null,
				country: null,
				addressStreet: null,
				addressNumber: null,
				addressFloor: null,
				city: null,
				state: null,
				region: null,
				zip: null,
				docType: null,
				docNumber: null,
				occupantLanguage: null,
				occupantFirstName: null,
				occupantLastName: null,
				occupantEmail: null,
				occupantPhoneAreaCode: null,
				occupantPhone: null,
				// init

				initialize: function (options) {

					var self = this;
					self.$context = $;
					self.reservationParams = {};

					window.$vent.trigger('reservationFlowReady');
				},
				// start module --------------------------------------------------------------------  /

				start: function () {

					var self = this;

					$(function () {
						self.onDomReady();
					});
				},
				onDomReady: function () {

					var self = this;

					self.$contentArea = self.$el.find('#content-area');
					self.$sidebar = self.$el.find('#reservation-sidebar');
					self.$pageFooter = self.$el.find('#page-footer');
					self.clientType = self.$el.find('#client-type').val();
					console.log("Client Type: " + self.clientType);

					//

					$(window).on('resize', $.proxy(self._onWindowResize, self));
					$(window).on('scroll', $.proxy(self._onScroll, self));
					$(window).mousewheel($.proxy(self._onMouseWheel, self));

					window.$vent.on('stickyGoBack', $.proxy(self._stickyGoBack, self));

					self._onWindowResize();

					//

					self.initIntro();
				},
				// check position - should bar be fixed or absolute

				checkPosition: function () {

					var self = this;

				},
				// recalculate costs

				recalculate: function () {

					var self = this;
				},
				// steps ---------------------------------------------------------------------------  /

				initIntro: function () {

					var self = this;

					var $welcomeBlock = self.$contentArea.find('.welcome-block');
					$welcomeBlock.removeClass('hide');


					var $userButton = self.$contentArea.find('.btn-selectable.user');
					var $clientButton = self.$contentArea.find('.btn-selectable.client');

					if (self.clientType == "Particular") {
						self.reservationParams.isUser = true;
						self.initStep1('user');
					} else if (self.clientType == "Empresa") {
						self.reservationParams.isUser = false;
						self.initStep1('client');
					}

					window.$vent.trigger('seekToTop');
				},
				endIntro: function () {

					var self = this;
				},
				// step 1 --------------------------------------------------------------------------  /

				initStep1: function (userType) {

					var self = this;

					if (self.stepIndex != 1) {

						self.stepIndex = 1;

						var section1FormTemplate = _.template($('#reservation-step-1-template').html());

						var $welcomeBlock = self.$contentArea.find('.welcome-block');
						$welcomeBlock.removeClass('hide');

						var $section1Container = self.$contentArea.find('.res-step1');
						var $section1FormContainer = $section1Container.find('.rs-step-container');
						var $section1FadedContainer = $section1Container.find('.res-faded-step');
						var $section1FormTemplate = window.$rootContext(section1FormTemplate(self.reservationParams));

						$section1Container.removeClass('disabled');
						$section1FormTemplate.find('select').selectBoxIt({
							isMobile: function () {
								return false;
							},
							autoWidth: false
						}); // custom forms
						$section1FormContainer.html($section1FormTemplate); // add to screen
						$section1FadedContainer.empty();

						//$section1FormTemplate.find('.btn-submit').on('click', $.proxy(self._onStep1SubmitClick, self));
						window.$vent.on('step1FormSubmitted', $.proxy(self._onStep1ValidationSuccess, self));
						$section1FormTemplate.find('#add_another_language').on('click', $.proxy(self._onStep1AddLanguage, self));
						$section1FormTemplate.find('#add_another_cp').on('click', $.proxy(self._onStep1AddChildrenPreference, self));
						$section1FormTemplate.find('.same-occupant-checkbox').on('click', $.proxy(self._onToggleSameOccupant, self));

						self._onWindowResize();

						window.$vent.trigger('domUpdate');

						if (self.$highchair) {
							$('[name="high_chair"]').each(function (index) {
								if (self.$highchair[index] == true) {
									$(this).prop('checked', true);
								}
							});
						}

						if (self.$crib) {
							$('[name="crib"]').each(function (index) {
								if (self.$crib[index] == true) {
									$(this).prop('checked', true);
								}
							});
						}

						// on change if country is spain use select box else use textbox
						var selectBoxCountry = $("#select-country");
						var regionSelectInput = $("#region-select-input");
						var regionTextInput = $("#region-text-input");

						selectBoxCountry.on("change", function () {
							console.log("country changed");
							if ($(this).val() == "73") {
								regionSelectInput.removeClass('hide');
								regionTextInput.addClass('hide');
							} else {
								regionTextInput.removeClass('hide');
								regionSelectInput.addClass('hide');
							}

						});

						window.$vent.trigger('navSeekToAnchor', {target: $('#sticky-nav')[0], anchorId: 'guests'});
					} else {

						$useProfileDetailsCheckbox = self.$el.find('.use-profile-details-checkbox');

						if (userType === 'user') {
							$useProfileDetailsCheckbox.removeClass('hide');
						} else {
							$useProfileDetailsCheckbox.removeClass('hide').addClass('hide');
						}
					}
				},

				_onToggleSameOccupant: function (e) {
					var self = this;

					var sameOccupantChecked = $("input[type='checkbox'][name='same_occupant']:checked");
					var prefix_select = $("[name='occupant_prefix']");
					var doc_type_select = $("[name='occupant_doc_type']");
					var country_select = $("[name='occupant_select_country']");

					if (sameOccupantChecked.length > 0) {
						$("[name='occupant_first_name']").val($('[name="first_name"]').val());
						$("[name='occupant_last_name']").val($('[name="last_name"]').val());
						$("[name='occupant_email']").val($('[name="email"]').val());
						$("[name='occupant_prefix']").val($('[name="prefix"]').val());
						$("[name='occupant_phone_number']").val($('[name="phone_number"]').val());
						$("[name='occupant_doc_type']").val($('[name="doc_type"]').val());
						$("[name='occupant_doc_number']").val($('[name="doc_number"]').val());
						$("[name='occupant_select_country']").val($('[name="select_country"]').val());
						prefix_select.data("selectBox-selectBoxIt").refresh();
						doc_type_select.data("selectBox-selectBoxIt").refresh();
						country_select.data("selectBox-selectBoxIt").refresh();
					} else {
						$("[name='occupant_first_name']").val('');
						$("[name='occupant_last_name']").val('');
						$("[name='occupant_email']").val('');
						$("[name='occupant_phone_number']").val('');
						$("[name='occupant_doc_type']").val('');
						$("[name='occupant_doc_number']").val('');
						$("[name='occupant_select_country']").val('');
						prefix_select.data("selectBox-selectBoxIt").selectOption(0);
						doc_type_select.data("selectBox-selectBoxIt").selectOption(0);
						country_select.data("selectBox-selectBoxIt").selectOption(0);
					}
				},
				storeFormStep1: function(form) {
					var self = this;

					if (form.length) {
						var userProfileCheckbox = $("input[type='checkbox'][name='prof_details']:checked");
						//self.rewriteUserInfo = userProfileCheckbox.val();
						self.rewriteUserInfo = 1;

						self.firstName = $('[name="first_name"]').val();
						self.lastName = $('[name="last_name"]').val();
						self.email = $('[name="email"]').val();
						self.phoneAreaCode = $('[name="prefix"]').val();
						self.phone = $('[name="phone_number"]').val();
						self.email2 = $('[name="email2"]').val();
						self.phoneAreaCode2 = $('[name="prefix_2"]').val();
						self.phone2 = $('[name="phone_number_2"]').val();
						self.country = $('[name="select_country"]').val();
						self.addressCountryName = $('#select-country option:selected').html();
						self.addressStreet = $('[name="address_street"]').val();
						self.addressNumber = $('[name="address_number"]').val();
						self.addressFloor = $('[name="address_floor"]').val();
						self.city = $('[name="address_city"]').val();
						if (self.country == '73') {
							self.region = $('[name="state_text"]').val();
						} else {
							self.region = $('[name="state_select"]').val();
						}
						self.zip = $('[name="address_zip"]').val();
						self.docType = $('[name="doc_type"]').val();
						self.docNumber = $('[name="doc_number"]').val();
						self.billingType = $('[name="billing_type"]').val();

						self.occupantFirstName = $('[name="occupant_first_name"]').val();
						self.occupantLastName = $('[name="occupant_last_name"]').val();
						self.occupantEmail = $('[name="occupant_email"]').val();
						self.occupantPhoneAreaCode = $('[name="occupant_prefix"]').val();
						self.occupantPhone = $('[name="occupant_phone_number"]').val();
						self.occupantCountry = $('[name="occupant_select_country"]').val();
						self.occupantCountryName = $('[name="occupant_select_country"] :selected').text();;
						self.occupantDocNumber = $('[name="occupant_doc_number"]').val();
						self.occupantDocType = $('[name="occupant_doc_type"]').val();

						console.log('storing form1 values', self);

						var high_chairs = 0;
						var cribs = 0;
						self.highchairs = 0;
						self.cribs = 0;
						$('[name="high_chair"]').each(function (index) {
							if ($(this).prop('checked') == true) {
								self.highchairs += 1;
								//self.$highchair[index] = true;
							}
						});
						$('[name="crib"]').each(function (index) {
							if ($(this).prop('checked') == true) {
								self.cribs += 1;
								//self.$crib[index] = true;
							}
						});

					}

				},
				_onStep1ValidationSuccess: function (e,data) {

					var self = this;
					console.log('_onStep1ValidationSuccess');
					var $rsFormWrapper = self.$contentArea.find('.res-step1 .rs-form-wrapper');
					$rsFormWrapper.removeClass('processing').addClass('processing');
					var form = $("#reservation-step-1-form:visible");
					var trackObject = {
						'trackAction':'book',
						'trackCategory':'Book Step 2',
						'propReference':$('.prop-title').text().trim(),
						'propLocation': $('.prop-location').text().trim(),
						'checkin': $('.pdark.details .checkIn').text().trim(),
						'checkout': $('.pdark.details .checkOut').text().trim(),
						'guests': $('.guests span').text()
					}

					self.trackEventUser(e,trackObject);

					self.storeFormStep1(form);

					console.log('validation success data', data);
					console.log('results', data.message);
					if (data.message) {
						self.userInfo = data.message;
					}
					window.$vent.trigger('hideSpinner');

					self.endStep1();
					self.initStep3();

					$rsFormWrapper.removeClass('processing');
				},
				_onStep1ValidationFail: function (e) {

					console.log('_onStep1ValidationFail');

					var self = this;
					var $rsFormWrapper = self.$contentArea.find('.res-step1 .rs-form-wrapper');
					$rsFormWrapper.removeClass('processing');

					window.$vent.trigger('navSeekToAnchor', {target: $('#sticky-nav')[0], anchorId: 'guests'});
				},
				_onStep1EditClick: function (e) {

					var self = this;
					self.endCurrentStep();
					self.initStep1();
				},
				_onStep1AddLanguage: function () {

					var self = this;
					var template = _.template($('#add-languale-template').html());
					var $addLanguageContainer = $('.add-language-container');

					$addLanguageContainer.html(template);
					$addLanguageContainer.find('#add-language').on('click', $.proxy(self._onAddLanguageClick, self));

					TweenMax.to($addLanguageContainer, 0, {'display': 'block'});
					TweenMax.to($addLanguageContainer, 0.4, {'opacity': 1, ease: Sine.easeIn});
				},
				_onAddLanguageClick: function () {

					var self = this;
					var newLanguage = $('.add-language-container').find('input').val();
					var $langDropdown = $('#spoken_language');
					var $addLanguageContainer = $('.add-language-container');

					$langDropdown.append($('<option>', {
						value: newLanguage,
						text: newLanguage
					}));

					$('.spoken_language').html($langDropdown);
					$langDropdown.selectBoxIt({
						isMobile: function () {
							return false;
						},
						autoWidth: false
					});

					TweenMax.to($addLanguageContainer, 0.4, {
						'opacity': 0, ease: Sine.easeIn, onComplete: function () {
							TweenMax.to($addLanguageContainer, 0, {'display': 'block'});
							$addLanguageContainer.html('');
						}
					});
				},
				_onStep1AddChildrenPreference: function () {

					var self = this;
					var template = _.template($('#children-preferences-template').html());
					var row = $('<div class="row children-row"></div>');

					row.html(template);
					row.find('select').selectBoxIt({
						isMobile: function () {
							return false;
						},
						autoWidth: false
					});
					$('.children_options .children-row').last().after(row);
				},
				endStep1: function () {
					var self = this;

					self.off('validationSuccess', self._onStep1ValidationSuccess, self);
					self.off('validationFail', self._onStep1ValidationSuccess, self);

					var high_chairs = 0;
					var cribs = 0;
					self.$highchair = [];
					self.$crib = [];
					$('[name="high_chair"]').each(function (index) {
						if ($(this).prop('checked') == true) {
							high_chairs += 1;
							self.$highchair[index] = true;
						}
					});
					$('[name="crib"]').each(function (index) {
						if ($(this).prop('checked') == true) {
							cribs += 1;
							self.$crib[index] = true;
						}
					});

					var $section1Container = self.$contentArea.find('.res-step1');
					var $section1FormContainer = $section1Container.find('.rs-step-container');
					var $section1FadedContainer = $section1Container.find('.res-faded-step');
					var $section1FormTemplate = window.$rootContext($('#reservation-step-1-faded-template').html());

					console.log("load faded template of step 1");
					$section1Container.removeClass('disabled').addClass('disabled');
					$section1FadedContainer.html($section1FormTemplate);
					$section1FormContainer.empty();

					$('#numCribs').html(cribs);
					$('#numHighChairs').html(high_chairs);

					$section1FormTemplate.find('.guest-details .rsf-edit').on('click', $.proxy(self._onStep1EditClick, self));
					$section1FormTemplate.find('.occupant-info .rsf-edit').on('click', $.proxy(self._onStep1EditClick, self));

					var $welcomeBlock = self.$contentArea.find('.welcome-block');
					$welcomeBlock.removeClass('hide').addClass('hide');
				},
				// step 2 --------------------------------------------------------------------------  /

				initStep2: function (userType) {
					var self = this;

					if (self.stepIndex != 2) {

						self.stepIndex = 2;

						var section2FormTemplate = _.template($('#reservation-step-2-template').html());

						var $section2Container = self.$contentArea.find('.res-step2');
						var $section2FormContainer = $section2Container.find('.rs-step-container');
						var $section2FadedContainer = $section2Container.find('.res-faded-step');
						var $section2FormTemplate = window.$rootContext(section2FormTemplate(self.reservationParams));

						$section2Container.removeClass('disabled');
						$section2FormTemplate.find('select').selectBoxIt({
							isMobile: function () {
								return false;
							},
							autoWidth: false
						}); // custom forms
						$section2FormContainer.html($section2FormTemplate); // add to screen
						$section2FadedContainer.empty();

						$section2FormTemplate.find('.btn-submit').on('click', $.proxy(self._onStep2SubmitClick, self));
						$section2FormTemplate.find('#btn-add-airport-transfer').on('click', $.proxy(self._onAirportTransferClick, self));

						self._onWindowResize();

						window.$vent.trigger('domUpdate');
						window.$vent.trigger('navSeekToAnchor', {target: $('#sticky-nav')[0], anchorId: 'payment-review'});

						self.$contentArea.find('#credit-btns a').on('click', $.proxy(self._onStep3SelectPaymentType, self));
					}
				},
				_onAirportTransferClick: function (e) {

					var self = this;
					var $target = $(e.currentTarget);

					if (window.innerWidth < 768) {

						if ($target.hasClass('selected')) {
						} else {
							window.$vent.trigger('switchModal', $target.data('modal-id'));
						}
					} else {

						if ($target.hasClass('selected')) {
							$('#step2-form').find('.collapsible-content').removeClass('hide').addClass('hide');
						} else {
							$('#step2-form').find('.collapsible-content').removeClass('hide');
						}
					}
				},
				_onStep2SubmitClick: function (e) {

					var self = this;
					var $rsFormWrapper = self.$contentArea.find('.res-step2 .rs-form-wrapper');
					$rsFormWrapper.removeClass('processing').addClass('processing');

					self.on('validationSuccess', self._onStep2ValidationSuccess, self);
					self.on('validationFail', self._onStep2ValidationFail, self);

					self.validateForm();
				},
				_onStep2ValidationSuccess: function (e) {

					var self = this;
					var $rsFormWrapper = self.$contentArea.find('.res-step2 .rs-form-wrapper');

					self.endStep2();
					self.initStep3();

					$rsFormWrapper.removeClass('processing');
				},
				_onStep2ValidationFail: function (e) {

					var self = this;
					var $rsFormWrapper = self.$contentArea.find('.res-step2 .rs-form-wrapper');
					$rsFormWrapper.removeClass('processing');

					window.$vent.trigger('navSeekToAnchor', {target: $('#sticky-nav')[0], anchorId: 'upgrades'});
				},
				_onStep2EditClick: function (e) {

					var self = this;
					self.endCurrentStep();
					self.initStep2();
				},
				endStep2: function () {

					var self = this;

					var $section2Container = self.$contentArea.find('.res-step2');
					var $section2FormContainer = $section2Container.find('.rs-step-container');
					var $section2FadedContainer = $section2Container.find('.res-faded-step');
					var $section2FormTemplate = window.$rootContext($('#reservation-step-2-faded-template').html());

					$section2Container.removeClass('disabled').addClass('disabled');
					$section2FadedContainer.html($section2FormTemplate);
					$section2FormContainer.empty();

					$section2FormTemplate.find('.airport-transfer .rsf-edit').on('click', $.proxy(self._onStep2EditClick, self));
				},
				// step 3 --------------------------------------------------------------------------  /

				initStep3: function (userType) {
					var self = this;
					console.log("init step 3");

					if (self.stepIndex != 3) {

						self.stepIndex = 3;

						var section3FormTemplate = _.template($('#reservation-step-3-template').html());

						var $section3Container = self.$contentArea.find('.res-step3');
						var $section3FormContainer = $section3Container.find('.rs-step-container');
						var $section3FadedContainer = $section3Container.find('.res-faded-step');
						var $section3FormTemplate = window.$rootContext(section3FormTemplate(self.reservationParams));

						$section3Container.removeClass('disabled');
						$section3FormTemplate.find('select').selectBoxIt({
							isMobile: function () {
								return false;
							},
							autoWidth: false
						}); // custom forms
						$section3FormContainer.html($section3FormTemplate); // add to screen
						$section3FadedContainer.empty();

						//$section3FormTemplate.find('#complete-payment-card').on('click', $.proxy(self._onStep3SubmitClick, self));
						window.$vent.on('step3FormSubmitted', $.proxy(self._onStep3ValidationSuccess, self));
						self.$contentArea.find('#credit-btns a').on('click', $.proxy(self._onStep3SelectPaymentType, self));
						self.$contentArea.find('#personal-info .rounded-checkbox').on('click', $.proxy(self._onStep3SelectAddressType, self));

						self.$contentArea.find('#agree-terms').on('click', $.proxy(self._onStep3AgreeTerms, self));

						self._onWindowResize();

						window.$vent.trigger('domUpdate');
						window.$vent.trigger('navSeekToAnchor', {target: $('#sticky-nav')[0], anchorId: 'payment'});

						// on change if country is spain use select box else use textbox
						var selectBoxCountry = $("#select-country");
						var regionSelectInput = $("#region-select-input");
						var regionTextInput = $("#region-text-input");

						selectBoxCountry.on("change", function () {
							console.log("country changed");
							if ($(this).val() == "73") {
								regionSelectInput.removeClass('hide');
								regionTextInput.addClass('hide');
							} else {
								regionTextInput.removeClass('hide');
								regionSelectInput.addClass('hide');
							}

						});

						console.log('getting userInfo', self.userInfo);
						self.restoreBillingAddress();

						$('#firstName').html(self.firstName);
						$('#lastName').html(self.lastName);
						$('#email').html(self.email);
						$('#phoneAreaCode').html(self.phoneAreaCode);
						$('#phone').html(self.phone);
						$('#phoneAreaCode2').html(self.phoneAreaCode2);
						$('#phone2').html(self.phone2);
						$('#email2').html(self.email2);
						$('#addressCountryName').html(self.addressCountryName);
						$('#addressStreet').html(self.addressStreet);
						$('#addressNumber').html(self.addressNumber);
						$('#addressFloorLetter').html(self.addressFloor);
						$('#addressCity').html(self.city);
						$('#addressRegion').html(self.region);
						$('#addressPostCode').html(self.zip);
						$('#documentType').html(self.docType);
						$('#documentNumber').html(self.docNumber);

						$('#occupantCountryName').html(self.occupantCountryName);
						$('#occupantFirstName').html(self.occupantFirstName);
						$('#occupantLastName').html(self.occupantLastName);
						$('#occupantEmail').html(self.occupantEmail);
						$('#occupantPhoneAreaCode').html(self.occupantPhoneAreaCode);
						$('#occupantPhone').html(self.occupantPhone);
						$("#occupantDocNumber").html(self.occupantDocNumber);
						$("#occupantDocType").html(self.occupantDocType);

						$('#occupantDocNumberForm').val(self.occupantDocNumber);
						$('#occupantDocTypeForm').val(self.occupantDocType);
						$('#occupantFirstNameForm').val(self.occupantFirstName);
						$('#occupantLastNameForm').val(self.occupantLastName);
						$('#occupantEmailForm').val(self.occupantEmail);
						$('#occupantPhoneAreaCodeForm').val(self.occupantPhoneAreaCode);
						$('#occupantPhoneForm').val(self.occupantPhone);
						$('#occupantCountryForm').val(self.occupantCountry);

						var clientType = self.userInfo.clientType;
						var billingType = self.userInfo.userClientBillingInfo.billingType;
						if (billingType == 'Empresa') {
							var $firstInput = $('.first-changing-input');
							var $secondInput = $('.second-changing-input');
							var $thirdInput = $('.third-changing-input');
							var $infoBox = $('#company-address-info');

							$firstInput.attr('placeholder', 'Company Name');
							$secondInput.attr('placeholder', 'VAT Number');
							$thirdInput.addClass('hide');

							$("[name='doc_type']").prop('disabled', true);
							$("[name='doc_number']").prop('disabled', true);

							TweenMax.set($infoBox, {'display': 'block'});
							TweenMax.to($infoBox, 0.4, {'opacity': 1, ease: Sine.easeIn});

							if (!self.userInfo.userClientBillingInfo.billingFirstName) {
								$firstInput.val('');
							}
							if (!self.userInfo.userClientBillingInfo.billingDocNumber) {
								$secondInput.val('');
							}
							self.setVAT('Empresa');
						} else {
							self.setVAT('Particular');
						}

						var cribs = $('#numCribs').text();
						var high_chairs = $('#numHighChairs').text();

						$('#form-cribs').val(cribs);
						$('#form-high-chairs').val(high_chairs);

					}

				},
				_onStep3AgreeTerms: function (e) {
					var self = this;
					var paymentButton = $('.complete-payment');

					if (paymentButton.hasClass('disabled')) {
						paymentButton.removeClass('disabled');
					} else {
						paymentButton.addClass('disabled');
					}

				},
				_onStep3ValidationSuccess: function (e,data) {

					var self = this;
					var $rsFormWrapper = self.$contentArea.find('.res-step3 .rs-form-wrapper');
					console.log('validation success data', data);
					console.log('urlRedirect', data.urlRedirect);
					if (data.message == 'card') {
						var trackObject={
							'trackAction':'book',
							'trackCategory':'Book Step 3 (card)',
							propReference:$('.prop-title').text().trim(),
							'propLocation': $('.prop-location').text().trim(),
							'checkin': $('.pdark.details .checkIn').text().trim(),
							'checkout': $('.pdark.details .checkOut').text().trim(),
							'guests': $('.guests span').text()
						}
						self.trackEventUser(e,trackObject);
						var form = $("#formhidden");
						if (data.paymentGateway) {
							form.attr("action", data.paymentGateway.endPoint);
							form.find("[name='Ds_MerchantParameters']").val(data.paymentGateway.merchantParameters);
							form.find("[name='Ds_Signature']").val(data.paymentGateway.signature);
							console.log("Ds_MerchantParameters", data.paymentGateway.merchantParameters);
							console.log("paymentGateway", data.paymentGateway);
						}
						console.log('submit to pasarela de pagos');
						form.submit();
					} else {
						if (data.urlRedirect) {
							var trackObject={
								'trackAction':'book',
								'trackCategory':'Book Step 3 (transfer)',
								propReference:$('.prop-title').text().trim(),
								'propLocation': $('.prop-location').text().trim(),
								'checkin': $('.pdark.details .checkIn').text().trim(),
								'checkout': $('.pdark.details .checkOut').text().trim(),
								'guests': $('.guests span').text()
							}
							self.trackEventUser(e,trackObject);
							window.location.href = data.urlRedirect;
						}
					}

					$rsFormWrapper.removeClass('processing');
				},
				_onStep3ValidationFail: function (e) {

					var self = this;
					var $rsFormWrapper = self.$contentArea.find('.res-step3 .rs-form-wrapper');
					$rsFormWrapper.removeClass('processing');

					window.$vent.trigger('navSeekToAnchor', {target: $('#sticky-nav')[0], anchorId: 'payment'});
				},
				_onStep3EditClick: function (e) {

					var self = this;
					self.endCurrentStep();
					self.initStep3();
				},
				_onStep3SelectPaymentType: function (e) {

					var self = this;
					var $target = $(e.currentTarget);
					var $buttons = self.$contentArea.find('#credit-btns a');
					var $infoBox = self.$contentArea.find('.bank-transfer-info');

					$buttons.removeClass('selected');
					$target.addClass('selected');

					if (!$target.data('showinfo')) {
						self.bankTransfer = false;
						$('#bank_transfer').val('false');
						//$('#complete-payment-bt').removeClass('show').addClass('hide');
						//$('#complete-payment-card').addClass('show').removeClass('hide');
					} else {
						self.bankTransfer = true;
						$('#bank_transfer').val('true');
						//$('#complete-payment-card').removeClass('show').addClass('hide');
						//$('#complete-payment-bt').addClass('show').removeClass('hide');
					}

					self.showBankInfo($target, $infoBox);
				},
				_onStep3SelectAddressType: function (e) {

					var self = this;
					var $target = $(e.currentTarget);
					var $infoBox = $('#company-address-info');

					self.restoreBillingAddress($target.attr('data-newAddress'));

					$("[name='personal_info']").removeAttr('checked');
					$target.find(".checkbox-input").attr("checked", true).prop("checked", true);

					self.showVAT($target, $infoBox);
				},

				restoreBillingAddress:function (new_address){
					var self = this;
					var regionSelectInput = $("#region-select-input");
					var regionTextInput = $("#region-text-input");
					if (new_address == 'true') {
						regionTextInput.removeClass('hide');
						regionSelectInput.addClass('hide');
						self.selectOption($("select#doc_type"),'');
						self.selectOption($("select#select-country"),'');
						self.selectOption($("select#region-select-input"),'');
						$("[name='first_name']").val("");
						$("[name='last_name']").val("");
						$("[name='doc_number']").val("");
						$("[name='address_street']").val("");
						$("[name='address_number']").val("");
						$("[name='address_floor']").val("");
						$("[name='address_city']").val("");
						$("[name='state_text']").val("");
						$("[name='address_zip']").val("");
					} else {
						var billingFirstName = self.userInfo.userClientBillingInfo.billingFirstName ? self.userInfo.userClientBillingInfo.billingFirstName : self.userInfo.firstName;
						var billingLastName = self.userInfo.userClientBillingInfo.billingLastName ? self.userInfo.userClientBillingInfo.billingLastName : self.userInfo.lastName;
						var billingDocNumber = self.userInfo.userClientBillingInfo.billingDocNumber ? self.userInfo.userClientBillingInfo.billingDocNumber : self.userInfo.documentNumber;
						var billingDocType = self.userInfo.userClientBillingInfo.billingDocType ? self.userInfo.userClientBillingInfo.billingDocType : self.userInfo.documentType;
						var billingAddressCountry = self.userInfo.userClientBillingInfo.billingAddressCountry ? self.userInfo.userClientBillingInfo.billingAddressCountry : self.userInfo.addressCountry;
						var billingAddressStreet = self.userInfo.userClientBillingInfo.billingAddressStreet ? self.userInfo.userClientBillingInfo.billingAddressStreet : self.userInfo.addressStreet;
						var billingAddressNumber = self.userInfo.userClientBillingInfo.billingAddressNumber ? self.userInfo.userClientBillingInfo.billingAddressNumber : self.userInfo.addressNumber;
						var billingAddressFloorLetter = self.userInfo.userClientBillingInfo.billingAddressFloorLetter ? self.userInfo.userClientBillingInfo.billingAddressFloorLetter : self.userInfo.addressFloorLetter;
						var billingAddressCity = self.userInfo.userClientBillingInfo.billingAddressCity ? self.userInfo.userClientBillingInfo.billingAddressCity : self.userInfo.addressCity;
						var billingAddressRegionCode = self.userInfo.userClientBillingInfo.billingAddressRegionCode ? self.userInfo.userClientBillingInfo.billingAddressRegionCode : self.userInfo.addressRegionCode;
						var billingAddressRegion = self.userInfo.userClientBillingInfo.billingAddressRegion ? self.userInfo.userClientBillingInfo.billingAddressRegion : self.userInfo.addressRegion;
						var billingAddressPostCode = self.userInfo.userClientBillingInfo.billingAddressPostCode ? self.userInfo.userClientBillingInfo.billingAddressPostCode : self.userInfo.addressPostCode;

						self.selectOption($("select#doc_type"), billingDocType);
						self.selectOption($("select#select-country"), billingAddressCountry);
						self.selectOption($("select#region-select-input"), billingAddressRegionCode);
						$("[name='first_name']").val(billingFirstName);
						if (self.userInfo.userClientBillingInfo.billingType == 'Empresa' && self.userInfo.userClientBillingInfo.billingDocNumber) {
							$("[name='last_name']").val(billingDocNumber);
						} else {
							$("[name='last_name']").val(billingLastName);
						}
						$("[name='doc_number']").val(billingDocNumber);
						$("[name='address_street']").val(billingAddressStreet);
						$("[name='address_number']").val(billingAddressNumber);
						$("[name='address_floor']").val(billingAddressFloorLetter);
						$("[name='address_city']").val(billingAddressCity);
						$("[name='state_text']").val(billingAddressRegion);
						$("[name='address_zip']").val(billingAddressPostCode);

						// on change if country is spain use select box else use textbox
						if (billingAddressCountry == "73") {
							regionSelectInput.removeClass('hide');
							regionTextInput.addClass('hide');
						} else {
							regionTextInput.removeClass('hide');
							regionSelectInput.addClass('hide');
						}
					}

				},
				setVAT: function(type){
					var self = this;

					var ivaParticular = self.$sidebar.find('.iva-particular');
					var ivaSubtotalParticular = self.$sidebar.find('.iva-subtotal-particular');
					var ivaDueTodayParticular = self.$sidebar.find('.duetoday-particular');
					var ivaDueNextParticular = self.$sidebar.find('.duenext-particular');
					var ivaBookingFeeParticular = self.$sidebar.find('.bookingfee-particular');
					var totalParticular = self.$sidebar.find('.total-particular');
					var paymentTotalParticular = self.$sidebar.find('.duetoday-particular-raw').text();
					var ivaEmpresa = self.$sidebar.find('.iva-empresa');
					var ivaSubtotalEmpresa = self.$sidebar.find('.iva-subtotal-empresa');
					var ivaDueTodayEmpresa = self.$sidebar.find('.duetoday-empresa');
					var ivaDueNextEmpresa = self.$sidebar.find('.duenext-empresa');
					var ivaBookingFeeEmpresa = self.$sidebar.find('.bookingfee-empresa');
					var totalEmpresa = self.$sidebar.find('.total-empresa');
					var paymentTotalEmpresa = self.$sidebar.find('.duetoday-empresa-raw').text();

					if (type == 'Empresa') {
						ivaParticular.removeClass('hide').addClass('hide');
						ivaSubtotalParticular.removeClass('hide').addClass('hide');
						ivaDueTodayParticular.removeClass('hide').addClass('hide');
						ivaDueNextParticular.removeClass('hide').addClass('hide');
						ivaBookingFeeParticular.removeClass('hide').addClass('hide');
						totalParticular.removeClass('hide').addClass('hide');
						ivaEmpresa.removeClass('hide');
						ivaSubtotalEmpresa.removeClass('hide');
						ivaDueTodayEmpresa.removeClass('hide');
						ivaDueNextEmpresa.removeClass('hide');
						ivaBookingFeeEmpresa.removeClass('hide');
						totalEmpresa.removeClass('hide');
						$('#payment_total').val(paymentTotalEmpresa);
					} else {
						ivaParticular.removeClass('hide');
						ivaSubtotalParticular.removeClass('hide');
						ivaDueTodayParticular.removeClass('hide');
						ivaDueNextParticular.removeClass('hide');
						ivaBookingFeeParticular.removeClass('hide');
						totalParticular.removeClass('hide');
						ivaEmpresa.removeClass('hide').addClass('hide');
						ivaSubtotalEmpresa.removeClass('hide').addClass('hide');
						ivaDueTodayEmpresa.removeClass('hide').addClass('hide');
						ivaDueNextEmpresa.removeClass('hide').addClass('hide');
						ivaBookingFeeEmpresa.removeClass('hide').addClass('hide');
						totalEmpresa.removeClass('hide').addClass('hide');
						$('#payment_total').val(paymentTotalParticular);
					}

				},
				selectOption: function(select, value){
					select.data("selectBox-selectBoxIt").selectOption(value);
				},
				showVAT: function ($target, $infoBox) {

					var self = this;

					var $firstInput = $('.first-changing-input');
					var $secondInput = $('.second-changing-input');
					var $thirdInput = $('.third-changing-input');
					var $bankCards = $(".bank-cards-form");
					var $billingBlock = $(".billing-block");
					var $paymentButton = $(".payment-btn");

					if ($target.attr('data-showinfo') == 'true') {

						$firstInput.attr('placeholder', 'Company Name');
						$secondInput.attr('placeholder', 'VAT Number');
						$thirdInput.addClass('hide');
						$("[name='doc_type']").prop('disabled', true);
						$("[name='doc_number']").prop('disabled', true);
						//$("[name='personal_info']").val(3);

						TweenMax.set($infoBox, {'display': 'block'});
						TweenMax.to($infoBox, 0.4, {'opacity': 1, ease: Sine.easeIn});

						/*TweenMax.to($bankCards, 0.4, {'opacity':0, ease:Sine.easeIn, onComplete:function () {
						 TweenMax.set($bankCards, {'display': 'none'});
						 }});

						 TweenMax.to($billingBlock, 0.4, {'opacity':0, ease:Sine.easeIn, onComplete:function () {
						 TweenMax.set($billingBlock, {'display': 'none'});
						 }});*/

						$paymentButton.attr("href", $target.data('page'));

						self.setVAT('Empresa');

					} else {

						$("[name='doc_type']").prop('disabled', false);
						$("[name='doc_number']").prop('disabled', false);

						TweenMax.to($infoBox, 0.4, {
							'opacity': 0, ease: Sine.easeIn, onComplete: function () {
								TweenMax.set($infoBox, {'display': 'none'});
							}
						});

						/*TweenMax.set($bankCards, {'display':'block'});
						 TweenMax.to($bankCards, 0.4, {'opacity':1, ease:Sine.easeIn});

						 TweenMax.set($billingBlock, {'display':'block'});
						 TweenMax.to($billingBlock, 0.4, {'opacity':1, ease:Sine.easeIn});*/
						$thirdInput.removeClass('hide');
						$firstInput.attr('placeholder', 'First Name');
						$secondInput.attr('placeholder', 'Last Name');
						$paymentButton.attr("href", $paymentButton.data('page'));

						self.setVAT('Particular');
					}
				},
				showBankInfo: function ($target, $infoBox) {

					var self = this;
					var $bankCards = $(".bank-cards");
					var $completePayment = $('.complete-payment');
					var $completePaymentM = $('.complete-payment-m');

					if ($target.data('showinfo') == true) {

						//TweenMax.set($infoBox, {'display': 'block'});
						//TweenMax.to($infoBox, 0.4, {'opacity': 1, ease: Sine.easeIn});

						TweenMax.to($bankCards, 0, {
							'opacity': 0, ease: Sine.easeIn, onComplete: function () {
								TweenMax.set($bankCards, {'display': 'none'});
							}
						});

						$completePayment.attr('href', $completePayment.data('conf-bank'));
						$completePaymentM.attr('href', $completePaymentM.data('conf-bank'));
					} else {

						//TweenMax.to($infoBox, 0.4, {'opacity': 0, ease: Sine.easeIn, onComplete: function () {
						//        TweenMax.set($infoBox, {'display': 'none'});
						//    }});

						TweenMax.set($bankCards, {'display': 'block'});
						TweenMax.to($bankCards, 0, {'opacity': 1, ease: Sine.easeIn});

						$completePayment.attr('href', $completePayment.data('conf-def'));
						$completePaymentM.attr('href', $completePaymentM.data('conf-def'));
					}
				},
				showInfoBox: function ($target, $infoBox) {

					var self = this;

					var $firstInput = $('.first-changing-input');
					var $secondInput = $('.second-changing-input');


					if ($target.data('showinfo') == true) {

						$firstInput.attr('placeholder', 'Company Name');
						$secondInput.attr('placeholder', 'VAT Number');
						TweenMax.set($infoBox, {'display': 'block'});
						TweenMax.to($infoBox, 0.4, {'opacity': 1, ease: Sine.easeIn});

					} else {

						TweenMax.to($infoBox, 0.4, {
							'opacity': 0, ease: Sine.easeIn, onComplete: function () {
								TweenMax.set($infoBox, {'display': 'none'});
							}
						});

						$firstInput.attr('placeholder', 'First Name');
						$secondInput.attr('placeholder', 'Last Name');
					}
					if ($target.data('reset') == true) {
						$('#address-form').find('input:text').val('');
					}
				},
				endStep3: function () {

					var self = this;

					var $section3Container = self.$contentArea.find('.res-step3');
					var $section3FormContainer = $section3Container.find('.rs-step-container');
					var $section3FadedContainer = $section3Container.find('.res-faded-step');
					var $section3FormTemplate = window.$rootContext($('#reservation-step-3-faded-template').html());

					$section3Container.removeClass('disabled').addClass('disabled');
					$section3FadedContainer.html($section3FormTemplate);
					$section3FormContainer.empty();

					$section3FormTemplate.find('.payment-details .rsf-edit').on('click', $.proxy(self._onStep3EditClick, self));
				},
				//

				endCurrentStep: function () {

					var self = this;

					switch (self.stepIndex) {

						case 1:
							self.endStep1();
							break;

						case 2:
							self.endStep2();
							break;

						case 3:
							self.endStep3();
							break;
					}
				},
				// server back-end validation query ------------------------------------------------  /
				validateForm: function () {

					var self = this;
					window.$vent.on('validationResponse', $.proxy(self._onValidationResponse, self));

					TweenMax.delayedCall(1, function () {
						window.$vent.trigger('validationResponse', {valid: true});
					});
				},
				_onValidationResponse: function (e, response) {

					var self = this;
					console.log("on validation response entered");

					window.$vent.off('validationResponse', $.proxy(self._onValidationResponse, self));

					if (response.valid) {
						self.trigger('validationSuccess');
					} else {
						self.trigger('validationFail');
					}
				},
				// scroll --------------------------------------------------------------------------  /

				_onMouseWheel: function (e) {

					var self = this;
					self.scrollTick();
				},
				_onScroll: function () {

					var self = this;
					self.scrollTick();
				},
				scrollTick: function () {

					var self = this;

					if (!self.isTicked) {
						self.isTicked = true;
						window.requestAnimationFrame(self.scrollNow.bind(self));
					}
				},
				scrollNow: function () {

					var self = this;
					self.scrollTop = $(document).scrollTop();

					self.checkPosition();
					self.isTicked = false;
				},
				// window resize -------------------------------------------------------------------  /

				_onWindowResize: function () {

					var self = this;

					self.winHeight = window.innerHeight;
					self.docHeight = $(document).outerHeight();
					self.sidebarHeight = self.$sidebar.outerHeight();
					self.footerHeight = self.$pageFooter.outerHeight();

					TweenMax.set(self.$contentArea, {
						'min-height': self.sidebarHeight
					});

					window.$vent.trigger('reservationContentResize');

					self.scrollTick();
					self.checkPosition();
				},
				_stickyGoBack: function (e, stepIndex) {

					var self = this;

					switch (stepIndex) {
						case 0:
							self._onStep1EditClick();
							break;
						case 1:
							self._onStep2EditClick();
							break;
					}
				},

				// ---------------------------------------------------------------------------------  /
				trackEventUser: function(e, data) {
					var self = this;

					var trackAction = data ? data.trackAction : '';
					var propReference = data.propReference ? data.propReference : '';
					var checkin = data.checkin ? data.checkin : '';
					var checkout = data.checkout ? data.checkout : '';
					var guests = data.guests ? data.guests : '';
					var trackCategory = data && data.trackCategory ? data.trackCategory : 'Reservation Search';

					console.log("trackAction", trackAction);
					console.log("propReference", propReference);
					console.log("checkin", checkin);
					console.log("checkout", checkout);
					console.log("guests", guests);
					console.log("trackCategory", trackCategory);

					window.dataLayer.push({
						'event': 'track',
						'trackAction': trackAction,
						'trackCategory': trackCategory,
						'trackLabel': {
							'propReference': propReference,
							'propLocation': '',
							'checkIn': checkin,
							'checkOut': checkout,
							'guests': guests
						}
					});
				}

			});

			return AppView;
		}
);

